import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUser } from '../../../services/provider/authProvider';
import {
  setAuthInfo,
  setForceAuthInfo,
} from '../../../services/redux/auth/auth.action';

function UserManager({ children }) {
  const dispatch = useDispatch();
  const { user, forceUpdate } = useSelector((state) => state.auth);

  const getUser = async () => {
    const currentUser = await fetchUser(forceUpdate);

    if (!isEmpty(currentUser)) {
      dispatch(setAuthInfo(currentUser));
    }

    if (forceUpdate) {
      dispatch(setForceAuthInfo(false));
    }
  };

  useEffect(() => {
    if (forceUpdate) {
      getUser();
    }
  }, [forceUpdate]);

  useEffect(() => {
    if (isEmpty(user)) {
      getUser();
    }
  }, [user]);

  return children;
}

export default UserManager;
