import PropTypes from 'prop-types';
import React from 'react';

const TestableContainer = ({
  children,
  nfid,
  nfcolspan,
  className,
  ...props
}) => {
  return (
    <div
      nfid={nfid}
      className={
        !className
          ? `nf-colspan-${nfcolspan}`
          : `${className} nfcolspan-${nfcolspan}`
      }
    >
      {React.Children.map(children, (child) =>
        React.cloneElement(child, {
          ...child.props,
          ...props,
        }),
      )}
    </div>
  );
};

TestableContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  nfcolspan: PropTypes.number,
  nfid: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

TestableContainer.defaultProps = {
  nfid: undefined,
  nfcolspan: undefined,
  className: undefined,
};

export default TestableContainer;
