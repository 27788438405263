import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  item: {
    width: '100%',
    borderRadius: 10,
    border: '1px solid #645DB3',
    marginBottom: theme.spacing(4),
    padding: theme.spacing(3, 4),
    transition: 'all 0.15s ease-in',
  },

  itemSelected: {
    backgroundColor: '#F1F2FA',
  },

  inner: {
    display: 'flex',
    gap: theme.spacing(4),
    alignItems: 'center',
  },
  image: {
    width: '30%',
    maxWidth: 150,
    aspectRatio: 1 / 0.7,
    objectFit: 'contain',
  },
  header: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center',
  },

  txtName: {
    fontSize: 16,
    fontFamily: 'QuickSandBold',
    color: '#131313',
  },

  txtSeater: {
    fontSize: 14,
    fontFamily: 'QuickSandBold',
    color: '#131313',
  },

  dot: {
    display: 'block',
    width: 3,
    height: 3,
    backgroundColor: '#131313',
    borderRadius: '100%',
  },

  infoContainer: {
    width: '100%',
  },

  detailContainer: {
    display: 'flex',
    marginTop: theme.spacing(2),
    justifyContent: 'space-between',
  },

  detailItem: {
    display: 'flex',
    gap: theme.spacing(1.5),
  },

  divide: {
    display: 'block',
    width: 1,
    backgroundColor: '#f2f2f2',
  },

  txtDetail: {
    fontSize: 12,
    fontFamily: 'QuickSandBold',
    color: '#131313',
  },
}));

export default useStyles;
