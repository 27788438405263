import { Box, Icon, Menu, MenuItem } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslate } from 'react-admin';
import CtaItem from './CtaItem';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiMenuItem-root': {
      fontSize: '13px',
      lineHeight: '19.5px',
      paddingTop: 8,
      paddingBottom: 8,
      color: 'rgb(51, 60, 68)',
    },
    '& .MuiSvgIcon-root, & .material-icons': {
      fontSize: '20px',
      color: 'rgba(0, 0, 0, 0.54)',
    },
  },
}));

const CmtDropdownMenu = ({ TriggerComponent, items, onItemClick }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const translate = useTranslate();

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (item) => {
    if (item.onClick && typeof item.onClick === 'function') {
      item.onClick(item);
    } else if (onItemClick && typeof onItemClick === 'function') {
      onItemClick(item);
    }

    closeMenu();
  };

  return (
    <>
      <Box className="pointer">
        <TriggerComponent.type {...TriggerComponent.props} onClick={openMenu} />
      </Box>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        className={classes.root}
      >
        {items.map((item, index) =>
          item.dto ? (
            <CtaItem key={index} item={item} onClick={closeMenu} />
          ) : (
            <MenuItem
              key={index}
              disabled={item.disabled}
              onClick={() =>
                handleMenuItemClick(
                  {
                    ...item,
                  },
                  index,
                )
              }
            >
              {typeof item.icon === 'string' ? (
                <Icon sx={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '20px' }}>
                  {item.icon}
                </Icon>
              ) : (
                item.icon
              )}
              <Box ml={2}>{translate(item.label)}</Box>
            </MenuItem>
          ),
        )}
      </Menu>
    </>
  );
};

CmtDropdownMenu.propTypes = {
  items: PropTypes.array.isRequired,
  TriggerComponent: PropTypes.element.isRequired,
  onItemClick: PropTypes.func,
};

export default CmtDropdownMenu;
