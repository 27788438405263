import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  error: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  image: {
    width: '80%',
    marginTop: theme.spacing(3),
    maxWidth: 414,
  },

  title: {
    fontFamily: 'QuickSandBold',
    fontSize: 34,
    color: '#041D55',
    lineHeight: 1.4,
  },

  message: {
    fontFamily: 'QuickSandRegular',
    fontSize: 18,
    lineHeight: 1.5,
    textAlign: 'center',
    padding: theme.spacing(2, 3),
  },
}));

export default useStyles;
