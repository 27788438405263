import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

const useStyles = makeStyles(() => ({
  root: {},
}));

export const withStandardButtonProps = (WrappedComponent) => (props) => {
  const classes = useStyles();
  const {
    // eslint-disable-next-line
    mutationMode,
    // eslint-disable-next-line
    validating,
    // eslint-disable-next-line
    alwaysOn,
    ...rest
  } = props;

  return (
    <WrappedComponent
      className={classes.root}
      size="medium"
      variant="contained"
      validating={validating || undefined}
      {...rest}
    />
  );
};
