import withLabel from '../../hoc/with-input-label';
import OriginDateInput from './date';
import LocationTimeInput from './location-time';
import NumberGuestInput from './number-of-guest';
import NumberLuggagesInput from './number-of-luggages';
import PassengerNameInput from './passenger-name';
import PaymentInput from './payment';
import PhoneInput from './phone';
import OriginTimeInput from './time';
import TotalTimeBookingInput from './total-time-booking';
import FlightNameInput from './flight';

const DateInput = withLabel(OriginDateInput);
const TimeInput = withLabel(OriginTimeInput);

export {
  DateInput,
  LocationTimeInput,
  NumberGuestInput,
  NumberLuggagesInput,
  PassengerNameInput,
  PaymentInput,
  PhoneInput,
  TimeInput,
  TotalTimeBookingInput,
  FlightNameInput,
};
