export const ColorStatus = {
  ASSIGNING_DRIVER: '#ffc23a',
  ONGOING: '#2490ef',
  PENDING: '#ffc23a',
  CANCELED: '#ff3030',
  DRIVER_ASSIGNED: '#2490ef',
  COMPLETED: '#2490ef',
  PICKINGUP: '#2490ef',
  PICKUP: '#2490ef',
  REJECTED: '#2490ef',
};
export const StatusDefault = {
  ASSIGNING_DRIVER: 'ASSIGNING_DRIVER',
  ONGOING: 'ONGOING',
  PENDING: 'PENDING',
  CANCELED: 'CANCELED',
  DRIVER_ASSIGNED: 'DRIVER_ASSIGNED',
  COMPLETED: 'COMPLETED',
  PICKINGUP: 'PICKINGUP',
  PICKUP: 'PICKUP',
  REJECTED: 'REJECTED',
};
export function replaceSpaceUpercaseString(text) {
  if (!text) {
    return null;
  }

  const str = text?.split(' ').join('_');
  return str.toUpperCase();
}
