import { useSelector, useDispatch } from 'react-redux';
import { toggleLoading as toggleLoadingAction, setPercentLoading as setPercentLoadingAction } from '../../services/redux/app/app.actions';

const useLoading = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(({ app }) => app?.isLoading);
  const percentLoading = useSelector(({ app }) => app?.percentLoading);
  const toggleLoading = visible => {
    dispatch(toggleLoadingAction(visible));
  };
  const setPercentLoading = number => {
    dispatch(setPercentLoadingAction(number));
  };
  return {
    isLoading,
    percentLoading,
    toggleLoading,
    setPercentLoading,
  };
};

export default useLoading;
