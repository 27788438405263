import { genUUID } from '../helpers';

export const MAP_ID = 'p-bk-map';
export const ERROR_PATH = '/p/event/online-fail';

export const NUMBER_OF_DROPOFF = 1;
export const MAX_OF_DROPOFF = 2;

export const FORM_FIELD_NAMES = {
  PICK_UP: 'pickup',
  DROP_OFF: 'dropoff',
  DATE: 'date',
  TIME: 'time',
  HISTORY_DATE: 'historyDate',
  HISTORY_TIME: 'historyTime',
  TYPE: 'type',
  PAYMENT: 'payment',
  PHONE: 'phoneNumber',
  TOTAL_TIME_BOOKING: 'totalHours',
  CHILD_BOOSTER_SEAT: 'childBoosterSeat',
  PASSENGER_NAME: 'fullName',
  NUMBER_OF_GUEST: 'numberOfGuest',
  NUMBER_OF_LUGGAGES: 'numberOfLuggages',
  NUMBER_OF_FLIGHT: 'flightNumber',
};

export const PAYMENT_VALUES = {
  CASH: 'cash',
  ONLINE: 'online',
};

export const SUBJOB_TYPE_NAMES = {
  PREMIUM: 'Valet service',
  STANDARD: 'Book a ride',
};

export const SUBJOB_TYPE_DISPLAY_NAMES = {
  PREMIUM: '5 Seater',
  STANDARD: '7 Seater (Noah/Serena)',
};

export const SUBJOB_TYPES = {
  PREMIUM: genUUID('sub_job_type', SUBJOB_TYPE_NAMES.PREMIUM),
  STANDARD: genUUID('sub_job_type', SUBJOB_TYPE_NAMES.STANDARD),
};

export const EVENTS = {
  DROPOFF_LOCATION: 'eventDropOff',
  SECOND_DROPOFF_LOCATION: 'eventSecondDropoff',
  PICKUP_LOCATION: 'eventPickUp',
  DROPOFF_LOCATION_CLICK: 'eventDropOffClick',
  SECOND_DROPOFF_LOCATION_CLICK: 'eventSecondDropOffClick',
  PICKUP_LOCATION_CLICK: 'eventPickUPClick',
  INCREASE_DROPOFF_LOCATION_CLICK: 'eventIncreaseDropOffClick',

  DECREASE_DROPOFF_LOCATION: 'eventDecreaseDropOff',
  CURRENT_DROPOFF_LOCATION: 'eventCurrentDropOff',

  ON_CHANGE_BOOSTER_SEAT: 'onChangeBoosterSeat',
  SELECT_AREA: 'selectArea',
  ON_CHANGE_TOTAL_TIME_BOOKING: 'onChangeTotalTimeBooking',
  RESET_DATE_TIME: 'resetDateTime',
  ROLLBACK_DATE_TIME: 'rollbackDateTime',
};

export const LOCATIONS = {
  MY_LOCATION: 'eventMyLocation',
};

export const JOB_TYPE = {
  STANDARD: 'STANDARD',
  PREMIUM: 'PREMIUM',
};

export const SUB_JOB_TYPE = {
  POINT2POINT: 'POINT2POINT',
  HOURLY: 'HOURLY',
  FIXED_PRICE: 'FIXED_PRICE',
};

export const HOURLY_TOTAL_BOOKING_TIME_MIN = 2;
export const NUMBER_OF_GUEST_MIN = 1;
export const NUMBER_OF_GUEST_MAX = 6;
export const NUMBER_OF_LUGGAGE_MIN = 0;
export const NUMBER_OF_LUGGAGE_MAX = 6;
export const MAX_CAPACITY = 6;

export const BAD_REQUEST_MSG =
  'This area is currently not supported. Kindly select another location.';
