import { Box, Typography, Button, IconButton, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import React, { useCallback, useRef, useState, useEffect } from 'react';
import { useTranslate } from 'react-admin';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    position: 'relative',
    '& .MuiFormControl-root': {
      margin: 0,
      padding: 0,
    },
    '& .MuiFilledInput-root': {
      backgroundColor: 'transparent',
    },
    '& input': {
      backgroundColor: 'transparent',
    },
    '& textarea': {
      backgroundColor: '#efefefe0',
      minHeight: '58px !important',
      maxHeight: '108px !important',
    },
    '& input, & textarea': {
      padding: 0,
      borderRadius: 3,
      border: '2px solid transparent',
    },

    '& .MuiFormHelperText-root': {
      display: 'none',
    },

    '& .Mui-focused': {
      '& input': {
        padding: '0 12px !important',
      },
      '& textarea, & input': {
        border: `2px solid ${theme.palette.primary.main}`,
        backgroundColor: '#fff !important',
      },
    },
  },

  noTruncate: {
    '& .MuiFilledInput-root': {
      padding: 0,
    },

    '& textarea': {
      backgroundColor: 'transparent',
      minHeight: 'auto !important',
      maxHeight: 'none !important',
    },
  },

  oneLine: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    '& .MuiButton-root': {
      marginBottom: 0,
      marginTop: 0,
    },
  },

  toolEditor: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },

  saveInline: {
    display: 'flex',
    borderRadius: 4,

    position: 'absolute',
    right: 0,
    bottom: '-80%',
    zIndex: 10,

    [theme.breakpoints.down(600)]: {
      bottom: '-100%',
    },
  },

  inlineButton: {
    boxShadow:
      'rgb(25 39 52 / 5%) 0px 1px 2px 0px, rgb(25 39 52 / 10%) 0px 0px 4px 0px',
    padding: 3,

    borderRadius: 6,
    transition: 'transform 0.1s ease-in',
    backgroundColor: '#f4f5f7',

    '&:hover': {
      backgroundColor: '#f4f5f7',
    },
    '&:active': {
      transform: 'translateY(2px)',
    },
    '& svg': {
      fontSize: '18px !important',
    },

    [theme.breakpoints.down(600)]: {
      '& svg': {
        fontSize: '24px !important',
      },
    },
  },
  buttonGroup: {
    display: 'flex',
    flexDirection: 'row',
  },

  saveButton: {
    backgroundColor: theme.palette.primary.main,
    padding: '5px 10px',
    color: '#fff',
    fontWeight: 'bold',
    marginLeft: 0,

    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },

  saveButtonMobile: {
    marginLeft: theme.spacing(1),
  },
  cancelButtonMobile: {
    marginLeft: theme.spacing(2),
  },

  iconSave: {
    color: '#61bd4f',
  },

  iconCancel: {
    color: '#ae4a5c',
  },

  cancelButton: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    color: 'rgb(51, 60, 68)',
    fontWeight: '500',
    '&:hover': {
      boxShadow: 'none',
    },
  },

  hint: {
    marginLeft: 'auto',
    color: '#bdbdbd',
    fontSize: 12,
  },

  disableOutline: {
    '& .Mui-focused': {
      '& textarea, & input': {
        border: '2px solid transparent',
      },
      '& input': {
        padding: '0 !important',
      },
    },
  },

  unsaved: {
    fontSize: 10,
    color: '#a9a7a7',
    backgroundColor: 'rgba(0,0,0, 0.05)',
    padding: theme.spacing(0.5, 1),
    borderRadius: 6,
    letterSpacing: 0.5,
  },

  unsavedMultiline: {
    marginTop: theme.spacing(1),
    display: 'inline-block',
  },
}));
const withLabelEditable = (WrappedComponent) => (props) => {
  const classes = useStyles();
  const translate = useTranslate();
  const {
    defaultValue = '',
    toolEditor = false,
    hideUnsaved = false,
    multiline = false,
    fullEditingTools = multiline,
    disableOutline,
    InputProps,
    InputLabelProps,
    onFocus,
    onBlur,
    onSave,
    onCancel,
    onEnter,
    onChange,
    overrideInputProps,
    noTruncate = false,
    inputType,
    ...rest
  } = props || {};

  const inputProps = overrideInputProps || {
    disableUnderline: true,
    ...InputProps,
  };
  const inputRef = inputProps?.inputRef;
  const [showToolEditor, setShowToolEditor] = useState(false);
  const [showUnsaved, setShowUnsaved] = useState(false);
  const [value, setValue] = useState(defaultValue || '');
  const isEditorClickRef = useRef(false);
  const forceCancelRef = useRef(false);
  const shouldInlineTool = !multiline && !fullEditingTools;

  const handleChange = useCallback((e) => {
    setValue(e?.target?.value || e);
    onChange?.(e);
  }, []);

  const handleHideUnsaved = useCallback(
    (force = false) => {
      if (defaultValue === value || force) setShowUnsaved(false);
    },
    [defaultValue, value],
  );

  const handleShowUnsaved = useCallback(
    (force = false) => {
      if (defaultValue !== value || force) setShowUnsaved(true);
    },
    [defaultValue, value],
  );

  const handleEnter = useCallback(
    (e) => {
      if (e.key === 'Enter' && e.ctrlKey) {
        forceCancelRef.current = true;
        e.target.blur();
        e.preventDefault();

        setShowToolEditor(false);
        onEnter?.(e);
      }

      // 81 is Q
      if (e.keyCode === 81 && e.ctrlKey) {
        forceCancelRef.current = true;

        e.target.blur();
        e.preventDefault();

        setShowToolEditor(false);
        onCancel?.(e);
      }
    },
    [defaultValue, value],
  );

  const handleFocus = useCallback(
    (e) => {
      isEditorClickRef.current = false;
      forceCancelRef.current = false;

      onFocus?.(e);
      handleHideUnsaved(true);
      setShowToolEditor(true);
    },
    [defaultValue, value],
  );

  const handleBlur = useCallback(
    (e) => {
      if (!toolEditor) {
        onBlur?.(e);
      }
      e.persist();

      if (forceCancelRef.current) return;
      if (isEditorClickRef.current) {
        isEditorClickRef.current = false;
        return;
      }

      onBlur?.(e);
      handleShowUnsaved();
      if (!inputType) {
        setShowToolEditor(false);
      }
    },
    [forceCancelRef.current, isEditorClickRef.current, defaultValue, value],
  );

  const handleEditorClick = useCallback(
    (type) => () => {
      setShowToolEditor(false);
      inputRef?.current?.blur();
      const fakeEvent = {
        target: {
          value,
        },
      };
      switch (type) {
        case 'save':
          onSave?.(fakeEvent);
          handleHideUnsaved(true);
          break;
        case 'cancel':
          forceCancelRef.current = true;
          handleHideUnsaved(true);
          onCancel?.(fakeEvent);
          break;
        default:
          break;
      }
    },
    [value],
  );

  useEffect(() => {
    if (defaultValue === value) return;
    setValue(defaultValue);
  }, [defaultValue]);

  const onButtonHover = () => {
    isEditorClickRef.current = true;
  };

  const onButtonOut = () => {
    isEditorClickRef.current = false;
  };

  const renderEditorTool = useCallback(() => {
    if (!toolEditor) return null;
    if (!showToolEditor) return null;
    if (shouldInlineTool) {
      return (
        <Box className={classes.saveInline}>
          <Tooltip title="Ctrl + Enter">
            <IconButton
              size="small"
              onClick={handleEditorClick('save')}
              onMouseEnter={onButtonHover}
              onMouseLeave={onButtonOut}
              className={`${classes.inlineButton} ${classes.iconSave} ${classes.saveButtonMobile}`}
            >
              <DoneRoundedIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Ctrl + Q">
            <IconButton
              size="small"
              onClick={handleEditorClick('cancel')}
              onMouseEnter={onButtonHover}
              onMouseLeave={onButtonOut}
              className={`${classes.inlineButton} ${classes.iconCancel} ${classes.cancelButtonMobile}`}
            >
              <CloseRoundedIcon />
            </IconButton>
          </Tooltip>
        </Box>
      );
    }
    return (
      <Box className={classes.toolEditor}>
        <Box className={classes.buttonGroup}>
          <Tooltip title="Ctrl + Enter">
            <Button
              onClick={handleEditorClick('save')}
              onMouseEnter={onButtonHover}
              onMouseLeave={onButtonOut}
              className={classes.saveButton}
            >
              Save
            </Button>
          </Tooltip>

          <Tooltip title="Ctrl + Q">
            <Button
              onClick={handleEditorClick('cancel')}
              onMouseEnter={onButtonHover}
              onMouseLeave={onButtonOut}
              className={classes.cancelButton}
            >
              Cancel
            </Button>
          </Tooltip>
        </Box>
        <Typography className={classes.hint}>
          {translate('resources.comment.hotkeys.ctrlEnter')}
        </Typography>
      </Box>
    );
  }, [showToolEditor, showUnsaved, toolEditor, defaultValue, value]);

  return (
    <Box
      className={clsx({
        [classes.root]: true,
        [classes.oneLine]: shouldInlineTool,
        [classes.disableOutline]: disableOutline,
        [classes.noTruncate]: noTruncate,
      })}
    >
      <WrappedComponent
        InputProps={inputProps}
        InputLabelProps={{
          shrink: true,
          ...InputLabelProps,
        }}
        label={false}
        helperText={false}
        multiline={multiline || noTruncate}
        autoComplete="off"
        defaultValue={defaultValue}
        {...rest}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onKeyDown={handleEnter}
        onChange={handleChange}
      />
      {!hideUnsaved && showUnsaved && !shouldInlineTool && (
        <Typography
          className={`${classes.unsaved} ${classes.unsavedMultiline}`}
        >
          Unsaved
        </Typography>
      )}
      {!hideUnsaved && showUnsaved && shouldInlineTool && (
        <Typography className={classes.unsaved}>Unsaved</Typography>
      )}
      {renderEditorTool()}
    </Box>
  );
};

export default withLabelEditable;
