import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  reservation: {
    marginTop: theme.spacing(4),
  },

  carListContainer: {
    marginTop: theme.spacing(4),
  },

  paymentContainer: {
    marginTop: theme.spacing(2),
  },

  titleBackContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },

  txtTitle: {
    fontSize: 15,
    fontFamily: 'QuickSandBold',
    color: '#686868',
    marginBottom: theme.spacing(4),
  },
  txtBack: { marginBottom: 0 },
  submitButton: {
    display: 'flex',
    margin: 'auto',
    marginTop: theme.spacing(4),
  },
  txtCashHint: {
    fontSize: 14,
    color: '#999999',
    textAlign: 'center',
    fontWeight: 300,
    margin: theme.spacing(4, 0, 0),
  },
  row: {
    width: '100%',
  },
  radioGroup: {
    '& > div': {
      width: '100%',
    },
  },
}));

export default useStyles;
