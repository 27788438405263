import DateRangeIcon from '@mui/icons-material/DateRange';
import { DateRangePicker } from '@mui/lab';
import { InputAdornment } from '@mui/material';
import clsx from 'clsx';
import get from 'lodash/get';
import set from 'lodash/set';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { memo, useEffect, useState } from 'react';
import { useListContext } from 'react-admin';
import { TextInput } from '../../ra/inputs';

export const supportedDateRangeOperators = ['$between', '$notbetween'];

export const prefixFilterDateRange = '**||between||**';

const formatDate = (date) => (date ? moment(date).format('DD/MM/YYYY') : '');

export const convertDate = (value, startDay) => {
  if (startDay) {
    return moment(value).startOf('day').toISOString();
  }
  return moment(value).endOf('day').toISOString();
};

const DateRangeInput = ({ source, label, onChange, className }) => {
  const [dateRange, setDateRange] = useState([null, null]);
  const [openDialog, setOpenDialog] = useState(false);
  const { setFilters, filterValues } = useListContext();

  const filterValue = get(filterValues, source);

  useEffect(() => {
    if (dateRange[0] && dateRange[1]) {
      setOpenDialog(false);
      if (onChange) {
        onChange(
          `${convertDate(dateRange[0], true)},${convertDate(dateRange[1])}`,
        );
      } else {
        const newFilterValues = {
          ...filterValues,
        };
        set(newFilterValues, source, {
          op: filterValue?.op,
          value: `${convertDate(dateRange[0], true)},${convertDate(
            dateRange[1],
          )}`,
        });
        setFilters(newFilterValues);
      }
    }
  }, [dateRange, onChange]);

  useEffect(() => {
    try {
      const [startDate, endDate] = filterValue?.value?.split(',') || [];
      if (startDate && endDate) {
        if (supportedDateRangeOperators.includes(filterValue?.op)) {
          setDateRange([moment(startDate), moment(endDate)]);
        } else {
          const newFilterValues = {
            ...filterValues,
          };
          set(newFilterValues, source, {
            op: filterValue?.op,
          });
          setFilters(newFilterValues);
        }
      }
    } catch {
      // do nothing
    }
  }, [filterValue]);

  return (
    <DateRangePicker
      startText=""
      endText=""
      value={dateRange}
      open={openDialog}
      disableMaskedInput
      onChange={(newRange) => setDateRange(newRange)}
      onClose={() => setOpenDialog(false)}
      renderInput={(startProps, endProps) => {
        const start = formatDate(startProps?.inputProps?.value);
        const end = formatDate(endProps?.inputProps?.value);
        return (
          <TextInput
            className={clsx(className, 'date-range-input')}
            source={source}
            label={label}
            onClick={() => setOpenDialog(true)}
            autoComplete="off"
            helperText={false}
            onChange={() => null}
            parse={() => null}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <DateRangeIcon />
                </InputAdornment>
              ),
            }}
            placeholder="dd/mm/yyyy ~ dd/mm/yyyy"
            format={() => (start || end ? `${start} ~ ${end}` : '')}
          />
        );
      }}
    />
  );
};

DateRangeInput.propTypes = {
  source: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  className: PropTypes.string,
};

DateRangeInput.defaultProps = {
  onChange: null,
  className: undefined,
};

export default memo(DateRangeInput);
