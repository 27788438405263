/* eslint-disable camelcase */
import { TextField } from '@mui/material';
import { Autocomplete, LoadScriptNext } from '@react-google-maps/api';
import PropTypes from 'prop-types';
import React, { useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';

const libraries = ['places'];

const center = {
  lat: 1.3521,
  lng: 103.8198,
};
// Create a bounding box with east-west side ~30km,
// north-south side ~15km away from the center point (Singapore area)
const defaultBounds = {
  north: center.lat + 0.15,
  south: center.lat - 0.15,
  east: center.lng + 0.3,
  west: center.lng - 0.3,
};

const options = {
  bounds: defaultBounds,
  componentRestrictions: {
    country: ['sg'],
  },
  fields: ['address_components', 'geometry', 'name', 'place_id'],
  strictBounds: false,
};

function GMapSearchBox(props) {
  const {
    onChange,
    defaultValue,
    forceLoadScript,
    placeholder,
    onlyLoadInput,
    disabled,
    helperText,
    customLabel,
  } = props;

  const settings = useSelector(({ setting }) => setting.commonSettings);
  const inputRef = useRef(null);
  const textFieldRef = useRef(null);

  const handleChange = () => {
    if (!inputRef.current) {
      console.log('Script not loaded');
    } else {
      const {
        geometry,
        name,
        address_components,
        place_id: placeId,
      } = inputRef.current.getPlace();
      const lat = geometry?.location.lat();
      const lng = geometry?.location.lng();
      const address = address_components?.map((item) => ({
        name: item.long_name,
        types: item.types,
      }));
      const postalCode = address_components?.find((x) =>
        x.types.includes('postal_code'),
      )?.long_name;
      const neighborhood = address_components?.find((x) =>
        x.types.includes('neighborhood'),
      )?.long_name;

      if (inputRef.current && !lat && !lng) {
        textFieldRef.current.value = '';
      }
      onChange({
        lat,
        lng,
        address,
        name,
        postalCode,
        placeId,
        neighborhood,
      });
    }
  };
  const onLoad = (ref) => {
    inputRef.current = ref;
  };

  const component = (
    <Autocomplete
      onPlaceChanged={handleChange}
      onLoad={onLoad}
      options={options}
    >
      <TextField
        disabled={disabled}
        placeholder={placeholder}
        defaultValue={defaultValue}
        inputRef={textFieldRef}
        helperText={helperText}
        {...(customLabel && { label: customLabel })}
      />
    </Autocomplete>
  );
  if (onlyLoadInput) return component;

  const renderLoadScript = useCallback(() => {
    if (!settings?.map_api_key) return null;
    if (window.google === undefined || forceLoadScript) {
      return (
        <LoadScriptNext
          id="google-map-search-box"
          googleMapsApiKey={settings?.map_api_key}
          libraries={libraries}
        >
          {component}
        </LoadScriptNext>
      );
    }
    return component;
  }, [forceLoadScript, settings?.map_api_key]);

  return renderLoadScript();
}

GMapSearchBox.propTypes = {
  onChange: PropTypes.func,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  forceLoadScript: PropTypes.bool,
  onlyLoadInput: PropTypes.bool,
  disabled: PropTypes.bool,
  helperText: PropTypes.node,
  customLabel: PropTypes.string,
};

GMapSearchBox.defaultProps = {
  onChange: () => {},
  defaultValue: undefined,
  placeholder: 'Enter a location',
  forceLoadScript: false,
  onlyLoadInput: false,
  disabled: false,
  helperText: undefined,
  customLabel: null,
};

export default React.memo(GMapSearchBox);
