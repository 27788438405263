import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import React from 'react';
import { useMainLayoutContext } from '../../../../base/context/main-layout';

const SidebarToggleHandler = (props, ref) => {
  const { openSidebar, toggleSidebar } = useMainLayoutContext();

  return (
    <Tooltip
      title={openSidebar ? 'Close Sidebar' : 'Open Sidebar'}
      enterDelay={500}
    >
      <IconButton
        className="Cmt-toggle-menu"
        onClick={toggleSidebar}
        size="large"
        sx={{ padding: '8px', '& svg': { fontSize: '20px !important' } }}
        {...props}
      >
        {openSidebar ? (
          <MenuOpenIcon fontSize="small" />
        ) : (
          <MenuIcon fontSize="small" />
        )}
      </IconButton>
    </Tooltip>
  );
};

export default SidebarToggleHandler;
