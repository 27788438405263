import React from 'react';
import { required, useDataProvider, ArrayInput } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { isEmpty } from 'lodash';
import { NumberInput } from './ra/inputs';
import FormIterator from './formIterator';
import useError from '../hooks/useError';

function PromotionCurrenciesInput() {
  const dataProvider = useDataProvider();
  const { notifyError } = useError();
  const { getValues, setValue } = useFormContext();

  const values = getValues();
  const { promotionCurrencies = [] } = values;

  React.useEffect(() => {
    dataProvider
      .getList('currency', {
        pagination: {
          perPage: 100,
          page: 1,
        },
        filter: {
          enabled: true,
        },
      })
      .then((resp) => {
        const currencies = getValues()?.promotionCurrencies;
        if (isEmpty(currencies)) {
          setValue(
            'promotionCurrencies',
            resp.data?.map((currency) => ({
              currency,
            }))
          );
        }
      })
      .catch(notifyError);
  }, [getValues]);

  return (
    <>
      {values.methodType === 'PERCENT' && (
        <ArrayInput
          source="promotionCurrencies"
          validate={required()}
          record={{
            ...values,
            promotionCurrencies,
          }}
        >
          <FormIterator disableAdd disableRemove rowTitle="currency.name">
            <NumberInput source="value" label="Percent" />
            <NumberInput source="maxPromoAmount" label="maxPromoAmount" />
            <NumberInput source="minPromoAmount" label="minPromoAmount" />
          </FormIterator>
        </ArrayInput>
      )}
      {values.methodType === 'AMOUNT' && (
        <ArrayInput
          source="promotionCurrencies"
          validate={required()}
          record={values}
        >
          <FormIterator disableAdd disableRemove rowTitle="currency.name">
            <NumberInput source="value" label="Value" />
          </FormIterator>
        </ArrayInput>
      )}
    </>
  );
}

export default PromotionCurrenciesInput;
