import {
  useIsMutating,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import { BAD_REQUEST_MSG } from '../../constants';
import useError from '../../hooks/useError';
import { convertUrlValueToFullUrl } from '../../utils';
import API_ACTION from '../api-handler/actions';

const fetcher = (url, configApiOptions) =>
  API_ACTION.GET(url, configApiOptions).then((response) => response.data);

const fetcherByThirdParty = (url, configApiOptions) =>
  API_ACTION.TP_GET(url, configApiOptions).then((response) => response.data);

export const useGetBase = ({
  queryKey: originQueryKey,
  url,
  configQueryOptions,
  configApiOptions,
  thirdParty,
  shouldNavigateToErrorPage,
  shouldNavigateToErrorPageOptions,
}) => {
  const error = useError();
  const queryKey = originQueryKey ? [originQueryKey, url] : [url];

  const whichFetcher = thirdParty ? fetcherByThirdParty : fetcher;

  const context = useQuery(
    queryKey,
    () => whichFetcher(url, configApiOptions),
    {
      refetchOnWindowFocus: false,
      onError: (err) => {
        if (shouldNavigateToErrorPage) {
          error.navigate(
            shouldNavigateToErrorPageOptions || err?.response.data,
          );
        } else {
          let errorMsg = err.response.data?.message || err.response.data;
          if (errorMsg === 'Bad Request') {
            errorMsg = BAD_REQUEST_MSG;
          }
          error.notify(errorMsg);
        }
      },
      ...configQueryOptions,
    },
  );

  return context;
};

export const useGetOne = (url, options) => {
  const context = useGetBase({
    url,
    ...options,
  });
  return context;
};

export const useGetOneByThirdParty = (url, options) => {
  const context = useGetBase({
    url,
    ...options,
    thirdParty: true,
  });
  return context;
};

export const usePost = (url, options) => {
  const { queryKey: originQueryKey, configQueryOptions } = options || {};
  const queryClient = useQueryClient();
  const error = useError();
  const queryKey = originQueryKey ? [originQueryKey, url] : [url];

  const context = useMutation(queryKey, {
    mutationFn: ({ data, params, config }) => {
      return API_ACTION.POST(
        convertUrlValueToFullUrl(url, params),
        data,
        config,
      ).then((response) => response.data);
    },
    onMutate: async (variables) => {
      await queryClient.cancelQueries(queryKey);
      const previousData = queryClient.getQueryData(queryKey);
      queryClient.setQueryData(queryKey, variables);
      return previousData;
    },
    onSuccess: (result) => {
      queryClient.setQueryData(queryKey, result);
    },
    onError: (err, _, ctx) => {
      queryClient.setQueryData(queryKey, ctx);
      let errorMsg = err.response.data?.message || err.response.data;
      if (errorMsg === 'Bad Request') {
        errorMsg = BAD_REQUEST_MSG;
      }
      error.notify(errorMsg);
    },
    onSettled: () => {
      queryClient.invalidateQueries(queryKey);
    },
    retry: 2,
    ...configQueryOptions,
  });

  return context;
};

export const useSelectData = (url, options) => {
  const queryClient = useQueryClient();
  const { queryKey: originQueryKey, params } = options || {};
  const queryKey = originQueryKey
    ? [originQueryKey, convertUrlValueToFullUrl(url, params)]
    : [convertUrlValueToFullUrl(url, params)];

  const isMutating = useIsMutating(queryKey);
  const mutationCaches = queryClient
    .getMutationCache()
    .findAll({ mutationKey: queryKey });

  const mutationCacheData =
    mutationCaches[mutationCaches.length - 1]?.state?.data;

  const queryData = queryClient.getQueryData(queryKey);

  const data = queryData === undefined ? mutationCacheData : queryData;

  // if (mutationCache?.status === 'success')
  //   data = queryClient.getQueryData(queryKey);
  // else data = undefined;

  return {
    data,
    isMutating: Boolean(isMutating),
  };
};
