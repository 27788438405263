/* eslint-disable react/prop-types, no-unused-vars */
import {
  IconButton,
  Button as MuiButton,
  Tooltip,
  useMediaQuery,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';

import classnames from 'classnames';
import { useTranslate } from 'react-admin';
import { withStandardButtonProps } from '../../../hoc/with-standard-button-props';

const ButtonTooltip = (props) => {
  const { children, tooltip, disabled } = props;

  if (tooltip?.title && !disabled) {
    return (
      <Tooltip title={tooltip.title} placement={tooltip?.placement || 'top'}>
        <>{children}</>
      </Tooltip>
    );
  }

  return children;
};
const useStyles = makeStyles(
  {
    button: {
      display: 'inline-flex',
      alignItems: 'center',
    },
    label: {
      paddingLeft: '0.5em',
    },
    labelRightIcon: {
      paddingRight: '0.5em',
    },
    smallIcon: {
      fontSize: 20,
    },
    mediumIcon: {
      fontSize: 22,
    },
    largeIcon: {
      fontSize: 24,
    },
  },
  {
    name: 'RaButton',
  },
);
export const sanitizeButtonRestProps = ({
  // The next props are injected by Toolbar
  handleSubmit,
  handleSubmitWithRedirect,
  invalid,
  onSave,
  pristine,
  record,
  redirect,
  resource,
  saving,
  submitOnEnter,
  undoable,
  ...rest
}) => rest;

const Button = (props) => {
  const {
    alignIcon = 'left',
    children,
    classes: classesOverride,
    className,
    color,
    disabled,
    label,
    size,
    tooltip,
    ...rest
  } = props;
  const translate = useTranslate();
  const classes = useStyles(props);
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const restProps = sanitizeButtonRestProps(rest);

  return isXSmall ? ( // eslint-disable-line no-nested-ternary
    label && !disabled ? (
      <Tooltip
        title={translate(label, {
          _: label,
        })}
      >
        <IconButton
          aria-label={translate(label, {
            _: label,
          })}
          className={className}
          color={color}
          {...restProps}
          size="large"
        >
          {children}
        </IconButton>
      </Tooltip>
    ) : (
      <IconButton
        className={className}
        color={color}
        disabled={disabled}
        {...restProps}
        size="large"
      >
        {children}
      </IconButton>
    )
  ) : (
    <ButtonTooltip tooltip={tooltip} disabled={disabled}>
      <MuiButton
        className={classnames(classes.button, className)}
        color={color}
        size={size}
        aria-label={
          label
            ? translate(label, {
                _: label,
              })
            : undefined
        }
        disabled={disabled}
        {...restProps}
      >
        {alignIcon === 'left' &&
          children &&
          React.cloneElement(children, {
            className: classes[`${size}Icon`],
          })}
        {label && (
          <span
            className={classnames({
              [classes.label]: alignIcon === 'left',
              [classes.labelRightIcon]: alignIcon !== 'left',
            })}
          >
            {translate(label, {
              _: label,
            })}
          </span>
        )}
        {alignIcon === 'right' &&
          children &&
          React.cloneElement(children, {
            className: classes[`${size}Icon`],
          })}
      </MuiButton>
    </ButtonTooltip>
  );
};

Button.propTypes = {
  /* eslint-disable react/require-default-props */
  alignIcon: PropTypes.oneOf(['left', 'right']),
  children: PropTypes.element,
  classes: PropTypes.object,
  className: PropTypes.string,
  color: PropTypes.oneOf(['default', 'inherit', 'primary', 'secondary']),
  disabled: PropTypes.bool,
  label: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  /*  eslint-enable react/require-default-props */
};

Button.defaultProps = {
  color: 'primary',
  size: 'medium',
};

export default withStandardButtonProps(Button);
