import {
  ClearAllSharp,
  ExitToApp,
  FeedbackSharp,
  Person,
  RefreshSharp,
} from '@mui/icons-material';
import { Avatar, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import React, { useCallback, useMemo, useState } from 'react';
import { Confirm, useLogout, useRefresh, useTranslate } from 'react-admin';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import configs from '../../../../services/configs';
import axios from '../../../../services/provider/axios';
import CmtAvatar from '../../../../theme/@coremat/CmtAvatar';
import CmtDropdownMenu from '../../../../theme/@coremat/CmtDropdownMenu';
import { useAuthUser, useFeedBack } from '../../../hooks';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import useResource from '../../../hooks/useResource';

const useStyles = makeStyles((theme) => ({
  profileRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 8,
    position: 'relative',
    '& div': {
      maxWidth: theme.spacing(7),
      maxHeight: theme.spacing(7),
    },
    '& svg': {
      color: '#ddd',
    },
  },
}));

const defaultActions = [
  {
    icon: <Person />,
    label: 'ra.text.profile',
    id: 'profile',
    index: 1,
  },
  {
    icon: <RefreshSharp />,
    label: 'ra.action.refresh',
    id: 'refresh',
    index: 3,
  },
  {
    icon: <ClearAllSharp />,
    label: 'ra.action.flushCache',
    id: 'flushCache',
    index: 4,
  },
  {
    icon: <FeedbackSharp />,
    label: 'ra.action.feedBack',
    id: 'feedBack',
    index: 12,
  },
  {
    icon: <ExitToApp />,
    label: 'ra.text.logout',
    id: 'logout',
    index: 13,
  },
];

const UserDropDown = () => {
  const classes = useStyles();
  const logout = useLogout();
  const refresh = useRefresh();
  const translate = useTranslate();
  const { toogleFeedBackShown } = useFeedBack();
  const navigate = useNavigate();
  const user = useAuthUser();
  const settings = useSelector(({ setting }) => setting.commonSettings);
  const { flushLocalStorage } = useLocalStorage();
  const resource = useResource('dashboard');
  const { callToActionList = [] } = resource?.options || {};
  const [confirmItem, setConfirmItem] = useState(null);

  const handleRefresh = useCallback(() => {
    refresh();
  }, [refresh]);

  const handleFeedback = useCallback(() => {
    toogleFeedBackShown(true);
  }, []);

  const closeConfirm = useCallback(() => {
    setConfirmItem(null);
  }, [confirmItem]);

  const actionsList = useMemo(() => {
    const filtered = callToActionList
      .filter(
        (x) =>
          (user?.superman ||
            !x?.nfLevel?.min ||
            (user?.level >= x.nfLevel?.min && !settings?.nf_prod)) &&
          x.groupName === 'user-popover',
      )
      .map((x) => ({
        label: x.title,
        id: x.api,
        ...x,
      }));

    return [...filtered, ...defaultActions].sort((a, b) => a.index - b.index);
  }, [callToActionList]);

  const handleDashboardCtaClicking = useCallback(
    async (item, ignoreConfirm = false) => {
      if (item.confirm && !ignoreConfirm) {
        setConfirmItem(item);
        return;
      }

      if (item?.options?.openNewWindow) {
        window.open(`${configs.apiUrl}/bo/${item.api}`);
        return;
      }

      await axios.post(`/bo/${item.api}`, null, { baseURL: configs.apiUrl });

      if (item?.options?.flushLocalStorage) {
        flushLocalStorage?.();
      }
    },
    [],
  );

  const handleConfirm = useCallback(() => {
    handleDashboardCtaClicking(confirmItem, true);
    setConfirmItem(null);
  }, [confirmItem, handleDashboardCtaClicking]);

  const onItemClick = (item) => {
    switch (item.id) {
      case 'profile':
        navigate('/profile');
        break;
      case 'refresh':
        handleRefresh();
        break;
      case 'logout':
        logout();
        break;
      case 'flushCache':
        flushLocalStorage();
        break;
      case 'feedBack':
        handleFeedback();
        break;
      default:
        handleDashboardCtaClicking(item);
        break;
    }
  };

  return (
    <>
      <Box className={clsx(classes.profileRoot, 'Cmt-profile-pic')}>
        <CmtDropdownMenu
          onItemClick={onItemClick}
          TriggerComponent={
            user?.avatar?.blobUrlBO ? (
              <CmtAvatar
                size="small"
                src={user?.avatar?.blobUrlBO}
                alt={user.optionText || user.email || 'A'}
                color="orange"
              />
            ) : (
              <Avatar />
            )
          }
          items={actionsList}
        />
      </Box>
      <Confirm
        isOpen={Boolean(confirmItem)}
        title={translate(confirmItem?.label || '')}
        content={`Are you sure you want to ${translate(
          confirmItem?.label || '',
        )}?`}
        onConfirm={handleConfirm}
        onClose={closeConfirm}
      />
    </>
  );
};

export default UserDropDown;
