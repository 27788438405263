import React, { useState, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import Marquee from 'react-double-marquee';
import { Box, IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import { useLocaleState } from 'react-admin';
import {
  getCookie,
  setCookie,
} from '../../../../services/provider/authProvider';

const useStyles = makeStyles(() => ({
  announcementWrapper: {
    '& span[style*="visibility: hidden"]': {
      display: 'none',
    },
  },
  announcement: {
    whiteSpace: 'nowrap',
    fontWeight: 'normal',
  },
}));

const MarqueeAnnouncement = ({
  announcements, delay,
}) => {
  const classes = useStyles();

  const [locale] = useLocaleState();
  const defaultValue = 'false';
  const bannerVisibleCookieName = 'isBannerAnnouncementVisible';
  const isBannerVisibleFromCookie = getCookie(
    bannerVisibleCookieName,
    defaultValue,
  );
  const [isBannerVisible, setBannerVisible] = useState(true);

  const toggleBannerAnnouncementVisible = () => {
    const value = !isBannerVisible;
    setCookie(bannerVisibleCookieName, value.toString());
    setBannerVisible(value);
  };

  useEffect(() => {
    setBannerVisible(isBannerVisibleFromCookie !== 'false');
  }, [isBannerVisibleFromCookie]);

  const handleOnClick = url => {
    if (url) {
      window.open(url);
    }
  };

  return (
    <>
      <Box
        display="flex"
        flexDirection="row-reverse"
        alignItems="center"
        width="100%"
      >
        {(announcements?.length && (
          <Box ml={2}>
            <IconButton
              aria-label="Refresh"
              aria-haspopup="true"
              onClick={toggleBannerAnnouncementVisible}
              color="default"
              size="large"
            >
              {!isBannerVisible ? <VolumeOffIcon /> : <VolumeUpIcon />}
            </IconButton>
          </Box>
        ))
          || null}
        <Box
          overflow="hidden"
          flexGrow={1}
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          className={classes.announcementWrapper}
        >
          {isBannerVisible && (
            <Marquee
              delay={delay}
              direction="left"
              scrollWhen="always"
            >
              {announcements?.map(({
                subject, id, url,
              }) => (
                <Box
                  key={id}
                  aria-hidden="true"
                  onClick={() => handleOnClick(url)}
                  style={{
                    cursor: url ? 'pointer' : 'default',
                  }}
                  className={classes.announcement}
                  component="span"
                  color="warning.main"
                  ml={3}
                  mr={3}
                >
                  {subject?.[locale]}
                </Box>
              ))}
            </Marquee>
          )}
        </Box>
      </Box>
    </>
  );
};

MarqueeAnnouncement.propTypes = {
  announcements: PropTypes.array.isRequired,
  delay: PropTypes.number,
};

MarqueeAnnouncement.defaultProps = {
  delay: 500,
};

export default memo(MarqueeAnnouncement);
