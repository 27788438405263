import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { Controller } from 'react-hook-form';
import Checkbox from '../checkbox';
import useStyles from './index.style';

const BoosterSeatCheckbox = ({ control, fieldName, label, onClick }) => {
  const classes = useStyles();

  return (
    <Box className={classes.boosterSeatCbWrapper}>
      <Controller
        control={control}
        name={fieldName}
        defaultValue={false}
        render={({ field: { onChange, value } }) => (
          <Checkbox
            label={label}
            checked={value}
            onChange={(e, checked) => {
              onChange(e, checked);
              onClick?.();
            }}
          />
        )}
      />
    </Box>
  );
};

BoosterSeatCheckbox.propTypes = {
  label: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};

BoosterSeatCheckbox.defaultProps = {
  onClick: null,
};

export default memo(BoosterSeatCheckbox);
