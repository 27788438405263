import { Box, Collapse, RadioGroup, Skeleton, Typography } from '@mui/material';
import isArray from 'lodash/isArray';
import React, { memo, useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';
import {
  useSelectFee,
  useSelectReservation,
  useSelectStripe,
} from '../../api/features/common.feature';
import {
  BookingCarRadioButtonItem,
  BookingList,
  DefaultButton,
  FlightNameInput,
  NumberGuestInput,
  NumberLuggagesInput,
  PassengerNameInput,
  PaymentInput,
  PhoneInput,
  TotalTimeBookingInput,
} from '../../components';
import BoostSeatCheckbox from '../../components/boost-seat-checkbox';
import {
  EVENTS,
  FORM_FIELD_NAMES,
  HOURLY_TOTAL_BOOKING_TIME_MIN,
  JOB_TYPE,
  MAX_CAPACITY,
  NUMBER_OF_LUGGAGE_MIN,
  PAYMENT_VALUES,
  SUB_JOB_TYPE,
} from '../../constants';
import { publish } from '../../events';
import { getSafeValue } from '../../helpers';
import useError from '../../hooks/useError';
import {
  getSelectedJobType,
  getSelectedSubJobType,
} from '../../services/redux/reducers/job-type.reducer';
import { validatePhone } from '../../utils';
import useStyles from './index.style';

const ReservationScreen = () => {
  const classes = useStyles();
  const errNotify = useError();
  const { setValue, getValues, control } = useFormContext();
  const watch = useWatch({ control });

  const [mockedFeeList, setMockedFeeList] = useState();

  const selectedJobType = useSelector(getSelectedJobType);
  const { id: jobTypeId, type: typeOfJobType } = selectedJobType || {};

  const { data: feeList } = useSelectFee(typeOfJobType);
  const { data: reservation, isMutating: isBookingMutating } =
    useSelectReservation();
  const { isMutating: isStripeMutating } = useSelectStripe({
    params: {
      id: reservation?.id,
    },
  });

  const watchCurrentSelected = watch[FORM_FIELD_NAMES.TYPE];
  const watchTotalTimeBooking = watch[FORM_FIELD_NAMES.TOTAL_TIME_BOOKING];

  const { addressName: pickupValue } = getSafeValue(
    getValues(),
    FORM_FIELD_NAMES.PICK_UP,
    {},
  );

  const selectedSubJobType = useSelector(getSelectedSubJobType);
  const {
    childBoosterSeat,
    id: subJobTypeId,
    type: typeOfSubJobType,
  } = selectedSubJobType || {};

  const isCashMethod = watch[FORM_FIELD_NAMES.PAYMENT] === PAYMENT_VALUES.CASH;

  const necessaryCondition =
    !watch[FORM_FIELD_NAMES.PAYMENT] || !watch[FORM_FIELD_NAMES.TYPE];

  const hourlyCondition =
    necessaryCondition ||
    !watchTotalTimeBooking ||
    watchTotalTimeBooking < HOURLY_TOTAL_BOOKING_TIME_MIN ||
    !validatePhone({ phone: watch[FORM_FIELD_NAMES.PHONE] });

  const isGuestAndLuggagesValid =
    watch[FORM_FIELD_NAMES.NUMBER_OF_GUEST] &&
    watch[FORM_FIELD_NAMES.NUMBER_OF_LUGGAGES] !== '' &&
    watch[FORM_FIELD_NAMES.NUMBER_OF_LUGGAGES] >= NUMBER_OF_LUGGAGE_MIN;

  const hasCapacityReached =
    Number(watch[FORM_FIELD_NAMES.NUMBER_OF_LUGGAGES]) +
      Number(watch[FORM_FIELD_NAMES.NUMBER_OF_GUEST]) >
    MAX_CAPACITY;

  const fixedPriceCondition =
    necessaryCondition ||
    !watch[FORM_FIELD_NAMES.PASSENGER_NAME] ||
    !watch[FORM_FIELD_NAMES.NUMBER_OF_FLIGHT] ||
    !isGuestAndLuggagesValid ||
    hasCapacityReached ||
    !validatePhone({ phone: watch[FORM_FIELD_NAMES.PHONE] });

  const isDisabledButton = {
    [JOB_TYPE.STANDARD]: necessaryCondition,
    [SUB_JOB_TYPE.POINT2POINT]: necessaryCondition,
    [SUB_JOB_TYPE.HOURLY]: hourlyCondition,
    [SUB_JOB_TYPE.FIXED_PRICE]: fixedPriceCondition,
  };

  const onCarChange = (event) => {
    const { value } = event?.target || {};
    setValue(FORM_FIELD_NAMES.TYPE, value);
  };

  const publishEvent = (eventName) => {
    if (!eventName) return;
    publish(eventName, {
      jobTypeId,
      subJobTypeId,
      typeOfSubJobType,
    });
  };

  const onClickBoosterSeat = () => {
    publishEvent(EVENTS.ON_CHANGE_BOOSTER_SEAT);
  };

  const onChangeTotalTimeInput = (totalTime) => {
    if (
      totalTime >= HOURLY_TOTAL_BOOKING_TIME_MIN &&
      totalTime !== watchTotalTimeBooking
    ) {
      publishEvent(EVENTS.ON_CHANGE_TOTAL_TIME_BOOKING);
    }
  };

  const hourlyFormFields = () => {
    if (typeOfSubJobType !== SUB_JOB_TYPE.HOURLY) return <></>;
    return (
      <Box>
        <PhoneInput fieldName={FORM_FIELD_NAMES.PHONE} />
        <TotalTimeBookingInput
          fieldName={FORM_FIELD_NAMES.TOTAL_TIME_BOOKING}
          onChange={onChangeTotalTimeInput}
        />
      </Box>
    );
  };

  const fixedPriceFormFields = () => {
    if (typeOfSubJobType !== SUB_JOB_TYPE.FIXED_PRICE) return <></>;
    return (
      <Box>
        <PassengerNameInput fieldName={FORM_FIELD_NAMES.PASSENGER_NAME} />
        <PhoneInput fieldName={FORM_FIELD_NAMES.PHONE} />
        <NumberGuestInput fieldName={FORM_FIELD_NAMES.NUMBER_OF_GUEST} />
        <NumberLuggagesInput fieldName={FORM_FIELD_NAMES.NUMBER_OF_LUGGAGES} />
        <FlightNameInput fieldName={FORM_FIELD_NAMES.NUMBER_OF_FLIGHT} />
      </Box>
    );
  };

  useEffect(() => {
    if (isArray(feeList) && feeList.length > 0) {
      onCarChange({ target: { value: feeList[0]?.id } });
    }
  }, [JSON.stringify(feeList)]);

  useEffect(() => {
    if (isGuestAndLuggagesValid && hasCapacityReached) {
      errNotify.notify('Total number of guests and luggage should be 6');
    }
  }, [
    watch[FORM_FIELD_NAMES.NUMBER_OF_LUGGAGES],
    watch[FORM_FIELD_NAMES.NUMBER_OF_GUEST],
  ]);

  const renderFeeList = () => {
    return (
      <RadioGroup onChange={onCarChange} className={classes.radioGroup}>
        <BookingList
          itemProps={{
            isSelectedById: watchCurrentSelected,
          }}
          data={feeList || mockedFeeList}
          component={BookingCarRadioButtonItem}
        />
      </RadioGroup>
    );
  };

  const renderBookingListSkeleton = () => {
    return (
      <Skeleton
        sx={{
          bgcolor: 'grey.100',
          borderRadius: '10px',
        }}
        variant="rounded"
      >
        {renderFeeList()}
      </Skeleton>
    );
  };

  useEffect(() => {
    if (feeList?.length > 0 && !mockedFeeList) {
      setMockedFeeList(feeList);
    }
  }, [feeList?.length, mockedFeeList]);

  return (
    <Box className={classes.reservation}>
      <Box className={classes.inner}>
        <Box className={classes.carListContainer}>
          {hourlyFormFields()}
          {fixedPriceFormFields()}

          {!!childBoosterSeat && (
            <BoostSeatCheckbox
              label="Booster Seat"
              fieldName={FORM_FIELD_NAMES.CHILD_BOOSTER_SEAT}
              control={control}
              onClick={onClickBoosterSeat}
            />
          )}
          <Typography className={classes.txtTitle}>
            Available Ride Types
          </Typography>

          {!feeList ? renderBookingListSkeleton() : renderFeeList()}
        </Box>
        <Box className={classes.paymentContainer}>
          <Typography className={classes.txtTitle}>Payment Method</Typography>
          <PaymentInput fieldName={FORM_FIELD_NAMES.PAYMENT} />
        </Box>
        <Collapse in={isCashMethod}>
          <Typography className={classes.txtCashHint}>
            Please proceed to our booths at {pickupValue} for payment
          </Typography>
        </Collapse>

        <DefaultButton
          className={classes.submitButton}
          buttonProps={{ type: 'submit' }}
          disabled={
            isDisabledButton[
              typeOfJobType === JOB_TYPE.STANDARD
                ? JOB_TYPE.STANDARD
                : typeOfSubJobType
            ]
          }
          loading={isStripeMutating || isBookingMutating}
          name="Book a Ride!"
        />
      </Box>
    </Box>
  );
};

export default memo(ReservationScreen);
