import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { NumberInput } from '.';
import { parseCoordinate, parsePoint } from '../../../utils';
import GMapSearchBox from './google-map-search-box/googleMapSearchBox.input';

const StyledDialog = withStyles({
  root: {
    zIndex: '9999 !important',
  },
  paperWidthMd: {
    maxWidth: '800px',
  },
})(Dialog);

const useStyles = makeStyles(() => ({
  input: {
    '& label': {},
    // transform: 'translate(14px, 6px) scale(1)',

    '& label.Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.6)',
    },
    '& div.Mui-disabled input': {
      color: 'rgb(51 60 68) !important',
      '-webkit-text-fill-color': 'unset',
    },
  },
  boxButtonLocation: {
    paddingTop: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  locationIcon: {
    padding: '5px !important',
  },
}));

const CoordinateInput = (props) => {
  const { source } = props;
  const classes = useStyles();
  const { setValue, watch, resetField } = useFormContext();
  const [addr, setAddr] = useState('');
  const settings = useSelector(({ setting }) => setting.commonSettings);
  const [searchBoxOpen, setOpenSearchBox] = useState(false);

  const realSource = useMemo(() => {
    return source?.split('.')?.pop();
  }, [source]);
  const parentSource = useMemo(() => {
    return source?.replace(`${realSource}`, '') || '';
  }, [source, realSource]);
  const latSource = `${source}Latitude`;
  const lngSource = `${source}Longitude`;

  const value = watch(source);

  useEffect(() => {
    if (isEmpty(value)) {
      resetField(latSource);
      resetField(lngSource);
      return;
    }

    const [parsedLat, parsedLng] = parseCoordinate(value);
    setValue(latSource, parsedLat);
    setValue(lngSource, parsedLng);
  }, [value, latSource, lngSource, setValue, resetField]);

  const handleChangeSearchBox = ({ lat, lng, address, name }) => {
    const addressSource = `${parentSource}address`;
    const addressNameSource = `${parentSource}addressName`;
    const fullAddress = `${address?.map((item) => item.name)?.join(', ')}`;

    setValue(source, parsePoint(lat, lng));
    setValue(addressSource, fullAddress);
    setValue(addressNameSource, name);
    setAddr(`${name} - ${fullAddress}`);
  };

  return (
    <>
      {/* Search dialog */}
      <StyledDialog
        open={searchBoxOpen}
        onClose={() => setOpenSearchBox(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Search for address and geometry</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter name, address or zip code to search for places
          </DialogContentText>
          <Box>
            {settings?.map_api_key && (
              <GMapSearchBox onChange={handleChangeSearchBox} />
            )}
            <Box paddingTop={4} display="flex">
              {addr && (
                <>
                  <span>
                    <LocationOnIcon />
                  </span>
                  <Typography>{addr}</Typography>
                </>
              )}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            onClick={() => setOpenSearchBox(false)}
            style={{
              margin: 0,
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </StyledDialog>

      {/* Inputs */}
      <Grid container spacing={2}>
        <Grid item xs={11} container spacing={2}>
          <Grid item xs={6}>
            <NumberInput
              source={latSource}
              label="Latitude"
              fullWidth
              disabled
              className={classes.input}
              onClick={() => setOpenSearchBox(true)}
            />
          </Grid>
          <Grid item xs={6}>
            <NumberInput
              source={lngSource}
              label="Longitude"
              fullWidth
              disabled
              className={classes.input}
              onClick={() => setOpenSearchBox(true)}
            />
          </Grid>
        </Grid>
        <Grid
          item
          xs={1}
          className={clsx('box-location', classes.boxButtonLocation)}
        >
          <Box mt={2}>
            <Tooltip title="Search">
              <IconButton
                onClick={() => setOpenSearchBox(true)}
                size="large"
                className={classes.locationIcon}
              >
                <LocationSearchingIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

CoordinateInput.propTypes = {
  source: PropTypes.string.isRequired,
};

CoordinateInput.defaultProps = {};

export default CoordinateInput;
