import SaveIcon from '@mui/icons-material/Save';
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { SimpleForm, Toolbar, useNotify, useTranslate } from 'react-admin';
import ReCAPTCHA from 'react-google-recaptcha';
import axios from '../../../services/provider/axios';
import useError from '../../hooks/useError';
import useSetting from '../../hooks/useSetting';
import { Button, SaveButton } from '../ra/buttons';
import { PasswordInput } from '../ra/inputs';

const ButtonActionSimpleForm = (props) => {
  const { close, loading, ...rest } = props;

  const translate = useTranslate();
  return (
    <Toolbar {...rest}>
      <Button
        label={translate('ra.action.close')}
        onClick={close}
        variant="text"
        color="primary"
        size="small"
      />
      <SaveButton
        label={translate('ra.action.save')}
        size="small"
        color="primary"
        variant="text"
        disabled={loading}
        icon={loading ? <CircularProgress size={16} /> : <SaveIcon />}
      />
    </Toolbar>
  );
};
ButtonActionSimpleForm.propTypes = {
  close: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

function OwnerChangePasswordForm(props) {
  const { open, close, record: user } = props;
  const translate = useTranslate();
  const notify = useNotify();
  const { notifyError } = useError();
  const [loading, setLoading] = React.useState(false);
  const recaptchaRef = React.useRef({});
  const setting = useSetting();
  const passwordNotMatched = translate(
    'nf.exception.newPasswordAndConfirmPasswordNotMatch',
  );

  // @TODO move to separated validation module if needed
  const matchPassword = () => (value, allValues) => {
    return value === allValues.newPassword ? undefined : passwordNotMatched;
  };

  const handleSubmit = async (formValues) => {
    try {
      setLoading(true);
      await axios.post('/Auth/password/change', {
        currentPassword: formValues.currentPassword,
        newPassword: formValues.newPassword,
        confirmPassword: formValues.confirmPassword,
      });
      setLoading(false);
      close();
      notify('ra.notification.updated', {
        type: 'success',
        messageArgs: {
          smart_count: 1,
        },
      });
    } catch (error) {
      setLoading(false);
      notifyError(error);
    }
  };

  return (
    <Dialog fullWidth open={!!open} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        Change password of &nbsp;
        <b>#{user.username}</b>
      </DialogTitle>
      {setting && (
        <ReCAPTCHA
          style={{
            display: 'inline-block',
          }}
          badge="bottomright"
          theme="dark"
          size="invisible"
          ref={recaptchaRef}
          sitekey={setting.auth_recaptcha_site_key}
        />
      )}
      <SimpleForm
        onSubmit={handleSubmit}
        toolbar={<ButtonActionSimpleForm {...props} loading={loading} />}
      >
        <DialogContent>
          <PasswordInput
            source="currentPassword"
            autoComplete="new-password"
            fullWidth
          />
          <PasswordInput
            source="newPassword"
            autoComplete="new-password"
            fullWidth
          />
          <PasswordInput
            source="confirmPassword"
            autoComplete="new-password"
            fullWidth
            validate={matchPassword()}
          />
        </DialogContent>
      </SimpleForm>
    </Dialog>
  );
}
OwnerChangePasswordForm.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  record: PropTypes.object,
};

OwnerChangePasswordForm.defaultProps = {
  record: {},
  open: false,
  close: undefined,
};
export default OwnerChangePasswordForm;
