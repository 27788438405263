export const stripResourceBuiltinData = (resourceData, propNames) => {
  if (!resourceData) {
    return {};
  }
  const result = JSON.parse(JSON.stringify(resourceData));

  if (propNames?.length > 0) {
    propNames.forEach(element => {
      delete result[element];
    });
  } else {
    delete result.id;
    delete result.created;
    delete result.updated;
    delete result.deleted;
    delete result.docStatus;
    delete result.buildIn;
  }

  return result;
};
