import { lazy } from 'react';
import {
  DateInput as RaDateInput,
  PasswordInput as RaPasswordInput,
  SelectArrayInput as RaSelectArrayInput,
  TextInput as RaTextInput,
} from 'react-admin';
import withLabelEditable from '../../../hoc/with-label-editable';
import { withStandardInputProps } from '../../../hoc/with-standard-input-props';
import { lazyWrapper } from '../../../lazy';

const RaReferenceArrayInput = lazyWrapper(
  lazy(() => import('./array-reference')),
);
const RaAutocompleteArrayInput = lazyWrapper(
  lazy(() => import('./autocomplete-array.input')),
);
const RaAutocompleteInput = lazyWrapper(
  lazy(() => import('./autocomplete.input')),
);
const RaBooleanInput = lazyWrapper(lazy(() => import('./boolean.input')));
const RaColorInput = lazyWrapper(lazy(() => import('./color.input')));
const RaJsonInput = lazyWrapper(lazy(() => import('./json.input')));
const RaNumberInput = lazyWrapper(lazy(() => import('./number.input')));
const RaReferenceInput = lazyWrapper(lazy(() => import('./reference')));
const RaSelectInput = lazyWrapper(lazy(() => import('./select.input')));
const RaTextInputCustom = lazyWrapper(lazy(() => import('./text.input')));
const RaTranslatableInputs = lazyWrapper(
  lazy(() => import('./translatable.input')),
);
const RaTipTapInput = lazyWrapper(lazy(() => import('./tiptap.input')));

export const AutocompleteArrayInput = withStandardInputProps(
  RaAutocompleteArrayInput,
);
export const TextInput = withStandardInputProps(RaTextInputCustom);
export const NumberInput = withStandardInputProps(RaNumberInput);
export const PasswordInput = withStandardInputProps(RaPasswordInput);
export const DateInput = withStandardInputProps(RaDateInput);
export const SelectInput = withStandardInputProps(RaSelectInput);
export const SelectArrayInput = withStandardInputProps(RaSelectArrayInput);
export const AutocompleteInput = withStandardInputProps(RaAutocompleteInput);
export const BooleanInput = withStandardInputProps(RaBooleanInput);
export const ColorInput = withStandardInputProps(RaColorInput);
export const TranslatableInputs = withStandardInputProps(RaTranslatableInputs);
export const TipTapInput = withStandardInputProps(RaTipTapInput);
export const ReferenceInput = withStandardInputProps(RaReferenceInput);
export const ReferenceArrayInput = withStandardInputProps(
  RaReferenceArrayInput,
);
export const LabelInput = withLabelEditable(RaTextInput);
export const JsonInput = withStandardInputProps(RaJsonInput);
