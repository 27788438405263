import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';
import { useAuthUser } from '../../hooks';
import Configs from '../../../services/configs';

if (Configs.nfGa) {
  ReactGA.initialize(Configs.nfGa);
}

const sendPageView = (path) => {
  ReactGA.send({
    hitType: 'pageview',
    page: path,
  });
};

const sendTrackingUser = (user, path) => {
  if (isEmpty(user?.id)) return;
  try {
    const { id, username } = user;
    ReactGA.event({
      category: `USER-${id}`,
      action: `[ACCESS] -> ${path}`,
      label: `username: ${username}`,
    });
  } catch (error) {
    console.error(error);
  }
};

const GoogleAnalytics = ({ children }) => {
  const location = useLocation();
  const user = useAuthUser();
  const locationRef = useRef();

  const gaPage = () => {
    locationRef.current = location;
    sendPageView(location.pathname);
  };

  const gaUser = () => {
    sendTrackingUser(user, location.pathname);
  };

  useEffect(() => {
    gaPage();
    gaUser();
  }, [location, user?.id]);

  return children;
};

GoogleAnalytics.propTypes = {
  children: PropTypes.node,
};

GoogleAnalytics.defaultProps = {
  children: null,
};

export default GoogleAnalytics;
