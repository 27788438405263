import { createTheme } from '@mui/material/styles';

const { breakpoints } = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});

const defaultTheme = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  spacing: 4,
  direction: 'ltr',
  palette: {
    mode: 'light',
    common: {
      black: '#000',
      white: '#fff',
      dark: '#808080',
      contrastText: '#fff',
    },
    primary: {
      main: 'rgb(36, 144, 239)',
      dark: 'rgb(11 112 201)',
      contrastText: '#fff',
    },
    secondary: {
      main: '#687178',
      dark: '#018786',
      contrastText: '#fff',
    },
    success: {
      light: '#D7F5B1',
      main: '#8DCD03',
      dark: '#5D9405',
      contrastText: '#fff',
    },
    info: {
      light: '#9BE7FD',
      main: '#0795F4',
      dark: '#0356AF',
      contrastText: '#fff',
    },
    warning: {
      light: '#FFDE99',
      main: '#FF8C00',
      dark: '#D36F1A',
      contrastText: '#000',
    },
    error: {
      light: '#FFC7D1',
      main: '#E00930',
      dark: '#87061E',
      contrastText: '#fff',
    },
    sidebar: {
      bgColor: '#fff',
      textColor: 'rgba(0, 0, 0, 0.6)',
      textDarkColor: 'rgb(51 60 68)',
      textActiveColor: 'rgb(36, 144, 239)',
      navHoverBgColor: 'rgb(235, 238, 240)',
      navActiveBgColor: 'rgb(239, 229, 253)',
      borderColor: 'rgba(33, 33, 33, 0.08)',
    },
    horizontalNav: {
      navigationColor: 'rgba(255, 255, 255, 1)',
      navigationActiveColor: 'rgba(255, 255, 255, 1)',
      textColor: 'rgb(31, 39, 46)',
      textDarkColor: 'rgb(51 60 68)',
      textActiveColor: 'rgb(36, 144, 239)',
      menuHoverBgColor: 'rgb(229, 229, 229)',
      menuActiveBgColor: 'rgb(239, 229, 253)',
    },
    background: {
      paper: '#ffffff',
      default: 'rgb(249, 250, 250)',
    },
    text: {
      primary: 'rgb(51 60 68)',
      secondary: 'rgba(0, 0, 0, 0.6)',
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.3)',
      white: '#fff',
    },
    btn: {
      hover: 'rgba(0, 0, 0, 0.08)',
    },
    lightBtn: {
      bgColor: '#f5f5f5',
      textColor: 'rgba(0, 0, 0, 0.38)',
    },
    borderColor: {
      main: 'rgba(0, 0, 0, 0.06)',
      dark: 'rgba(0, 0, 0, 0.12)',
    },
    popupColor: {
      main: '#fff',
    },
    mixedColors: [
      'rgb(36, 144, 239)',
      '#f59c11',
      '#01c0ef',
      '#605ca8',
      '#06a558',
      '#de4c38',
      '#3d9970',
      '#0873b7',
      '#ff841a',
      '#d81c60',
      '#8884d8',
      '#82ca9d',
    ],
  },
  status: {
    danger: 'orange',
  },
  typography: {
    fontFamily:
      '"Inter","-apple-system","BlinkMacSystemFont","Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif',
    fontWeightExtraLight: 200,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightBold: 'bold',
    fontWeightExtraBold: 800,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: 'rgb(249, 250, 250)',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h1: {
          fontSize: 20,
          fontWeight: 'bold',
          [breakpoints.up('md')]: {
            fontSize: 22,
          },
        },
        h2: {
          fontSize: 18,
          fontWeight: 'bold',
          [breakpoints.up('md')]: {
            fontSize: 20,
          },
        },
        h3: {
          fontSize: 18,
          fontWeight: 'bold',
          lineHeight: '22px',
          [breakpoints.up('md')]: {
            fontSize: 20,
            lineHeight: '24px',
          },
        },
        h4: {
          fontSize: 16,
          fontWeight: 'bold',
          lineHeight: '24px',
        },
        h5: {
          fontSize: 14,
          fontWeight: 400,
        },
        h6: {
          fontSize: 14,
          fontWeight: 'bold',
          letterSpacing: 0.5,
        },
        subtitle1: {
          fontSize: 16,
          fontWeight: 400,
          letterSpacing: 0.15,
        },
        subtitle2: {
          fontSize: 14,
          fontWeight: 'bold',
          letterSpacing: 0.1,
        },
        body1: {
          fontSize: '13px',
          lineHeight: '19.5px',
          fontWeight: 400,
        },
        body2: {
          fontSize: '13px',
          lineHeight: '19.5px',
          fontWeight: 400,
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        item: {
          [breakpoints.down('md')]: {
            padding: 'unset',
          },
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'none',
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgb(244, 245, 246)',
          '&.Mui-focused fieldset': {
            borderWidth: '1px !important',
          },
        },
        input: {
          minWidth: '187px',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          minHeight: 34,
          borderRadius: 6,
          padding: '0px !important',
        },
        input: {
          padding: '6px 12px !important',
          fontSize: '13px',
          lineHeight: '19.5px',
          fontWeight: 500,
        },
        inputMultiline: {
          padding: 'unset !important',
          margin: '6px 12px',
          fontSize: '13px',
          lineHeight: '19.5px',
          fontWeight: 500,
        },
        notchedOutline: {
          borderWidth: '0px',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        outlined: {
          fontSize: '13px',
          lineHeight: '19.5px',
          fontWeight: 500,
        },
        root: {
          transform: 'translate(12px, 6px) scale(1)',
        },
        shrink: {
          transform: 'translate(12px, -6px) scale(0.75)',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 400,
          fontSize: '13px',
          lineHeight: '19.5px',
          letterSpacing: 'normal',
          whiteSpace: 'nowrap',
          minWidth: 30,
          borderRadius: 6,
          padding: '5px 5px',
          textTransform: 'capitalize',
          boxShadow:
            'rgba(0, 0, 0, 0.05) 0px 0.5px 0px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px, rgba(0, 0, 0, 0.05) 0px 2px 4px 0px',
        },
        textSizeSmall: {
          background: '#fff',
          padding: '5px 5px',
        },
        text: {
          boxShadow:
            'rgba(0, 0, 0, 0.05) 0px 0.5px 0px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px, rgba(0, 0, 0, 0.05) 0px 2px 4px 0px',
          padding: '5px 5px',
        },
        containedSizeSmall: {
          padding: '5px 5px',
          fontSize: '0.8125rem',
        },
        contained: {
          padding: '5px 5px',
          color: 'rgb(36, 144, 239)',
          backgroundColor: '#fff',
          boxShadow:
            'rgba(0, 0, 0, 0.05) 0px 0.5px 0px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px, rgba(0, 0, 0, 0.05) 0px 2px 4px 0px',
          '&:hover': {
            backgroundColor: 'rgba(84, 121, 247, 0.04)',
            boxShadow:
              'rgba(0, 0, 0, 0.05) 0px 0.5px 0px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px, rgba(0, 0, 0, 0.05) 0px 2px 4px 0px',
          },
          '&:focus': {
            boxShadow:
              'rgba(0, 0, 0, 0.05) 0px 0.5px 0px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px, rgba(0, 0, 0, 0.05) 0px 2px 4px 0px',
          },
        },
        startIcon: {
          marginRight: 0,
        },
        label: {
          margin: '0px 5px',
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          borderRadius: 4,
        },
      },
    },
    MuiCardLg: {
      styleOverrides: {
        root: {
          borderRadius: 10,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          borderRadius: '20px',
          backgroundColor: 'white',
          border: '1px solid rgb(241 243 244)',
          color: 'rgb(230, 230, 230)',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontWeight: 400,
          fontSize: '13px',
          lineHeight: '19.5px',
          color: 'rgba(0, 0, 0, 0.6)',
          textTransform: 'capitalize',
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          backgroundColor: '#ffffff',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        elevation1: {
          boxShadow:
            'rgba(25, 39, 52, 0.05) 0px 1px 2px 0px, rgba(25, 39, 52, 0.1) 0px 0px 4px 0px',
        },
        elevation8: {
          boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 18%)',
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          width: '100%',
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          '& .MuiTableRow-root': {
            height: '45px !important',
          },
          '& .MuiTableCell-root': {
            fontWeight: 400,
            fontSize: 'small',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            color: 'rgb(104 113 120)',
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          height: 45,
          position: 'relative',
          maxWidth: '250px',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          '& .single-field-list': {
            flexWrap: 'nowrap',
          },
          '&:last-child': {
            borderRight: 'none',
          },
          borderBottomColor: '#f4f5f6',
        },
        sizeSmall: {
          padding: '0px 8px',
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          cursor: 'pointer',
          height: 45,
          borderBottomColor: '#f4f5f6',
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        spacer: {
          display: 'none',
        },
        toolbar: {
          minHeight: 45,
          paddingLeft: 16,
          paddingRight: 16,
          '& div[class*="paginationActionsWrapper"]': {
            position: 'relative',
            display: 'flex',
            flex: 1,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
            margin: '0px -8px',
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          minHeight: 40,
          fontWeight: 300,
          fontSize: '13px',
          lineHeight: '19.5px',
          whiteSpace: 'nowrap',
          padding: '8px 16px',
          overflow: 'hidden',
          '&.Mui-selected': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.04)',
            },
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 30,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          top: 'calc(50% - 10px)',
          fontSize: '20px !important',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: 'rgb(104 113 120)',
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: '20px',
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          width: '100%',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          backgroundColor: '#ffffff',
          [breakpoints.up('sm')]: {
            minWidth: 500,
          },
          [breakpoints.up('md')]: {
            minWidth: 600,
          },
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: '18.5px 20px',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: '18.5px 20px',
        },
        dividers: {
          borderTopColor: 'rgb(244, 245, 246)',
          borderBottomColor: 'rgb(244, 245, 246)',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '18.5px 20px',
          '& button': {
            boxShadow: 'none',
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: '8px',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          height: '24px',
          fontSize: '12px',
          fontWeight: 500,
          lineHeight: '18px',
          textTransform: 'capitalize',
          borderRadius: 16,
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        root: {
          width: 40,
          height: 40,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgb(244, 245, 246)',
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          padding: 10,
          '&.Mui-expanded': { marginTop: '0px' },
          '&:not(:last-of-type)': { marginBottom: 16 },
          '&::before': {
            display: 'none',
          },
        },
        rounded: {
          borderRadius: '8px !important',
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          minHeight: 44,
          justifyContent: 'flex-start',
          '&.Mui-expanded': {
            margin: 0,
            minHeight: 44,
          },
        },
        content: {
          margin: 0,
          padding: '10px 15px',
          flexGrow: 0,
          '&.Mui-expanded': {
            margin: 0,
            minHeight: 44,
          },
        },
        expandIcon: {
          padding: 0,
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          overflowX: 'scroll',
        },
      },
    },
    MuiStack: {
      styleOverrides: {
        root: {
          alignItems: 'stretch !important',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        listbox: {
          '& .MuiAutocomplete-option': {
            minHeight: 40,
            fontWeight: 300,
            fontSize: '13px',
            lineHeight: '19.5px',
            whiteSpace: 'nowrap',
            padding: '8px 16px',
            overflow: 'hidden',
          },
        },
        noOptions: {
          display: 'flex',
          alignItems: 'center',
          minHeight: 40,
          color: 'rgb(51, 60, 68)',
          fontWeight: '300',
          fontSize: '13px',
          lineHeight: '19.5px',
          whiteSpace: 'nowrap',
          padding: '8px 16px',
          margin: '8px 0px',
          overflow: 'hidden',
        },
        endAdornment: {
          top: 'unset',
        },
      },
    },
    RaChipField: {
      styleOverrides: {
        chip: {
          margin: 0,
          marginRight: 4,
        },
      },
    },
    RaSaveButton: {
      styleOverrides: {
        button: {
          marginRight: '0px',
        },
      },
    },
    RaImageField: {
      styleOverrides: {
        image: {
          margin: 0,
          objectFit: 'contain',
          backgroundColor: '#e0e0e0',
        },
      },
    },
    RaBulkActionsToolbar: {
      styleOverrides: {
        root: {
          '& .RaBulkActionsToolbar-toolbar': {
            height: 61,
            minHeight: 61,
            transform: 'translateY(-61px)',
            '&.RaBulkActionsToolbar-collapsed': {
              height: 0,
              minHeight: 0,
            },
            '& h6': {
              fontSize: '13px',
              lineHeight: '19.5px',
              fontWeight: 500,
              height: '19.5px',
            },
          },
          '& .RaBulkActionsToolbar-title': {
            alignItems: 'center',
          },
          '& .RaBulkActionsToolbar-icon': {
            marginLeft: 0,
            marginRight: 4,
          },
        },
      },
    },
    RaTopToolbar: {
      styleOverrides: {
        root: {
          alignItems: 'center',
          justifyContent: 'space-between',
          backgroundColor: 'transparent !important',
          [breakpoints.down('md')]: {
            minHeight: 'unset',
          },
        },
      },
    },
    RaList: {
      styleOverrides: {
        main: {},
        bulkActionsDisplayed: {
          marginTop: '0',
        },
        filterToolbar: {
          borderBottom: '1px solid rgb(244, 245, 246)',
          '& > div': {
            padding: '10px 12px !important',
          },
        },
      },
    },
    RaListToolbar: {
      styleOverrides: {
        root: {
          alignItems: 'center',
          backgroundColor: 'transparent !important',
          [breakpoints.up('sm')]: {
            minHeight: '60px !important',
          },
        },
        actions: {
          paddingTop: '0px',
          paddingBottom: '0px',
          backgroundColor: 'transparent !important',
        },
      },
    },
    RaSelectInput: {
      styleOverrides: {
        input: {},
      },
    },
    RaFilterFormInput: {
      styleOverrides: {
        root: {
          width: '100%',
          '& .MuiAutocomplete-root': {
            width: 140,
            '&  > .MuiFormControl-root': {
              marginTop: 0,
              marginBottom: 0,
              '& > label': {
                color: 'rgb(152 161 169)',
                '&.MuiInputLabel-root': {
                  transform: 'translate(12px, 6px) scale(1) !important',
                },
                '&.MuiInputLabel-shrink': {
                  transform: 'translate(12px, -6px) scale(0.75) !important',
                },
              },
              '& .MuiInputBase-root': {
                minHeight: 26,
              },
            },
          },
          '& button.hide-filter': { display: 'none' },
          '& .MuiCheckbox-root': { padding: 5 },
          '& .RaFilterFormInput-spacer': { display: 'none' },
        },
        body: {
          alignItems: 'center',
          margin: '0px 5px',
        },
        spacer: { display: 'none' },
        hideButton: { display: 'none' },
      },
    },
    RaFilterGuesser: {
      styleOverrides: {
        input: {
          width: '100%',
          marginTop: 0,
          marginBottom: 0,
          '& > label': {
            color: 'rgb(152 161 169)',
            width: '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            paddingRight: 25,
            whiteSpace: 'nowrap',
            '& > span[class*="MuiTypography-root"]': {
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              maxWidth: '100%',
            },
            '&[class*="MuiInputLabel-root"]': {
              transform: 'translate(12px, 6px) scale(1) !important',
            },
            '&[class*="MuiInputLabel-shrink"]': {
              transform: 'translate(12px, -6px) scale(0.75) !important',
            },
          },
          '& div[class*="MuiInputAdornment-root"]': {
            display: 'none',
          },
          '& .MuiFormControlLabel-root': {
            marginLeft: 0,
            marginRight: 0,
          },
          '& > div': {
            minHeight: 26,
            paddingRight: 2,
            marginLeft: 0,
            backgroundColor: 'rgb(244, 245, 246)',
          },
          '& > div > div': {
            marginLeft: 0,
            '&[class*="RaResettableTextField-selectAdornment"]': {
              right: 16,
            },
          },
          '& > div > input': {
            minWidth: 'auto',
            paddingRight: '4px !important',
            color: 'rgb(31, 39, 46)',
          },
          '& > p': {
            display: 'none',
          },
          '&[class*="RaSelectInput-input"]': {
            '& > div > div': {
              padding: '4px 12px !important',
            },
          },
          '& input[type*="date" i]::-webkit-calendar-picker-indicator': {
            marginLeft: 0,
            marginRight: 25,
            width: '100%',
            zIndex: 0,
            position: 'absolute',
            opacity: 0,
          },
        },
      },
    },
    RaTabbedShowLayout: {
      styleOverrides: {
        content: {
          overflow: 'scroll',
          padding: '16px !important',
          '& > span > div > div': {
            width: '100%',
          },
        },
      },
    },
    RaTabbedForm: {
      styleOverrides: {
        content: {
          padding: '16px !important',
        },
      },
    },
    RaAutocompleteSuggestionList: {
      styleOverrides: {
        suggestionsContainer: {
          zIndex: 999999,
        },
      },
    },
    RaEdit: {
      styleOverrides: {
        root: {
          '& > div:first-of-type': {
            minHeight: 60,
          },
          '& .RaEdit-main': {
            maxWidth: '800px !important',
            margin: 'auto',
            '& > .MuiPaper-root': {
              background: 'none',
              boxShadow: 'none',
            },
          },
        },
      },
    },
    RaCreate: {
      styleOverrides: {
        root: {
          '& .RaCreate-main': {
            maxWidth: '800px !important',
            margin: 'auto',
          },
        },
      },
    },
    RaShow: {
      styleOverrides: {
        root: {
          '& .RaShow-main': {
            margin: 'auto',
            display: 'block',
          },
        },
      },
    },
    RaAutocompleteSuggestionItem: {
      styleOverrides: {
        suggestion: {
          minHeight: '24px',
          lineHeight: '24px',
        },
      },
    },
    RaLabeled: {
      styleOverrides: {
        label: {
          fontSize: '12px',
          lineHeight: '18px',
          fontWeight: 400,
          transform: 'none !important',
        },
        value: {
          fontSize: '13px',
        },
      },
    },
    RaButton: {
      styleOverrides: {
        button: {
          padding: '5px 5px',
        },
        mediumIcon: {
          fontSize: '20px',
        },
        label: {
          padding: '0px 5px',
        },
      },
    },
    RaNotification: {
      styleOverrides: {
        success: {
          backgroundColor: '#8DCD03',
          color: '#fff',
        },
        error: {
          backgroundColor: '#E00930',
          color: '#fff',
        },
      },
    },
    RaBooleanField: {
      styleOverrides: {
        root: {
          '& > span': {
            height: 20,
          },
        },
      },
    },
    RaFormInput: {
      styleOverrides: {
        input: {
          width: '100%',
        },
      },
    },
    RaFileInput: {
      styleOverrides: {
        root: {
          fontSize: '13px',
          lineHeight: '19.5px',
          fontWeight: 400,
          color: 'rgb(51 60 68)',
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          paddingLeft: '12px',
          paddingRight: '12px',
        },
      },
    },
  },
};
export default createTheme(defaultTheme);
