import { clearCaches, fetchAndCache } from '../cache';
import axios from './axios';

let refreshTimeOutId;
const userStorageKey = 'user.me';
const clearableCacheStorageKeys = [
  'resource.permitted',
  userStorageKey,
  'schema',
  'translation.*',
  '*.filterId',
];

export function getCookie(cookieName) {
  const cookieValue = document.cookie
    ? document?.cookie
        ?.split('; ')
        ?.find((row) => row.startsWith(cookieName))
        ?.split('=')[1]
    : undefined;
  return cookieValue;
}

export function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = `expires=${d.toUTCString()}`;
  document.cookie = `${cname}=${cvalue};${expires};path=/`;
}

const abordRefreshToken = () => {
  if (refreshTimeOutId) {
    window.clearTimeout(refreshTimeOutId);
  }
};

const clearAllDataAfterLogout = () => {
  clearCaches({
    localStorageKeys: clearableCacheStorageKeys,
  });
};

export const fetchUser = (forceUpdate = false) =>
  fetchAndCache({
    localStorageKey: userStorageKey,
    forceUpdate,
    cacheDurationInMinutes: 1,
    handler: async () => {
      const { data } = await axios.get('/Auth/me');
      return data;
    },
  });

export default {
  login: async (payload) => {
    try {
      const response = await axios.post('/Auth/token/password', payload, {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        validateStatus: (status) => status < 500,
      });

      if (response.status < 200 || response.status >= 300) {
        return Promise.reject(
          new Error(response?.data?.message ?? response.statusText),
        );
      }

      return Promise.resolve();
    } catch (error) {
      return Promise.reject(
        new Error(error.response?.data?.message || error.message),
      );
    }
  },

  // called when the user clicks on the logout button
  logout: async () => {
    try {
      await axios.post('/Auth/signout');
      return Promise.resolve();
    } catch {
      return Promise.resolve();
    } finally {
      abordRefreshToken();
      clearAllDataAfterLogout();
    }
  },
  // called when the API returns an error
  checkError: ({ status }) => {
    if (status === 401) {
      clearAllDataAfterLogout();
      return Promise.reject();
    }
    return Promise.resolve();
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: async () => {
    return axios.post('/Auth/check-auth');
  },
  getPermissions: async () => [],
};
