import { Box } from '@mui/material';
import { styled } from '@mui/system';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { useFormContext } from 'react-hook-form';
import { HOURLY_TOTAL_BOOKING_TIME_MIN } from '../../../constants';
import NumberInput from '../number';
import useStyles from './index.style';

const InputAdornment = styled('div')(
  () => `
  margin: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  grid-row: 1/3;
  color: black;
`,
);

const TotalTimeBookingInput = ({ fieldName, onChange }) => {
  const classes = useStyles();
  const { setValue, unregister } = useFormContext();
  const inputRef = React.useRef();

  const handleChange = (event) => {
    event.preventDefault();
    const { value } = event?.target || {};
    if (value) {
      const finalValue =
        value < HOURLY_TOTAL_BOOKING_TIME_MIN
          ? HOURLY_TOTAL_BOOKING_TIME_MIN
          : value;
      setValue(fieldName, finalValue);
      onChange?.(finalValue);
    }
  };

  React.useEffect(() => {
    setValue(fieldName, HOURLY_TOTAL_BOOKING_TIME_MIN);
    return () => {
      unregister(fieldName);
    };
  }, []);

  return (
    <Box className={classes.root}>
      <NumberInput
        ref={inputRef}
        min={HOURLY_TOTAL_BOOKING_TIME_MIN}
        defaultValue={HOURLY_TOTAL_BOOKING_TIME_MIN}
        onChange={handleChange}
        endAdornment={<InputAdornment>hours</InputAdornment>}
      />
    </Box>
  );
};

TotalTimeBookingInput.propTypes = {
  fieldName: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};

TotalTimeBookingInput.defaultProps = {
  onChange: () => null,
};

TotalTimeBookingInput.defaultProps = {};
export default memo(TotalTimeBookingInput);
