/* eslint-disable react/require-default-props */
import { Box, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useInput } from 'react-admin';
import { useFormContext } from 'react-hook-form';

const DateInput = props => {
  const { label } = props;
  const {
    field: {
      name, onChange, ...rest
    },
    fieldState: {
      error, isTouched: touched,
    },
  } = useInput(props);

  return (
    <TextField
      name={name}
      label={label}
      onChange={onChange}
      error={!!(touched && error)}
      helperText={touched && error}
      {...rest}
      type="datetime-local"
      InputLabelProps={{
        shrink: true,
      }}
      variant="outlined"
    />
  );
};

DateInput.propTypes = {
  label: PropTypes.string,
};

function DatePickerInput(props) {
  const {
    source, className, ...rest
  } = props;
  const [op, setOp] = useState('');
  const { getValues } = useFormContext();

  const values = getValues();

  useEffect(() => {
    setOp(values?.[source]?.op);
  }, [values?.[source]?.op]);

  return (
    <>
      {op === '$between' || op === '$notbetween' ? (
        <Box
          display="flex"
          className={className}
        >
          <Box
            marginRight="8px"
            flexGrow={1}
          >
            <DateInput
              source={`${source}.value.start`}
              label="Start"
              {...rest}
            />
          </Box>
          <Box flexGrow={1}>
            <DateInput
              source={`${source}.value.end`}
              label="End"
              {...rest}
            />
          </Box>
        </Box>
      ) : (
        <Box className={className}>
          <DateInput
            source={`${source}.value`}
            label="Value"
            {...rest}
          />
        </Box>
      )}
    </>
  );
}
export default DatePickerInput;

DatePickerInput.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  source: PropTypes.string,
};
