import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .slick-dots li button:before': {
      display: 'none !important',
    },
  },

  wrapperImg: {
    width: '100%',
    height: 230,
    borderRadius: '10px',
    overflow: 'hidden',
  },

  image: {
    width: '100%',
    height: 230,
  },

  carouselContainer: {
    marginTop: theme.spacing(3),
  },

  article: {
    marginTop: theme.spacing(4),
  },

  info: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    gap: theme.spacing(2),
  },

  articleHeader: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    gap: theme.spacing(2),
  },
  articleHeaderInner: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
  },
  articleFooterInner: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    width: '100%',
  },
  txtAddressName: {
    fontSize: 15,
    color: '#000000',
    fontFamily: 'QuickSandBold',
  },

  txtAddress: {
    fontSize: 12,
    color: '#022491',
    fontFamily: 'QuickSandBold',
  },

  locationComponentContainer: {
    // marginTop: theme.spacing(3),
  },
  chooseButton: {
    padding: theme.spacing(0, 4),
    fontSize: 14,
    height: 40,
    alignSelf: 'flex-end',
    borderRadius: 24,
    whiteSpace: 'nowrap',
  },

  directionButton: {
    padding: theme.spacing(0, 2),
    fontSize: 10,
    height: 20,
    alignSelf: 'flex-end',
    backgroundColor: '#ffffff',
    color: '#000000',

    border: '1px solid #DBDBDC',
    borderRadius: 6,
    gap: theme.spacing(1),
    fontFamily: 'QuickSandSemiBold',

    '& svg': {
      fontSize: 14,
    },
  },

  placeholder: {
    minHeight: 250,
  },

  directionContainer: {
    '& img': {
      maxWidth: '100%',
    },
  },
}));

export default useStyles;
