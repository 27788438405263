import axios from 'axios';
import { debounce } from 'lodash';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useError from '../../../../../../../base/hooks/useError';
import { REQUEST_INBOX } from '../../../../../../../services/redux/notification/notification.action';
import InboxItem from './InboxItem';
import InfinityList from './InfinityList';

const InboxList = () => {
  const dispatch = useDispatch();
  const [readIds, setReadIds] = useState([]);
  const { notifyError } = useError();

  const handleReadItem = (id) => setReadIds((ids) => [...ids, id]);

  const makeReads = useCallback(
    debounce((ids) => {
      axios
        .post(`/inbox/mark-reads`, ids)
        .then(() => {
          setReadIds([]);
        })
        .catch((err) => notifyError(err));
    }, 3000),
    [setReadIds],
  );

  useEffect(() => {
    if (readIds.length) {
      makeReads(readIds);
    }
  }, [readIds]);

  const { data, page, pageCount } = useSelector(
    ({ notification }) => notification.inbox,
  );

  const canLoadMore = !(page >= pageCount);

  const loadMore = () => {
    dispatch({ type: REQUEST_INBOX, page: page + 1 });
  };

  return (
    <InfinityList
      data={data}
      hasMore={canLoadMore}
      next={loadMore}
      renderItem={(item, scrollTargetId, previous) => (
        <InboxItem
          data={item}
          key={item?.id}
          scrollTargetId={scrollTargetId}
          previous={previous}
          onRead={handleReadItem}
        />
      )}
    />
  );
};

export default memo(InboxList);
