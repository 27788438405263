import { Box } from '@mui/material';
import { styled } from '@mui/system';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { useFormContext } from 'react-hook-form';
import { NUMBER_OF_GUEST_MAX, NUMBER_OF_GUEST_MIN } from '../../../constants';
import NumberInput from '../number';
import useStyles from './index.style';

const InputAdornment = styled('div')(
  () => `
  margin: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  grid-row: 1/3;
  color: black;
`,
);

const NumberGuestInput = ({ fieldName }) => {
  const classes = useStyles();
  const { setValue, unregister } = useFormContext();
  const inputRef = React.useRef();

  const handleChange = (event) => {
    event.preventDefault();
    const { value } = event?.target || {};
    setValue(
      fieldName,
      value <= NUMBER_OF_GUEST_MIN ? NUMBER_OF_GUEST_MIN : value,
    );
  };

  React.useEffect(() => {
    setValue(fieldName, NUMBER_OF_GUEST_MIN);
    return () => {
      unregister(fieldName);
    };
  }, []);

  return (
    <Box className={classes.root}>
      <NumberInput
        ref={inputRef}
        min={NUMBER_OF_GUEST_MIN}
        max={NUMBER_OF_GUEST_MAX}
        defaultValue={NUMBER_OF_GUEST_MIN}
        onChange={handleChange}
        endAdornment={<InputAdornment>guests</InputAdornment>}
      />
    </Box>
  );
};

NumberGuestInput.propTypes = {
  fieldName: PropTypes.string.isRequired,
};

NumberGuestInput.defaultProps = {};

export default memo(NumberGuestInput);
