import PropTypes from 'prop-types';
import React, { useCallback, useContext } from 'react';

const MainLayoutContext = React.createContext(null);

export const useMainLayoutContext = () => useContext(MainLayoutContext);

export const MainLayoutContextProvider = ({ children }) => {
  const [scrollOffsetY, setScrollOffsetY] = React.useState(0);
  const [containerWidth, setContainerWidth] = React.useState(0);
  const [containerHeight, setContainerHeight] = React.useState(0);
  const [backgroundColor, setBackgroundColor] = React.useState(undefined);
  const [backgroundImage, setBackgroundImage] = React.useState(undefined);
  const [openSidebar, toggleSidebar] = React.useState(false);
  const [sidebarContentTextColor, setSidebarContentTextColor] =
    React.useState(undefined);

  const handleToggleSidebar = useCallback(
    (value) => {
      if (typeof value === 'boolean') toggleSidebar(value);
      else toggleSidebar((v) => !v);
    },
    [toggleSidebar],
  );

  return (
    <MainLayoutContext.Provider
      value={{
        scrollOffsetY,
        setScrollOffsetY,
        containerWidth,
        setContainerWidth,
        containerHeight,
        setContainerHeight,
        backgroundColor,
        setBackgroundColor,
        backgroundImage,
        setBackgroundImage,
        sidebarContentTextColor,
        setSidebarContentTextColor,
        openSidebar,
        toggleSidebar: handleToggleSidebar,
      }}
    >
      {children}
    </MainLayoutContext.Provider>
  );
};

MainLayoutContextProvider.propTypes = {
  children: PropTypes.object.isRequired,
};

MainLayoutContextProvider.defaultProps = {};
