import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
  },
  serviceSelectedTxt: {
    fontSize: 15,
    color: 'black',
    fontFamily: 'QuickSandBold',
  },
  menuWrapper: {
    marginLeft: theme.spacing(2)
  }
}));

export default useStyles;
