const prefix = 'notification';

export const REQUEST_INBOX = `${prefix}/REQUEST_INBOX`;
export const SET_INBOX = `${prefix}/SET_INBOX`;
export const READ_INBOX = `${prefix}/READ_INBOX`;
export const SET_INVISIBLE_INBOX_COUNT = `${prefix}/SET_INVISIBLE_INBOX_COUNT`;

export const setInbox = (params) => ({
  data: params.data,
  page: params.page,
  pageCount: params.pageCount,
  limit: params.limit,
  type: SET_INBOX,
});
