import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  item: {
    height: 230,
    borderRadius: 10,
    overflow: 'hidden',
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
}));

export default useStyles;
