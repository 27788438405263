import { camelCase, isEmpty, startCase, without } from 'lodash';
import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router';
import configs from '../../services/configs';
import useAuthUser from './useAuthUser';
import useNavigation from './useNavigation';
import useSetting from './useSetting';

const FAVICON_DEFAULT = './favicon.png';
const USER_TYPE = {
  system: 'system',
  merchant: 'merchant',
};

const useSite = () => {
  const user = useAuthUser();
  const { currentPage } = useNavigation();
  const settings = useSetting();

  const location = useLocation();

  const userType = useMemo(() => {
    if (user?.merchant?.id) {
      return USER_TYPE.merchant;
    }
    return USER_TYPE.system;
  }, [user?.merchant?.id]);

  const USER_TYPE_OPTIONS = {
    [USER_TYPE.system]: {
      favicon: settings?.site_favicon,
      title: 'Master BO - NF System',
    },
    [USER_TYPE.merchant]: {
      favicon: settings?.merchantSettings?.siteFavicon
        ? `${configs.apiUrl}/bo/blob/static?blobId=${settings.merchantSettings.siteFavicon.id}`
        : '',
      title: `Merchant ${user?.merchant?.code}`,
    },
  };

  const getTitle = () => {
    let { title } = USER_TYPE_OPTIONS[USER_TYPE.system];
    const { pathname } = location;

    if (!pathname) return title;
    const pathNameSplitted = without(pathname?.split('/'), '').filter(
      (pName) => pName !== 'group',
    );

    if (!isEmpty(pathNameSplitted)) {
      const pageName = pathNameSplitted?.shift();
      title = `BO - ${startCase(camelCase(pageName))}`;
    }

    return title;
  };

  useEffect(() => {
    const faviconEl = document.getElementById('site-favicon');
    if (!faviconEl) return null;

    const faviconUrl = USER_TYPE_OPTIONS[userType].favicon;
    if (faviconUrl) {
      faviconEl.href = faviconUrl;
      faviconEl.onerror = `this.onerror=null;this.href='${FAVICON_DEFAULT}'`;
    }

    return () => {
      // eslint-disable-line consistent-return
      if (!user?.id && faviconEl) {
        faviconEl.href = FAVICON_DEFAULT;
      }
    };
  }, [user, currentPage?.label]);

  useEffect(() => {
    if (user?.id) {
      document.title = getTitle();
    } else {
      document.title = 'BO - NF System';
    }
  }, [user, currentPage?.label, user?.id, location]);

  return {};
};

export default useSite;
