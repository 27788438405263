export default {
  flow: {
    list: true,
    show: true,
  },
  job: {
    create: true,
    edit: true,
    list: true,
    show: true,
  },
  analytic: { show: true },
  chap: {
    list: true,
    show: true,
  },
  'chap-kinto': {
    list: true,
    show: true,
  },
  form: {
    create: true,
    edit: true,
    list: true,
    show: true,
  },
  'form-public': {
    show: true,
  },
  role: {
    edit: true,
    show: true,
  },
  'shell-card-history': { show: true },
  staff: { list: true },
  user: {
    list: true,
    show: true,
  },
  'role-profile': {
    edit: true,
    show: true,
  },
};
