import React, { memo } from 'react';
import { Box } from '@mui/material';
import { uniqueId } from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';

const InfinityList = (props) => {
  const { renderItem, data, ...rest } = props;
  const id = uniqueId('scrollableTarget');

  return (
    <Box height="100%" overflow="auto" id={id}>
      <InfiniteScroll {...rest} dataLength={data.length} scrollableTarget={id}>
        {data.map((item, index) => renderItem(item, id, data[index - 1]))}
      </InfiniteScroll>
    </Box>
  );
};

export default memo(InfinityList);
