import { useResourceContext } from 'react-admin';
import useResources from './useResources';

const useResource = (name) => {
  const currentResourceName = useResourceContext();
  const resources = useResources();
  const resourceName = name || currentResourceName;
  const resource = resources?.find((x) => x.name === resourceName);

  return resource && { ...resource, resource: resourceName };
};

export default useResource;
