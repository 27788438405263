import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(4),
  },
  title: {
    fontSize: 15,
    fontFamily: 'QuickSandBold',
    color: '#686868',
    marginBottom: theme.spacing(4),
  },
}));

export default useStyles;
