import AddIcon from '@mui/icons-material/AddCircleOutline';
import CloseIcon from '@mui/icons-material/RemoveCircleOutline';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import get from 'lodash/get';
import Type from 'prop-types';
import React, { Children, cloneElement, isValidElement, useRef } from 'react';
import { useTranslate } from 'react-admin';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { TextInput } from './ra/inputs';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      padding: 0,
      marginBottom: 0,
      marginTop: 15,
      '& > li:last-child': {
        borderBottom: 'none',
      },
      width: '100%',
    },
    line: {
      display: 'flex',
      listStyleType: 'none',
      borderBottom: `solid 1px ${theme.palette.divider}`,
      [theme.breakpoints.down('xs')]: {
        display: 'block',
      },
      '&.fade-enter': {
        opacity: 0.01,
        transform: 'translateX(100vw)',
      },
      '&.fade-enter-active': {
        opacity: 1,
        transform: 'translateX(0)',
        transition: 'all 500ms ease-in',
      },
      '&.fade-exit': {
        opacity: 1,
        transform: 'translateX(0)',
      },
      '&.fade-exit-active': {
        opacity: 0.01,
        transform: 'translateX(100vw)',
        transition: 'all 500ms ease-in',
      },
    },
    index: {
      width: '3em',
      paddingTop: '1em',
    },
    form: {
      flex: 2,
      overflow: 'scroll',
    },
    action: {
      paddingTop: '0.5em',
    },
    leftIcon: {
      marginRight: theme.spacing(1),
    },
  }),
  {
    name: 'RaSimpleFormIterator',
  },
);

const DefaultAddButton = (props) => {
  const classes = useStyles(props);
  const translate = useTranslate();
  return (
    <Button size="small" {...props}>
      <AddIcon className={classes.leftIcon} />
      {translate('ra.action.add')}
    </Button>
  );
};

const DefaultRemoveButton = (props) => {
  const classes = useStyles(props);
  const translate = useTranslate();
  return (
    <Button size="small" {...props}>
      <CloseIcon className={classes.leftIcon} />
      {translate('ra.action.remove')}
    </Button>
  );
};

const FormIterator = (props) => {
  const {
    addButton = <DefaultAddButton />,
    removeButton = <DefaultRemoveButton />,
    children,
    fields,
    record,
    resource,
    source,
    disableAdd,
    disableRemove,
    variant,
    margin,
    TransitionProps,
    defaultValue,
    rowTitle,
  } = props;
  const classes = useStyles(props);

  // We need a unique id for each field for a proper enter/exit animation
  // so we keep an internal map between the field position and an auto-increment id
  const nextId = useRef(
    (fields && fields.length) || (defaultValue && defaultValue.length) || 0,
  );

  // We check whether we have a defaultValue (which must be an array) before checking
  // the fields prop which will always be empty for a new record.
  // Without it, our ids wouldn't match the default value and we would get key warnings
  // on the CssTransition element inside our render method
  const ids = useRef(
    nextId.current > 0 ? Array.from(Array(nextId?.current)?.keys()) : [],
  );

  const removeField = (index) => () => {
    ids.current.splice(index, 1);
    fields.remove(index);
  };

  // Returns a boolean to indicate whether to disable the remove button for certain fields.
  // If disableRemove is a function, then call the function with the current record to
  // determining if the button should be disabled. Otherwise, use a boolean property that
  // enables or disables the button for all of the fields.
  const disableRemoveField = (r, disable) => {
    if (typeof disable === 'boolean') {
      return disable;
    }
    return disable && disableRemove(r);
  };

  const addField = () => {
    ids.current.push(nextId.current);
    nextId.current += 1;
    fields.push(undefined);
  };

  // add field and call the onClick event of the button passed as addButton prop
  const handleAddButtonClick = (originalOnClickHandler) => (event) => {
    addField();
    if (originalOnClickHandler) {
      originalOnClickHandler(event);
    }
  };

  // remove field and call the onClick event of the button passed as removeButton prop
  const handleRemoveButtonClick =
    (originalOnClickHandler, index) => (event) => {
      removeField(index)();
      if (originalOnClickHandler) {
        originalOnClickHandler(event);
      }
    };

  const records = get(record, source);

  return fields ? (
    <ul className={classes.root}>
      {/* {submitFailed && typeof error !== 'object' && error && (
        <FormHelperText error>
          <ValidationError error={error} />
        </FormHelperText>
      )} */}
      <TransitionGroup component={null}>
        {fields.map((member, index) => (
          <CSSTransition
            key={member.id}
            timeout={500}
            classNames="fade"
            {...TransitionProps}
          >
            <li className={classes.line}>
              {!disableRemoveField(records?.[index] || {}, disableRemove) && (
                <span className={classes.action}>
                  {cloneElement(removeButton, {
                    onClick: handleRemoveButtonClick(
                      removeButton.props.onClick,
                      index,
                    ),
                    className: classNames(
                      'button-remove',
                      `button-remove-${source}-${index}`,
                    ),
                  })}
                </span>
              )}
              <section className={classes.form}>
                <Box display="flex">
                  <Box>
                    <Typography variant="body1" className={classes.index}>
                      {rowTitle ? (
                        get(records?.[index], rowTitle)
                      ) : (
                        <>{index + 1}</>
                      )}
                    </Typography>
                  </Box>
                  {Children.map(children, (input, index2) =>
                    isValidElement(input) ? (
                      <Box
                        flexGrow={1}
                        key={`${input.props.source}-${member.id}`}
                        marginLeft="8px"
                      >
                        <TextInput
                          {...{
                            defaultValue:
                              records?.[index]?.[input.props.source],
                            source: input.props.source
                              ? `${source}[${index}].${input.props.source}`
                              : `${source}[${index}]`,
                            index: input.props.source ? undefined : index2,
                            label:
                              typeof input.props.label === 'undefined'
                                ? (input.props.source &&
                                    `resources.${resource}.fields.${input.props.source}`) ||
                                  undefined
                                : input.props.label,
                          }}
                          record={records?.[index] || {}}
                          resource={resource}
                          variant={variant}
                          margin={margin}
                          style={{
                            width: '100%',
                          }}
                        />
                      </Box>
                    ) : null,
                  )}
                </Box>
              </section>
            </li>
          </CSSTransition>
        ))}
      </TransitionGroup>
      {!disableAdd && (
        <li className={classes.line}>
          <span className={classes.action}>
            {cloneElement(addButton, {
              onClick: handleAddButtonClick(addButton.props.onClick),
              className: classNames('button-add', `button-add-${source}`),
            })}
          </span>
        </li>
      )}
    </ul>
  ) : null;
};

FormIterator.defaultProps = {
  disableAdd: false,
  disableRemove: false,
  source: undefined,
  resource: undefined,
  variant: undefined,
  margin: undefined,
  translate: undefined,
  TransitionProps: undefined,
  rowTitle: undefined,
  children: undefined,
  classes: undefined,
  className: undefined,
  record: undefined,
  fields: undefined,
  defaultValue: undefined,
  addButton: undefined,
  removeButton: undefined,
};

FormIterator.propTypes = {
  // eslint-disable-next-line
  defaultValue: Type.any,
  addButton: Type.element,
  removeButton: Type.element,
  children: Type.node,
  classes: Type.object,
  className: Type.string,
  // @ts-ignore
  fields: Type.object,
  // @ts-ignore
  record: Type.object,
  source: Type.string,
  resource: Type.string,
  variant: Type.string,
  margin: Type.string,
  translate: Type.func,
  disableAdd: Type.bool,
  disableRemove: Type.oneOfType([Type.func, Type.bool]),
  TransitionProps: Type.shape({}),
  rowTitle: Type.string,
};

export default FormIterator;
