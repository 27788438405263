import { SET_FIELD_FILTER } from './filter.action';

const initialSettings = {
  resources: {},
};

export const filterReducer = (state = initialSettings, action) => {
  switch (action.type) {
    case SET_FIELD_FILTER: {
      return {
        ...state,
        resources: {
          ...state.resources,
          [action.resource]: action.properties,
        },
      };
    }

    default:
      return state;
  }
};
