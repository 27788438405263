import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    backgroundColor: '#022491',
    position: 'relative',
    justifyContent: 'center',
  },

  inner: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(15),
    alignItems: 'center',
    justifyContent: 'space-between',
    color: '#ffffff',
  },

  thanksContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    alignItems: 'center',
  },

  qrCodeImage: {
    flex: 1,
  },

  txtTitle: {
    fontSize: 32,
    fontFamily: 'QuickSandBold',
    lineHeight: 1.5,
  },

  txtSubtitle: {
    fontSize: 28,
    fontFamily: 'QuickSandRegular',
    lineHeight: 1.2,
  },
}));

export default useStyles;
