import React, { memo } from 'react';
import CmtVertical from '../../../../theme/@coremat/CmtNavigation/Vertical';
import useNavigation from '../../../hooks/useNavigation';

const AppSidebar = () => {
  const { navigationMenus } = useNavigation();
  return <CmtVertical menuItems={navigationMenus} />;
};

AppSidebar.defaultProps = {};
AppSidebar.propTypes = {};

export default memo(AppSidebar);
