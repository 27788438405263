import PropTypes from 'prop-types';
import ReactGA from 'react-ga4';

const useAnalyticsTracker = (category = 'EVENT CATEGORY') => {
  const eventGATracker = (
    action = 'action',
    label = 'label',
    ...eventProps
  ) => {
    ReactGA.event({
      category: category?.toLocaleUpperCase(),
      action,
      label,
      ...eventProps,
    });
  };

  return eventGATracker;
};

export const GAEventTypes = {
  ga: PropTypes.shape({
    category: PropTypes.string,
    action: PropTypes.string,
    label: PropTypes.string,
  }),
};
export default useAnalyticsTracker;
