/* eslint-disable react/require-default-props */
import InfoIcon from '@mui/icons-material/Info';
import { Box, FormControl, InputLabel, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';
import { FieldTitle } from './fields/FieldTitle';

const useStyles = makeStyles(
  (theme) => ({
    label: {
      position: 'relative',
      width: 'fit-content',
    },
    value: {
      fontFamily: theme.typography.fontFamily,
      color: 'currentColor',
      padding: `calc(${theme.spacing(1)} 0 ${theme.spacing(1)} / 2)`,
      border: 0,
      boxSizing: 'content-box',
      verticalAlign: 'middle',
      background: 'none',
      margin: 0, // Reset for Safari
      display: 'block',
      width: '100%',
      [theme.breakpoints.down(590)]: {
        '&.label-value>div': {
          whiteSpace: 'unset',
          height: 'auto !important',
          marginTop: '-8px',
          '& span[class*="MuiChip-label"]': {
            overflow: 'unset',
            whiteSpace: 'unset',
            textOverflow: 'unset',
            paddingLeft: '0',
            paddingRight: '0',
          },
        },
      },
    },
  }),
  {
    name: 'RaLabeled',
  },
);

/**
 * Use any component as read-only Input, labeled just like other Inputs.
 *
 * Useful to use a Field in the Edit or Create components.
 * The child component will receive the current record.
 *
 * This component name doesn't have a typo. We had to choose between
 * the American English "Labeled", and the British English "Labelled".
 * We flipped a coin.
 *
 * @example
 * <Labeled label="Comments">
 *     <FooComponent source="title" />
 * </Labeled>
 */
const Labeled = (props) => {
  const {
    children,
    className,
    fullWidth,
    id,
    input,
    isRequired,
    label,
    margin = 'dense',
    meta,
    resource,
    source,
    tooltip,
    ...rest
  } = props;

  if (label === false) {
    return <></>;
  }
  const classes = useStyles(props);
  if (!label && !source) {
    // @ts-ignore
    const name = children && children.type && children.type.name;

    throw new Error(
      `Cannot create label for component <${name}>: You must set either the label or source props.
      You can also disable automated label insertion by setting 'addLabel: false' in the component default props`,
    );
  }
  const restProps = fullWidth
    ? {
        ...rest,
        fullWidth,
      }
    : rest;

  return (
    <FormControl
      className={className}
      fullWidth={fullWidth}
      error={meta && meta.touched && !!meta.error}
      margin={margin}
    >
      {tooltip ? (
        <Tooltip title={tooltip} placement="right">
          <InputLabel htmlFor={id} shrink className={classes.label}>
            <Box display="flex" alignItems="center">
              <FieldTitle
                label={label}
                source={source}
                resource={resource}
                isRequired={isRequired}
              />
              <InfoIcon
                style={{
                  marginLeft: '8px',
                }}
              />
            </Box>
          </InputLabel>
        </Tooltip>
      ) : (
        <InputLabel htmlFor={id} shrink className={classes.label}>
          <FieldTitle
            label={label}
            source={source}
            resource={resource}
            isRequired={isRequired}
          />
        </InputLabel>
      )}
      <div className={`${classes.value} label-value`}>
        {children && typeof children.type !== 'string'
          ? React.cloneElement(children, {
              input,
              resource,
              ...restProps,
            })
          : children}
      </div>
    </FormControl>
  );
};

Labeled.propTypes = {
  children: PropTypes.element,
  className: PropTypes.string,
  classes: PropTypes.object,
  fullWidth: PropTypes.bool,
  id: PropTypes.string,
  input: PropTypes.object,
  isRequired: PropTypes.bool,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.bool,
  ]),
  meta: PropTypes.object,
  onChange: PropTypes.func,
  record: PropTypes.object,
  resource: PropTypes.string,
  source: PropTypes.string,
  labelStyle: PropTypes.object,
  margin: PropTypes.string,
  tooltip: PropTypes.string,
};

export default React.memo(Labeled);
