import { Box } from "@mui/material";
import clsx from "clsx";
import React, {
  Children,
  cloneElement,
  isValidElement,
  useEffect,
  useRef,
} from "react";
import { useResizeDetector } from "react-resize-detector";
import { useMainLayoutContext } from "../../../../base/context/main-layout";
import useStyles from "./MainContainer.style";

const MainContainer = ({
  drawerBreakPoint,
  sidebarWidth,
  className,
  children,
}) => {
  const headerRef = useRef(null);
  const sidebarRef = useRef(null);
  const contentRef = useRef(null);
  const footerRef = useRef(null);
  const horizontalLayout = {};

  const {
    width: containerWidth,
    height: containerHeight,
    ref: containerRef,
  } = useResizeDetector();
  const {
    setContainerWidth,
    setContainerHeight,
    backgroundColor,
    backgroundImage,
  } = useMainLayoutContext();

  useEffect(() => {
    setContainerWidth(containerWidth);
  }, [containerWidth]);

  useEffect(() => {
    setContainerHeight(containerHeight);
  }, [containerHeight]);

  Children.map(children, (child) => {
    if (isValidElement(child)) {
      if (child.type.render && child.type.defaultProps.name) {
        if (child.type.defaultProps.name === "Header") {
          horizontalLayout.header = cloneElement(child, {
            ref: headerRef,
          });
        } else if (child.type.defaultProps.name === "LayoutSidebar") {
          horizontalLayout.sidebar = cloneElement(child, {
            ref: sidebarRef,
            drawerBreakPoint,
            sidebarWidth,
          });
        } else if (child.type.defaultProps.name === "LayoutContent") {
          horizontalLayout.content = cloneElement(child, {
            ref: contentRef,
          });
        } else if (child.type.defaultProps.name === "LayoutFooter") {
          horizontalLayout.footer = cloneElement(child, {
            ref: footerRef,
          });
        }
      }
    }
  });

  const classes = useStyles({
    drawerBreakPoint,
    backgroundImage,
    backgroundColor,
  });

  return (
    <Box className={clsx(classes.appRoot, className)}>
      <Box className={classes.appInnerRoot}>
        <Box className={classes.appMainContainer}>
          <Box className={classes.appMain}>
            {horizontalLayout.header}
            <div ref={containerRef} className={classes.contentLayout}>
              {horizontalLayout.sidebar}
              {horizontalLayout.content}
            </div>
            {horizontalLayout.footer}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default MainContainer;
