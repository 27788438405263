import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import { ButtonBase, CircularProgress, Typography } from '@mui/material';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import useStyles from './index.style';

const DefaultButton = ({
  name,
  buttonProps,
  onClick,
  className,
  startIcon,
  variant,
  loading,
  success,
  disabled,
  ...props
}) => {
  const classes = useStyles();
  const isTextVariant = variant === 'text';

  const render = () => {
    if (success) return <DoneRoundedIcon />;
    if (loading)
      return <CircularProgress className={classes.loading} size={16} />;
    return (
      <>
        {startIcon}
        <Typography fontSize="inherit" component="span">
          {name}
        </Typography>
      </>
    );
  };
  return (
    <ButtonBase
      {...buttonProps}
      classes={{
        disabled: classes.buttonDisabled,
      }}
      onClick={onClick}
      className={clsx(
        classes.button,
        isTextVariant && classes.textVariantButton,
        success && classes.buttonSuccess,
        className,
      )}
      disabled={disabled || loading}
      {...props}
    >
      {render()}
    </ButtonBase>
  );
};

DefaultButton.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
  buttonProps: PropTypes.object,
  onClick: PropTypes.func,
  startIcon: PropTypes.element,
  variant: PropTypes.oneOf(['text', 'contained']),
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  success: PropTypes.bool,
};

DefaultButton.defaultProps = {
  buttonProps: {},
  name: 'button',
  className: null,
  onClick: undefined,
  startIcon: null,
  variant: 'contained',
  disabled: false,
  loading: false,
  success: false,
};

export default DefaultButton;
