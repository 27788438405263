import { Box } from '@mui/material';
import React from 'react';
import { useBookingCommonStyles } from '../common/style';
import { BookingHeader } from '../components';

const withMainLayout = (WrappedComponent) => (props) => {
  const bookingCommonClasses = useBookingCommonStyles();
  return (
    <Box className={bookingCommonClasses.root}>
      <Box className={bookingCommonClasses.commonHeaderContainer}>
        <BookingHeader />
      </Box>

      <Box className={bookingCommonClasses.commonArticleContainer}>
        <WrappedComponent {...props} />
      </Box>
    </Box>
  );
};

export default withMainLayout;
