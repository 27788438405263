import withRadioButton from '../../hoc/with-radio-button';
import BookingCarItem from './car';
import BookingDirectionItem from './direction';
import BookingLocationItem from './location';
import BookingPlaceItem from './place';

const BookingCarRadioButtonItem = withRadioButton(BookingCarItem);

export {
  BookingCarItem,
  BookingLocationItem,
  BookingPlaceItem,
  BookingDirectionItem,
  BookingCarRadioButtonItem,
};
