import Input from '@mui/material/Input';
import React, { forwardRef, memo } from 'react';
import useStyles from './index.style';

const ariaLabel = { 'aria-label': 'description' };

const NormalInput = forwardRef((props, ref) => {
  const classes = useStyles();
  return (
    <Input
      ref={ref}
      className={classes.root}
      inputProps={ariaLabel}
      {...props}
    />
  );
});

NormalInput.propTypes = {};

NormalInput.defaultProps = {};

export default memo(NormalInput);
