import { Box, Typography } from '@mui/material';
import React from 'react';
import { useLocation } from 'react-router';
import { Image } from '../../components';
import withHeader from '../../hoc/with-header';
import useStyles from './index.style';
import ErrorMessages from '../../messages/error';
import { ERROR_PATH } from '../../constants';

const BookingError = () => {
  const classes = useStyles();
  const { state } = useLocation();

  const isStripeFail = window.location.hash.includes(ERROR_PATH);

  let error = state;
  if (state === 'Event not found or not eligible.')
    error = ErrorMessages.NOT_ELIGIBLE;
  if (isStripeFail) error = ErrorMessages.ONLINE_PAYMENT_UNAVAILABLE;

  const {
    message = '',
    title = 'We’re Sorry!',
    description = 'Something went wrong. Please try again',
  } = error || {};

  return (
    <Box className={classes.error}>
      <Image
        className={classes.image}
        src="images/booking/error.jpg"
        alt="Oops"
      />
      <Typography className={classes.title}>{title}</Typography>
      <Typography className={classes.message}>{message}</Typography>
      {description && (
        <Typography className={classes.message}>{description}</Typography>
      )}
    </Box>
  );
};

export default withHeader(BookingError);
