import { maxBy, minBy } from 'lodash';
import { LOCATIONS } from '../constants';
import { publish } from '../events';
import { haversine } from '../../../mapView/untils';

export const getMyLocation = (successCallback, errorCallback) => {
  navigator?.geolocation.getCurrentPosition(
    (geo) => {
      successCallback?.(geo);
      publish(LOCATIONS.MY_LOCATION, geo);
    },
    (error) => {
      errorCallback?.(error);
      publish(LOCATIONS.MY_LOCATION, {
        ...error,
        isSuccess: false,
      });
    },
    {
      enableHighAccuracy: true,
      maximumAge: 60000,
      timeout: 5000,
    },
  );
};

export const computeDistanceBetweenCoords = (fromCoords, toCoords, options) => {
  return haversine(fromCoords, toCoords, options);
};

export const getDistanceBetweenCoords = (
  fromCoords,
  coordList = [],
  options = {
    unit: 'km',
    format: '{id,location}',
  },
) => {
  let furthest = 0;
  let closest = 0;
  const computedList = [];

  coordList.forEach((coord) => {
    const distanceResult = computeDistanceBetweenCoords(
      fromCoords,
      coord,
      options,
    );
    computedList.push({
      distanceResult,
      id: coord.id,
      name: coord.location.addressName,
    });
  });

  furthest = maxBy(computedList, 'distanceResult');
  closest = minBy(computedList, 'distanceResult');
  return {
    furthest,
    closest,
  };
};
