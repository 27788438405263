import { clearLocalStorage } from '../../base/hooks/useLocalStorage';

const appVersion = '1.0.1';
const key = 'app_version';

const checkAppVersion = () => {
  const currentVersion = localStorage.getItem(key);

  if (appVersion !== currentVersion) {
    clearLocalStorage();
    localStorage.setItem(key, appVersion);
  }
};

export default checkAppVersion;
