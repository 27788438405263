export const API_ROUTES = {
  // GET
  getEventByCode: '/event/code/{code}',
  getQrCode: '/bo/nf/qr',
  getQrCodeDownload: '/bo/nf/qr-download',
  getReservation: '/event-reservation/get-reservation-job',
  getEventZoneArea: '/event-zone-area',

  //   POST
  postFee: '/event-reservation/job/fees',
  postBooking: '/event-reservation/create-reservation-job',
  postSendReceipt: '/event-reservation/{id}/send-receipt',
  postStripeCheckout: '/event-reservation/{id}/stripe-checkout',
};

export const GOOGLE_ROUTES = {
  getPlacePhoto: 'https://maps.googleapis.com/maps/api/place/photo',
  getPlaceDetail: 'https://maps.googleapis.com/maps/api/place/details/json',
};
