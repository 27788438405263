/* eslint-disable import/no-cycle */
import guessFieldComponentWithProp from './nf-field.guesser';
import guessInputComponentWithProp from './nf-input.guesser';

export const guessProperty = ({
  source,
  properties,
  required: requiredFields,
  apiRef,
  api,
  view,
  resource,
  resources,
  record,
  locale,
  translate,
  createForm,
  hidden,
  renderExtraComponents,
  withHoc,
  ignoreDependOn,
}) => {
  if (!properties?.[source]) {
    throw new Error(`Unknown property ${source}`);
  }

  switch (view) {
    case 'list':
      return guessFieldComponentWithProp({
        source,
        properties,
        requiredFields,
        apiRef,
        api,
        view,
        resource,
        resources,
        record,
        locale,
        translate,
        hidden,
        ignoreDependOn,
      });
    case 'edit':
    case 'create':
      return guessInputComponentWithProp({
        source,
        properties,
        requiredFields,
        apiRef,
        api,
        view,
        resource,
        resources,
        record,
        locale,
        translate,
        createForm,
        hidden,
        renderExtraComponents,
        withHoc,
        ignoreDependOn,
      });
    default:
      return guessFieldComponentWithProp({
        source,
        properties,
        requiredFields,
        apiRef,
        api,
        view,
        resource,
        resources,
        record,
        locale,
        translate,
        hidden,
        ignoreDependOn,
      });
  }
};
