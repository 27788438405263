import FileCopyIcon from '@mui/icons-material/FileCopy';
import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { get, kebabCase, omit, set } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import {
  Datagrid,
  Labeled,
  SingleFieldList,
  useNotify,
  useRecordContext,
  useTranslate,
} from 'react-admin';
import { JsonField } from 'react-admin-json-view';
import {
  getRefField,
  getResourceFieldMessageKey,
  getSourceRefTitle,
  parseSourceRef,
} from '../../utils';
import TestableContainer from '../container/testable';
import {
  ArrayField,
  BooleanField,
  ChipField,
  IdField,
  ImageField,
  NumberField,
  OwnerField,
  RefField,
  TextField,
} from '../ra/fields';
import CustomLabeled from '../ra/labeled';
import FieldTooltip from '../tooltip/field';
import { guessFieldComponent } from './nf-component.guesser';

const useStyles = makeStyles(() => ({
  btn: {
    cursor: 'pointer',
  },
}));

const CustomLabel = React.memo((props) => {
  const classes = useStyles();
  const notify = useNotify();
  const translate = useTranslate();
  const { record, source, isCopy, translatableInput, locale, label } = props;
  let title = translate(label);

  // TRANSLATABLE_INPUT
  if (translatableInput) {
    title = `${title} (${locale})`;
  }

  return (
    <Box display="inline-flex" alignItems="center">
      <span>{title}</span>
      {isCopy && (
        <Box
          display="inline"
          ml={3}
          className={classes.btn}
          onClick={(e) => {
            e.preventDefault();
            navigator.clipboard.writeText(record[source]);
            notify('Copied', {
              type: 'success',
            });
          }}
        >
          <FileCopyIcon />
        </Box>
      )}
    </Box>
  );
});

CustomLabel.propTypes = {
  translatableInput: PropTypes.bool,
  locale: PropTypes.string.isRequired,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
  isCopy: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
};

CustomLabel.defaultProps = {
  translatableInput: undefined,
  record: {},
};

const ArrayFieldSingleList = ({ children, ...props }) => {
  const {
    translatableInput, // eslint-disable-line no-unused-vars
    ...restProps
  } = props;
  return (
    <SingleFieldList
      {...restProps}
      linkType={false}
      className="single-field-list"
    >
      {React.cloneElement(children, {
        ...restProps,
        ...children.props, // eslint-disable-line react/prop-types
      })}
    </SingleFieldList>
  );
};
ArrayFieldSingleList.propTypes = {
  children: PropTypes.element.isRequired,
  translatableInput: PropTypes.bool,
};
ArrayFieldSingleList.defaultProps = {
  translatableInput: undefined,
};
// @TODO: REFACTOR use constant for hardcode (view, type, format)
const guessFieldComponentWithProp = ({
  source,
  properties,
  apiRef,
  view = 'show',
  resource,
  resources,
  record: globalRecord,
  locale,
  translate,
}) => {
  // const propertyKeys = Object.keys(properties || {});
  const sourceSchema = properties?.[source];
  const realResource = resource.includes('/')
    ? resource?.split('/')[1]
    : resource;
  const nfColSpan = sourceSchema?.colSpan;

  let messageKey = getResourceFieldMessageKey(properties, realResource, source);

  if (view === 'show') {
    const filterOns = sourceSchema?.properties?.filterOn?.properties || {};
    const dependOns =
      (!sourceSchema?.noDependOnShow &&
        sourceSchema?.properties?.dependOn?.properties) ||
      {};
    const dependOnProperties = {
      ...filterOns,
      ...dependOns,
    };

    const dependOnPropertyList = Object.keys(dependOnProperties);

    const shouldBeHidden = dependOnPropertyList.some((i) => {
      const filterOnProperty = filterOns[i]?.default;
      const dependOnValue = get(dependOns, i);
      const shouldFilterOnProp = !dependOnValue && !!filterOnProperty;
      const property = shouldFilterOnProp ? filterOnProperty : i;
      const propertyValue = get(globalRecord, property);

      // dependOn prop
      if (dependOnValue?.default === undefined) {
        return !propertyValue || propertyValue?.length === 0;
      }

      if (Array.isArray(dependOnValue?.default)) {
        if (Array.isArray(propertyValue)) {
          return !dependOnValue?.default?.some((item) =>
            propertyValue?.includes(item),
          );
        }
        return !dependOnValue?.default?.includes(propertyValue);
      }
      if (Array.isArray(propertyValue)) {
        return !propertyValue?.includes(dependOnValue?.default);
      }
      if (typeof dependOnValue?.default === 'boolean') {
        return propertyValue !== dependOnValue.default;
      }

      return propertyValue !== dependOnValue?.default;
    });

    if (shouldBeHidden) {
      return null;
    }
  }

  let { PropComponent: FieldComponent, guessedPropsComponent } =
    guessFieldComponent(sourceSchema);
  let sourceName = source;
  let SourceReferenceField;
  // TRANSLATABLE INPUTS
  let translatableInput = sourceSchema?.properties?.translatable?.default;

  let className = '';
  // Get field format
  const fieldFormat = sourceSchema?.format?.includes('||')
    ? sourceSchema?.format?.split('||')[0]
    : sourceSchema.format;
  const isJSON = fieldFormat === 'json';
  const collapseJSON = view === 'list' || !!sourceSchema?.properties?.collapse;

  // ref to a defined object type
  const fieldRef = getRefField(sourceSchema);

  const customRef = sourceSchema?.properties?.reference?.$ref;

  if (fieldRef) {
    const sourceRef = parseSourceRef(source, properties, apiRef, locale);
    const {
      PropComponent: sourceRefFieldComponent,
      guessedPropsComponent: sourceRefGuessedPropsComponent,
    } = guessFieldComponent(sourceRef.sourceRefTitleSchema);

    sourceName = sourceRef.source;
    FieldComponent = sourceRefFieldComponent;
    guessedPropsComponent = sourceRefGuessedPropsComponent;
    SourceReferenceField = sourceRef.SourceReferenceField;
    translatableInput = sourceRef.translatableInput;

    if (sourceName === 'blobUrlBO') {
      className = view === 'list' ? 'table-image-field' : 'default-image-field';
    }
  }

  // Guess ReferenceField
  if (customRef) {
    const realRef = resources?.find((r) => {
      if (r.name.includes('/')) {
        return r.name === kebabCase(SourceReferenceField.reference);
      }

      return false;
    });

    // ReferenceField is memorized while RAReferenceField not.
    // Memorized component has a problem with addLabel prop -> field won't display label
    // const ReferenceFieldComponent = view === 'list' ? ReferenceField : RAReferenceField;

    const isReferenced = !!properties[source]?.referenced;
    const trueSource = isReferenced
      ? `${SourceReferenceField.source}.id`
      : SourceReferenceField.source;

    const component = (
      <RefField
        key={sourceName}
        source={trueSource}
        reference={
          sourceSchema?.properties?.alias?.default || // custom reference resource
          realRef?.name ||
          kebabCase(SourceReferenceField.reference)
        }
        label={messageKey}
        sortable={false}
        view={view}
      >
        <FieldComponent
          source={sourceName}
          className={className}
          view={view}
          {...guessedPropsComponent}
        />
      </RefField>
    );

    if (view === 'show') {
      return (
        <TestableContainer
          key={sourceName}
          nfid={kebabCase(`${resource}-${view}-field-${sourceName}`)}
        >
          {SourceReferenceField.reference ? (
            component
          ) : (
            <Labeled label={messageKey}>{component}</Labeled>
          )}
        </TestableContainer>
      );
    }

    return (
      <TestableContainer
        key={sourceName}
        nfid={kebabCase(`${resource}-${view}-field-${sourceName}`)}
      >
        {component}
      </TestableContainer>
    );
  }

  // Guess ReferenceField
  if (SourceReferenceField) {
    // TRANSLATABLE_INPUT
    if (view === 'show' && translatableInput) {
      messageKey = `${translate(messageKey)} (${locale})`;
    }

    if (guessedPropsComponent?.valueType === 'image') {
      delete guessedPropsComponent.valueType;
      return (
        <TestableContainer
          key={sourceName}
          nfid={kebabCase(`${resource}-${view}-field-${sourceName}`)}
        >
          <RefField
            key={sourceName}
            source={SourceReferenceField.source}
            reference={kebabCase(SourceReferenceField.reference)}
            label={messageKey}
            link={false}
            sortable={false}
            view={view}
          >
            <FieldComponent
              source={sourceName}
              className={className}
              {...guessedPropsComponent}
              view={view}
              label={messageKey}
            />
          </RefField>
        </TestableContainer>
      );
    }

    return (
      <TestableContainer
        key={sourceName}
        nfid={kebabCase(`${resource}-${view}-field-${sourceName}`)}
      >
        <RefField
          sourceSchema={sourceSchema}
          source={SourceReferenceField.source}
          reference={kebabCase(SourceReferenceField.reference)}
          label={messageKey}
          translatableInput={translatableInput}
          hasLink={FieldComponent?.type?.name !== 'ImageField'}
          sortable={false}
          view={view}
        >
          <FieldComponent
            source={sourceName}
            className={className}
            view={view}
            {...guessedPropsComponent}
          />
        </RefField>
      </TestableContainer>
    );
  }

  if (sourceName === 'ownerId') {
    const { _metaData } = properties;
    return (
      <TestableContainer
        key={sourceName}
        nfid={kebabCase(`${resource}-${view}-field-${sourceName}`)}
      >
        <Labeled source={sourceName} key={sourceName}>
          <OwnerField
            ownerRef={_metaData?.properties?.owner?.default}
            formClassName={sourceName}
          />
        </Labeled>
      </TestableContainer>
    );
  }

  // Guess ArrayField
  if (sourceSchema?.type === 'array') {
    const refField =
      sourceSchema?.items?.$ref ||
      sourceSchema?.items?.allOf?.filter((i) => i?.$ref)?.[0]?.$ref;
    const refName = kebabCase(refField?.split('/schemas/')?.pop());

    // TRANSLATABLE_INPUT
    const sourceRef = apiRef.get(refField);
    let chipSource = getSourceRefTitle(
      sourceRef.properties,
      null,
      null,
      sourceRef,
    );
    const sourceRefTitleSchema = sourceRef.properties?.[chipSource];
    let translatableInput = false; // eslint-disable-line no-shadow
    if (sourceRefTitleSchema?.properties?.translatable?.default) {
      chipSource = `${chipSource}.${locale}`;
      translatableInput = true;
    }

    // let chipSource = "name";
    if (refName === 'user') chipSource = 'username';

    const DatagridContainer = (props) => {
      const { data, ids, reference } = props;
      const {
        // eslint-disable-next-line
        translatableInput,
        ...datagridProps
      } = props;

      const fields = Object.keys(data?.[ids?.[0]] || {});
      let requiredFields = [...fields];
      let tmpResource = resource;
      let sourceRef = null; // eslint-disable-line no-shadow
      let sourceRefTitle = 'name';
      if (refField) {
        sourceRef = apiRef.get(refField);
        sourceRefTitle = getSourceRefTitle(
          sourceRef.properties,
          null,
          null,
          sourceRef,
        );
        requiredFields = fields.filter((field) =>
          sourceRef.required.includes(field),
        );
        if (reference) {
          tmpResource = reference;
        }
      }
      const fieldsComp = requiredFields.map((field) => {
        // TRANSLATABLE_INPUT
        const translatable =
          sourceRef?.properties?.[field]?.properties?.translatable?.default; // eslint-disable-line no-shadow
        messageKey = getResourceFieldMessageKey(
          sourceRef?.properties,
          tmpResource,
          field,
        );

        if (translatable) {
          messageKey = `${translate(messageKey)} (${locale})`;
          sourceRefTitle = `${field}.${locale}`;
        }
        switch (typeof data?.[ids?.[0]]?.[field]) {
          case 'number':
            return (
              <NumberField source={field} key={field} label={messageKey} />
            );
          case 'boolean':
            return (
              <BooleanField source={field} key={field} label={messageKey} />
            );
          case 'object': {
            if (!data?.[ids?.[0]]?.[field]) {
              return (
                <TextField source={field} key={field} label={messageKey} />
              );
            }

            // eslint-disable-next-line react/prop-types
            if (data?.[ids?.[0]]?.[field]?.blobUrlBO) {
              return (
                <ImageField
                  source={`${field}`}
                  className={className}
                  view={view}
                />
              );
            }

            if (translatable) {
              return (
                <TextField
                  label={messageKey}
                  key={field}
                  source={sourceRefTitle}
                />
              );
            }

            // Guess field to display
            const fieldSourceProperties = apiRef.get(
              sourceRef?.properties?.[field].$ref ||
                sourceRef?.properties?.[field]?.allOf?.filter(
                  (i) => i?.$ref,
                )?.[0]?.$ref ||
                sourceRef?.properties?.[field]?.properties?.reference?.$ref,
            )?.properties;

            let sourceToDisplay = 'id';
            if (Object.keys(fieldSourceProperties)?.includes('name')) {
              sourceToDisplay = 'name';
            } else if (
              Object.keys(fieldSourceProperties)?.includes('username')
            ) {
              sourceToDisplay = 'username';
            }

            let InnerField = FieldComponent;
            if (sourceToDisplay === 'id') {
              InnerField = IdField;
            }

            return (
              <RefField
                key={field}
                sourceSchema={sourceSchema}
                source={`${field}.id`}
                reference={field}
                label={messageKey}
                view={view}
              >
                <InnerField
                  source={sourceToDisplay}
                  {...guessedPropsComponent}
                />
              </RefField>
            );
          }
          default:
            return <TextField source={field} key={field} label={messageKey} />;
        }
      });

      return (
        <div
          style={{
            overflowX: 'auto',
            width: '100vw',
          }}
        >
          <Datagrid {...datagridProps} optimized>
            <RefField
              sourceSchema={sourceSchema}
              label={getResourceFieldMessageKey(
                sourceRef?.properties,
                tmpResource,
                'id',
              )}
              source="id"
              reference={tmpResource}
              view={view}
            >
              <IdField source="id" />
            </RefField>
            {fieldsComp}
          </Datagrid>
        </div>
      );
    };
    DatagridContainer.propTypes = {
      data: PropTypes.array,
      ids: PropTypes.array.isRequired,
      reference: PropTypes.string.isRequired,
    };
    DatagridContainer.defaultProps = {
      data: [],
    };
    return (
      <TestableContainer
        key={sourceName}
        nfid={kebabCase(`${resource}-${view}-field-${source}`)}
      >
        <ArrayField
          key={refName}
          source={source}
          reference={refName}
          label={messageKey}
          translatableInput={translatableInput}
        >
          {view === 'list' ? (
            <ArrayFieldSingleList>
              <FieldTooltip source={chipSource}>
                <ChipField clickable={false} source={chipSource} />
              </FieldTooltip>
            </ArrayFieldSingleList>
          ) : (
            <DatagridContainer />
          )}
        </ArrayField>
      </TestableContainer>
    );
  }

  // Guest Normal Field
  const FieldContainer = ({
    children,
    // eslint-disable-next-line
    translatableInput,
    ...props
  }) => {
    let recordFromFieldmProps = useRecordContext();

    let newProps = props || {};
    if (globalRecord) recordFromFieldmProps = globalRecord;
    let schema = {
      ...sourceSchema,
      sourceName,
    };

    // Guess Normal Field by "value" for "setting" resource
    if (resource === 'setting' && sourceName === 'value') {
      schema = {
        type: 'string',
        format: recordFromFieldmProps?.format,
      };

      if (recordFromFieldmProps?.maskMe) {
        schema = {
          type: 'mask',
        };
      } else if (recordFromFieldmProps?.format === 'boolean') {
        schema = {
          type: recordFromFieldmProps?.format,
        };
      }

      if (recordFromFieldmProps?.format === 'blob') {
        schema = {
          type: 'string',
          format: 'image',
        };
      }
    }

    let { PropComponent: Component } = guessFieldComponent(schema);
    const { guessedPropsComponent } = guessFieldComponent(schema); // eslint-disable-line no-shadow
    if (isJSON) {
      if (typeof get(recordFromFieldmProps, source) === 'string') {
        set(
          recordFromFieldmProps,
          source,
          JSON.parse(get(recordFromFieldmProps, source)),
        );
      }
      Component = JsonField;
      newProps = {
        ...props,
        jsonString: false,
        sortable: false,
        reactJsonOptions: {
          // Props passed to react-json-view
          name: null,
          collapsed: collapseJSON,
          enableClipboard: false,
          displayDataTypes: false,
        },
      };
    }

    return children({
      props: newProps,
      isCopy: schema?.properties?.copiable?.default,
      record: recordFromFieldmProps,
      Component,
      guessedPropsComponent,
    });
  };

  // TRANSLATABLE_INPUT
  if (translatableInput) {
    sourceName = `${sourceName}.${locale}`;
    if (view === 'list') {
      source = `${source}.${locale}`;
    }
  }

  return (
    <FieldContainer
      key={sourceName}
      source={sourceName}
      label={messageKey}
      tooltip={sourceSchema?.description}
      translatableInput={translatableInput}
    >
      {({
        props,
        isCopy,
        record,
        Component,
        guessedPropsComponent, // eslint-disable-line no-shadow
      }) => {
        const { className: defaultClassName } = props;
        let restDefaultProps = props;
        if (!defaultClassName) {
          restDefaultProps = omit(props, ['className']);
        }
        return (
          <TestableContainer
            key={sourceName}
            nfcolspan={nfColSpan}
            nfid={kebabCase(`${resource}-${view}-field-${source}`)}
          >
            {view === 'list' ? (
              <>
                {isJSON ? (
                  <Component
                    {...restDefaultProps}
                    record={record}
                    {...guessedPropsComponent}
                    view={view}
                  />
                ) : (
                  <FieldTooltip source={source} record={record}>
                    <Component
                      {...restDefaultProps}
                      record={record}
                      {...guessedPropsComponent}
                      view={view}
                      {...(sourceSchema?.nfRef?.source && {
                        source: `${sourceSchema?.nfRef?.name}.${sourceSchema?.nfRef?.source}`,
                      })}
                    />
                  </FieldTooltip>
                )}
              </>
            ) : (
              <CustomLabeled
                label={
                  <CustomLabel
                    translatableInput={translatableInput}
                    locale={locale}
                    label={messageKey}
                    source={source}
                    isCopy={isCopy || false}
                    record={record}
                  />
                }
              >
                <Component
                  {...restDefaultProps}
                  {...guessedPropsComponent}
                  {...(sourceSchema?.nfRef?.source && {
                    source: `${sourceSchema?.nfRef?.name}.${sourceSchema?.nfRef?.source}`,
                  })}
                  record={record}
                />
              </CustomLabeled>
            )}
          </TestableContainer>
        );
      }}
    </FieldContainer>
  );
};

export default guessFieldComponentWithProp;
