import React, { useEffect } from 'react';
import { useDataProvider } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { SelectInput } from './ra/inputs';
import { useEnumOptions } from '../hooks';

function MethodTypeInput() {
  const [methodTypes, setMethodTypes] = React.useState([]);
  const methodTypeOptions = useEnumOptions('promotion', 'methodType');
  const dataProvider = useDataProvider();
  const {
    getValues, setValue,
  } = useFormContext();

  const values = getValues();

  const promotionTypeId = values?.promotionType?.id;
  const selectedMethodType = values?.methodType;

  const checkMethodType = promotionType => {
    const acceptPercentType = promotionType?.acceptPercentType;
    const acceptAmountType = promotionType?.acceptAmountType;

    if (acceptPercentType && acceptAmountType) {
      setMethodTypes(methodTypeOptions);
      setValue('methodType', null);
    } else if (acceptPercentType) {
      setMethodTypes(methodTypeOptions?.filter(mt => mt.id === 'PERCENT'));
      if (selectedMethodType !== 'PERCENT') setValue('methodType', null);
    } else if (acceptAmountType) {
      setMethodTypes(methodTypeOptions?.filter(mt => mt.id === 'AMOUNT'));
      if (selectedMethodType !== 'AMOUNT') setValue('methodType', null);
    }
  };

  const setMethod = async () => {
    if (promotionTypeId) {
      const { data: promotionType } = await dataProvider.getOne(
        'promotion-type',
        {
          id: promotionTypeId,
        },
      );
      checkMethodType(promotionType);
    }
  };

  useEffect(() => {
    setMethod();
  }, [promotionTypeId]);

  return (
    <>
      {methodTypes && (
        <SelectInput
          source="methodType"
          choices={methodTypes}
          fullWidth
        />
      )}
    </>
  );
}

export default MethodTypeInput;
