import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import axios from 'axios';
import React, { useCallback } from 'react';
import { useNotify, useTranslate } from 'react-admin';
import { Field, Form } from 'react-final-form';
import { useFeedBack } from '../../../hooks';

const useStyles = makeStyles(theme => ({
  dialogActions: {
    padding: theme.spacing(3),
  },
  button: {
    margin: 0,
  },
  input: {},
  dialogContent: {
    paddingTop: 0,
  },
}));

export default function FeedBackForm() {
  const styles = useStyles();
  const notify = useNotify();
  const translate = useTranslate();
  const {
    isFeedBackShown, toogleFeedBackShown,
  } = useFeedBack();

  const handleClose = () => {
    toogleFeedBackShown(false);
  };

  const onSubmit = useCallback(values => {
    axios
      .post('feedback', {
        subject: values.subject,
        content: values.content,
      })
      .then(() => {
        handleClose();
        notify(translate('ra.message.feedBackSubmitted'), {
          type: 'success',
        });
      })
      .catch(error => {
        if (error?.response?.data) {
          notify(error.response.data?.message || error.response.data, {
            type: 'error',
          });
        } else {
          notify(error.message, {
            type: 'error',
          });
        }
      });
  }, []);

  const handleValidation = values => {
    const errors = {};
    if (!values.subject) {
      errors.subject = translate('ra.validation.required');
    } else if (!values.content) {
      errors.content = translate('ra.validation.required');
    }
    return errors;
  };

  return (
    <Dialog
      open={isFeedBackShown}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="form-dialog-title">
        {translate('ra.action.feedBack')}
      </DialogTitle>
      <Form
        onSubmit={onSubmit}
        validate={handleValidation}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <DialogContent className={styles.dialogContent}>
              <Field
                name="subject"
                component={TextField}
              >
                {({
                  input, meta,
                }) => (
                  <TextField
                    {...input}
                    label={translate('resources.card.fields.subject')}
                    type="text"
                    fullWidth
                    autoFocus
                    required
                    margin="normal"
                    variant="outlined"
                    helperText={meta.error && meta.touched && meta.error}
                    error={meta.error && meta.touched}
                    className={styles.input}
                  />
                )}
              </Field>
              <Field
                name="content"
                component={TextField}
              >
                {({
                  input, meta,
                }) => (
                  <TextField
                    {...input}
                    label={translate('resources.card.fields.content')}
                    type="text"
                    fullWidth
                    margin="normal"
                    minRows={10}
                    multiline
                    required
                    variant="outlined"
                    helperText={meta.error && meta.touched && meta.error}
                    error={meta.error && meta.touched}
                    className={styles.input}
                  />
                )}
              </Field>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleClose}
                color="primary"
              >
                {translate('ra.action.cancel')}
              </Button>
              <Button
                onClick={handleSubmit}
                color="primary"
                type="submit"
                className={styles.button}
              >
                {translate('ra.action.submit')}
              </Button>
            </DialogActions>
          </form>
        )}
      />
    </Dialog>
  );
}

FeedBackForm.propTypes = {};
