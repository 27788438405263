import { TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import { makeStyles } from '@mui/styles';
import { isArray, kebabCase } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import {
  FieldTitle,
  InputHelperText,
  InputPropTypes,
  useInput,
} from 'react-admin';
import EditableChip from './editable-chip';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiInputBase-fullWidth': {
      paddingRight: '30px !important',
    },
  },
}));

const AutocompleteFreeSoloInput = ({
  format,
  label,
  onBlur,
  onChange,
  onFocus,
  options: defaultOptions,
  resource,
  source,
  validate,
  multiple,
  helperText,
  isRequired: isRequiredOverride,
  editable,
  disabled,
  ...rest
}) => {
  const classes = useStyles();
  const mappingValue = (value, index) =>
    typeof value === 'string'
      ? {
          id: `${kebabCase(value)}-${!Number.isNaN(index) ? index : ''}`,
          label: value,
        }
      : value;

  const parse = (value) => {
    if (Array.isArray(value)) {
      return value.map(mappingValue);
    }

    return mappingValue(value);
  };

  const {
    field: { value: fieldValueInput, ...field },
    isRequired,
    fieldState: { error, isTouched },
    formState: { isSubmitted },
  } = useInput({
    format,
    onBlur,
    onChange,
    onFocus,
    resource,
    source,
    parse,
    validate,
    ...rest,
  });

  const fieldValue = isArray(fieldValueInput) ? fieldValueInput : [];

  const options = useMemo(
    () => (Array.isArray(defaultOptions) ? defaultOptions : []),
    [defaultOptions],
  );
  const handleChange = useCallback(
    (_, newValue) => {
      if (multiple) {
        if (Array.isArray(newValue)) {
          field.onChange(newValue);
        } else {
          field.onChange([...(fieldValue ?? []), newValue]);
        }
      } else {
        field.onChange(newValue);
      }
    },
    [field, fieldValue, multiple],
  );

  const handleUpdate = useCallback(
    (updatedValue) => {
      if (multiple) {
        field.onChange(
          fieldValue.map((x) => ({
            ...x,
            ...(x.id === updatedValue.id && updatedValue),
          })),
        );
      } else {
        field.onChange(updatedValue);
      }
    },
    [field, fieldValue, multiple],
  );

  return (
    <Autocomplete
      {...field}
      disabled={disabled}
      multiple={multiple}
      value={fieldValue}
      options={options}
      freeSolo
      handleHomeEndKeys
      className={classes.root}
      onChange={handleChange}
      renderTags={(_, getTagProps) =>
        fieldValue.map((option, index) => (
          <EditableChip
            key={option.id || option}
            editable={editable}
            value={option}
            {...getTagProps({
              index,
            })}
            onChange={handleUpdate}
          />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Dropdown attribute value"
          label={
            <FieldTitle
              label={label}
              source={source}
              resource={resource}
              isRequired={
                typeof isRequiredOverride !== 'undefined'
                  ? isRequiredOverride
                  : isRequired
              }
            />
          }
          helperText={
            helperText ? (
              <InputHelperText
                touched={isTouched || isSubmitted}
                error={error?.message}
                helperText={helperText}
              />
            ) : (
              ''
            )
          }
        />
      )}
    />
  );
};

AutocompleteFreeSoloInput.propTypes = {
  ...InputPropTypes,
  options: PropTypes.shape(Checkbox.propTypes),
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  editable: PropTypes.bool,
};

AutocompleteFreeSoloInput.defaultProps = {
  options: {},
  disabled: undefined,
  multiple: true,
  editable: false,
};

export default AutocompleteFreeSoloInput;
