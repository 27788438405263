import AccountCircleSharpIcon from '@mui/icons-material/AccountCircleSharp';
import { Card, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import moment from 'moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useLocaleState } from 'react-admin';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
  },
  timelineItem: {
    position: 'relative',
    paddingLeft: 60,
    paddingRight: 0,
    width: '100%',
  },
  timelineItemContent: {
    borderRadius: 8,
    fontSize: 13,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(4),
    position: 'relative',
    overflow: 'visible',
    border: 'unset',
    boxShadow: 'unset',
    width: '100% !important',
    '& img': {
      maxHeight: 500,
      width: 'auto',
    },
  },
  timeRoot: {
    marginLeft: 12,
    marginRight: 12,
    fontSize: '12px',
    lineHeight: '18px',
    fontWeight: 300,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: 90,
  },
  timelineItemRight: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& $timelineItemContent': {
      maxWidth: '70%',
    },
    '& $message': {
      borderTopRightRadius: 0,
      background: '#488EFF',
    },
    '& .boxUser': {
      marginLeft: 10,
    },
    '& $contentMessage': {
      justifyContent: 'flex-end',
    },
  },
  contentMessage: {
    display: 'flex',
    '& > div': {
      maxWidth: '100%',
    },
  },
  message: {
    borderRadius: 10,
    padding: 10,
    color: '#fff',
    maxWidth: '100%',
    overflowWrap: 'anywhere',
    position: 'relative',
  },
  iconUser: {
    fontSize: 50.4,
    color: '#cdcece',
  },
  unRead: {
    backgroundColor: theme.palette.error.main,
    width: '8px',
    height: '8px',
    borderRadius: '4px',
    position: 'absolute',
    left: '4px',
    top: '4px',
  },
}));

const InboxItem = (props) => {
  const {
    id,
    user: { fullName },
    created,
    message,
    read,
  } = props.data;
  const classes = useStyles(props);
  const [locale] = useLocaleState();
  const time = useMemo(
    () => moment(created).format('HH:mm'),
    [created, locale],
  );
  const fullTime = useMemo(
    () => moment(created).format('DD-MM-YYYY HH:mm'),
    [created, locale],
  );
  const ref = useRef();

  const [isRead, setIsRead] = useState(Boolean(read));

  useEffect(() => {
    const root = document.getElementById(props.scrollTargetId);
    const options = {
      root,
      rootMargin: '0px',
      threshold: 0,
    };
    let timeout;
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          timeout = setTimeout(() => {
            props.onRead(id);
            setIsRead(true);
            observer.unobserve(ref.current);
          }, 2000);
        }
        if (!entry.isIntersecting && timeout) {
          clearTimeout(timeout);
        }
      });
    }, options);

    if (!isRead && ref.current && root) {
      observer.observe(ref.current);
    }

    return () => {
      observer.unobserve(ref.current);
      clearTimeout(timeout);
    };
  }, [isRead]);

  const renderDate = () => {
    const { previous } = props;
    const currentDate = moment(created).format('DD-MM-YYYY');
    const previousDate = moment(previous?.created).format('DD-MM-YYYY');

    if (!previous || currentDate !== previousDate) {
      return (
        <Typography textAlign="center" fontWeight={600}>
          {currentDate}
        </Typography>
      );
    }

    return null;
  };

  return (
    <Box className={classes.root} ref={ref}>
      {renderDate()}
      <Box className={clsx(classes.timelineItem, classes.timelineItemRight)}>
        <Card
          className={clsx(classes.timelineItemContent, 'timelineItemContent')}
          sx={{ maxWidth: { xs: '100% !important', sm: '70% !important' } }}
        >
          <Box className={classes.contentMessage}>
            <Tooltip title={fullTime} enterDelay={1000}>
              <Box>
                <Box display="flex" justifyContent="flex-end">
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <Typography className={classes.timeRoot} variant="body2">
                      {time}
                    </Typography>
                    <Typography variant="h6">{fullName}</Typography>
                  </Box>
                </Box>
                <Box className={classes.message}>
                  {!isRead && <Box className={classes.unRead} />}
                  <p dangerouslySetInnerHTML={{ __html: message }} />
                </Box>
              </Box>
            </Tooltip>
            <Box className="boxUser" position="relative">
              <AccountCircleSharpIcon className={classes.iconUser} />
            </Box>
          </Box>
        </Card>
      </Box>
    </Box>
  );
};

export default InboxItem;
