/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';
import { Box, Chip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  unselectedField: {
    margin: theme.spacing(1),
  },
  sectionField: {
    backgroundColor: theme.palette.warning.light,
  },
  dragging: {
    userSelect: 'none',
    background: theme.palette.secondary.light,
  },
}));

const PrintFormatField = ({
  field, index, draggableId,
}) => {
  const classes = useStyles();

  return (
    <Draggable
      draggableId={draggableId}
      index={index}
    >
      {(provided, snapshot) => (
        <Box>
          <Chip
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            size="small"
            label={`${field.label}${field.type ? ` [${field.type}]` : ''}`}
            className={clsx(
              classes.unselectedField,
              field.field === '_section' && classes.sectionField,
              snapshot.isDragging && classes.dragging,
            )}
            style={provided.draggableProps.style}
          />
        </Box>
      )}
    </Draggable>
  );
};

PrintFormatField.propTypes = {
  field: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  draggableId: PropTypes.string.isRequired,
};

PrintFormatField.defaultProps = {};

export default PrintFormatField;
