import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { memo, useEffect } from 'react';
import { useNotify, useTranslate } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { parseCoordinate, parsePoint } from '../../../utils';
import GoogleMapSearchBoxInput from './google-map-search-box/googleMapSearchBox.input';

const useStyles = makeStyles(
  () => ({
    root: {},
  }),
  {
    name: 'Nf-PointInput',
  },
);

const PointInput = ({
  source,
  className,
  useNameToSetSource,
  name: inputName,
  ...rest
}) => {
  const classes = useStyles();
  const { setValue, watch, resetField } = useFormContext();
  const notify = useNotify();
  const translate = useTranslate();

  const realSource = useNameToSetSource ? inputName : source;

  const transformFormKeyToSource = (key) => `${realSource}.${key}`;

  const setValues = (
    address = '',
    addressName = '',
    coordinate = '',
    postalCode = '',
    placeId = '',
  ) => {
    setValue(realSource, {
      address,
      addressName,
      coordinate,
      postalCode,
      placeId,
    });
  };

  const coordinateSource = transformFormKeyToSource('coordinate');
  const latSource = `${coordinateSource}Latitude`;
  const lngSource = `${coordinateSource}Longitude`;
  const addrSource = `${coordinateSource}Address`;

  const value = watch(coordinateSource);
  const defaultAddress = watch(addrSource);

  useEffect(() => {
    if (isEmpty(value)) {
      resetField(latSource);
      resetField(lngSource);
      return;
    }
    const [parsedLat, parsedLng] = parseCoordinate(value);
    setValue(latSource, parsedLat);
    setValue(lngSource, parsedLng);
  }, [value, latSource, lngSource, setValue, resetField]);

  const handleOnChange = ({ lat, lng, address, name, placeId }) => {
    if (!lat && !lng) {
      setValues();
      notify('Cannot find your location, please help to try again!', {
        type: 'error',
      });
      return;
    }

    const fullAddress = `${address?.map((item) => item.name)?.join(', ')}`;
    const postalCode = (address || []).find((item) =>
      item.types.includes('postal_code'),
    )?.name;

    // NOTE: should not use setValue multiple times
    // CAUSE: data will not sync each other, try to set value at the same time
    setValues(fullAddress, name, parsePoint(lat, lng), postalCode, placeId);
  };

  return (
    <Box className={clsx(classes.root, className)}>
      <GoogleMapSearchBoxInput
        {...rest}
        onChange={handleOnChange}
        defaultValue={defaultAddress || rest?.defaultValue}
        customLabel={translate(rest?.label)}
      />
    </Box>
  );
};

PointInput.propTypes = {
  source: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  forceLoadScript: PropTypes.bool,
  useNameToSetSource: PropTypes.bool,
  onlyLoadInput: PropTypes.bool,
};

PointInput.defaultProps = {
  source: undefined,
  className: undefined,
  name: undefined,
  placeholder: undefined,
  forceLoadScript: false,
  useNameToSetSource: false,
  onlyLoadInput: false,
};

export default memo(PointInput);
