import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import useStyles from './index.style';
import { PAYMENT_VALUES } from '../../../constants';

const BookingPayment = ({ fieldName }) => {
  const classes = useStyles();
  const { setValue } = useFormContext();

  const handleChange = (event) => {
    const { value } = event?.target || {};
    setValue(fieldName, value);
  };

  return (
    <RadioGroup onChange={handleChange} className={classes.root}>
      <Box className={classes.input}>
        <Box className={clsx(classes.item)}>
          <FormControlLabel value={PAYMENT_VALUES.CASH} control={<Radio />} />
          <Typography className={classes.txtLabel}>Payment at Booth</Typography>
        </Box>

        <Box className={clsx(classes.item)}>
          <FormControlLabel value={PAYMENT_VALUES.ONLINE} control={<Radio />} />
          <Typography className={classes.txtLabel}>Self Check-out</Typography>

          <Box className={classes.iconContainer}>
            <img src="images/icons/visa-icon.svg" alt="Visa" />
            <img src="images/icons/mastercard-icon.svg" alt="Mastercard" />
          </Box>
        </Box>
      </Box>
    </RadioGroup>
  );
};

BookingPayment.propTypes = {
  fieldName: PropTypes.string.isRequired,
};

export default React.memo(BookingPayment);
