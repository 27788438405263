import lodashMemoize from 'lodash/memoize';

const isEmpty = value => typeof value === 'undefined'
  || value === null
  || value === ''
  || (Array.isArray(value) && value.length === 0);

export const EMAIL_REGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/; // eslint-disable-line no-useless-escape

const memoize = fn => lodashMemoize(fn, (...args) => JSON.stringify(args));

const getMessage = (
  message,
  messageArgs,
  value,
  values,
) => (typeof message === 'function' // eslint-disable-line no-nested-ternary
  ? message({
    args: messageArgs,
    value,
    values,
  })
  : messageArgs
    ? {
      message,
      args: messageArgs,
    }
    : message);

export const regex = lodashMemoize(
  (pattern, message = 'ra.validation.regex') => (value, values) => (!isEmpty(value) && typeof value === 'string' && !pattern.test(value)
    ? getMessage(message, {
      pattern,
    }, value, values)
    : undefined),
  (pattern, message) => pattern.toString() + message,
);

export const isEmail = memoize((message = 'ra.validation.email') => regex(EMAIL_REGEX, message));
