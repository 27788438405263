import {
  READ_INBOX,
  SET_INBOX,
  SET_INVISIBLE_INBOX_COUNT,
} from './notification.action';

const initialSettings = {
  inbox: {
    countInvisible: 0,
    data: [],
    page: 1,
    pageCount: undefined,
    limit: 25,
  },
};

export const notificationReducer = (state = initialSettings, action) => {
  const { type, ...payload } = action;

  switch (type) {
    case SET_INBOX: {
      return {
        ...state,
        inbox: {
          ...state.inbox,
          ...payload,
          data:
            payload.page === 1
              ? [...payload.data]
              : [...state.inbox.data, ...payload.data],
        },
      };
    }

    case SET_INVISIBLE_INBOX_COUNT: {
      return {
        ...state,
        inbox: {
          ...state.inbox,
          countInvisible: payload.countInvisible,
        },
      };
    }

    case READ_INBOX: {
      return {
        ...state,
        inbox: {
          ...state.inbox,
          countInvisible: state.inbox.countInvisible - (payload.amount || 1),
        },
      };
    }

    default:
      return state;
  }
};
