import React, { memo } from 'react';
import PropTypes from 'prop-types';
import PopupAnnouncement from './announcement.popup';
import PermanentAnnouncement from './announcement.permanent';
import useAnnouncement from '../../../hooks/useAnnouncement';
import MarqueeAnnouncement from './announcement.marquee';

export const AnnouncementType = Object.freeze({
  permanent: 'permanent',
  marquee: 'marquee',
});

const Announcement = props => {
  const {
    popupAnnouncements,
    messageAnnouncements,
    currentPopupAnnouncementIndex,
    moveToNextPopupAnnouncement,
    checkAndSkipAnnouncement,
  } = useAnnouncement();
  const { type } = props;

  const onClose = (announcement, isPopup) => {
    checkAndSkipAnnouncement(announcement);
    if (isPopup) {
      moveToNextPopupAnnouncement();
    }
    return false;
  };

  const renderMarqueeAnnouncements = () => (
    <MarqueeAnnouncement announcements={messageAnnouncements} />
  );

  const renderMessageAnnouncements = () => messageAnnouncements?.map(announcement => (
    <PermanentAnnouncement
      {...props}
      {...announcement}
      onClose={() => onClose(announcement, false)}
      key={`permanent-announcement-item-${announcement.id}`}
    />
  ));

  const renderPopupAnnouncements = () => {
    if (currentPopupAnnouncementIndex < 0 || !(popupAnnouncements?.length > 0)) return null;

    const announcement = popupAnnouncements[currentPopupAnnouncementIndex];

    return (
      <PopupAnnouncement
        {...props}
        {...announcement}
        onClose={() => onClose(announcement, true)}
      />
    );
  };

  return (
    <>
      {type === 'marquee'
        ? renderMarqueeAnnouncements()
        : renderMessageAnnouncements()}
      {renderPopupAnnouncements()}
    </>
  );
};

Announcement.propTypes = {
  type: PropTypes.oneOf(['permanent', 'marquee']),
};

Announcement.defaultProps = {
  type: 'marquee',
};

export default memo(Announcement);
