import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  input: {},
  item: {
    display: 'flex',
    alignItems: 'center',
    height: 50,
    borderRadius: 8,
    backgroundColor: '#F0F0F4',
    position: 'relative',

    marginBottom: theme.spacing(4),
    paddingRight: theme.spacing(2),
    paddingLeft: 36,

    '& label': {
      position: 'absolute',
      inset: 0,
      margin: 0,
    },
    '&:last-child': {
      marginBottom: 0,
    },
  },

  txtLabel: {
    fontSize: 14,
    fontFamily: 'QuickSandSemiBold',
  },
  iconContainer: {
    display: 'flex',
    marginLeft: 'auto',
  },
}));

export default useStyles;
