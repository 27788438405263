import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useLocalStorage } from './useLocalStorage';

const AnnoucementType = {
  popup: 'popup',
  message: 'message',
};

const useAnnouncement = () => {
  const {
    storedValue: skipedAnnouncementIds,
    setLocalStorage,
  } = useLocalStorage('skipedAnnouncementIds', []);
  const announcements =
    useSelector((state) => state.auth?.user?.announcements) || [];
  const [popupAnnouncements, setPopupAnnouncements] = useState([]);
  const [messageAnnouncements, setMessageAnnouncements] = useState([]);
  const [
    currentPopupAnnouncementIndex,
    setCurrentPopupAnnouncementIndex,
  ] = useState(0);

  const getAnnouncementsByType = type => {
    switch (type) {
      case AnnoucementType.popup:
        return announcements?.filter(_ => _.popup) || [];
      case AnnoucementType.message:
        return announcements?.filter(_ => !_.popup) || [];
      default:
        return announcements || [];
    }
  };

  const moveToNextPopupAnnouncement = () => {
    if (popupAnnouncements?.length > 0) {
      let index = currentPopupAnnouncementIndex + 1;
      if (index >= popupAnnouncements.length) index = -1;
      setCurrentPopupAnnouncementIndex(index);
    }
  };

  const checkAndSkipAnnouncement = announcement => {
    if (!announcement?.skippable) return;
    setLocalStorage([...skipedAnnouncementIds, announcement.id]);
  };

  const removeSkipedAnnouncement = (items, skipedIds) => {
    try {
      return items.filter(_ => !_.skippable || !skipedIds.includes(_.id));
    } catch (ex) {
      return items;
    }
  };

  useEffect(() => {
    if (announcements?.length > 0) {
      setPopupAnnouncements(
        removeSkipedAnnouncement(
          getAnnouncementsByType(AnnoucementType.popup),
          skipedAnnouncementIds,
        ),
      );
      setMessageAnnouncements(
        removeSkipedAnnouncement(
          getAnnouncementsByType(AnnoucementType.message),
          skipedAnnouncementIds,
        ),
      );
    }
  }, [announcements]);

  return {
    announcements,
    popupAnnouncements,
    messageAnnouncements,
    currentPopupAnnouncementIndex,
    moveToNextPopupAnnouncement,
    checkAndSkipAnnouncement,
  };
};

export default useAnnouncement;
