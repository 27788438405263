import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    width: '100%',
    background: 'linear-gradient(118.72deg, #083AA9 0%, #031847 100.62%)',
    height: 90,
    borderRadius: '0px 0px 10px 10px',
    // borderRadius: '0 0 10px  10px ',
  },

  logo: {
    height: 60,
    margin: 'auto',
  },
}));

export default useStyles;
