const getItem = (key, defaultValue) => {
  try {
    const value = JSON.parse(window.localStorage.getItem(key));
    return value ?? defaultValue;
  } catch {
    return defaultValue; // eslint-disable-line no-unsafe-finally
  }
};

const setItem = (key, value) => {
  try {
    window.localStorage.setItem(key, JSON.stringify(value));
    return true;
  } catch (e) {
    return false;
  }
};

const removeItem = (key) => {
  try {
    window.localStorage.removeItem(key);
    return true;
  } catch (e) {
    return false;
  }
};

const getAllKeys = () => {
  try {
    return Object.entries(window.localStorage).map((pair) => pair[0]);
  } catch (e) {
    return [];
  }
};

const LocalStorage = {
  getItem,
  setItem,
  removeItem,
  getAllKeys,
};

export default LocalStorage;
