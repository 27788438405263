import { useEffect } from 'react';
import { useResourceDefinitions } from 'react-admin';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { PULL_NF_RESOURCE_SUCCESS } from '../../services/redux/root.actions';

const useResources = () => {
  const resourcesDefinitions = useResourceDefinitions();
  const dispatch = useDispatch();
  const resources = useSelector(state => state.resource.resources);

  useEffect(() => {
    if (!isEmpty(resourcesDefinitions)) {
      const allResources = Object.keys(resourcesDefinitions).map(
        name => resourcesDefinitions[name],
      );
      dispatch({
        type: PULL_NF_RESOURCE_SUCCESS,
        payload: {
          resources: allResources,
        },
      });
    }
  }, [resourcesDefinitions, dispatch]);

  return resources;
};

export default useResources;
