import { EVENT_ACTIONS } from '../actions';

const initialStates = {
  dropoff: [],
  pickup: {},
};

const eventReducer = (state = initialStates, action) => {
  switch (action.type) {
    case EVENT_ACTIONS.SET_DROPOFF: {
      return {
        ...state,
        dropoff: action.payload,
      };
    }
    case EVENT_ACTIONS.ADD_DROPOFF: {
      return {
        ...state,
        dropoff: [...state.dropoff, action.payload],
      };
    }

    case EVENT_ACTIONS.UPDATE_DROPOFF: {
      const { index, value } = action.payload;
      const updatedDropOff = [...state.dropoff];

      updatedDropOff[index] = {
        ...updatedDropOff[index],
        ...value,
      };

      return {
        ...state,
        dropoff: updatedDropOff,
      };
    }

    case EVENT_ACTIONS.REMOVE_DROPOFF: {
      const index = action.payload;
      const removedDropOff = [...state.dropoff];
      removedDropOff.splice(index, 1);
      return {
        ...state,
        dropoff: removedDropOff,
      };
    }

    case EVENT_ACTIONS.SET_PICKUP:
      return {
        ...state,
        pickup: action.payload,
      };
    default:
      return state;
  }
};

export const selectDropOff = (state) => state?.event?.dropoff || [];
export const selectPickUp = (state) => state?.event?.pickup || {};

export default eventReducer;
