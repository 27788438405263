import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  RecordContextProvider,
  useNotify,
  useRefresh,
  useTranslate,
} from 'react-admin';
import { FormProvider, useForm } from 'react-hook-form';
import axios from '../../../../services/provider/axios';
import { convertFileToBase64 } from '../../../../services/util/convertFileToBase64';
import useError from '../../../hooks/useError';
import { saveFileFromResponse } from '../../../utils';
import { Button } from '../../ra/buttons';

function CallToActionFormGuesser(props) {
  const {
    components,
    action,
    open,
    onClose,
    record,
    hasIdRegex, // eslint-disable-line
    refName,
  } = props;

  const [loading, setLoading] = useState(false);
  const translate = useTranslate();
  const notify = useNotify();
  const { notifyError } = useError();
  const refresh = useRefresh();
  const hasId = String(action?.api).includes(':id');

  const form = useForm();
  const { handleSubmit, setValue } = form;

  useEffect(() => {
    if (refName) {
      setValue(refName, { id: record.id });
    }
  }, [refName, setValue]);

  const handleSubmitForm = async (values) => {
    setLoading(true);
    const fileKeys = [];
    Object.keys(values || {}).forEach(async (i) => {
      if (values?.[i]?.rawFile && values?.[i]?.src) {
        fileKeys.push(i);
      }
    });

    const options = {
      validateStatus: (status) => status <= 500,
    };

    if (action.saveFileExtension) {
      options.responseType = 'arraybuffer';
    }

    if (fileKeys?.length) {
      await Promise.all(
        fileKeys.map(async (i) => {
          const fileData = await convertFileToBase64(values?.[i]?.rawFile);
          values[i] = {
            data: fileData,
            type: values?.[i]?.rawFile?.type,
            name: values?.[i]?.rawFile?.name,
          };
        }),
      );
    }

    try {
      let resp = null;
      if (!hasId || !record?.id) {
        switch (action.method) {
          case 'POST':
            resp = await axios.post(action?.api, values, options);
            break;
          case 'PUT':
            resp = await axios.put(action?.api, values, options);
            break;
          case 'patch':
            resp = await axios.patch(action?.api, values, options);
            break;
          default:
            break;
        }
      } else {
        switch (action.method) {
          case 'POST':
            resp = await axios.post(
              action?.api?.replace(hasIdRegex, record.id),
              values,
              options,
            );
            break;
          case 'PATCH':
            resp = await axios.patch(
              action?.api?.replace(hasIdRegex, record.id),
              values,
              options,
            );
            break;
          case 'PUT':
            resp = await axios.put(action?.api, values, options);
            break;
          default:
            break;
        }
      }

      if (action.saveFileExtension && resp?.data && resp.status < 300) {
        saveFileFromResponse(resp, null, action.saveFileExtension);

        if (action.messageOnCompleted) {
          notify(action.messageOnCompleted, { type: 'success' }, 1);
        }
      } else if (resp.status >= 400) {
        notifyError(resp);
      } else if (resp.status < 300) {
        if (action.messageOnCompleted) {
          notify(action.messageOnCompleted, { type: 'success' }, 1);
        }
      }

      if (action.reloadOnCompleted) {
        window.location.reload();
      } else if (action.refreshedOnCompleted) {
        refresh();
      }

      if (resp.status < 300) {
        notify('Success', { type: 'success' }, 1);
        onClose();
      }
    } catch (error) {
      notifyError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    return () => setLoading(false);
  }, []);

  return (
    <>
      {open ? (
        <RecordContextProvider value={{}}>
          <FormProvider {...form}>
            <form onSubmit={(e) => e.preventDefault()}>
              <Dialog
                open={open}
                PaperProps={{
                  style: {
                    maxWidth: '800px',
                  },
                }}
                fullWidth
              >
                <DialogTitle id="form-dialog-title">
                  {translate(action.title)}
                </DialogTitle>
                <DialogContent>{components}</DialogContent>
                <DialogActions>
                  <Button
                    variant="text"
                    label={translate('ra.action.cancel')}
                    onClick={onClose}
                  >
                    <CloseIcon />
                  </Button>
                  <Button
                    variant="text"
                    color="primary"
                    label={translate('ra.action.save')}
                    onClick={handleSubmit(handleSubmitForm)}
                    disabled={loading}
                  >
                    {loading ? <CircularProgress size={16} /> : <SaveIcon />}
                  </Button>
                </DialogActions>
              </Dialog>
            </form>
          </FormProvider>
        </RecordContextProvider>
      ) : (
        <></>
      )}
    </>
  );
}

CallToActionFormGuesser.propTypes = {
  components: PropTypes.array.isRequired,
  action: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  record: PropTypes.object,
  refName: PropTypes.string,
};

CallToActionFormGuesser.defaultProps = {
  record: {},
  refName: '',
};

export default CallToActionFormGuesser;
