import polyglotI18nProvider from 'ra-i18n-polyglot';
import axios from '../axios';

const defaultMessages = require('./defaultMessages.json');

const getAndStoreLocalTranslation = async (locale, callback) => {
  try {
    const { data } = await axios.get(`/i18n/backoffice/${locale}`);
    callback?.(data);
    localStorage.setItem(
      `languages`,
      JSON.stringify({ languages: data.languages }),
    );
  } catch (err) {
    console.log(err);
  }
};

let init = 1;

const i18nProvider = polyglotI18nProvider(
  (locale) => {
    if (init) {
      init = false;
      return defaultMessages;
    }

    return new Promise((resolve) => {
      getAndStoreLocalTranslation(locale, (data) => resolve(data.translations));
    });
  },
  '',
  {
    allowMissing: true,
  },
);

export { i18nProvider, getAndStoreLocalTranslation };
