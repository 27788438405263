import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  map: {
    width: '100%',
    aspectRatio: 1 / 1.3,
  },
}));

export default useStyles;
