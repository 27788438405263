import { setUser as SentryUser, captureException } from '@sentry/react';
import { isEmpty } from 'lodash';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

const useAuthUser = () => {
  const user = useSelector(({ auth }) => auth?.user);

  return useMemo(() => {
    if (!isEmpty(user)) {
      try {
        SentryUser({ ...user, id: user?.id, username: user?.username });
      } catch (error) {
        captureException(error);
      }
      return {
        ...user,
        optionText: `${user.firstName} ${user.lastName}`,
      };
    }

    return {
      optionText: '',
    };
  }, [user?.id]);
};

export default useAuthUser;
