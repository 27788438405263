export const FieldType = {
  ENUM: 'enum',
  MANY_TO_ONE: 'uuid',
};

export const ReferenceFieldActionType = {
  REDIRECT: 'Redirect',
  NONE: 'None',
};

export const SocialType = {
  FACEBOOK: 'FACEBOOK',
  GOOGLE: 'GOOGLE',
  TWITTER: 'TWITTER',
};

export const Gender = {
  MALE: 'MALE',
  FEMALE: 'FEMALE',
};

export const FlowPerm = {
  READER: 'READER',
  REPORTER: 'REPORTER',
  WRITER: 'WRITER',
  MANAGER: 'MANAGER',
};

export const emptyValueOperators = ['isnull', 'notnull', '$isnull', '$notnull'];

export const Bucket = {
  GAME: 'game',
  VEHICLE_MODEL: 'vehicle-model',
  BLOG: 'blog',
  PAYMENT: 'payment',
  COMMON: 'common',
  ATTACHMENT: 'attachment',
  AVATAR: 'avatar',
  VENDOR: 'vendor',
  PAYMENT_GATEWAY: 'payment-gateway',
  JOB: 'job',
  NRIC: 'nric',
  DRIVER_LICENSE: 'driver-license',
  FLOW: 'flow',
};

export const FILE_TYPE = {
  EXCEL: 'xlsx',
  DOCX: 'docx',
  POWER_POINT: 'ppt',
  PDF: 'pdf',
  ZIP: 'zip',
  RAR: 'rar',
  JSON: 'json',
};

export const ActivityType = {
  CARD: 'CARD',
  CARD_MEMBER: 'CARD_MEMBER',
  CARD_LABEL: 'CARD_LABEL',
  CARD_ATTRIBUTE: 'CARD_ATTRIBUTE',
  CARD_ATTACHMENT: 'CARD_ATTACHMENT',
  CARD_COVER: 'CARD_COVER',
  CARD_CHECKLIST: 'CARD_CHECKLIST',
  CARD_CHECKLIST_ITEM: 'CARD_CHECKLIST_ITEM',
  CARD_COMMENT: 'CARD_COMMENT',
};

export const ActivityAction = {
  ADD: 'ADD',
  UPDATE: 'UPDATE',
  ARCHIVE: 'ARCHIVE',
  UNARCHIVE: 'UNARCHIVE',
  REMOVE: 'REMOVE',
  COPY: 'COPY',
  MOVE: 'MOVE',
  REORDER: 'REORDER',
  CARD_COMMENT: 'CARD_COMMENT',
};

export const GA_EVENTS = {
  CLICK: '[CLICK] -> ',
  ENTER: '[ENTER] -> ',
  TOGGLE: '[TOGGLE] -> ',
};
