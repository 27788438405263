import { get } from 'lodash';
import md5 from 'md5';

export const getSafeValue = (data, key, defaultValue) => {
  return get(data, key, defaultValue);
};

export const genUUID = (target = '', scope = '') => {
  const hashStatus = md5(`${target}@${scope}`);
  return `${hashStatus.substr(0, 8)}-${hashStatus.substr(
    8,
    4,
  )}-${hashStatus.substr(12, 4)}-${hashStatus.substr(
    16,
    4,
  )}-${hashStatus.substr(20, 12)}`;
};
