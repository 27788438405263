import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import AttachMoneyRoundedIcon from '@mui/icons-material/AttachMoneyRounded';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';

import { Box, Typography } from '@mui/material';
import clsx from 'clsx';
import React, { memo } from 'react';
import useStyles from './index.style';
import { convertNumberToCurrency } from '../../../utils';

const BookingCarItem = (props) => {
  const { id, image, name, seater, distance, duration, price, isSelectedById } =
    props || {};
  const classes = useStyles();
  const isSelect = isSelectedById === id;

  return (
    <Box className={clsx(classes.item, isSelect && classes.itemSelected)}>
      <Box className={classes.inner}>
        <img className={classes.image} src={image} alt={name} />
        <Box className={classes.infoContainer}>
          <Box className={classes.header}>
            <Typography className={classes.txtName}>{name}</Typography>
            {seater && (
              <>
                <Box className={classes.dot} />
                <Typography className={classes.txtSeater}>
                  {seater} pax
                </Typography>
              </>
            )}
          </Box>

          <Box className={classes.detailContainer}>
            <Box className={classes.detailItem}>
              <MapOutlinedIcon />
              <Typography className={clsx(classes.txtDetail)}>
                {distance} km
              </Typography>
            </Box>
            <Box className={classes.divide} />
            <Box className={classes.detailItem}>
              <AccessTimeOutlinedIcon />
              <Typography className={clsx(classes.txtDetail)}>
                {duration} min
              </Typography>
            </Box>
            <Box className={classes.divide} />
            <Box className={classes.detailItem}>
              <AttachMoneyRoundedIcon />
              <Typography className={clsx(classes.txtDetail)}>
                {convertNumberToCurrency(price)}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

BookingCarItem.propTypes = {
  // onClick: PropTypes.func,
};

BookingCarItem.defaultProps = {
  // onClick: undefined,
};

export default memo(BookingCarItem);
