import React, { Suspense } from 'react';
import { Admin, CustomRoutes } from 'react-admin';
import { Route } from 'react-router';
import BookingMain from './base/components/public-pages/booking';
import {
  BookingCashSuccessScreen,
  BookingErrorScreen,
  BookingOnlineSuccessScreen,
} from './base/components/public-pages/booking/screens';
import { i18nProvider } from './services/provider/i18n/i18nProvider';
import theme from './theme';
import EventQrCode from './base/components/public-pages/booking/screens/qr';

const Empty = () => {
  return null;
};

const PublicApp = () => {
  return (
    <Suspense fallback={<></>}>
      <Admin
        theme={theme}
        i18nProvider={i18nProvider}
        disableTelemetry
        catchAll={Empty}
      >
        <CustomRoutes noLayout>
          <Route path="p/event/c/:code" element={<BookingMain />} />
          <Route
            path="p/event/c/:code/t/:ticketNumber/cash-success"
            element={<BookingCashSuccessScreen />}
          />
          <Route path="p/event/trip" element={<BookingOnlineSuccessScreen />} />

          <Route path="p/event/online-fail" element={<BookingErrorScreen />} />
          <Route path="p/event/oops" element={<BookingErrorScreen />} />
          <Route path="p/event/qr" element={<EventQrCode />} />
        </CustomRoutes>
      </Admin>
    </Suspense>
  );
};

PublicApp.propTypes = {};

export default PublicApp;
