import { MyLocation } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import CenterFocusStrongIcon from '@mui/icons-material/CenterFocusStrong';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import RemoveIcon from '@mui/icons-material/Remove';
import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
import { GoogleMap, LoadScriptNext } from '@react-google-maps/api';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useMainLayoutContext } from '../context/main-layout';
import { mapStyleShow, mapStyles } from './mapStyle';
import {
  ColorStatus,
  replaceSpaceUpercaseString,
} from './ra/fields/color.status';
import CustomTooltip from './tooltip/custom';

const containerStyle = {
  width: '100%',
  height: '100%',
};
const useStyles = makeStyles((theme) => ({
  '@global': {
    [theme.breakpoints.down('sm')]: {
      'div[class*="commentBoxMap"] > div > div': {
        padding: 0,
      },

      'div[class*="timelineItemContent"]': {
        padding: 0,
      },
    },
    '#root:has(.mapContainerMobile) .job-toolbarWrapper': {
      display: 'none',
    },
  },
  groupButton: {
    display: 'flex',
    gap: 4,
    zIndex: 999,
    '& button': {
      background: '#fff',
      borderRadius: 25,
      height: 33,
      width: 33,
      boxShadow:
        'rgb(25 39 52 / 5%) 0px 1px 2px 0px, rgb(25 39 52 / 10%) 0px 0px 4px 0px',
      // borderRadius: 0,
      '& svg': {
        color: '#000',
      },
      '&:hover': {
        background: '#fff',
      },
    },
    [theme.breakpoints.down('md')]: {
      zIndex: 99999,
    },
  },
  groupSetting: {
    display: 'flex',
    gap: 4,
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row-reverse',
    },
  },
  groupSettingMobile: {},
  moreSettingMap: {
    background: '#fff',
    zIndex: 99999,
    border: 'none',
    borderRadius: 25,
    width: 33,
    height: 33,
    [theme.breakpoints.up('md')]: {
      '&:hover': {
        background: '#fff',
      },
    },
  },
  buttonMap: {
    top: 16,
    position: 'absolute',
    display: 'flex',
    left: 100,
    gap: 4,
    [theme.breakpoints.down('md')]: {
      left: 0,
    },
    [theme.breakpoints.down('sm')]: {
      left: 'unset',
      right: 15,
      top: 30,
    },
    '& button': {
      marginRight: 0,
    },
  },
  buttonMapShow: {
    top: 13,
    left: 186,
    position: 'absolute',
    display: 'flex',
    gap: '5px',
    zIndex: 0,
    transition: 'left 0.3s ease',
    '&.open-side-bar': {
      [theme.breakpoints.up('md')]: {
        left: 0,
      },
    },
    [theme.breakpoints.down('md')]: {
      left: 0,
    },
    [theme.breakpoints.down('sm')]: {
      position: 'initial',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: '0 15px',
      left: 0,
      top: 11,
    },
  },
  buttonMapEdit: {
    top: 10,
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      top: -5,
      padding: '0 20px',
    },
  },
  btnContentMap: {
    zIndex: 99999,
    background: '#fff',
    borderRadius: 25,
    width: 33,
    height: 33,
    '& svg': {
      color: '#000',
      zIndex: 5,
    },
    '&:hover': {
      opacity: '0.7',
    },
  },
  buttonEffect: {
    position: 'relative',
    background: '#ffc23a',
    '&::before': {
      content: '""',
      background: '#ffc23a',
      width: '100%',
      height: '100%',
      position: 'absolute',
      animation: `$btnEffect 1500ms 1s alternate infinite ease-in`,
      zIndex: 1,
      borderRadius: 15,
    },
    '& svg': {
      color: '#fff',
      zIndex: 5,
    },
  },
  '@keyframes btnEffect': {
    '0%': { transform: 'scale(0.8)', opacity: 1 },
    ' 100%': { transform: ' scale(1.3)', opacity: 0.7 },
  },

  contentMap: {
    maxWidth: 400,
    height: 'auto',
    position: 'absolute',
    maxHeight: 'calc(100% - 135px)',
    overflow: 'scroll',
    top: 20,
    left: 0,
    borderRadius: 5,
    width: '100%',
    marginTop: 40,
    minHeight: 'calc(100% - 135px)',
    transition: 'all .3s ease-in-out',
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      position: 'relative',
      minHeight: 'unset',
      height: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    ' & .MuiAccordionSummary-content': {
      paddingLeft: 0,
    },
  },
  contentMapShow: {
    height: '100%',
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      height: 'auto',
      maxHeight: 'calc(100% - 65px)',
      borderRadius: 5,
      marginTop: 38,
      minHeight: 'calc(100% - 65px)',
      paddingTop: 18,
      '& .MuiAccordionSummary-content': {
        paddingLeft: 0,
      },
    },
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      width: 'calc(100% - 30px)',
      margin: 'auto',
      paddingBottom: '10px',
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
      paddingTop: '10px',
      overflow: 'hidden',
      '& > div:first-of-type': {
        flexGrow: 1,
      },
    },
  },
  contentMapEdit: {
    position: 'unset !important',
    paddingTop: 'unset !important',
  },
  hide: {
    display: 'none',
  },
  btnCloseContentMap: {
    top: 0,
    right: 0,
    boxShadow: 'none',
    background: '#fff',
    zIndex: 999,
    position: 'absolute',
    '& svg': {
      color: 'rgb(36, 144, 239)',
    },
  },
  jobStatus: {
    zIndex: 999999,
    borderRadius: 25,
    padding: '5px 10px',
    fontWeight: 600,
    minHeight: 33,
    color: '#fff',
    '&:hover': {
      opacity: '0.7',
    },
  },

  contentMapList: {
    width: 0,
    maxWidth: 400,
    height: 'auto',
    position: 'absolute',
    maxHeight: 'calc(100% - 72px)',
    overflow: 'scroll',
    top: 20,
    left: 0,
    borderRadius: 5,
    marginTop: -30,
    // minHeight: 'calc(100% - 135px)',
    transition: 'all .4s ease-in-out',
    background: '#fff',
  },
  contentMapListView: {
    width: '100%',
  },
  mapContainer: {
    '& > div:first-child': {
      position: 'absolute',
    },
    '& .gm-style': {
      position: 'fixed !important',
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: '51px',
      display: 'flex',
      flexDirection: 'column',
    },
    '&.mapContainerMobile': {
      [theme.breakpoints.down('sm')]: {
        paddingTop: '10px',
        '& .gm-style': {
          display: 'block',
          zIndex: 99999,
        },
        '& $contentMapShow': {
          display: 'none',
        },
      },
    },
  },
  mapContainerInfo: {
    '& .gm-style': {
      zIndex: '99',
    },
  },
}));
const initCenter = {
  lat: 1.3521,
  lng: 103.8198,
};
const libraries = ['geometry', 'places'];
function Gmap(props) {
  const {
    children,
    zoom: defaultZoom,
    getIsLoaded,
    getClassName,
    locations,
    onShow,
    jobStatus,
    isViewFull,
    isClustererMarker,
    handleViewFull,
    getCurrentZoom,
    activeMarker,
    handleRemoveInfoWindow,
    status,
  } = props;
  const classes = useStyles();
  const [isLoaded, setIsLoaded] = useState(false);
  const settings = useSelector(({ setting }) => setting.commonSettings);
  const [zoom, setZoom] = useState(defaultZoom);
  const [mapOptions, setMapOptions] = useState({
    styles: onShow ? mapStyleShow : mapStyles,
  });
  const [settingMap, setSettingMap] = useState(false);
  const [openContent, setOpenContent] = useState(true);
  const [markerPos, setMarkerPos] = useState();
  const [map, setMap] = useState();
  const [jobStatusName, setJobStatusName] = useState();
  const [openComment, setOpenComment] = useState(false);
  const ref = useRef();
  const calcularCenter = (locas) => {
    let lat = 0;
    let lng = 0;
    locas?.map((x) => {
      lat += parseFloat(x?.lat);
      lng += parseFloat(x?.lng);
      return x;
    });
    const lengthLocal = locas?.length;
    const newCenter = {
      lat: lat / lengthLocal,
      lng: lng / lengthLocal,
    };
    setMarkerPos(newCenter);
  };

  useEffect(() => {
    if (!isLoaded) return;
    let mounted = true;
    if (locations?.length >= 2 && mounted) {
      const bounds = new window.google.maps.LatLngBounds();
      for (let i = 0; i < locations.length; i += 1) {
        bounds.extend(locations[i]);
      }
      map.fitBounds(bounds);
    }
    // eslint-disable-next-line
    return () => {
      mounted = false;
    };
  }, [isLoaded, locations]);

  useEffect(() => {
    if (!markerPos) return;
    setZoom(14);
  }, [markerPos]);

  useEffect(() => {
    if (!activeMarker) return;
    setOpenContent(false);
    setOpenComment(false);
  }, [activeMarker]);
  const loadedMap = useCallback(
    (maps) => {
      setIsLoaded(true);
      getIsLoaded?.(true);
      setMap(maps);
      setMapOptions({
        styles: onShow ? mapStyleShow : mapStyles,
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false,
        zoomControl: false,
        minZoom: 11,
        maxZoom: 18,
      });
    },
    [settings?.map_api_key],
  );

  const handleOpenSetting = () => {
    getClassName?.(!settingMap);
    setSettingMap(!settingMap);
  };
  const handleMoveMap = () => {
    setOpenContent(false);
    if (isViewFull || isClustererMarker) {
      handleViewFull?.();
    }
    if (openComment) {
      setOpenComment(false);
    }
    if (activeMarker) {
      handleRemoveInfoWindow?.();
    }
  };

  const requestFullscreen = (element) => {
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.webkitRequestFullScreen) {
      element.webkitRequestFullScreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.msRequestFullScreen) {
      element.msRequestFullScreen();
    }
  };
  const handleFullScreen = () => {
    requestFullscreen(ref.current?.state?.map?.getDiv()?.firstChild);
  };
  const handleCenterMap = () => {
    if (locations.length === 0 || !isLoaded) return;
    if (locations.length === 1) {
      setMarkerPos({ lat: locations[0]?.lat, lng: locations[0]?.lng });
    } else {
      calcularCenter(locations);
    }
  };

  const handleZoomChange = (change) => {
    const maxZoom = 16;
    const minZoom = 11;

    if (change > maxZoom || change < minZoom) return;

    setZoom(change);
  };

  const renderBtnZoom = () => {
    return (
      <Box className={classes.groupSetting}>
        <CustomTooltip
          title={settingMap ? 'Hide Map Actions' : 'Open Map Actions'}
        >
          <Button
            onClick={handleOpenSetting}
            className={clsx(classes.moreSettingMap)}
          >
            <CenterFocusStrongIcon />
          </Button>
        </CustomTooltip>

        {settingMap && (
          <Box className={classes.groupButton}>
            <CustomTooltip title="Zoom In">
              <Button onClick={() => handleZoomChange(zoom + 1)}>
                <AddIcon />
              </Button>
            </CustomTooltip>

            <CustomTooltip title="Zoom Out">
              <Button onClick={() => handleZoomChange(zoom - 1)}>
                <RemoveIcon />
              </Button>
            </CustomTooltip>

            <CustomTooltip title="Full Screen">
              <Button onClick={handleFullScreen}>
                <FullscreenIcon />
              </Button>
            </CustomTooltip>

            <CustomTooltip title="Focus Path">
              <Button onClick={handleCenterMap}>
                <MyLocation />
              </Button>
            </CustomTooltip>
          </Box>
        )}
      </Box>
    );
  };
  const handleOpenContent = () => {
    setOpenContent((prev) => !prev);
    handleRemoveInfoWindow?.();
  };
  useEffect(() => {
    if (!jobStatus) return;
    setJobStatusName(replaceSpaceUpercaseString(jobStatus?.name));
  }, [jobStatus]);
  function handleZoomChanged() {
    const currentZoom = this.getZoom(); //eslint-disable-line
    getCurrentZoom?.(currentZoom);
  }

  useEffect(() => {
    if (openContent) {
      setSettingMap(false);
    }
  }, [openContent]);

  useEffect(() => {
    if (settingMap) {
      setOpenContent(false);
    }
  }, [settingMap]);

  const { openSidebar } = useMainLayoutContext();

  if (!settings?.map_api_key) return null;
  return (
    <LoadScriptNext
      id="google-map-gmap"
      googleMapsApiKey={settings?.map_api_key}
      libraries={libraries}
      {...props}
    >
      <GoogleMap
        ref={ref}
        mapContainerStyle={containerStyle}
        center={markerPos || initCenter}
        zoom={zoom}
        options={mapOptions}
        onLoad={loadedMap}
        onClick={handleMoveMap}
        onZoomChanged={handleZoomChanged}
        mapContainerClassName={clsx(
          classes.mapContainer,
          activeMarker && classes.mapContainerInfo,
        )}
      >
        <Box
          className={clsx(
            onShow ? classes.buttonMapShow : classes.buttonMap,
            openSidebar && 'open-side-bar',
            status === 'edit' && openContent && classes.buttonMapEdit,
          )}
        >
          {jobStatus && (
            <Button
              className={clsx(classes.jobStatus)}
              style={{
                background: ColorStatus[jobStatusName],
              }}
            >
              {jobStatus?.name}
            </Button>
          )}

          <CustomTooltip title={openContent ? 'Hide Info' : 'Show Info'}>
            <Button
              className={clsx(
                classes.btnContentMap,
                !openContent && classes.buttonEffect,
              )}
              onClick={handleOpenContent}
            >
              <InfoOutlinedIcon />
            </Button>
          </CustomTooltip>

          {renderBtnZoom()}
        </Box>
        <Box
          className={clsx(
            !onShow ? classes.contentMap : classes.contentMapShow,
            status === 'edit' && classes.contentMapEdit,
            !openContent && classes.hide,
            'wrapContentMap',
          )}
          id="wrapContentMap"
        >
          {children}
        </Box>
      </GoogleMap>
    </LoadScriptNext>
  );
}

Gmap.propTypes = {
  // markerPos: PropTypes.shape({
  //   lat: PropTypes.number,
  //   lng: PropTypes.number,
  // }),
  zoom: PropTypes.number,
  children: PropTypes.node,
  getIsLoaded: PropTypes.func,
  getClassName: PropTypes.func,
  locations: PropTypes.array,
  onShow: PropTypes.bool,
  jobStatus: PropTypes.object,
  isViewFull: PropTypes.bool,
  isClustererMarker: PropTypes.bool,
  getCurrentZoom: PropTypes.func,
  handleViewFull: PropTypes.func,
  handleRemoveInfoWindow: PropTypes.func,
  activeMarker: PropTypes.string,
  resource: PropTypes.string,
  permissions: PropTypes.array,
  options: PropTypes.object,
  status: PropTypes.string,
};

Gmap.defaultProps = {
  zoom: 12,
  children: undefined,
  getIsLoaded: undefined,
  getClassName: undefined,
  locations: undefined,
  onShow: undefined,
  jobStatus: undefined,
  isViewFull: undefined,
  isClustererMarker: undefined,
  getCurrentZoom: undefined,
  handleViewFull: undefined,
  handleRemoveInfoWindow: undefined,
  activeMarker: undefined,
  resource: undefined,
  permissions: undefined,
  options: undefined,
  status: undefined,
};

export default React.memo(Gmap);
