import { Box } from '@mui/material';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

const BookingList = ({
  data,
  component,
  onItemClick,
  containerClassName,
  itemClassName,
  itemProps,
}) => {
  const handleItemClick = (item) => (event) => {
    onItemClick?.(item, event);
  };

  if (!component) return <></>;
  if (isEmpty(data)) return <></>;
  return (
    <Box className={containerClassName}>
      {data?.map((item) =>
        React.createElement(component, {
          ...item,
          ...itemProps,
          key: item.id,
          className: itemClassName,
          onClick: handleItemClick(item),
        }),
      )}
    </Box>
  );
};

BookingList.propTypes = {
  component: PropTypes.func.isRequired,
  data: PropTypes.array,
  onItemClick: PropTypes.func,
  containerClassName: PropTypes.string,
  itemClassName: PropTypes.string,
  itemProps: PropTypes.object,
};

BookingList.defaultProps = {
  onItemClick: undefined,
  containerClassName: null,
  itemClassName: null,
  itemProps: {},
  data: [],
};

export default BookingList;
