import { useParams } from 'react-router';
import { usePostStripe } from '../api/features/common.feature';

const useStripeCheckout = (props) => {
  const {
    successUrl = '/p/event/trip',
    // eslint-disable-next-line
    cancelUrl = '/p/event/online-fail',
    target = 'LLA',
  } = props || {};

  const checkoutMutation = usePostStripe();
  const { code = '' } = useParams() || {};

  const stripeCheckout = (jobId) => {
    const { origin } = window.location;

    const fullSuccessURL = `${origin}/#${successUrl}?session_id={CHECKOUT_SESSION_ID}`;
    const fullCancelURL = `${origin}/#/p/event/c/${code}`;
    checkoutMutation.mutate(
      {
        params: { id: jobId },
        data: {
          successUrl: fullSuccessURL,
          cancelUrl: fullCancelURL,
          target,
        },
      },
      {
        onSuccess: (response) => {
          window.open(response.url, '_self');
        },
      },
    );
  };

  return {
    stripeCheckout,
    ...checkoutMutation,
  };
};

export default useStripeCheckout;
