import gzip from 'gzip-js';
import { isEmpty } from 'lodash';
import moment from 'moment';
import LocalStorage from '../local-storage';

const shouldCache = true; // process.env.NODE_ENV === 'production';

/**
 * @fetchAndCache will cache data for reuse.
 */
export const fetchAndCache = async ({
  localStorageKey,
  handler,
  cacheDurationInSeconds = process.env.NODE_ENV === 'production' ? 30 : 15,
  forceUpdate = false,
  transformData = (data) => data,
}) => {
  if (shouldCache) {
    if (!forceUpdate) {
      const data = LocalStorage.getItem(localStorageKey);
      if (!isEmpty(data)) {
        const { cacheExpiredTime } = data;
        if (moment(cacheExpiredTime).isSameOrAfter(new Date())) {
          return transformData(data);
        }
      }
    }
  }

  try {
    if (!handler) {
      return transformData({});
    }
    const response = await handler();
    if (shouldCache) {
      LocalStorage.setItem(localStorageKey, {
        ...response,
        cacheExpiredTime: moment()
          .add(cacheDurationInSeconds, 'seconds')
          .toDate(), // cache will live 'cacheDurationInSeconds' minutes
      });
    }
    return transformData(response);
  } catch (ex) {
    console.error(ex);
    return transformData({});
  }
};

export const fetchAndZipped = async ({
  localStorageKey,
  handler,
  cacheDurationInSeconds = process.env.NODE_ENV === 'production' ? 30 : 15,
  forceUpdate = false,
  transformData = (data) => data,
}) => {
  if (shouldCache) {
    if (!forceUpdate) {
      const data = LocalStorage.getItem(localStorageKey);
      if (!isEmpty(data)) {
        const { cacheExpiredTime, data: zippedData } = data;
        if (
          !!cacheExpiredTime &&
          moment(cacheExpiredTime).isSameOrAfter(new Date())
        ) {
          return transformData(
            JSON.parse(Buffer.from(gzip.unzip(zippedData)).toString()),
          );
        }
      }
    }
  }

  try {
    const response = await handler();
    if (shouldCache) {
      const zippedData = gzip.zip(JSON.stringify(response), {
        level: 3,
      });
      LocalStorage.setItem(localStorageKey, {
        data: zippedData,
        compressed: true,
        cacheExpiredTime: moment()
          .add(cacheDurationInSeconds, 'seconds')
          .toDate(), // cache will live 'cacheDurationInSeconds' minutes
      });
    }
    return transformData(response);
  } catch (ex) {
    console.error(ex);
    return transformData({});
  }
};

export const clearCaches = async ({ localStorageKeys }) => {
  if (shouldCache && localStorageKeys?.length) {
    const allKeys = LocalStorage.getAllKeys();
    localStorageKeys.forEach((key) => {
      if (key.includes('*')) {
        const keyPattern = key.replace('*', '');
        allKeys.forEach((k) => {
          if (k.includes(keyPattern)) {
            LocalStorage.removeItem(k);
          }
        });
      } else {
        LocalStorage.removeItem(key);
      }
    });
  }
};
