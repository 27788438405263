import { Box, Icon, MenuItem } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import CallToActionFormGuesser from '../../../base/components/guesser/call-to-action-form';
import useCtaButton from '../../../base/hooks/useCtaButton';

const CtaItem = ({ item, resource, record, view, onClick, onClose }) => {
  const {
    id,
    name,
    icon,
    onClick: onCtaClick,
    callToActionForm,
    setCallToActionForm,
  } = useCtaButton({
    item,
    resource: resource || 'dashboard',
    record,
    view,
  });

  const handleOnClick = () => {
    onClick?.();
    onCtaClick?.();
  };

  return (
    <>
      <MenuItem key={id} component="span" onClick={handleOnClick}>
        {icon && (
          <Icon color="inherit" fontSize="small">
            {icon}
          </Icon>
        )}
        <Box ml={2}>{name}</Box>
      </MenuItem>
      {callToActionForm.open && (
        <CallToActionFormGuesser
          {...callToActionForm}
          onClose={() => {
            onClose?.();
            setCallToActionForm({
              open: false,
            });
          }}
          record={record}
        />
      )}
    </>
  );
};

CtaItem.propTypes = {
  item: PropTypes.object.isRequired,
  resource: PropTypes.string,
  record: PropTypes.object,
  view: PropTypes.string,
  onClick: PropTypes.func,
  onClose: PropTypes.func,
  hasIcon: PropTypes.bool,
};

CtaItem.defaultProps = {
  view: 'list',
  onClick: undefined,
  onClose: undefined,
  record: undefined,
  hasIcon: true,
  resource: null,
};

export default CtaItem;
