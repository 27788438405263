/* eslint-disable no-param-reassign */
import { CondOperator, RequestQueryBuilder } from '@nestjsx/crud-request';
import {
  camelCase,
  flatten,
  isBoolean,
  isEmpty,
  isNil,
  startCase,
  upperCase,
} from 'lodash';
import moment from 'moment';
import { stringify } from 'query-string';
import { fetchUtils, HttpError } from 'react-admin';
import { prefixFilterDateRange } from '../../base/components/guesser/date-range-input';
import { emptyValueOperators } from '../../base/constants';
import { fetchSchema } from '../redux/schema/schema.saga';
import { convertFileToBase64 } from '../util/convertFileToBase64';
import { stripResourceBuiltinData } from '../util/strip-resource-builtin-data';
import axios from './axios';

export const getSyntaxJsonUnquoteExtract = (field) => `->>'$.${field}'`;

export const getJsonSource = (source, locale) => `${source}.json.${locale}`;

export const isJsonSource = (source) =>
  source?.includes('.json.') && source?.split('.json.')?.length === 2;

export const extractJsonSource = (source) => {
  if (!isJsonSource(source)) return source;

  const parts = source?.split('.json.');
  return {
    source: parts[0],
    locale: parts[1],
  };
};

export const getSourceForJson = (originalSource) => {
  const jsonSource = extractJsonSource(originalSource);

  if (typeof jsonSource === 'string') return jsonSource;

  const { source, locale } = jsonSource;
  return `${source}->>'$.${locale}'`;
};

const httpClient = async (args) =>
  new Promise((resolve, reject) => {
    axios
      .request({
        ...args,
        method: args.method || 'get',
        // eslint-disable-next-line no-unused-vars
        validateStatus: (code) => true,
      })
      .then(({ data, ...rest }) => {
        if (data.statusCode) {
          return reject(
            new HttpError(
              Array.isArray(data.message)
                ? data.message.join(', ')
                : data.message,
              data.statusCode,
              data,
            ),
          );
        }

        if (rest.status >= 400) {
          return reject(
            new HttpError(
              `${
                (Array.isArray(data.message)
                  ? data.message.join(', ')
                  : data.message) || rest.statusText
              }`,
              rest.status,
              data,
            ),
          );
        }

        return resolve({
          ...rest,
          data,
        });
      });
  });

const getFilterOperator = (op) => {
  if (!op || op === '$is') return '$eq';
  if (op === '$isnot') return '$ne';
  return op;
};

const isValidFilter = (filter) => {
  if (!filter) return false;
  if (
    ['$between', '$notbetween'].includes(filter.op) &&
    !filter.value?.includes(',')
  ) {
    return false;
  }
  return true;
};

const addFilter = (filters, field, filter) => {
  if (!filters || !field || isNil(filter)) return;
  if (
    (Array.isArray(filter) ||
      typeof filter === 'object' ||
      typeof filter === 'string') &&
    isEmpty(filter)
  ) {
    return;
  }

  if (typeof filter === 'object') {
    // eslint-disable-next-line
    if (filter.hasOwnProperty('value') || filter.hasOwnProperty('op')) {
      if (isValidFilter(filter)) {
        filters[field] = filter;
      }
    } else {
      Object.keys(filter)?.forEach((key) => {
        addFilter(filters, `${field}.${key}`, filter[key]);
      });
    }
  } else {
    let op;

    if (typeof filter === 'boolean') {
      op = CondOperator.EQUALS;
    } else if (
      typeof filter === 'string' &&
      filter.startsWith(prefixFilterDateRange)
    ) {
      op = CondOperator.BETWEEN;
      filter = filter?.split(prefixFilterDateRange)[1]; // eslint-disable-line prefer-destructuring
    } else {
      op = CondOperator.CONTAINS;
    }

    filters[field] = {
      value: filter,
      op,
    };
  }
};

export const composeFilter = (paramsFilter) => {
  const allFilters = {};
  Object.keys(paramsFilter)?.forEach((field) => {
    const filter = paramsFilter[field];
    addFilter(allFilters, field, filter);
  });

  const filters =
    Object.keys(allFilters)
      .filter(
        (key) =>
          (typeof allFilters[key].value !== 'object' &&
            !isNil(allFilters[key].value) &&
            allFilters[key].value !== '') ||
          emptyValueOperators.includes(allFilters[key].op),
      )
      .map((key) => {
        let field = key;
        let { op, value } = allFilters[field];

        if (field.startsWith('_') && field.includes('.')) {
          field = field?.split(/\.(.+)/)[1]; // eslint-disable-line prefer-destructuring
        }

        if (field === 'docStatus') {
          value = `"${Number(value)}"`;
        }

        if (op === '$on' || op === '$non') {
          if (moment(value).isValid()) {
            value = `${moment(value).startOf('day').toISOString()},${moment(
              value,
            )
              .endOf('day')
              .toISOString()}`;
          }
          op = op === '$non' ? '$notbetween' : '$between';
        }

        if (emptyValueOperators.includes(allFilters[key].op)) {
          value = null;
        }

        return {
          field: getSourceForJson(field),
          operator: getFilterOperator(op),
          value,
        };
      }) || [];

  return filters;
};

export const composeOperator = (paramsOperator) => {
  const value = paramsOperator
    ?.map((op) => {
      if (op?.value) {
        return op.value;
      }
      return typeof op === 'string' && op;
    })
    ?.filter((i) => !isBoolean(i));
  return value;
};

export const composeOr = (paramsFilter) => {
  const orParams = flatten(
    Object.keys(paramsFilter)
      .filter((key) => key === '$or')
      .map((key) => paramsFilter[key]),
  );
  return orParams.map((item) => {
    const splitKey = item?.split('||');
    const field = splitKey[0];
    const operator = splitKey[1];
    const value = splitKey?.[2];
    return {
      field,
      operator,
      value,
    };
  });
};

export const composeQueryParams = (queryParams = {}) =>
  stringify(fetchUtils.flattenObject(queryParams));
export const mergeEncodedQueries = (...encodedQueries) =>
  encodedQueries.map((query) => query).join('&');

export const transform = (data) => {
  if (typeof data !== 'object') return data;
  return Object.keys(data || {}).reduce(
    (prev, curr) => ({
      ...prev,
      [curr]: data?.[curr] === '' ? null : data?.[curr],
    }),
    {},
  );
};

const crudProvider = (apiUrl) => ({
  getAll: (resource, params) => {
    const { q: queryParams, ...filter } = params.filter || {};
    const encodedQueryParams = composeQueryParams(queryParams);
    const encodedQueryFilter = RequestQueryBuilder.create({
      filter: composeFilter(filter),
    }).query();
    const query = mergeEncodedQueries(encodedQueryParams, encodedQueryFilter);
    const url = `${apiUrl}/${resource}?${query}`;
    return httpClient({
      url,
    }).then(({ data }) => ({
      data: data || [],
      total: data?.length,
    }));
  },
  getList: (resource, params) => {
    const { page, perPage = 25 } = params.pagination || {};
    const { q: queryParams, ...filter } = params.filter || {};

    // Get rid of null/undefined/empty string search value
    const enhancedFilters = Object.entries(filter || {}).reduce(
      (obj, [key, value]) => {
        if (!Number.isInteger(value) && typeof value !== 'boolean') {
          if (!value) {
            return obj;
          }
        }
        obj[key] = value;
        return obj;
      },
      {},
    );

    const composedFilters = composeFilter(enhancedFilters);
    if (composedFilters?.length) {
      composedFilters.forEach((i) => {
        const [field, operator] = i?.field?.split('||');
        if (operator) {
          i.operator = operator;
          i.field = field;
        }
      });
    }

    // TODO: fix this issue on nf-input.guesser which causes issues with quota field's defaultFilter props not able to filter number fields
    const encodedQueryParams = composeQueryParams(queryParams);
    const queryBuilder = RequestQueryBuilder.create({
      filter: composedFilters,
      or: composeOr(enhancedFilters),
    }).sortBy(params.sort);
    if (!isEmpty(params.pagination)) {
      queryBuilder
        .setLimit(perPage)
        .setPage(page)
        .setOffset((page - 1) * perPage);
    }
    const encodedQueryFilter = queryBuilder.query();

    const query = mergeEncodedQueries(encodedQueryParams, encodedQueryFilter);
    const path = params.meta?.path || resource;
    const url = `${apiUrl}/${path}?${query.replace(
      /\%7C\%7C\%24cont\%7C\%7C\%24isnull/g, // eslint-disable-line no-useless-escape
      '%7C%7C%24isnull',
    )}`;
    return httpClient({
      url,
      method: params.meta?.method || (path.includes('/') && 'post') || 'get',
    }).then(({ data: { data, total, summary } }) => {
      // we have no way to pass extra data to useGetList result (as react-admin ignores)
      global[resource] = {
        ...(global[resource] || {}),
        report: {
          summary,
        },
      };
      return {
        data,
        total,
      };
    });
  },

  getOne: (resource, params) => {
    const path = params.meta?.path || resource;
    return httpClient({
      url: `${apiUrl}/${path}/${params.id}`,
      method: params.meta?.method || (path.includes('/') && 'post') || 'get',
    }).then(({ data }) => ({
      data,
    }));
  },

  getMany: (resource, params) => {
    const ids = composeOperator(params?.ids);
    const query = !isEmpty(ids)
      ? RequestQueryBuilder.create()
          .setFilter({
            field: 'id',
            operator: CondOperator.IN,
            value: `${ids}`,
          })
          .query()
      : '';
    const path = params.meta?.path || resource;
    const url = `${apiUrl}/${path}?${query}`;
    return httpClient({
      url,
      method: params.meta?.method || (path.includes('/') && 'post') || 'get',
    }).then(({ data }) => ({
      data,
    }));
  },

  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { q: queryParams, ...otherFilters } = params.filter || {};
    const filter = composeFilter(otherFilters);
    filter.push({
      field: params.target,
      operator: CondOperator.EQUALS,
      value: params.id,
    });

    const encodedQueryParams = composeQueryParams(queryParams);
    const encodedQueryFilter = RequestQueryBuilder.create({
      filter,
    })
      .sortBy(params.sort)
      .setLimit(perPage)
      .setOffset((page - 1) * perPage)
      .query();

    const query = mergeEncodedQueries(encodedQueryParams, encodedQueryFilter);
    const path = params.meta?.path || resource;
    const url = `${apiUrl}/${path}?${query}`;

    return httpClient({
      url,
      method: params.meta?.method || (path.includes('/') && 'post') || 'get',
    }).then(({ data: { data, total } }) => ({
      data,
      total,
    }));
  },

  update: (resource, params) =>
    httpClient({
      url: `${apiUrl}/${resource}/${params.id}`,
      method: 'patch',
      data: transform(stripResourceBuiltinData(params?.data)),
    }).then(({ data }) => ({
      data,
    })),

  submit: (resource, params) =>
    httpClient({
      url: `${apiUrl}/${resource}/${params.id}/submit`,
      method: 'post',
    }).then(({ data }) => ({
      data,
    })),

  updateMany: (resource, params) =>
    Promise.all(
      params.ids.map((id) =>
        httpClient({
          url: `${apiUrl}/${resource}/${id}`,
          method: 'patch',
          data: transform(params.data),
        }).then(({ data }) => data),
      ),
    ).then((data) => ({
      data,
    })),

  create: (resource, params) =>
    httpClient({
      url: `${apiUrl}/${resource}`,
      method: 'post',
      data: transform(params.data),
    }).then(({ data }) => ({
      data: {
        ...data,
        id: data.id,
      },
    })),

  delete: (resource, params) =>
    httpClient({
      url: `${apiUrl}/${resource}/${params.id}`,
      method: 'delete',
    }).then(({ data }) => ({
      data: {
        ...data,
        id: params.id,
      },
    })),

  deleteMany: (resource, params) =>
    Promise.all(
      params.ids.map((id) =>
        httpClient({
          url: `${apiUrl}/${resource}/${id}`,
          method: 'delete',
        }),
      ),
    ).then(() => ({
      data: params.ids,
    })),

  put: async (resource, params) =>
    httpClient({
      url: `${apiUrl}/${resource}${params.id ? `/${params.id}` : ''}`,
      method: 'put',
      data: transform(params.data),
    }).then(({ data }) => ({
      data,
    })),
});

const pascalCase = (str) => startCase(camelCase(str)).replace(/ /g, '');

const parseBase64Data = async (resource, params) => {
  // Check has image field
  let realResource;
  realResource = pascalCase(resource?.split('/')[0]);

  if (resource.includes('/')) {
    realResource = pascalCase(resource?.split('/')[1]);
  }

  const { api: data } = await fetchSchema();
  const responseSchema =
    data?.paths?.[`/bo/${resource}`]?.get?.responses?.['200']?.content?.[
      'application/json'
    ]?.schema;
  let responseRef;
  if (responseSchema?.oneOf) {
    responseRef = responseSchema.oneOf.filter((r) => r.type === 'array')?.[0]
      ?.items;
    responseRef =
      responseRef?.$ref ||
      responseRef?.allOf?.filter((i) => i?.$ref)?.[0]?.$ref;
  }
  const schemas = responseRef?.split('/');
  const schema = schemas[schemas?.length - 1];

  const dataProperties =
    data.components.schemas[realResource]?.properties ||
    data.components.schemas[upperCase(realResource)]?.properties ||
    data.components.schemas[schema]?.properties;

  const imageFields = [];
  const fileFields = [];

  // eslint-disable-next-line no-undef
  if (
    realResource.toLocaleLowerCase() === 'setting' &&
    params.data.value?.rawFile instanceof Blob // eslint-disable-line no-undef
  ) {
    return {
      value: {
        id: await convertFileToBase64(params.data.value.rawFile),
      },
    };
  }

  Object.keys(dataProperties || {}).forEach((key) => {
    const refField =
      dataProperties[key].$ref ||
      dataProperties[key].allOf?.filter((i) => i?.$ref)?.[0]?.$ref;
    if (refField?.endsWith('Blob')) {
      imageFields.push(key);
    }

    if (
      dataProperties[key].format === 'file' &&
      dataProperties[key].isFileBase64
    ) {
      fileFields.push(key);
    }
  });

  if (imageFields?.length === 0 && fileFields === 0) {
    return {};
  }

  const base64Pictures = await Promise.all(
    imageFields
      .filter(
        (fieldName) => params.data?.[fieldName]?.rawFile instanceof window.File,
      )
      .map(async (fieldName) => ({
        key: fieldName,
        value: await convertFileToBase64(params.data[fieldName]?.rawFile),
      })),
  );

  const base64Files = (
    await Promise.all(
      fileFields
        .filter(
          (fieldName) =>
            params.data?.[fieldName]?.rawFile instanceof window.File,
        )
        .map(async (fieldName) => ({
          key: fieldName,
          value: {
            data: await convertFileToBase64(params.data[fieldName]?.rawFile),
            type: params.data[fieldName]?.rawFile?.type,
            name: params.data[fieldName]?.rawFile?.name,
          },
        })),
    )
  ).reduce((obj, file) => {
    obj[file.key] = file.value;
    return obj;
  }, {});

  return base64Pictures.reduce(
    (obj, picture) => {
      obj[picture.key] = {
        id: picture.value,
      };
      return obj;
    },
    { ...base64Files },
  );
};

const enhancedDataProvider = {
  ...crudProvider(''),
  create: async (resource, params) => {
    const blobData = await parseBase64Data(resource, params);

    return crudProvider('').create(resource, {
      ...params,
      data: transform({
        ...params.data,
        ...blobData, // overide blob fields with blob data
      }),
    });
  },
  update: async (resource, params) => {
    const blobData = await parseBase64Data(resource, params);
    return crudProvider('').update(resource, {
      ...params,
      data: transform({
        ...params.data,
        ...blobData,
      }),
    });
  },
  // Write custom handlers here
  // https://marmelab.com/react-admin/DataProviders.html#extending-a-data-provider-example-of-file-upload
};

export default enhancedDataProvider;
