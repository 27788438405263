import { useTranslate } from 'react-admin';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';

const useError = () => {
  const navigate = useNavigate();
  const translate = useTranslate();

  const transformError = (error) => {
    let message = '';
    if (error?.key) message = translate(error.key);
    else message = error;

    return message;
  };

  const move = (errorMessage) => {
    const message = transformError(errorMessage);

    navigate('/p/event/oops', {
      state: message,
    });
  };

  const notify = (error) => {
    const message = transformError(error);
    toast.error(message, {
      autoClose: true,
      hideProgressBar: true,
      position: 'bottom-center',
      style: {
        width: '95%',
        margin: '0 auto 12px',
        borderRadius: 6,
      },
    });
  };

  return {
    navigate: move,
    notify,
  };
};

export default useError;
