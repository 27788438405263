import { Box, Stack, useMediaQuery, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { Children, useEffect } from 'react';
import { useLocation } from 'react-router';
import { useMainLayoutContext } from '../../../../base/context/main-layout';
import useNavigation from '../../../../base/hooks/useNavigation';
import { invertColor } from '../../../../base/utils';
import { PageBreadcrumbs } from '../../../@jumbo/components/PageComponents';
import CmtDrawer from '../../CmtDrawer';
import {
  getBackgroundStyle,
  getOverLayStyle,
} from '../../CmtHelpers/JssHelper';
import SidebarToggleHandler from './SidebarToggleHandler';

const useStyles = makeStyles((theme) => ({
  appSidebar: {
    display: 'flex',
    flexDirection: 'column',
    width: (props) => props.sidebarWidth,
    height: '100%',
    boxSizing: 'border-box',
    transition: 'all 0.3s ease',
    zIndex: 5,
    overflowY: 'scroll',
    overflowX: 'hidden',
    '.Cmt-drawer-sidebar &': {
      position: 'relative',
    },
    '& .scrollbar-container': {
      height: '100%',
      width: '100%',
    },
    '&.hide': {
      width: '0px',
    },
  },

  appSidebarHeader: {
    minHeight: 60,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'absolute',
    zIndex: 2,
    padding: '0px 8px',
    [theme.breakpoints.down('md')]: {
      minHeight: 'unset',
    },
  },
  appSidebarContent: {
    paddingTop: 48,
    height: '100%',
    position: 'relative',
    transition: 'all 0.3s ease',
    color: theme.palette.sidebar.textColor,
    overflow: 'hidden',
    '& > *': {
      position: 'relative',
      zIndex: 3,
    },
    '& .MuiList-root': {
      padding: 5,
    },
    '#root:has(.show-page) &.toolbar-fixed, #root:has(.list-page) &.toolbar-fixed':
      { paddingTop: 64 },
  },
  appSidebarContentDynamicColor: {
    '& .MuiList-root': {
      borderRadius: 6,
      backgroundColor: (props) =>
        !!props.sidebarContentTextColor
          ? invertColor(props.sidebarContentTextColor, true)
          : 'unset',
    },
    '& nav > a': {
      color: (props) =>
        props.sidebarContentTextColor ?? theme.palette.sidebar.textColor,
    },
  },
  overlayRoot: {
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 0,
    width: '100%',
    height: '100%',
  },
  menuButton: {
    padding: 8,
  },
  title: {
    fontSize: 20,
    lineHeight: '24px',
    fontWeight: 'bold',
  },
  sideBarMap: {
    padding: '0 15px !important',
    gap: '12px',
    '& button': {
      backgroundColor: '#fff',
      borderTopLeftRadius: '20px',
      borderBottomLeftRadius: '20px',
      borderTopRightRadius: '0',
      borderBottomRightRadius: '0',
      boxShadow:
        'rgb(25 39 52 / 5%) 0px 1px 2px 0px, rgb(25 39 52 / 10%) 0px 0px 4px 0px',
      '&:hover': {
        backgroundColor: '#fff !important',
      },
    },
    '& nav': {
      backgroundColor: '#fff',
      borderTopLeftRadius: '0',
      borderBottomLeftRadius: '0',
      borderTopRightRadius: '20px',
      borderBottomRightRadius: '20px',
      padding: '5px 10px 5px 6px',
      boxShadow:
        'rgb(25 39 52 / 5%) 0px 1px 2px 0px, rgb(25 39 52 / 10%) 0px 0px 4px 0px',
    },
  },
  iconMap: {
    display: 'flex',
    '& button': {
      borderRadius: '50%',
    },
  },
}));

const CmtSidebar = React.forwardRef((props, ref) => {
  const {
    children,
    drawerBreakPoint,
    sidebarWidth,
    backgroundColor,
    backgroundImage,
    gradientDirection,
    isMap,
  } = props;
  const theme = useTheme();
  const { breadcrumbs, hideSidebar } = useNavigation();
  const location = useLocation();
  const matches = useMediaQuery(theme.breakpoints.down(drawerBreakPoint));
  const link = breadcrumbs[0]?.link === '/job';
  const contentRef = React.createRef();
  const { scrollOffsetY, sidebarContentTextColor, openSidebar, toggleSidebar } =
    useMainLayoutContext();
  const backgroundStyles = getBackgroundStyle(
    backgroundColor,
    backgroundImage,
    gradientDirection,
  );
  const overlayStyles = getOverLayStyle({
    colors: backgroundColor,
    opacity: 0.8,
    direction: '180deg',
  });

  const classes = useStyles({ sidebarWidth, sidebarContentTextColor });

  useEffect(() => {
    if (matches) {
      toggleSidebar(false);
    }
  }, [location]);

  useEffect(() => {
    if (hideSidebar) {
      toggleSidebar(false);
    }
  }, [hideSidebar]);

  const getSidebarContent = (indrawer) => (
    <Box
      ref={contentRef}
      className={clsx(
        classes.appSidebar,
        !openSidebar && !matches && 'hide',
        'Cmt-sidebar',
      )}
    >
      <Box
        className={clsx(
          classes.appSidebarContent,
          sidebarContentTextColor &&
            !matches &&
            classes.appSidebarContentDynamicColor,
          'Cmt-sidebar-content',
          scrollOffsetY > 1 && 'toolbar-fixed',
        )}
        style={backgroundStyles}
      >
        {isMap
          ? Children.map(children, (child, index) => {
              if (index === 1) {
                return React.cloneElement(child, {
                  ...child.props,
                });
              }
              return null;
            })
          : children}
        {overlayStyles && backgroundImage && (
          <Box
            className={clsx(classes.overlayRoot, 'Cmt-Drawer-overlay')}
            style={overlayStyles}
          />
        )}
      </Box>
      <Box
        className={clsx(
          classes.appSidebarHeader,
          isMap || link ? classes.sideBarMap : 'Cmt-sideBarDefault',
          'Cmt-sideBarMap',
        )}
      >
        <Stack
          alignItems="center !important"
          direction="row"
          pt={matches && '6px'}
          ml={matches && '-5px'}
          gap="4px"
        >
          <SidebarToggleHandler />
          <PageBreadcrumbs items={breadcrumbs} indrawer={indrawer} />
        </Stack>

        {isMap &&
          Children.map(children, (child, index) => {
            if (index === 0) {
              return React.cloneElement(child, {
                ...child.props,
                className: classes.iconMap,
              });
            }
            return null;
          })}
      </Box>
    </Box>
  );

  if (matches) {
    return (
      <CmtDrawer
        variant="temporary"
        open={openSidebar}
        onClose={() => toggleSidebar()}
        classes={{
          paper: 'Cmt-drawer-sidebar',
        }}
      >
        {getSidebarContent(true)}
      </CmtDrawer>
    );
  }

  return getSidebarContent();
});

export default CmtSidebar;

CmtSidebar.defaultProps = {
  sidebarWidth: 220,
  drawerBreakPoint: 'xs',
  name: 'LayoutSidebar',
  gradientDirection: '180deg',
  isMap: undefined,
};

CmtSidebar.propTypes = {
  sidebarWidth: PropTypes.number,
  drawerBreakPoint: PropTypes.oneOf(['xs', 'sm', 'md']),
  backgroundColor: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  backgroundImage: PropTypes.string,
  gradientDirection: PropTypes.string,
  isMap: PropTypes.bool,
};
