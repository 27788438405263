import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import DefaultButton from '../default';
import useStyles from './index.style';

const JobTypeButton = ({ name, onClick, selected }) => {
  const classes = useStyles();
  return (
    <DefaultButton
      name={name}
      onClick={onClick}
      className={clsx(classes.jobTypeBtn, selected && classes.selected)}
    />
  );
};

JobTypeButton.propTypes = {
  name: PropTypes.string,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
};

JobTypeButton.defaultProps = {
  name: 'service button',
  onClick: undefined,
  selected: false,
};

export default JobTypeButton;
