import { Box, Typography } from '@mui/material';
import clsx from 'clsx';
import { omit } from 'lodash';
import PropTypes from 'prop-types';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSelectPopularDestinationList } from '../../api/features/common.feature';
import { useBookingCommonStyles } from '../../common/style';
import {
  BookingBottomSheet,
  BookingList,
  BookingLocationItem,
  BookingPlaceItem,
  LocationTimeInput,
} from '../../components';
import SelectLocationPoint from '../../components/select-location-point';
import { EVENTS, JOB_TYPE, SUB_JOB_TYPE } from '../../constants';
import { publish, subscribe } from '../../events';
import { formatValueToPlaceItem } from '../../helpers/components';
import useGetPlacePhotos from '../../hooks/useGetPlacePhotos';
import useMultipleDropoff from '../../hooks/useMultipleDropoff';
import {
  getSelectedJobType,
  getSelectedSubJobType,
} from '../../services/redux/reducers/job-type.reducer';
import useStyles from './index.style';

const BookingDropOffScreen = ({ onDone }) => {
  const classes = useStyles();
  const locationTimeInputRef = useRef();
  const commonClasses = useBookingCommonStyles();
  const { data: popularDestinationList } = useSelectPopularDestinationList();
  const placePhotoService = useGetPlacePhotos();
  const { currentDropOffValue, update, currentDropOffIndex, setIndex } =
    useMultipleDropoff();

  const selectedSubJobType = useSelector(getSelectedSubJobType);
  const { type: typeOfSubJobType } = selectedSubJobType || {};

  const selectedJobType = useSelector(getSelectedJobType);
  const { type: typeOfJobType } = selectedJobType || {};

  const { placeId } = currentDropOffValue || {};
  const [photoList, setPhotoList] = useState([]);
  const [openDropOff, setOpenDropOff] = useState(false);

  const handleLocationClose = useCallback(() => {
    setOpenDropOff(false);
    setPhotoList([]);
    setIndex(null);
    locationTimeInputRef.current?.clearTextInput();
  }, []);

  const onPopularItemClick = useCallback((item) => {
    setOpenDropOff(true);
    update(
      {
        id: item.id,
        ...item.location,
      },
      currentDropOffIndex,
    );
    setIndex(0);
  }, []);

  const onConfirmLocationButtonClick = () => {
    onDone?.();
    handleLocationClose();
  };

  const onLocationChange = (value, locationIndex = currentDropOffIndex) => {
    setIndex(locationIndex);
    update(value, locationIndex);
    publish(EVENTS.DROPOFF_LOCATION);
    setOpenDropOff(true);
  };

  const onDropOffLocationClick = (data) => {
    setOpenDropOff(true);
    setIndex(data.detail?.index);
  };

  useEffect(() => {
    if (!placeId) {
      setPhotoList([]);
      return;
    }
    placePhotoService.getList(placeId, (photos) => {
      setPhotoList(formatValueToPlaceItem(photos));
    });
  }, [placeId]);

  useEffect(() => {
    subscribe(EVENTS.DROPOFF_LOCATION_CLICK, onDropOffLocationClick);
    subscribe(EVENTS.INCREASE_DROPOFF_LOCATION_CLICK, onDropOffLocationClick);
  }, []);

  return (
    <>
      {(typeOfSubJobType === SUB_JOB_TYPE.POINT2POINT ||
        typeOfJobType === JOB_TYPE.STANDARD) && (
        <Box className={classes.home}>
          <Box className={classes.inner}>
            <LocationTimeInput
              onLocationChange={(value) => onLocationChange(value, 0)}
            />
            <Box className={classes.popularDestinationContainer}>
              <Typography
                className={clsx(commonClasses.titleRegular, classes.txtTitle)}
                onClick={onLocationChange}
              >
                Popular Destinations
              </Typography>

              <BookingList
                containerClassName={classes.popularDestinationList}
                data={popularDestinationList}
                component={BookingLocationItem}
                onItemClick={onPopularItemClick}
              />
            </Box>
          </Box>
        </Box>
      )}

      {/* Booking Dropoff Location */}
      <BookingBottomSheet
        // allowMinHeight={isEmpty(photoList)}
        drawerPaperMinHeight
        title={`Select your Dropoff Point ${
          currentDropOffIndex === 0 ? '' : currentDropOffIndex + 1
        }`}
        open={openDropOff}
        onClose={handleLocationClose}
      >
        <SelectLocationPoint
          ctaButtonName="Choose this Dropoff"
          value={omit(currentDropOffValue, 'id')}
          photos={photoList}
          component={BookingPlaceItem}
          onConfirmLocationClick={onConfirmLocationButtonClick}
          locationComponent={
            <LocationTimeInput
              ref={locationTimeInputRef}
              onLocationChange={onLocationChange}
            />
          }
        />
      </BookingBottomSheet>
    </>
  );
};

BookingDropOffScreen.propTypes = {
  onDone: PropTypes.func,
};

BookingDropOffScreen.defaultProps = {
  onDone: undefined,
};

export default memo(BookingDropOffScreen);
