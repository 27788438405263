import { isArray } from 'lodash';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import configs from '../../../../../../services/configs';
import { JOB_TYPE } from '../../constants';
import { useGetOne, usePost, useSelectData } from '../../services/tanstack';
import {
  convertDurationToMinute,
  convertUrlValueToFullUrl,
  sortToAlphabet,
} from '../../utils';
import { API_ROUTES } from '../routes';

export const useGetEventByCode = (options) => {
  const params = useParams();
  const urlWithId = convertUrlValueToFullUrl(API_ROUTES.getEventByCode, params);
  return useGetOne(urlWithId, {
    ...options,
    shouldNavigateToErrorPage: true,
  });
};

export const useGetEventByCodeOnly = (options) => {
  const params = useParams();
  const urlWithId = convertUrlValueToFullUrl(API_ROUTES.getEventByCode, {
    code: params.code,
  });
  return useGetOne(urlWithId, {
    ...options,
    shouldNavigateToErrorPage: true,
  });
};

export const useSelectEventBycode = () => {
  const params = useParams();
  const urlWithId = convertUrlValueToFullUrl(API_ROUTES.getEventByCode, {
    code: params.code,
  });
  return useSelectData(urlWithId);
};

export const useSelectEvent = () => {
  const params = useParams();
  const urlWithId = convertUrlValueToFullUrl(API_ROUTES.getEventByCode, params);

  return useSelectData(urlWithId);
};

export const useSelectPickupList = () => {
  const { data, ...rest } = useSelectEvent();
  const fiteredPickupList =
    data?.eventLocations
      ?.filter(
        (location) =>
          location.type === 'PICK_UP' && location?.location?.coordinate,
      )
      .sort((a, b) => a.displayOrder - b.displayOrder) || [];

  const formatted = fiteredPickupList.map((location) => ({
    id: location.id,
    location: {
      ...location.location,
      addressName: location.name,
    },
  }));
  return {
    data: formatted,
    ...rest,
  };
};

export const useSelectPopularDestinationList = () => {
  const { data, ...rest } = useSelectEvent();
  const fiteredDropoffList =
    data?.eventLocations
      ?.filter((location) => location.type === 'DROP_OFF')
      .sort((a, b) => a.displayOrder - b.displayOrder) || [];
  const formatted = fiteredDropoffList.map((location) => ({
    id: location.id,
    location: location.location,
  }));
  return {
    data: formatted,
    ...rest,
  };
};

export const useGetReservationBySessionId = () => {
  const [searchParams] = useSearchParams();

  const sessionId = searchParams.get('session_id');
  const jobCode = searchParams.get('code');

  const params = {};
  if (sessionId) params.session_id = sessionId;
  if (jobCode) params.jobCode = jobCode;

  const context = useGetOne(API_ROUTES.getReservation, {
    configApiOptions: {
      params,
    },
    shouldNavigateToErrorPage: true,
  });

  return context;
};

export const useSelectReservationBySessionId = () => {
  return useSelectData(API_ROUTES.getReservation, {
    shouldNavigateToErrorPage: true,
  });
};

export const useGetQrCode = (value, options) => {
  return useGetOne(API_ROUTES.getQrCode, {
    ...options,
    configApiOptions: {
      baseURL: configs.apiUrl,
      responseType: 'arraybuffer',
      params: {
        qrString: JSON.stringify({
          type: 'JobCode',
          value,
        }),
        qrDark: '000000',
      },
    },
  });
};

export const useGetQrCodeDownload = (value) => {
  window.open(
    `${configs.apiUrl}${API_ROUTES.getQrCodeDownload}?qrString=%7B%22type%22:%22JobCode%22,%22value%22:%22${value}%22%7D&qrDark=000000`,
  );
};

export const usePostStripe = () => {
  return usePost(API_ROUTES.postStripeCheckout);
};

export const useSelectStripe = (options) => {
  return useSelectData(API_ROUTES.postStripeCheckout, options);
};

export const usePostFee = () => {
  return usePost(API_ROUTES.postFee);
};

export const useSelectFee = (typeOfJobType) => {
  const { data, ...rest } = useSelectData(API_ROUTES.postFee);

  if (!isArray(data)) return [];

  const formatted = data?.map((fee) => {
    const { subJobType, jobDistance, jobDuration, totalFare } = fee || {};
    const { id, name } = subJobType || {};

    // const isStandard = id === SUBJOB_TYPES.STANDARD;
    // const name = isStandard
    //   ? SUBJOB_TYPE_DISPLAY_NAMES.STANDARD
    //   : SUBJOB_TYPE_DISPLAY_NAMES.PREMIUM;
    const image =
      typeOfJobType === JOB_TYPE.PREMIUM
        ? 'images/booking/limousine_car.png'
        : 'images/booking/car.png';

    return {
      id,
      name: name.en,
      image,
      seater: '',
      distance: jobDistance?.toFixed(2),
      price: totalFare,
      duration: convertDurationToMinute(jobDuration),
    };
  });

  const sorted = sortToAlphabet(formatted, 'name');
  return {
    data: sorted,
    ...rest,
  };
};

export const usePostCreateReservation = () => {
  return usePost(API_ROUTES.postBooking);
};

export const useSelectReservation = () => {
  return useSelectData(API_ROUTES.postBooking);
};

export const usePostReceipt = () => {
  return usePost(API_ROUTES.postSendReceipt);
};

export const useGetEventZoneArea = (options) => {
  const url = convertUrlValueToFullUrl(API_ROUTES.getEventZoneArea);
  return useGetOne(url, {
    ...options,
  });
};
