import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import axios from '../../../services/provider/axios';
import { SETTINGS } from '../../../services/redux/root.actions';
import useError from '../../hooks/useError';

const SettingManager = ({ children }) => {
  const { notifyError } = useError();
  const dispatch = useDispatch();

  const fetchSetting = async () => {
    try {
      const { data } = await axios.get('/shared/setting');
      dispatch({ type: SETTINGS, settings: data });
    } catch (ex) {
      notifyError(ex);
    }
  };

  useEffect(() => {
    fetchSetting();
  }, []);

  return children;
};

export default SettingManager;
