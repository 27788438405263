import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#ffffff',
    padding: theme.spacing(6, 0, 0),
  },
  inner: {},

  image: {
    margin: '0 auto',
    display: 'block',
    maxWidth: 300,
    marginTop: theme.spacing(1),
  },

  txtTitle: {
    fontSize: 22,
    fontWeight: 700,
    lineHeight: '30px',
    marginBottom: theme.spacing(1),
    fontFamily: 'QuickSandBold',
  },

  txtSubTitle: {
    fontSize: 16,
    color: '#92929D',
    lineHeight: '20px',
    marginBottom: theme.spacing(3),
  },

  txtNote: {
    fontSize: 18,
    marginTop: theme.spacing(3),
    lineHeight: '25px',
    width: '80%',

    '& span': {
      fontSize: 'inherit',
      fontFamily: 'QuickSandBold',
    },
  },

  ticketNumberContainer: {
    display: 'flex',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(4),

    textAlign: 'center',
    alignItems: 'flex-end',
    justifyContent: 'center',
    gap: theme.spacing(4),
  },
  txtTicketNumberLabel: {
    fontSize: 16,
    fontFamily: 'QuickSandMedium',
  },

  txtTicketNumber: {
    fontSize: 36,
    color: '#083AA9',
    lineHeight: 1,
    fontFamily: 'QuickSandBold',
  },

  txtAccordionTitle: {
    fontSize: 16,
    color: '#022491',
    fontFamily: 'QuickSandBold',
  },
  accordion: {
    boxShadow: 'none',
    padding: 0,
  },
  accordionSummary: {
    width: '100%',
    padding: 0,
  },
  expandIcon: {
    '& svg': {
      fontSize: 24,
      color: '#000000',
    },
  },
  accordionContent: {
    width: '100%',
    paddingLeft: 0,
    paddingRight: 0,
  },
  accordionExpanded: {
    minHeight: '0 !important',
    marginBottom: '0px !important',
  },
  accordionDetail: {
    padding: 0,
  },
  accordionDetailContainer: {},
  locationDetailContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(8),
  },
  locationDetailInner: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(5),
    position: 'relative',
  },
  locationItem: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'flex-end',

    '& svg': {
      zIndex: 1,
    },
  },
  txtTripDetailTitle: {
    fontSize: 16,
    color: '#000000',
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
    fontFamily: 'QuickSandBold',
  },
  txtLocationTime: {
    color: '#022491',
    fontSize: 10,
    fontFamily: 'QuickSandBold',
  },
  txtLocation: {
    fontSize: 14,
    fontFamily: 'QuickSandBold',
  },

  txtDistanceAndDuration: {
    color: '#022491',
    fontSize: 10,
    fontFamily: 'QuickSandBold',
  },

  locationConnector: {
    width: 1,
    borderLeft: '2px dashed #dddddd',
    position: 'absolute',
    top: 40,
    bottom: 20,
    left: 8,
  },
  divide: {
    width: '95%',
    padding: 0,
    margin: '0 auto',
    marginBottom: theme.spacing(3),
  },

  paymentDetailContainer: {
    marginTop: theme.spacing(2),
  },

  paymentDetailInner: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
  },

  paymentItem: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  txtPaymentFieldName: {
    fontSize: 14,
    fontFamily: 'QuickSandBold',
  },

  txtPaymentFieldValue: {
    fontSize: 14,
    color: '#92929D',
    fontFamily: 'QuickSandMedium',
  },
  txtTotalPrice: {
    fontSize: 20,
    color: '#022491',
    fontFamily: 'QuickSandBold',
  },
  txtPaymentMethod: {
    display: 'flex',
    gap: theme.spacing(2),
  },

  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(5),
    justifyContent: 'center',
  },

  input: {
    width: '95%',
    margin: '0 auto 16px',
    backgroundColor: 'transparent',
    '& .MuiInputBase-root': {
      marginTop: theme.spacing(2),
      backgroundColor: 'transparent',
    },
  },

  saveQrButton: {
    display: 'block',
    margin: '0 auto',

    fontSize: 10,
    padding: theme.spacing(1, 3),
    height: 'auto',
    borderRadius: 6,
  },

  receiptContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: theme.spacing(2),
  },

  receiptButton: {
    fontSize: 10,
    padding: theme.spacing(1, 3),
    height: 'auto',
    borderRadius: 6,
    backgroundColor: '#F4F6F8',
    color: '#3E3E3E',
  },

  map: {
    aspectRatio: 1 / 0.7,
  },
}));
export default useStyles;
