import { RA_CRUD_GET_LIST_SUCCESS } from '../root.actions';

const initialData = {
  resources: {},
};

export const dataReducer = (state = initialData, action) => {
  switch (action.type) {
    case RA_CRUD_GET_LIST_SUCCESS:
      return {
        ...state,
        resources: {
          ...state.resources,
          [action.meta.resource]: action.payload,
        },
      };
    default:
      return state;
  }
};
