import { MAP_ID } from '../constants';

const useGetPlacePhotos = ({ mapId = MAP_ID } = {}) => {
  const isOk = (status) => status === 'OK';

  const loadService = () => {
    const map = document?.getElementById(mapId)?.cloneNode();
    if (!map) return null;
    const service = new window.google.maps.places.PlacesService(map);
    return service;
  };

  const getPlacePhotoList = (placeId, cb) => {
    const service = loadService();
    if (!placeId || !service) return;
    const request = {
      placeId,
      fields: ['photos'],
    };

    service.getDetails(request, (results, status) => {
      if (isOk(status)) {
        const photoList = results?.photos?.map((photo) => photo?.getUrl());
        cb(photoList);
      }
    });
  };

  return {
    getList: getPlacePhotoList,
  };
};

export default useGetPlacePhotos;
