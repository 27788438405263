import PropTypes from 'prop-types';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { fetchAndCache } from '../../../services/cache';
import axios from '../../../services/provider/axios';
import { useAuthUser } from '../../hooks';

const PermissionsContext = createContext({ perms: [], permitted: [] });

const PermissionsProvider = ({ children }) => {
  const [state, setState] = useState({ perms: [], permitted: [] });
  const user = useAuthUser();

  useEffect(() => {
    fetchAndCache({
      localStorageKey: 'resource.permitted',
      handler: () => axios.get('/resource/permitted').then(({ data }) => data),
    }).then((res) => {
      setState({ perms: res.perms, permitted: res.permitted });
    });
  }, [user?.id]);

  return (
    <PermissionsContext.Provider value={state}>
      {children}
    </PermissionsContext.Provider>
  );
};

export default PermissionsProvider;

PermissionsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useNFPerms = () => {
  const { perms } = useContext(PermissionsContext);
  return perms;
};

export const usePermitted = () => {
  const { permitted } = useContext(PermissionsContext);
  return permitted;
};

export const usePermissionsContext = () => useContext(PermissionsContext);
