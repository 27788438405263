import { all } from 'redux-saga/effects';
import { authSaga } from './auth/auth.saga';
import { notificationSaga } from './notification/notification.saga';
import { topupSaga } from './payment/topup.saga';
import { schemaSaga } from './schema/schema.saga';

export function* rootSagas() {
  yield all([
    schemaSaga(),
    topupSaga(),
    authSaga(),
    notificationSaga(),
  ]);
}
