import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import { Button, useLocaleState, useTranslate } from 'react-admin';
import CloseIcon from '@mui/icons-material/Close';

const PopupAnnouncement = ({
  isVisible, subject, content, onClose,
}) => {
  const [open, setOpen] = React.useState(isVisible);
  const translate = useTranslate();
  const [locale] = useLocaleState();

  const handleClose = () => {
    if (onClose instanceof Function) {
      const shouldClose = onClose();
      // prevent the unnecessary close event while we're trying to display multiple announcements from this popup
      if (!shouldClose) {
        return;
      }
    }
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="paper"
    >
      <DialogTitle>{subject?.[locale]}</DialogTitle>
      <DialogContent dividers>
        <DialogContentText
          ref={descriptionElementRef}
          tabIndex={-1}
        >
          {content?.[locale]}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          color="primary"
          startIcon={<CloseIcon />}
          label={translate('ra.action.close')}
        />
      </DialogActions>
    </Dialog>
  );
};

PopupAnnouncement.propTypes = {
  subject: PropTypes.object,
  content: PropTypes.object,
  isVisible: PropTypes.bool,
  onClose: PropTypes.func,
};

PopupAnnouncement.defaultProps = {
  subject: undefined,
  content: undefined,
  isVisible: true,
  onClose: undefined,
};

export default PopupAnnouncement;
