import CloseIcon from '@mui/icons-material/Close';
import { Box, ClickAwayListener, Stack } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import { Button } from 'react-admin';
import { useDispatch, useSelector } from 'react-redux';
import { REQUEST_INBOX } from '../../../../../../../services/redux/notification/notification.action';
import InboxList from './InboxList';
import NotificationGroupItem from './NotificationGroupItem';

const useStyles = makeStyles((theme) => ({
  commentContainer: {
    position: 'fixed',
    right: 0,
    top: 48,
    width: '100%',
    height: '100%',
    '#root:has(.mobile-actions-bar) &': {
      height: 'calc(100% - 50px)',
    },
    zIndex: 999999,
    background: 'rgb(0 0 0 / 46%)',
    '& .MuiAccordionSummary-content': {
      paddingLeft: 0,
      marginRight: 'auto',
    },
    '& .content-wrapper': {
      '& > div ': {
        boxShadow: 'none',
        '& > div': {
          padding: 0,
        },
      },
    },
  },
  wrapComment: {
    width: '100%',
    height: 'calc(100% - 48px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  contentComment: {
    maxWidth: 800,
    minHeight: 200,
    width: '100%',
    background: '#fff',
    maxHeight: 'calc(100% - 110px)',
    height: '100%',
    borderRadius: 20,
    position: 'relative',
    padding: 6,
    [theme.breakpoints.down(600)]: {
      minWidth: 'unset',
      width: 'calc(100vw)',
      marginLeft: 'auto',
      marginRight: 'auto',
      maxHeight: '100%',
      borderRadius: '0px',
    },
  },
  btnCloseComment: {
    position: 'absolute',
    right: -4,
    top: -40,
    zIndex: 9,
    width: 30,
    height: 30,
    padding: '0 !important',
    borderRadius: '50% !important',
    background: '#fff',
    [theme.breakpoints.down(600)]: {
      top: 0,
      right: 0,
      position: 'initial',
    },
    '&:hover': {
      background: '#fff',
    },
  },
}));

const INBOX = 1;

const NotificationPopup = ({ onClose, open }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [type, setType] = useState(INBOX);
  const firstOpen = useRef(false);

  useEffect(() => {
    if (!firstOpen.current && open) {
      firstOpen.current = true;
      dispatch({ type: REQUEST_INBOX, page: 1 });
    }
  }, [open]);

  const handleChangeType = (value) => () => {
    if (value !== type) {
      setType(value);
    }
  };

  const inboxData = useSelector(({ notification }) => notification.inbox);
  const firstInbox = useMemo(() => inboxData.data[0], [inboxData.data]);

  return (
    <ClickAwayListener onClickAway={onClose}>
      <Box className={clsx(classes.commentContainer, classes.commentShow)}>
        <Box
          className={classes.wrapComment}
          borderTop={{ xs: '1px solid #eee', sm: 'none' }}
        >
          <Box className={classes.contentComment}>
            <Stack
              direction="row"
              height="100%"
              flexDirection={{ xs: 'column', sm: 'row' }}
            >
              <Box
                width={{ xs: '100%', sm: '30%' }}
                bgcolor="#F3F3F3"
                borderRadius="20px"
                overflow="auto"
                display={{ xs: 'flex', sm: 'block' }}
              >
                <NotificationGroupItem
                  data={firstInbox}
                  active={type === INBOX}
                  onClick={handleChangeType(INBOX)}
                  countInvisible={inboxData.countInvisible}
                  flexGrow={{ xs: 1, sm: '0' }}
                />
                <Box bgcolor="white">
                  <Button className={classes.btnCloseComment} onClick={onClose}>
                    <CloseIcon />
                  </Button>
                </Box>
              </Box>
              <Box
                width={{ xs: '100%', sm: '70%' }}
                height="100%"
                overflow="auto"
                paddingLeft="5px"
                borderTop={{ xs: '1px solid #eee', sm: 'none' }}
              >
                {type === INBOX && <InboxList />}
              </Box>
            </Stack>
          </Box>
        </Box>
      </Box>
    </ClickAwayListener>
  );
};

export default memo(NotificationPopup);
