import AddLocationAltRoundedIcon from '@mui/icons-material/AddLocationAltRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import AdjustRoundedIcon from '@mui/icons-material/AdjustRounded';
import FmdGoodRoundedIcon from '@mui/icons-material/FmdGoodRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';

import { InfoOutlined } from '@mui/icons-material';
import { Box, Collapse, IconButton, Typography } from '@mui/material';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import React, { Fragment, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useSelectEvent } from '../api/features/common.feature';
import { useBookingCommonStyles } from '../common/style';
import { BookingMap, DefaultButton } from '../components';
import { EVENTS, JOB_TYPE, MAX_OF_DROPOFF, SUB_JOB_TYPE } from '../constants';
import { publish } from '../events';
import useMultipleDropoff from '../hooks/useMultipleDropoff';
import { selectPickUp } from '../services/redux/reducers/event.reducer';
import {
  getSelectedJobType,
  getSelectedSubJobType,
} from '../services/redux/reducers/job-type.reducer';

const withMap = (WrappedComponent) => (props) => {
  // eslint-disable-next-line react/prop-types
  const bookingCommonClasses = useBookingCommonStyles();
  const pickup = useSelector(selectPickUp);
  const { data: eventInfo } = useSelectEvent();
  const {
    dropOffList,
    add,
    remove,
    init: initDropoffList,
  } = useMultipleDropoff();

  const selectedJobType = useSelector(getSelectedJobType);
  const { id: jobTypeId, type: jobType } = selectedJobType || {};

  const selectedSubJobType = useSelector(getSelectedSubJobType);
  const { id: subJobTypeId, type: typeOfSubJobType } = selectedSubJobType || {};

  const isShowLocation =
    pickup || dropOffList.every((dropoff) => dropoff?.address);

  const isShowWelcome = dropOffList.some((dropoff) => !dropoff?.address);
  const isDisabledAdd =
    !dropOffList?.[dropOffList.length - 1]?.address ||
    !!dropOffList?.some((dropoff) => !!dropoff?.stopId) ||
    [SUB_JOB_TYPE.FIXED_PRICE].includes(typeOfSubJobType);
  const willHideAddBtn = [SUB_JOB_TYPE.FIXED_PRICE].includes(typeOfSubJobType);

  const handleLocationClick = (fieldName, index) => {
    if (!fieldName) return;
    if (fieldName === EVENTS.PICKUP_LOCATION_CLICK) {
      publish(EVENTS.PICKUP_LOCATION_CLICK);
      return;
    }

    if (fieldName === EVENTS.SELECT_AREA) {
      publish(EVENTS.SELECT_AREA, index);
      return;
    }

    // handle Dropoff Click
    publish(EVENTS.DROPOFF_LOCATION_CLICK, { index, typeOfSubJobType });
  };

  const handleAddLocationClick = (isAdd, index) => {
    if (isAdd) {
      add();
      publish(EVENTS.INCREASE_DROPOFF_LOCATION_CLICK, { index: index + 1 });
      return;
    }
    remove(index);
    publish(EVENTS.DECREASE_DROPOFF_LOCATION, {
      jobTypeId,
      subJobTypeId,
      typeOfSubJobType,
    });
    publish(EVENTS.DROPOFF_LOCATION);
  };

  const renderSelectAreaBtn = (index) => {
    if (typeOfSubJobType !== SUB_JOB_TYPE.POINT2POINT) return <></>;

    const { selectedAreaName } = dropOffList[index] || {};
    return (
      <Box
        className={clsx(
          bookingCommonClasses.selectAreaBtn,
          selectedAreaName && bookingCommonClasses.selectedSelectAreaBtn,
        )}
        onClick={() => {
          handleLocationClick(EVENTS.SELECT_AREA, index);
        }}
      >
        <InfoOutlined />
        <Typography>{selectedAreaName || 'Select Area'}</Typography>
      </Box>
    );
  };

  const willHideDropOffList = () => {
    if (isEmpty(dropOffList) || isEmpty(typeOfSubJobType)) return false;
    return (
      jobType === JOB_TYPE.PREMIUM &&
      ![SUB_JOB_TYPE.POINT2POINT, SUB_JOB_TYPE.FIXED_PRICE].includes(
        typeOfSubJobType,
      )
    );
  };

  const renderDropOff = () => {
    if (willHideDropOffList()) return null;
    return dropOffList.map((dropoff, index) => {
      const isAlwaysDropoffActive = index === 0;
      const isLastItem = dropOffList.length - 1 === index;
      const isLimitDropOff = MAX_OF_DROPOFF !== index + 1;

      return (
        // eslint-disable-next-line
        <Fragment key={dropoff.id}>
          <Box className={bookingCommonClasses.locationConnector} />
          <Box className={bookingCommonClasses.commonLocationItem}>
            {isAlwaysDropoffActive ? (
              <FmdGoodRoundedIcon />
            ) : (
              <AddLocationAltRoundedIcon />
            )}

            <Box className={bookingCommonClasses.commonLocationInfoContainer}>
              <Typography className={bookingCommonClasses.txtLocationLabel}>
                Dropoff Point {index > 0 ? index + 1 : ''}
                {!willHideAddBtn && (
                  <IconButton
                    className={clsx(
                      bookingCommonClasses.btnAddLocation,
                      (!isLastItem || !isLimitDropOff) &&
                        bookingCommonClasses.btnRemoveLocation,
                      isDisabledAdd &&
                        bookingCommonClasses.btnAddLocationDisabled,
                    )}
                    onClick={() =>
                      handleAddLocationClick(
                        isLastItem && isLimitDropOff,
                        index,
                      )
                    }
                  >
                    {isLastItem && isLimitDropOff ? (
                      <AddRoundedIcon />
                    ) : (
                      <RemoveRoundedIcon />
                    )}
                  </IconButton>
                )}
              </Typography>
              <Box className={bookingCommonClasses.addressLocationWrapper}>
                <DefaultButton
                  className={bookingCommonClasses.txtLocationValue}
                  variant="text"
                  name={dropOffList?.[index]?.addressName || ''}
                  onClick={() =>
                    handleLocationClick(EVENTS.DROPOFF_LOCATION_CLICK, index)
                  }
                />
                {(dropoff?.stopId === dropoff?.id || dropoff?.showSelectArea) &&
                  renderSelectAreaBtn(index)}
              </Box>
            </Box>
          </Box>
        </Fragment>
      );
    });
  };

  useEffect(() => {
    initDropoffList();
  }, [jobType, typeOfSubJobType]);

  return (
    <>
      <Box
        className={clsx(
          bookingCommonClasses.commonLocationPopup,
          !isShowLocation && bookingCommonClasses.hideLocation,
        )}
      >
        <Collapse in={Boolean(isShowLocation)}>
          <Box className={bookingCommonClasses.commonLocationPopupInner}>
            <Box className={bookingCommonClasses.commonLocationItem}>
              <AdjustRoundedIcon />

              <Box className={bookingCommonClasses.commonLocationInfoContainer}>
                <Typography className={bookingCommonClasses.txtLocationLabel}>
                  Pickup Point
                </Typography>
                <DefaultButton
                  className={bookingCommonClasses.txtLocationValue}
                  sx={{
                    width: '100%',
                  }}
                  variant="text"
                  name={pickup?.addressName || 'Choose your pickup'}
                  onClick={() =>
                    handleLocationClick(EVENTS.PICKUP_LOCATION_CLICK)
                  }
                />
              </Box>
            </Box>
            {renderDropOff()}
          </Box>
        </Collapse>
      </Box>

      <Collapse in={![SUB_JOB_TYPE.HOURLY].includes(typeOfSubJobType)}>
        <Box className={bookingCommonClasses.mapContainer}>
          <BookingMap />
          {isShowWelcome && (
            <Box className={bookingCommonClasses.welcomeContainer}>
              <Typography className={bookingCommonClasses.txtWelcomeTitle}>
                Ola, Welcome to {eventInfo?.name}
              </Typography>
              <Typography className={bookingCommonClasses.txtWelcomeDesc}>
                Where are you going?
              </Typography>
            </Box>
          )}
        </Box>
      </Collapse>
      <WrappedComponent {...props} />
    </>
  );
};

export default withMap;
