import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import QueryBuilderRoundedIcon from '@mui/icons-material/QueryBuilderRounded';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { Box, Button } from '@mui/material';
import clsx from 'clsx';
import { isEmpty, isNil } from 'lodash';
import PropTypes from 'prop-types';
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useFormContext } from 'react-hook-form';
import { parsePoint } from '../../../../../../utils';
import GoogleMapSearchBoxInput from '../../../../../ra/inputs/google-map-search-box/googleMapSearchBox.input';
import { getSafeValue } from '../../../helpers';
import { DefaultButton } from '../../buttons';
import useStyles from './index.style';
import BookingBottomSheet from '../../bottom-sheet';
import BookingList from '../../list';
import { BookingLocationItem } from '../../items';

const LocationTimeInput = forwardRef(
  (
    {
      timeLabel: defaultTimeLabel,
      dateLabel: defaultDateLabel,
      placeholder,
      onLocationChange,
      onTimeButtonClick,
      onSelectionItemClick,
      fieldTimeName,
      fieldDateName,
      fieldLocationName,
      data,
      disableItemSetValue,
    },
    ref,
  ) => {
    const classes = useStyles();
    const containerRef = useRef();

    const { getValues, setValue } = useFormContext();
    const timeLabel = getSafeValue(
      getValues(),
      fieldTimeName,
      defaultTimeLabel,
    );
    const dateLabel = getSafeValue(
      getValues(),
      fieldDateName,
      defaultDateLabel,
    );

    const isSelect = isNil(data);

    const [selectionOpen, setSelectionOpen] = useState(false);

    const onSelectionClose = () => {
      setSelectionOpen(false);
    };

    const onSelectionClick = () => {
      setSelectionOpen(true);
    };

    const isInitTime =
      timeLabel === defaultTimeLabel && dateLabel === defaultDateLabel;

    const finalTimeLabel = () => {
      if (isInitTime) return timeLabel;
      return `${dateLabel} ${timeLabel}`;
    };

    const onChange = (location) => {
      if (isEmpty(location)) return;
      try {
        const address = `${location.address
          ?.map((item) => item.name)
          ?.join(', ')}`;
        const result = {
          address,
          addressName: location.name,
          coordinate: parsePoint(location.lat, location.lng),
          postalCode: location.postalCode,
          placeId: location.placeId,
          areaName: location.neighborhood,
        };
        onLocationChange?.(result);
        if (fieldLocationName) setValue(fieldLocationName, result);
      } catch (error) {
        console.log('ERRORRR', error);
      }
    };

    const handleSelectionItemClick = (item) => {
      if (disableItemSetValue) setValue(fieldLocationName, item);
      onSelectionClose?.();
      onSelectionItemClick?.(item);
    };

    const handleClearTextInput = () => {
      const input = containerRef.current.querySelector('input');
      input.value = '';
    };

    useImperativeHandle(
      ref,
      () => ({
        clearTextInput: handleClearTextInput,
      }),
      [],
    );

    return (
      <>
        <Box className={classes.input}>
          <Box className={classes.inner}>
            <Box
              className={clsx(
                classes.locationInputContainer,
                !fieldDateName && classes.inputContainerFullWidth,
              )}
              ref={containerRef}
            >
              <SearchRoundedIcon />
              {isSelect ? (
                <GoogleMapSearchBoxInput
                  onChange={onChange}
                  placeholder={placeholder}
                  onlyLoadInput
                />
              ) : (
                <DefaultButton
                  className={classes.selectionLabel}
                  name={placeholder}
                  variant="text"
                  onClick={onSelectionClick}
                />
              )}
            </Box>

            {fieldDateName && (
              <Box className={classes.timeInputContainer}>
                <Button
                  onClick={onTimeButtonClick}
                  className={clsx(
                    classes.timeButton,
                    !isInitTime && classes.fullDate,
                  )}
                  {...(isInitTime && {
                    startIcon: <QueryBuilderRoundedIcon />,
                    endIcon: <KeyboardArrowDownRoundedIcon />,
                  })}
                >
                  {finalTimeLabel()}
                </Button>
              </Box>
            )}
          </Box>
        </Box>

        <BookingBottomSheet
          open={selectionOpen}
          onClose={onSelectionClose}
          drawerProps={{
            SlideProps: {
              appear: isSelect,
            },
          }}
        >
          <BookingList
            data={data}
            component={BookingLocationItem}
            onItemClick={handleSelectionItemClick}
          />
        </BookingBottomSheet>
      </>
    );
  },
);

LocationTimeInput.propTypes = {
  timeLabel: PropTypes.string,
  dateLabel: PropTypes.string,
  placeholder: PropTypes.string,
  fieldLocationName: PropTypes.string,
  fieldTimeName: PropTypes.string,
  fieldDateName: PropTypes.string,
  onLocationChange: PropTypes.func,
  onTimeButtonClick: PropTypes.func,
  onSelectionItemClick: PropTypes.func,
  onLocationButtonClick: PropTypes.func,
  data: PropTypes.array,
  disableSetValue: PropTypes.bool,
  disableItemSetValue: PropTypes.bool,
};

LocationTimeInput.defaultProps = {
  timeLabel: 'Now',
  dateLabel: 'Today',
  placeholder: 'Where To?',
  fieldTimeName: null,
  fieldDateName: null,
  fieldLocationName: null,
  onLocationChange: undefined,
  onTimeButtonClick: undefined,
  onSelectionItemClick: undefined,
  onLocationButtonClick: undefined,
  data: null,
  disableSetValue: false,
  disableItemSetValue: false,
};

export default LocationTimeInput;
