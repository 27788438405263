import {
  isPossiblePhoneNumber,
  isValidPhoneNumber
} from 'libphonenumber-js';
import { isEmpty, isString } from 'lodash';
import { genUUID } from '../helpers';

export const convertNumberToCurrency = (value) => {
  if (!value) return 0;
  return Number(value).toFixed(2);
};

export const convertUrlValueToFullUrl = (url = '', values = {}) => {
  if (isEmpty(values)) return url;
  if (!isString(url)) return url;
  let fullUrl = '';

  Object.keys(values).forEach((key) => {
    if (Object.hasOwnProperty.call(values, key)) {
      const value = values[key];
      // eslint-disable-next-line
      fullUrl = url.replace(RegExp('\\{' + key + '\\}', 'gi'), value);
    }
  });
  return fullUrl;
};

export const convertDurationToMinute = (duration = 0) => {
  const toMinute = Number(duration) * 60;
  return toMinute.toFixed(1);
};

export const convertBufferToBase64 = (src) => {
  if (!src) return null;
  const base64 = Buffer.from(src, 'binary').toString('base64');
  const prefix = 'data:image/png;base64,';
  return prefix + base64;
};

export function parseCoordinate(point) {
  if (!point || typeof point !== 'string') {
    return [null, null];
  }

  const [lat, lng] = point
    .replace(/[^\d .-]/g, '')
    .trim()
    .split(/\s+/);
  return [Number.parseFloat(lat), Number.parseFloat(lng)];
}

export const sortToAlphabet = (data = [], key) => {
  if (isEmpty(data) || !key) return [];
  return data.sort((a, b) => {
    const aKey = a?.[key];
    const bKey = b?.[key];
    if (aKey < bKey) return -1;
    if (aKey > bKey) return 1;
    return 0;
  });
};

export const generateArrayToIndex = (
  length,
  defaultValue,
  shouldGenerateId,
) => {
  return [...Array(length).keys()].map((i) => {
    const id = genUUID(`item-${i}-${new Date().getTime()}`, 'event');
    return {
      ...(shouldGenerateId && { id }),
      ...(defaultValue || i),
    };
  });
};

/**
 * @param {*} phone: +65XXXXYYYY
 * @returns phone valid
 */
export const validatePhone = ({ phone, countryCode = 'SG' }) => {
  if (!phone) return false;
  const isPossible = isPossiblePhoneNumber(phone, countryCode);
  const isValid = isValidPhoneNumber(phone, countryCode);
  const isLengthValid = phone?.length === 11;
  return isLengthValid && isPossible && isValid;
};
