import axios from 'axios';
import instance from '.';

const API_ACTION = {
  GET: (url, config) => instance.get(url, config),
  POST: (url, data, config) => instance.post(url, data, config),

  TP_GET: (url, config) => axios.get(url, config),
};

export default API_ACTION;
