import { lazy } from 'react';
import { lazyWrapper } from '../../../lazy';

export const ArrayField = lazyWrapper(lazy(() => import('./array.field')));
export const BooleanField = lazyWrapper(lazy(() => import('./boolean.field')));
export const ChipField = lazyWrapper(lazy(() => import('./chip.field')));
export const ColorField = lazyWrapper(lazy(() => import('./color.field')));
export const CurrencyField = lazyWrapper(
  lazy(() => import('./currency.field')),
);
export const DateField = lazyWrapper(lazy(() => import('./date.field')));
export const DocStatusField = lazyWrapper(
  lazy(() => import('./docStatus.field')),
);
export const EmailField = lazyWrapper(lazy(() => import('./email.field')));
export const IdField = lazyWrapper(lazy(() => import('./id.field')));
export const ImageField = lazyWrapper(lazy(() => import('./image.field')));
export const LinkField = lazyWrapper(lazy(() => import('./link.field')));
export const MaskField = lazyWrapper(lazy(() => import('./mask.field')));
export const MenuField = lazyWrapper(lazy(() => import('./menu.field')));
export const NumberField = lazyWrapper(lazy(() => import('./number.field')));
export const OwnerField = lazyWrapper(lazy(() => import('./owner.field')));
export const QrField = lazyWrapper(lazy(() => import('./qr.field')));
export const RefField = lazyWrapper(lazy(() => import('./ref.field')));
export const ReferenceField = lazyWrapper(
  lazy(() => import('./reference.field')),
);
export const TextField = lazyWrapper(lazy(() => import('./text.field')));
export const TipTapField = lazyWrapper(lazy(() => import('./tiptap.field')));
export const UrlField = lazyWrapper(lazy(() => import('./url.field')));
export const LocationField = lazyWrapper(lazy(() => import('./location.field')));
export const FileField = lazyWrapper(lazy(() => import('./file.field')));
