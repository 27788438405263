import { Box, darken, Hidden } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import Announcement from '../../../../../../../base/components/layout/announcement';
import SearchBar from '../../../../../../../base/components/search-bar';
import useNavigation from '../../../../../../../base/hooks/useNavigation';
import SidebarToggleHandler from '../../../../../../@coremat/CmtLayouts/Horizontal/SidebarToggleHandler';
import { PageBreadcrumbs } from '../../../../PageComponents';
import LanguageSwitcher from '../../LanguageSwitcher';
import Logo from '../../Logo';
import UserDropDown from '../../UserDropDown';
import HeaderNotifications from '../HeaderNotifications';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    minHeight: 10,
    padding: 0,
    height: '100%',
    '& .Cmt-appIcon': {
      color: theme.palette.text.secondary,
      '&:hover, &:focus': {
        color: darken(theme.palette.text.secondary, 0.2),
      },
      [theme.breakpoints.down('sm')]: {
        padding: 7,
      },
    },
    '& .MuiIconButton-root': {
      padding: 8,
      '& .MuiSvgIcon-root': {
        fontSize: '20px',
      },
    },
  },
  lavaGroupRoot: {
    display: 'flex',
    background: '#ebedf0',
    borderRadius: theme.spacing(4),
    padding: '0px 2px',
    border: 'solid 1px #e2e3e5',
    height: 30,
  },
  langRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    '& .MuiIconButton-root': {
      padding: 8,
      '& .MuiIconButton-label': {
        width: 20,
        height: 20,
      },
    },
  },
  searchIcon: {
    [theme.breakpoints.down('sm')]: {
      padding: 9,
    },
  },
  transactionWrapper: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  divider: {
    height: 24,
    backgroundColor: 'rgb(220, 224, 227)',
    margin: '0px 4px',
  },
}));

const HeaderTop = () => {
  const classes = useStyles();
  const { breadcrumbs } = useNavigation();
  const globalSearchEnabled =
    useSelector(
      ({ setting }) => setting.commonSettings?.backoffice_global_search,
    ) === '1';

  return (
    <Toolbar className={classes.root}>
      <SidebarToggleHandler edge="start" color="inherit" aria-label="menu" />
      <PageBreadcrumbs items={breadcrumbs} />
      <Hidden smDown>
        <Logo
          mr={{
            sm: 4,
            lg: 6,
            xl: 8,
          }}
        />
      </Hidden>
      <Box
        display="flex"
        alignItems="center"
        color="warning.main"
        overflow="hidden"
        flex={1}
      >
        <Announcement />
      </Box>
      <Box display="flex" alignItems="center" ml="auto">
        <SearchBar />
        <HeaderNotifications />
        <Box className={clsx(classes.lavaGroupRoot)}>
          <Box className={clsx(classes.langRoot, 'Cmt-i18n-switch')}>
            <LanguageSwitcher />
          </Box>
          <UserDropDown />
        </Box>
      </Box>
    </Toolbar>
  );
};

export default memo(HeaderTop);
