import { Box, Typography } from '@mui/material';
import React from 'react';
// import { useController } from 'react-hook-form';
import { useWithLabelStyles } from '../common/style';

const withLabel = (WrappedComponent) => (props) => {
  // eslint-disable-next-line react/prop-types
  const { label } = props;
  const labelClasses = useWithLabelStyles();
  // const { field } = useController({ name: fieldName, shouldUnregister: true });
  return (
    <Box className={labelClasses.root}>
      <Typography className={labelClasses.txtLabel}>{label}</Typography>

      <WrappedComponent
        {...props}
        inputBaseOnButtonClassName={labelClasses.inputBaseOnButtonClassName}
      />
      <input className={labelClasses.inputPlaceholder} />
    </Box>
  );
};

export default withLabel;
