/* eslint-disable import/no-cycle */
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  ArrayInput,
  SimpleFormIterator,
  useLocaleState,
  useTranslate,
} from 'react-admin';
import { useSelector } from 'react-redux';
import { useSchema } from '../../../../../hooks';
import { pascalCase } from '../../../../../utils';
import { guessProperty } from '../../../../guesser/nf-guesser';
import Labeled from '../../../labeled';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      marginTop: 0,
    },
    form: {
      marginTop: theme.spacing(2),
      '& .RaSimpleFormIterator-line': {
        paddingTop: theme.spacing(2),
        '& .RaSimpleFormIterator-action': {
          '& > button': {
            '& .MuiButton-startIcon': {
              marginRight: theme.spacing(1),
            },
          },
        },
        '&:not(:last-child)': {
          position: 'relative',
          paddingBottom: theme.spacing(7),
          '& .RaSimpleFormIterator-action': {
            position: 'absolute',
            bottom: theme.spacing(2),
            right: 0,
          },
        },
        '& .RaSimpleFormIterator-indexContainer': {
          marginRight: theme.spacing(2),
          '& .RaSimpleFormIterator-index': {
            display: 'none',
          },
          '& .button-reorder': {
            display: 'flex',
            flexDirection: 'column',
            '& button': {
              padding: theme.spacing(2),
            },
          },
        },
      },
      '& .RaSimpleFormIterator-form': {
        '& > *': {
          width: '100%',
        },
      },
    },
    noIndex: {
      '& .RaSimpleFormIterator-line': {
        '& .RaSimpleFormIterator-indexContainer': {
          display: 'none',
        },
      },
    },
  }),
  {
    name: 'ReferenceIteratorForm',
  },
);

const ReferenceIteratorForm = ({
  reference,
  resource,
  source,
  maxItems,
  indexable,
}) => {
  const classes = useStyles();
  const resources = useSelector((state) => state.resource.resources || []);
  const [locale] = useLocaleState();
  const translate = useTranslate();
  const { api, ref, getWritableFields } = useSchema();
  const [guessedInfo, setGuessedInfo] = useState({});

  const noIterator = maxItems === 1;

  useEffect(() => {
    if (!api || isEmpty(reference) || isEmpty(resource)) return;

    const schemas = ref.get(`#/components/schemas/${pascalCase(reference)}`);
    const { properties, required: requiredFields } = schemas;
    const writableFields = getWritableFields({
      properties,
    });

    const components = writableFields?.map((i) =>
      guessProperty({
        source: i,
        properties,
        requiredFields,
        apiRef: ref,
        api,
        view: 'create',
        resource,
        resources,
        locale,
        translate,
      }),
    );

    setGuessedInfo({
      components,
      writableFields,
    });
  }, [api, reference, resource, source, resources, locale, translate]);

  if (noIterator) {
    return (
      <Labeled source={source}>
        <Box>
          {React.Children.map(guessedInfo?.components, (child) =>
            React.cloneElement(child, {
              ...child.props,
              source: `${source}.${child.props.source}`,
            }),
          )}
        </Box>
      </Labeled>
    );
  }

  return (
    <Labeled source={source}>
      <ArrayInput source={source} className={classes.root} label={false}>
        <SimpleFormIterator
          disableRemove={false}
          className={clsx(classes.form, !indexable && classes.noIndex)}
        >
          {guessedInfo?.components}
        </SimpleFormIterator>
      </ArrayInput>
    </Labeled>
  );
};

ReferenceIteratorForm.propTypes = {
  reference: PropTypes.string.isRequired,
  resource: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
  maxItems: PropTypes.number,
  indexable: PropTypes.bool,
};

ReferenceIteratorForm.defaultProps = {
  maxItems: undefined,
  indexable: false,
};

export default ReferenceIteratorForm;
