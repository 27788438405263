import AdjustRoundedIcon from '@mui/icons-material/AdjustRounded';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import FmdGoodRoundedIcon from '@mui/icons-material/FmdGoodRounded';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  TextField,
  Typography,
} from '@mui/material';
import { LoadScriptNext } from '@react-google-maps/api';
import clsx from 'clsx';
import moment from 'moment';
import React, { Fragment, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  useGetQrCodeDownload as downloadQrCode,
  useGetQrCode,
  useGetReservationBySessionId,
  usePostReceipt,
} from '../../api/features/common.feature';
import { BookingDialog, BookingMap, DefaultButton } from '../../components';
import Image from '../../components/image';
import { SUB_JOB_TYPE } from '../../constants';
import {
  convertDurationToMinute,
  convertNumberToCurrency,
  parseCoordinate,
} from '../../utils';
import BookingSplashScreen from '../splash-screen';
import useStyles from './index.style';

const center = {
  lat: 1.3521,
  lng: 103.8198,
};

const BookingCashSuccessScreen = () => {
  const classes = useStyles();
  const inputRef = useRef();
  const [receiptOpen, setRecipeOpen] = useState(false);
  const mapKey = useSelector(
    ({ setting }) => setting?.commonSettings?.map_api_key,
  );
  const {
    data: reservation,
    isSuccess,
    isError,
  } = useGetReservationBySessionId();
  const {
    mutate: receiptMutate,
    isLoading: receiptLoading,
    isSuccess: isReceiptSuccess,
    reset,
  } = usePostReceipt();

  const { data: qrCode, isSuccess: isQrCodeSuccess } = useGetQrCode(
    reservation?.code,
    {
      configQueryOptions: {
        enabled: isSuccess,
      },
    },
  );

  const {
    code,
    jobDistance,
    jobDuration,
    estimatePickupTime,
    estimateDropoffTime,
    pickUpAddressName,
    stops,
    card,
    eventReservation,
    subJobType,
    totalFare: rawTotalFare = 0,
    rideFare: rawRideFare = 0,
    fee,
    childBoosterSeatFee,
    midNightSurchargeFee,
    gstFee = 0,
  } = reservation || {};

  const {
    fixedPeakFee = 0,
    flatBookingFee = 0,
    isInPeakTime = false,
  } = fee || {};

  const { type: typeOfSubJobType } = subJobType || {};

  const pickup = stops?.filter((stop) => stop.type === 'PICK_UP')?.[0];
  const dropoff =
    stops
      ?.filter((stop) => stop.type === 'DROP_OFF')
      .sort((a, b) => a.index - b.index) || [];

  const pickupTime = estimatePickupTime
    ? moment(estimatePickupTime).format('HH:mm')
    : '';
  const dropoffTime = estimateDropoffTime
    ? moment(estimateDropoffTime).format('HH:mm')
    : '';

  const paid = convertNumberToCurrency(rawTotalFare);
  const rideFare = convertNumberToCurrency(rawRideFare);
  const childBoosterSeatCurrency = convertNumberToCurrency(childBoosterSeatFee);
  const midNightSurchargeCurrency =
    convertNumberToCurrency(midNightSurchargeFee);
  const gstFeeCurrency = convertNumberToCurrency(gstFee);

  const bookingFee = convertNumberToCurrency(flatBookingFee);
  const distance = Number(jobDistance).toFixed(2);
  const duration = convertDurationToMinute(jobDuration);
  const surchargePeakFee = convertNumberToCurrency(fixedPeakFee);

  const [pickupLat, pickupLng] = (pickup?.coordinate &&
    parseCoordinate(pickup.coordinate)) || [null, null];

  const handleReceiptClose = () => {
    setRecipeOpen(false);
    reset();
  };

  const handleReceiptOpen = () => {
    setRecipeOpen(true);
  };

  const handleSaveQRClick = () => {
    downloadQrCode(reservation.code);
  };

  const handleSendClick = () => {
    if (isReceiptSuccess) {
      handleReceiptClose();
      return;
    }
    receiptMutate({
      params: { id: eventReservation?.id },
      data: {
        email: inputRef.current.value,
      },
    });
  };

  const renderPaymentImage = () => {
    if (!card || !card?.brand) return <></>;

    if (card?.brand === 'visa')
      return <Image src="images/icons/visa-icon.svg" alt="payment" />;

    if (card?.brand === 'mastercard')
      return <Image src="images/icons/mastercard-icon.svg" alt="payment" />;

    return card.brand;
  };

  return (
    <>
      <BookingSplashScreen isStart={isSuccess || isError} />
      <Box className={classes.root}>
        <Box className={classes.inner}>
          <Typography className={classes.txtTitle}>
            Thank you for choosing Lylo!
          </Typography>
          <Box className={classes.receiptContainer}>
            <Typography className={classes.txtSubTitle}>
              Have an enjoyable ride!
            </Typography>
            <DefaultButton
              name="I need a receipt"
              className={classes.receiptButton}
              onClick={handleReceiptOpen}
            />
          </Box>
          <Divider light />

          <Typography className={classes.txtNote}>
            Please present the QR Code to our friendly
            <Typography component="span"> Lylo Ushers </Typography>
            and <Typography component="span"> Driver</Typography> when you board
            the vehicle.
          </Typography>
          {isQrCodeSuccess && (
            <Image
              buffer
              src={qrCode}
              alt="Qr Code"
              className={classes.image}
            />
          )}
          <DefaultButton
            name="Save QR to Gallery"
            className={classes.saveQrButton}
            onClick={handleSaveQRClick}
          />
          <Box className={classes.ticketNumberContainer}>
            <Typography className={classes.txtTicketNumberLabel}>
              Your Identification Code:
            </Typography>
            <Typography className={classes.txtTicketNumber}>{code}</Typography>
          </Box>
          <Divider className={classes.divide} light />
          <Accordion
            classes={{
              expanded: classes.accordionExpanded,
            }}
            className={classes.accordion}
            TransitionProps={{ unmountOnExit: true }}
          >
            <AccordionSummary
              classes={{
                content: classes.accordionContent,
                expanded: classes.accordionExpanded,
                expandIconWrapper: classes.expandIcon,
              }}
              className={classes.accordionSummary}
              expandIcon={<ExpandMoreRoundedIcon />}
            >
              <Typography className={classes.txtAccordionTitle}>
                Trip Details
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetail}>
              <Box className={classes.accordionDetailContainer}>
                <LoadScriptNext
                  libraries={['places']}
                  googleMapsApiKey={mapKey}
                >
                  <BookingMap
                    dropoff={dropoff}
                    pickup={{
                      lat: pickupLat || center.lat,
                      lng: pickupLng || center.lng,
                    }}
                    className={classes.map}
                  />
                </LoadScriptNext>

                <Typography className={classes.txtTripDetailTitle}>
                  {subJobType?.name?.en || ''}
                </Typography>

                <Box className={classes.locationDetailContainer}>
                  <Box className={classes.locationDetailInner}>
                    <Box className={classes.locationItem}>
                      <AdjustRoundedIcon />
                      <Box className={classes.locationInfoContainer}>
                        <Typography className={classes.txtLocationTime}>
                          {pickupTime}
                        </Typography>
                        <Typography className={classes.txtLocation}>
                          {pickUpAddressName}
                        </Typography>
                      </Box>
                    </Box>

                    {dropoff.map((d, index) => (
                      // eslint-disable-next-line
                      <Fragment key={d?.id}>
                        <Box className={classes.locationConnector} />
                        <Box className={classes.locationItem}>
                          <FmdGoodRoundedIcon />
                          <Box className={classes.locationInfoContainer}>
                            <Typography className={classes.txtLocationTime}>
                              {index === dropoff.length - 1 ? dropoffTime : ' '}
                            </Typography>
                            <Typography className={classes.txtLocation}>
                              {d.addressName}
                            </Typography>
                          </Box>
                        </Box>
                      </Fragment>
                    ))}
                  </Box>
                  <Typography className={classes.txtDistanceAndDuration}>
                    {distance}km . {duration} minutes
                  </Typography>
                </Box>

                <Divider className={classes.divide} light />
              </Box>
            </AccordionDetails>
          </Accordion>

          <Accordion
            className={classes.accordion}
            TransitionProps={{ unmountOnExit: true }}
          >
            <AccordionSummary
              classes={{
                content: classes.accordionContent,
                expanded: classes.accordionExpanded,
                expandIconWrapper: classes.expandIcon,
              }}
              className={classes.accordionSummary}
              expandIcon={<ExpandMoreRoundedIcon />}
            >
              <Typography className={classes.txtAccordionTitle}>
                Payment Details
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetail}>
              <Box className={classes.paymentDetailContainer}>
                <Box className={classes.paymentDetailInner}>
                  <Box className={classes.paymentItem}>
                    <Typography className={classes.txtPaymentFieldName}>
                      Ride Fare
                    </Typography>
                    <Typography className={classes.txtPaymentValue}>
                      ${rideFare}
                    </Typography>
                  </Box>

                  {![
                    SUB_JOB_TYPE.POINT2POINT,
                    SUB_JOB_TYPE.HOURLY,
                    SUB_JOB_TYPE.FIXED_PRICE,
                  ].includes(typeOfSubJobType) && (
                    <>
                      <Box className={classes.paymentItem}>
                        <Typography className={classes.txtPaymentFieldName}>
                          Flat Booking Fee
                        </Typography>
                        <Typography className={classes.txtPaymentValue}>
                          ${bookingFee}
                        </Typography>
                      </Box>

                      {isInPeakTime && (
                        <Box className={classes.paymentItem}>
                          <Typography className={classes.txtPaymentFieldName}>
                            Peak Hour Surcharge
                          </Typography>
                          <Typography className={classes.txtPaymentValue}>
                            ${surchargePeakFee}
                          </Typography>
                        </Box>
                      )}
                    </>
                  )}
                  <Box className={classes.paymentItem}>
                    <Typography className={classes.txtPaymentFieldName}>
                      Midnight Surcharge
                    </Typography>
                    <Typography className={classes.txtPaymentValue}>
                      ${midNightSurchargeCurrency}
                    </Typography>
                  </Box>
                  <Box className={classes.paymentItem}>
                    <Typography className={classes.txtPaymentFieldName}>
                      Booster Seat
                    </Typography>
                    <Typography className={classes.txtPaymentValue}>
                      ${childBoosterSeatCurrency}
                    </Typography>
                  </Box>
                  {Number(gstFeeCurrency) > 0 && (
                    <Box className={classes.paymentItem}>
                      <Typography className={classes.txtPaymentFieldName}>
                        GST
                      </Typography>
                      <Typography className={classes.txtPaymentValue}>
                        ${gstFeeCurrency}
                      </Typography>
                    </Box>
                  )}
                  <Divider className={classes.divide} light />
                  <Box className={classes.paymentItem}>
                    <Typography className={classes.txtPaymentFieldName}>
                      Total
                    </Typography>
                    <Typography
                      className={clsx(
                        classes.txtPaymentValue,
                        classes.txtTotalPrice,
                      )}
                    >
                      ${paid}
                    </Typography>
                  </Box>
                  {card && (
                    <Box className={classes.paymentItem}>
                      <Typography className={classes.txtPaymentFieldName}>
                        Payment Method
                      </Typography>

                      <Typography
                        component="span"
                        className={classes.txtPaymentMethod}
                      >
                        {renderPaymentImage()}
                        {` `}
                        **** {card?.last4}
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Box>

      <BookingDialog
        open={receiptOpen}
        title={isReceiptSuccess ? 'Receipt sent!' : 'Do you need a receipt?'}
        description={
          isReceiptSuccess ? 'Please check your mailbox for the receipt.' : ''
        }
        imageSrc={
          isReceiptSuccess
            ? 'images/booking/receipt-sent.png'
            : 'images/booking/receipt.png'
        }
        onCloseClick={handleReceiptClose}
      >
        <Box className={classes.dialogContent}>
          {!isReceiptSuccess && (
            <TextField
              className={classes.input}
              label="Email"
              variant="standard"
              inputRef={inputRef}
            />
          )}

          <DefaultButton
            onClick={handleSendClick}
            className={classes.submitButton}
            name={isReceiptSuccess ? 'Ok' : 'Submit'}
            loading={receiptLoading}
          />
        </Box>
      </BookingDialog>
    </>
  );
};

export default BookingCashSuccessScreen;
