import { Box, Collapse, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import configs from '../../../../../../services/configs';
import useStyles from './index.style';

const EventQrCode = () => {
  const [sessionId, setSessionId] = useState(null);
  const [jobCode, setJobCode] = useState(null);

  const sessionTokenURL = new URLSearchParams(
    `${window.location.origin}/#/p/event/trip?session_id=${sessionId}`,
  ).toString();

  const jobUrl = new URLSearchParams(
    `${window.location.origin}/#/p/event/trip?code=${jobCode}`,
  ).toString();

  const classes = useStyles();
  const channel = useMemo(() => new BroadcastChannel('eventQr'), []);

  const startCountDown = (onDone) => {
    let time = 30;
    const timer = setInterval(() => {
      time -= 1;
      if (time === 0) {
        onDone?.();
      }
    }, 1000);

    return timer;
  };

  const onMessage = (event) => {
    if (event.origin !== window.origin) return;

    const { jobCode: job, sessionId: session } = event.data;

    if (session) setSessionId(session);
    else setJobCode(job);
  };

  const onListenCaller = (event) => {
    if (event.origin !== window.origin) return;
    if (event.data.signal === 'exits') {
      channel.postMessage({ signal: 'exits' });
      return;
    }
    if (event.data.signal !== 'ping') return;
    channel.postMessage({ signal: 'pong' });
  };

  const onExit = () => {
    channel.postMessage({ signal: 'exit' });
  };

  useEffect(() => {
    channel.addEventListener('message', onListenCaller);
    return () => channel.removeEventListener('message', onListenCaller);
  }, []);

  useEffect(() => {
    channel.addEventListener('message', onMessage);
    return () => channel.removeEventListener('message', onMessage);
  }, [onMessage]);

  useEffect(() => {
    window.addEventListener('beforeunload', onExit);
    return () => window.removeEventListener('beforeunload', onExit);
  }, [onExit]);

  useEffect(() => {
    const counter = startCountDown(() => {
      channel.postMessage({ signal: 'timeout' });
    });
    return () => clearInterval(counter);
  }, [sessionId, jobCode]);

  useEffect(() => {
    channel.postMessage({ signal: 'pong' });
  }, []);

  return (
    <Box className={classes.root}>
      <Box className={classes.inner}>
        <Box className={classes.thanksContainer}>
          <img src="images/booking/logo.png" alt="thank you" />
          <Typography className={classes.txtTitle}>
            Thank you for choosing Lylo!
          </Typography>
          <Typography className={classes.txtSubtitle}>
            Please scan the QR Code for your trip details
          </Typography>
        </Box>
        <Collapse
          in={!isEmpty(sessionId) || !isEmpty(jobCode)}
          unmountOnExit
          mountOnEnter
          orientation="horizontal"
        >
          <img
            src={`${configs.apiUrl}/usr/nf/qr?qrString=${
              jobCode ? jobUrl : sessionTokenURL
            }&qrDark=fff&qrLight=022491`}
            alt="QR CODE"
            width={600}
            height={600}
            className={classes.qrCodeImage}
          />
        </Collapse>
      </Box>
    </Box>
  );
};

export default EventQrCode;
