const ErrorMessages = {
  ONLINE_PAYMENT_UNAVAILABLE: {
    title: 'Uh Oh!',
    message:
      'Online booking is temporarily unavailable due to overwhelming demand for Lylo Rides. For booking of rides, please proceed to our booths',
    description: 'Thank You!',
  },

  NOT_ELIGIBLE: {
    title: 'Uh Oh!',
    message:
      'Our online booking system is unavailable at the moment. For booking of rides, please proceed to booths',
    description: 'Thank You!',
  },
};

export default ErrorMessages;
