import { ShortcutProvider } from '@shopify/react-shortcuts';
import React from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';
import Layout from '../base/components/layout';
import { MainLayoutContextProvider } from '../base/context/main-layout';
import { SocketContextProvider } from '../base/context/socket';
import AppWrapper from './@jumbo/components/AppWrapper';
import AppContextProvider from './@jumbo/components/contextProvider/AppContextProvider';

const App = (props) => (
  <AppContextProvider>
    <MainLayoutContextProvider>
      <SocketContextProvider>
        <ShortcutProvider>
          <AppWrapper>
            <Layout {...props} />
          </AppWrapper>
        </ShortcutProvider>
      </SocketContextProvider>
    </MainLayoutContextProvider>
  </AppContextProvider>
);

export default App;
