import { darken } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  appRoot: (props) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100vh',
    overflowX: 'hidden',
    boxSizing: 'border-box',
    position: 'relative',
    transition: 'all 0.3s ease',
    '& .Cmt-header': {
      '& .Cmt-toggle-menu': {
        display: 'none',
        marginRight: 4,
        [theme.breakpoints.down(props.drawerBreakPoint)]: {
          padding: theme.spacing(0, 2),
          display: 'block !important',
        },
      },
      '& .MuiBreadcrumbs-root': {
        marginRight: 10,
        display: 'none',
        [theme.breakpoints.down(props.drawerBreakPoint)]: {
          display: 'flex',
        },
      },
      '& .Cmt-header-nav': {
        [theme.breakpoints.down(props.drawerBreakPoint)]: {
          display: 'none',
        },
      },
    },
  }),
  appMain: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100vh',
    boxSizing: 'border-box',
    transition: 'all 0.3s ease',
    overflow: 'hidden',
    '& .Cmt-customizerBtn': {
      top: 220,
    },
  },
  appInnerRoot: {
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
  },
  appMainContainer: ({ backgroundColor, backgroundImage }) => ({
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    boxSizing: 'border-box',
    position: 'relative',
    backgroundColor: backgroundColor || 'unset',
    backgroundImage: backgroundImage ? `url(${backgroundImage})` : 'unset',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  }),
  contentLayout: {
    alignSelf: 'center',
    display: 'flex',
    flexDirection: 'row',
    maxWidth: 1290,
    width: '100%',
    height: '100vh',
    paddingTop: 54,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 50,
    },
  },
  '@global': {
    '.layout-type-framed': {
      '@media screen and (min-width: 1366px)': {
        padding: 20,
        backgroundColor: darken(theme.palette.primary.main, 0.6),
        overflow: 'hidden',
        '& > #root': {
          height: 'calc(100vh - 40px)',
          borderRadius: 12,
          backgroundColor: theme.palette.background.default,
          overflow: 'hidden',
        },
        '& $appRoot': {
          overflowY: 'auto',
          height: '100%',
        },
      },
      [theme.breakpoints.up('xl')]: {
        padding: 50,
        '& > #root': {
          height: 'calc(100vh - 100px)',
        },
      },
    },
    '.layout-type-boxed': {
      '@media screen and (min-width: 1366px)': {
        backgroundColor: darken(theme.palette.primary.main, 0.6),
        overflow: 'hidden',
        '& > #root': {
          width: 1300,
          marginRight: 'auto',
          marginLeft: 'auto',
          backgroundColor: theme.palette.background.default,
          overflow: 'hidden',
        },
        '& $appRoot': {
          overflowY: 'auto',
        },
      },
      [theme.breakpoints.up('xl')]: {
        '& > #root': {
          width: 1740,
        },
      },
    },
  },
}));

export default useStyles;
