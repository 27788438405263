/* eslint-disable no-underscore-dangle */
import ViewListIcon from '@mui/icons-material/ViewList';
import { Icon } from '@mui/material';
import { LexoRank } from 'lexorank';
import lodash, {
  camelCase,
  get,
  isEmpty,
  isString,
  orderBy,
  startCase,
  toLower,
  uniqBy,
  upperCase,
} from 'lodash';
import moment from 'moment';
import React, { lazy } from 'react';
import { useStore } from 'react-admin';
import { canI } from '../services/provider/rbacProvider';
import {
  TRELLO_COLORS,
  isTrelloColor,
} from './components/guesser/flow/workflow/color/constants';
import { FILE_TYPE } from './constants';

export const defaultPageSize = 30;

export const getPageIndexFromIndex = (index, pageSize = defaultPageSize) =>
  Math.ceil((index + 1) / pageSize);

export const getPageItemIndexFromIndex = (index, pageSize = defaultPageSize) =>
  index - (getPageIndexFromIndex(index, pageSize) - 1) * pageSize;

export const processPages = (loadedPages, pageSize, totalCards, handler) => {
  // const maxPage = Math.ceil(totalCards / pageSize);
  const keys = Object.keys(loadedPages || {});
  const maxPage = [...keys].sort((a, b) => Number(b) - Number(a))?.[0] || 1;
  const valueArrs = Object.values(loadedPages || {});
  const originItems = uniqBy(
    valueArrs.reduce(
      (prev, items) => [...prev, ...(items?.cards || items)],
      [],
    ),
    'id',
  );
  const handledItems =
    handler?.({
      originItems,
    }) || originItems;
  const results = {};
  keys.forEach((key) => {
    results[key] = handledItems.splice(0, pageSize);
  });

  if (loadedPages) {
    if (handledItems.length) {
      results[maxPage + 1] = handledItems;
    }
  }

  return results;
};

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list ?? []);
  try {
    const [removed] = result?.splice(startIndex, 1) || [];
    if (removed) {
      result.splice(endIndex, 0, removed);
    }
  } catch {} // eslint-disable-line no-empty

  return result;
};

export const generateLexorankIndex = (beforeCard, afterCard) => {
  let index = LexoRank.middle();
  let minLexoRank;
  let maxLexoRank;

  if (beforeCard && afterCard) {
    minLexoRank = LexoRank.parse(`${beforeCard.index}`);
    maxLexoRank = LexoRank.parse(`${afterCard.index}`);
  } else if (beforeCard) {
    minLexoRank = LexoRank.parse(`${beforeCard.index}`);
  } else if (afterCard) {
    maxLexoRank = LexoRank.parse(`${afterCard.index}`);
  }

  if (minLexoRank || maxLexoRank) {
    if (!minLexoRank) {
      index = maxLexoRank.genPrev();
    } else if (!maxLexoRank) {
      index = minLexoRank.genNext();
    } else {
      index = minLexoRank.between(maxLexoRank);
    }
  }

  return index.toString();
};

export const reorderV2 = ({
  totalCards,
  loadedPages,
  beforeCard,
  afterCard,
  card,
  pageSize = defaultPageSize,
}) =>
  processPages(loadedPages, pageSize, totalCards, ({ originItems }) => {
    const modifiedItem = originItems?.find((x) => x.id === card.id);
    if (modifiedItem) {
      const nextIndex = generateLexorankIndex(beforeCard, afterCard);
      modifiedItem.index = nextIndex;
    }
    return orderBy(originItems, ['index'], ['asc']);
  });

export const addV2 = ({
  totalCards,
  loadedPages,
  beforeCard,
  afterCard,
  nextStateId,
  card,
  pageSize = defaultPageSize,
}) =>
  processPages(loadedPages, pageSize, totalCards, ({ originItems }) => {
    const nextIndex = generateLexorankIndex(beforeCard, afterCard);
    originItems.push({
      ...card,
      stateId: nextStateId,
      index: nextIndex,
    });
    return orderBy(originItems, ['index'], ['asc']);
  });

export const removeV2 = ({
  totalCards,
  loadedPages,
  card,
  pageSize = defaultPageSize,
}) =>
  processPages(loadedPages, pageSize, totalCards, ({ originItems }) =>
    orderBy(
      originItems?.filter((x) => x.id !== card.id),
      ['index'],
      ['asc'],
    ),
  );

export const updateV2 = ({
  totalCards,
  loadedPages,
  card,
  pageSize = defaultPageSize,
}) =>
  processPages(loadedPages, pageSize, totalCards, ({ originItems }) => {
    return originItems?.map((x) =>
      x.id === card.id
        ? {
            ...x,
            ...card,
          }
        : x,
    );
  });

export const isValidUuid = (value) =>
  /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(
    value,
  );

export const isValidJsonArray = (value) => {
  if (!value?.length) {
    return false;
  }

  try {
    if (value.some((v) => !Object.keys(v)?.length)) {
      return false;
    }
  } catch {} // eslint-disable-line no-empty

  return true;
};

export const getIcon = (icon) => {
  if (!icon) return <ViewListIcon />; // eslint-disable-line
  if (typeof icon !== 'string') {
    return <Icon>{React.createElement(icon)}</Icon>;
  }
  return <Icon>{icon}</Icon>;
};

export const pascalCase = (str) => startCase(camelCase(str)).replace(/ /g, '');
export const parseTokenName = (resourceName) => {
  // parse resource name to token name used in API schema
  // ex: game-type => GameType
  // ex: toc-rate => TOCRate
  let tokenName = pascalCase(resourceName);
  if (resourceName.startsWith('toc')) {
    tokenName = `${resourceName?.split('-')[0].toUpperCase()}${pascalCase(
      resourceName.split('-')[1],
    )}`;
  }
  if (resourceName.startsWith('vip')) {
    tokenName = upperCase(tokenName);
  }
  return tokenName;
};

export function parseCoordinate(point) {
  if (!point || typeof point !== 'string') {
    return [null, null];
  }

  const [lat, lng] = point
    .replace(/[^\d .-]/g, '')
    .trim()
    .split(/\s+/);
  return [Number.parseFloat(lat), Number.parseFloat(lng)];
}

export function parsePoint(latitude, longitude) {
  return latitude && longitude ? `POINT(${latitude} ${longitude})` : '';
}

export const isArrayEqual = function (x, y) {
  return lodash(x).xorWith(y, lodash.isEqual).isEmpty();
};

export const getRefField = (sourceSchema) => {
  const fieldRef =
    sourceSchema?.$ref ||
    sourceSchema?.allOf?.filter((i) => i?.$ref)?.[0]?.$ref ||
    sourceSchema?.properties?.reference?.$ref;
  return fieldRef;
};

export const getTranslatableSource = (sourceSchema, source, locale) => {
  const translatableInput = sourceSchema?.properties?.translatable?.default;
  if (translatableInput) {
    return `${source}.${locale}`;
  }
  return source;
};

export const getSourceRefTitle = (properties, source, refName) => {
  let optionText = 'id';

  if (refName === 'Status') {
    return 'name';
  }

  if (source === 'merchantId') {
    return 'name';
  }

  if (!properties) {
    return optionText;
  }

  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(properties)) {
    if (value.displayName || value.properties?.optionText?.default) {
      if (source) {
        if (key === source) {
          return key;
        }
      } else {
        return key;
      }
    }
  }

  if (properties._metaData?.properties?.optionText?.default) {
    optionText = properties._metaData.properties?.optionText?.default;
  }

  return optionText;
};

export const parseSourceRef = (source, properties, apiRef, locale) => {
  const name = source?.split('.').pop();
  const sourceSchema = properties?.[name];
  const fieldRef =
    sourceSchema?.$ref ||
    sourceSchema?.allOf?.filter((i) => i?.$ref)?.[0]?.$ref ||
    sourceSchema?.properties?.reference?.$ref;

  const sourceRef = apiRef.get(fieldRef);

  let translatableInput = false;
  let sourceName = source;
  let sourceRefTitle = getSourceRefTitle(sourceRef.properties, null);
  let sourceRefTitleSchema = sourceRef.properties?.[sourceRefTitle];

  const sourceSchemaFromSourceRef = sourceRef?.properties?.[sourceRefTitle];
  const fieldRefFromSourceRef =
    sourceSchemaFromSourceRef?.$ref ||
    sourceSchemaFromSourceRef?.allOf?.filter((i) => i?.$ref)?.[0]?.$ref ||
    sourceSchemaFromSourceRef?.properties?.reference?.$ref;
  if (fieldRefFromSourceRef) {
    const sourceRefFromSourceRef = apiRef.get(fieldRefFromSourceRef);
    const sourceRefTitleFromSourceRef = getSourceRefTitle(
      sourceRefFromSourceRef.properties,
      null,
    );
    sourceRefTitle = `${sourceRefTitle}.${sourceRefTitleFromSourceRef}`;
  }

  // TRANSLATABLE_INPUT
  if (sourceRefTitleSchema?.properties?.translatable?.default) {
    sourceRefTitle = `${sourceRefTitle}.${locale}`;
    translatableInput = true;
  }

  const refName = fieldRef?.split('/schemas/').pop();

  if (!sourceSchema?.properties?.reference?.$ref) {
    sourceName = `${source}.id`;
  }

  if (sourceRefTitle === 'id') {
    sourceRefTitleSchema = {
      type: 'string',
      format: 'id',
    };
  }

  return {
    source: sourceRefTitle,
    SourceReferenceField: {
      label: source,
      source: sourceName,
      reference: refName,
    },
    translatableInput,
    sourceRefTitleSchema,
  };
};

export const getResourceFieldMessageKey = (properties, resource, source) =>
  properties?.[source]?.delegateMessageKey ||
  `resources.${
    properties?.[source]?.delegateMessageResource ||
    properties?._metaData?.delegateMessageResource ||
    resource
  }.fields.${source}`;

const evaluateCondition = (record, conditions) => {
  if (!conditions) return true;

  return Object.keys(conditions)?.every((conditionKey) => {
    if (conditionKey === '$or') {
      return (
        !conditions[conditionKey]?.length ||
        conditions[conditionKey]?.some((childCondition) =>
          evaluateCondition(record, childCondition),
        )
      );
    }

    const conditionValue = conditions[conditionKey];
    const conditionExpect = get(record, conditionKey);

    if (conditionValue && typeof conditionValue === 'object') {
      const { op, value } = conditionValue;
      if (op && value) {
        switch (op) {
          case '$starts':
            return conditionExpect?.startsWith(value);
          case '$cont':
            return conditionExpect?.includes(value);
          case '$ends':
            return conditionExpect?.endsWith(value);
          case '$excl':
            return !conditionExpect?.includes(value);
          case '$eq':
            return value === conditionExpect;
          case '$ne':
            return value !== conditionExpect;
          case '$in':
            if (Array.isArray(value)) {
              return value?.includes(conditionExpect);
            }
            break;
          case '$notin':
            if (Array.isArray(value)) {
              return !value?.includes(conditionExpect);
            }
            break;
          case '$notnull':
            return (
              conditionValue !== null && typeof conditionValue !== 'undefined'
            );
          default:
            break;
        }
      }
    }
    return conditionValue === conditionExpect;
  });
};

export const isAccessibleCtaButton = ({
  item,
  resource,
  record,
  permissions,
}) => {
  let permFine = true;
  if (item.perms?.length) {
    permFine = item.perms.every((p) =>
      canI(
        p.action,
        p.resource === '[self]' ? resource : p.resource,
        permissions,
      ),
    );
  }

  return permFine && evaluateCondition(record, item.conditions);
};

export const getStandardFormat = (format) => {
  switch (format) {
    case 'duedate':
    case 'datetime':
      return 'datetime-local';
    case 'select':
      return 'string';
    default:
      return format;
  }
};

export const getTypeFromFormat = (format) => {
  switch (format) {
    case 'boolean':
    case 'number':
    case 'blob':
      return format;
    default:
      return 'string';
  }
};

export const getValueBasedOnFormat = (format, value) => {
  switch (format) {
    case 'boolean':
      return value === 'true';
    case 'number':
      return Number(value);
    default:
      return value;
  }
};
const padZero = (str, len = 2) => {
  const zeros = new Array(len).join('0');
  return (zeros + str).slice(-len);
};

export const invertColor = (hex, bw) => {
  try {
    let newHex = hex;
    if (isTrelloColor(newHex)) {
      newHex = TRELLO_COLORS[newHex];
    }

    if (newHex?.indexOf('#') === 0) {
      newHex = newHex.slice(1);
    }
    // convert 3-digit newHex to 6-digits.
    if (newHex.length === 3) {
      newHex =
        newHex[0] + newHex[0] + newHex[1] + newHex[1] + newHex[2] + newHex[2];
    }
    if (newHex.length !== 6) {
      throw new Error('Invalid newHEX color.');
    }
    let r = parseInt(newHex.slice(0, 2), 16);
    let g = parseInt(newHex.slice(2, 4), 16);
    let b = parseInt(newHex.slice(4, 6), 16);
    if (bw) {
      // https://stackoverflow.com/a/3943023/112731
      return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? '#3f474f' : '#ffffff';
    }
    // invert color components
    if (!isTrelloColor(newHex)) {
      r = (255 - r).toString(16);
      g = (255 - g).toString(16);
      b = (255 - b).toString(16);
    }
    // pad each with zeros and return

    return `#${padZero(r)}${padZero(g)}${padZero(b)}`;
  } catch {
    return TRELLO_COLORS.default;
  }
};

export const getAverageRGB = (imgEl) => {
  const defaultRGB = {
    r: 255,
    g: 255,
    b: 255,
  }; // for non-supporting envs
  try {
    const blockSize = 5; // only visit every 5 pixels
    if (isEmpty(imgEl)) return defaultRGB;
    const canvas = document.createElement('canvas');
    const context = canvas.getContext && canvas.getContext('2d');
    let i = -4;
    const rgb = {
      r: 0,
      g: 0,
      b: 0,
    };
    let count = 0;

    if (!context) {
      return defaultRGB;
    }

    const height =
      canvas.height ||
      imgEl.naturalHeight ||
      imgEl.offsetHeight ||
      imgEl.height;
    const width =
      canvas.width || imgEl.naturalWidth || imgEl.offsetWidth || imgEl.width;
    let data;

    try {
      context.drawImage(imgEl, 0, 0);
      data = context.getImageData(0, 0, width, height);
    } catch {
      return defaultRGB;
    }

    const { length } = data.data;

    /* eslint no-cond-assign: "error" */
    while ((i += blockSize * 4) < length) {
      count += 1;
      rgb.r += data.data[i];
      rgb.g += data.data[i + 1];
      rgb.b += data.data[i + 2];
    }
    rgb.r = Math.floor(rgb.r / count);
    rgb.g = Math.floor(rgb.g / count);
    rgb.b = Math.floor(rgb.b / count);
    return rgb;
  } catch {
    return defaultRGB;
  }
};

export const getUploadFileType = (file) => {
  let result = '';

  const TYPE_LIST = {
    IMAGE: 'image',
    VIDEO: 'video',
    APPLICATION: 'application',
  };

  const extensionList = [
    {
      fileType: 'image/*',
      type: TYPE_LIST.IMAGE,
    },
    {
      fileType: 'video/*',
      type: TYPE_LIST.VIDEO,
    },
    {
      fileType: 'application/*',
      type: TYPE_LIST.APPLICATION,
    },
  ];

  if (!file || !file?.size)
    return {
      type: result,
      typeList: TYPE_LIST,
    };

  extensionList.forEach((extension) => {
    if (file.type.match(extension.fileType)) result = extension.type;
  });

  return {
    type: result,
    typeList: TYPE_LIST,
  };
};

export const getFileType = ({ name, fileType }) => {
  if (isEmpty(name) || !isString(name)) return null;
  if (!isEmpty(fileType)) return fileType;

  let type;
  let extension = 'application';
  const extensionList = {
    pdf: [FILE_TYPE.PDF],
    docx: [FILE_TYPE.DOCX],
    excel: [FILE_TYPE.EXCEL],
    ppt: [FILE_TYPE.POWER_POINT],
    rar: [FILE_TYPE.RAR],
    zip: [FILE_TYPE.ZIP],
    json: [FILE_TYPE.JSON],
    image: ['jpg', 'png', 'jpeg', 'gif', 'webp', 'bmp'],
    audio: ['mp3', 'mp4'],
    application: ['html'],
  };

  const typeOfName = name?.split('.').pop();
  if (isEmpty(typeOfName)) return type;
  for (let i = 0, len = Object.keys(extensionList).length; i < len; i += 1) {
    const key = Object.keys(extensionList)[i];
    const values = extensionList[key];
    if (values.includes(typeOfName)) {
      extension = key;
      break;
    }
  }

  return {
    type: typeOfName,
    fileType: extension,
  };
};

export const getDisplayFilter = (resource, mapFilter) => {
  let filter;
  const [mapFilters] = useStore(`${resource}.heatMapFilter`, {});
  const [listFilters] = useStore(`${resource}.listParams`, {});
  if (mapFilter) {
    filter = mapFilters;
  } else {
    filter = listFilters;
  }
  const displayFilter = filter?.displayedFilters || {};
  return displayFilter;
};

export const formatDateValueInObject = (input) => {
  if (typeof input !== 'object') {
    return input;
  }

  const output = JSON.parse(JSON.stringify(input));
  try {
    // Format time string
    Object.keys(output || {}).forEach((i) => {
      const isDateString =
        !!output[i] && moment(output[i], moment.ISO_8601).isValid();
      if (isDateString) {
        if (toLower(output[i]).endsWith('.000')) {
          output[i] = `${output[i]}Z`;
        }
        output[i] = moment(output[i]).format('YYYY-MM-DD HH:mm:ss');
      }
    });
  } catch {} // eslint-disable-line no-empty

  return output;
};

export const formatRecordId = (input) => {
  if (typeof input !== 'string') {
    return input;
  }

  return `#${input.substring(input.length - 6)}`;
};

export const formatDateValueString = (input) => {
  if (typeof input !== 'string') {
    return input;
  }

  // deep copy
  let output = `${input}`;

  try {
    // Format time string
    const isDateString = !!output && moment(output, moment.ISO_8601).isValid();
    if (isDateString) {
      if (toLower(output).endsWith('.000')) {
        output = `${output}Z`;
      }
      output = moment(output).format('YYYY-MM-DD HH:mm:ss');
    }
  } catch {} // eslint-disable-line no-empty

  return output;
};

/* eslint-disable */
export const lazyDelayImport = (module, ms = 0) => {
  return lazy(() =>
    Promise.all([
      module(),
      new Promise((resolve) => setTimeout(resolve, ms)),
    ]).then(([moduleExports]) => moduleExports),
  );
};

/* eslint-enable */

export const standardizeHtml = (text) => {
  try {
    const result = text?.replaceAll('\n', '<br>') ?? text;
    return (
      new DOMParser().parseFromString(result, 'text/html')?.body?.innerHTML ||
      ''
    );
  } catch {
    return text;
  }
};

export function arrayMoveMutable(array, fromIndex, toIndex) {
  const startIndex = fromIndex < 0 ? array.length + fromIndex : fromIndex;

  if (startIndex >= 0 && startIndex < array.length) {
    const endIndex = toIndex < 0 ? array.length + toIndex : toIndex;

    const [item] = array.splice(fromIndex, 1);
    array.splice(endIndex, 0, item);
  }
}

export function arrayMoveImmutable(array, fromIndex, toIndex) {
  const newArray = [...array];
  arrayMoveMutable(newArray, fromIndex, toIndex);
  return newArray;
}

export const dedupeMerge = (keyFn, mergeFn, array) =>
  Array.from(
    array
      .reduce((acc, o) => {
        const key = keyFn(o);
        if (!acc.has(key)) return acc.set(key, o);
        return acc.set(key, mergeFn(acc.get(key), o));
      }, new Map())
      .values(),
  );

export const dedupeMergePerms = (perms = []) =>
  dedupeMerge(
    (x) => x.resource.id,
    (o1, o2) => ({
      ...o1,
      create: o1.create || o2.create,
      list: o1.list || o2.list,
      update: o1.update || o2.update,
      delete: o1.delete || o2.delete,
      submit: o1.submit || o2.submit,
      cancel: o1.cancel || o2.cancel,
      approve: o1.approve || o2.approve,
      comment: o1.comment || o2.comment,
      export: o1.export || o2.export,
      import: o1.import || o2.import,
      onlyMe: o1.onlyMe || o2.onlyMe,
    }),
    perms?.filter((p) => !!p.resource.id),
  );

function getFilenameFromResponse(response) {
  if (!response?.headers && typeof response.headers !== 'object') return null;

  let filename;
  const disposition = response?.headers['content-disposition'];

  if (disposition && disposition.indexOf('attachment') !== -1) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);
    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, '');
    }
  }

  return filename;
}

export function saveFileFromResponse(response, filename, ext) {
  const fName =
    getFilenameFromResponse(response) ||
    `${filename || `export-${Date.now()}`}.${ext || 'txt'}`;
  const temp = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');

  link.href = temp;
  link.setAttribute('download', fName);

  document.body.appendChild(link);

  link.click();
  link.parentNode.removeChild(link);
}
