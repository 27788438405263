import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const RemoveFilterButton = ({
  filterName, onClick,
}) => {
  const handleRemoveFilter = () => {
    onClick(filterName);
  };

  return (
    <IconButton
      aria-label="remove filter"
      onClick={handleRemoveFilter}
      size="large"
    >
      <HighlightOffIcon />
    </IconButton>
  );
};

RemoveFilterButton.propTypes = {
  filterName: PropTypes.string,
  onClick: PropTypes.func,
};

RemoveFilterButton.defaultProps = {
  filterName: '',
  onClick: () => {},
};

export default RemoveFilterButton;
