/* eslint-disable no-shadow */
import { FormHelperText } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useInput, useTranslate } from 'react-admin';
import { TextInput } from '.';
import useCountries from '../../../hooks/useCountries';
import RCPhoneInput from '../../phone-input';

const useStyles = makeStyles((theme) => ({
  phoneInputWrapper: {
    width: '100%',
    marginBottom: '4px',
    '& .react-tel-input .form-control': {
      fontSize: '13px',
      lineHeight: '19.5px',
      fontWeight: 500,
    },
    '& .react-tel-input .flag.sg': {
      backgroundPosition: '-216px -285px',
    },
    '& input': {
      padding: '6px 30px 6px 58px !important',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      borderWidth: 1,
      '&:focus': {
        borderColor: `${theme.palette.primary.main} !important`,
        boxShadow: `0 0 0 1px ${theme.palette.primary.main} !important`,
      },
    },
    '& .special-label': {
      backgroundColor: '#f9fcff',
      borderRadius: '6px',
      top: -10,
      left: '-5px !important',
      color: 'rgba(0, 0, 0, 0.6)',
      transform: 'scale(0.75)',
    },
    '& .invalid-number-message': {
      left: '10px !important',
    },
  },
  fade: {
    '& input': {
      color: 'transparent',
    },
  },
  phoneInput: {
    width: '100% !important',
    backgroundColor: `${theme.components.MuiInputBase.styleOverrides.root.backgroundColor} !important`,
    borderWidth: '0px !important',
  },
  inFilter: {
    marginBottom: '0 !important',
  },
}));

const PhoneInput = (props) => {
  const { record, source, disabled, inFilter, helperText, placeholder } = props;
  const {
    // id,
    field: input,
    // isRequired,
    // fieldState: {
    //   error, isTouched: touched,
    // },
  } = useInput(props);

  const translate = useTranslate();
  const classes = useStyles();
  const countries = useCountries();
  const onlyCountries = countries.map((c) => c.countryCode);
  const defaultCountry = 'sg';
  const [fade, setFade] = useState(false);
  const [value, setValue] = useState(null);
  const [dCode, setDCode] = useState('+65');

  useEffect(
    () => {
      if (onlyCountries?.length && (record[source] ?? input.value) && !value) {
        const value = record[source] ?? input.value;
        const dialCode = countries
          .map(({ dialCode }) => dialCode)
          .find((code) => value?.startsWith(code));
        const purePhone = dialCode
          ? value.substring(dialCode.length, value.length)
          : value;
        setValue(purePhone);
      }
    },
    [onlyCountries?.length, record[source]],
    input.value,
  );

  const handleOnChange = (phone, data) => {
    const { dialCode } = data;
    const purePhone = phone;

    setValue(phone);
    if (purePhone === dialCode) {
      setFade(true);
      setTimeout(() => {
        setValue('');
        setFade(false);
      }, 10);
    }

    // // remove all 0 digits if they have in the beginning of string
    // if (purePhone.length && purePhone.startsWith('0')) {
    //   purePhone = Number(purePhone).toString();
    // }

    // format: XX-XXX...XXX (country code - phone number)
    const formattedPhone = purePhone === '' ? null : `${dialCode}${purePhone}`;

    if (!inFilter) {
      const e = {
        target: {
          name: 'phoneNumber',
          value: formattedPhone,
        },
      };

      input.onChange(e);
    }
  };

  const handleFocusLost = () => {
    const formattedPhone = value === '' ? null : `${dCode}${value}`;
    const e = {
      target: {
        name: 'phoneNumber',
        value: formattedPhone,
      },
    };
    input.onChange(e);
  };

  const handleFocus = (_, data) => {
    const { dialCode } = data;
    setDCode(dialCode);
  };

  return onlyCountries.length > 0 ? (
    <div
      className={clsx(
        classes.phoneInputWrapper,
        inFilter && classes.inFilter,
        fade && classes.fade,
        'phone-input',
      )}
    >
      <RCPhoneInput
        country={defaultCountry}
        countryCodeEditable
        onlyCountries={onlyCountries}
        placeholder={
          placeholder || translate('resources.user.fields.phoneNumber')
        }
        specialLabel={
          placeholder || translate('resources.user.fields.phoneNumber')
        }
        inputClass={classes.phoneInput}
        variant="outlined"
        color="primary"
        id={source}
        value={value}
        onBlur={input.onBlur}
        onChange={handleOnChange}
        disabled={disabled}
        disableCountryCode
        disableCountryGuess
        {...(inFilter && {
          onFocus: handleFocus,
          onBlur: handleFocusLost,
        })}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </div>
  ) : (
    <TextInput
      source={source}
      fullWidth
      inputProps={{
        maxLength: 15,
      }}
      disabled={disabled}
      helperText={helperText}
    />
  );
};

PhoneInput.propTypes = {
  disabled: PropTypes.bool,
  source: PropTypes.string.isRequired,
  record: PropTypes.object,
  inFilter: PropTypes.bool,
  helperText: PropTypes.node,
  placeholder: PropTypes.string,
};

PhoneInput.defaultProps = {
  disabled: false,
  inFilter: false,
  record: {},
  helperText: undefined,
  placeholder: undefined,
};

export default PhoneInput;
