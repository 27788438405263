import { Search } from '@mui/icons-material';
import { Box, CircularProgress, Input, Typography } from '@mui/material';
import isArray from 'lodash/isArray';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useGetEventZoneArea } from '../../api/features/common.feature';
import useDebounce from '../../hooks/useDebounce';
import useStyles from './index.style';

const THREE_MINS = 60000 * 3;

const ZoneAreas = ({ handleOnClickAreaItem }) => {
  const classes = useStyles();
  const { data: eventZoneAreas, isLoading } = useGetEventZoneArea({
    configQueryOptions: {
      staleTime: THREE_MINS,
    },
  });

  const [eventZoneAreaData, setEventZoneAreaData] = useState([]);
  const [searchZoneStr, setSearchZoneStr] = useState('');

  const handleSearchZone = (e) => setSearchZoneStr(e.target.value);

  useEffect(() => {
    setEventZoneAreaData(eventZoneAreas);
  }, [eventZoneAreas]);

  useDebounce(
    () => {
      setEventZoneAreaData(
        eventZoneAreas.filter(({ name }) =>
          name.toLowerCase().includes(searchZoneStr.toLowerCase()),
        ),
      );
    },
    [eventZoneAreas, searchZoneStr],
    500,
  );

  return (
    <>
      <Box className={classes.inputSearchWrapper} noValidate autoComplete="off">
        <Input
          startAdornment={<Search />}
          placeholder="Search"
          inputProps={{ 'aria-label': 'description' }}
          value={searchZoneStr}
          onChange={handleSearchZone}
        />
      </Box>

      {!isArray(eventZoneAreaData) || isLoading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <CircularProgress size={16} />
        </Box>
      ) : (
        <Box className={classes.body}>
          {eventZoneAreaData.map(({ id, name, zone, postalCodes }) => {
            const zoneName = zone?.parent?.name || zone?.name;
            return (
              <Box
                onClick={handleOnClickAreaItem(postalCodes, name)}
                key={id}
                sx={{
                  backgroundColor: '#F5F5F5',
                  marginBottom: 4,
                  padding: 4,
                  borderRadius: 2,
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
                }}
              >
                <Typography
                  sx={{
                    fontFamily: 'QuickSandBold!important',
                    textAlign: 'center',
                    fontSize: 16,
                    color: 'black',
                  }}
                >
                  {name}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: 'QuickSandBold!important',
                    textAlign: 'center',
                    fontSize: 12,
                    color: '#757575',
                  }}
                >
                  {zoneName}
                </Typography>
              </Box>
            );
          })}
        </Box>
      )}
    </>
  );
};

ZoneAreas.propTypes = {
  handleOnClickAreaItem: PropTypes.func.isRequired,
};
ZoneAreas.defaultProps = {};
export default React.memo(ZoneAreas);
