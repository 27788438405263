import { makeStyles } from '@mui/styles';

export const useBookingCommonStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100vw',
    minHeight: '100vh',
    background: '#ffffff',
    margin: '0 auto',

    '& *': {
      fontFamily: 'QuickSandRegular',
    },
  },

  commonHeaderContainer: {},

  commonArticleContainer: {
    position: 'relative',
    padding: theme.spacing(4, 3, 6),
  },

  commonMapContainer: {
    width: '100%',
  },

  commonLocationPopup: {
    backgroundColor: '#ffffff',
    padding: theme.spacing(4, 6),
    boxShadow: '0px 0px 5px #999999',
    marginBottom: theme.spacing(4),
    zIndex: 1,

    borderRadius: 6,
  },

  hideLocation: {
    zIndex: -1,
  },

  commonLocationPopupInner: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    transition: 'all 0.1s ease',
    '& *': {
      transition: 'all 0.1s ease',
    },
  },
  commonLocationItem: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center',
    marginTop: theme.spacing(4),

    '& svg': {
      zIndex: 1,
      backgroundColor: '#ffffff',
    },

    '&:first-child': {
      marginTop: 0,
    },
  },
  commonLocationInfoContainer: {
    width: '100%',
  },

  mapContainer: {
    position: 'relative',
    borderRadius: 6,
    overflow: 'hidden',
  },

  welcomeContainer: {
    position: 'absolute',
    padding: theme.spacing(5, 4),
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'rgba(0,0,0,.8)',
    color: '#ffffff',
  },

  txtWelcomeTitle: {
    fontSize: 20,
    fontFamily: 'QuicksandBold',
    lineHeight: 1.4,
  },

  txtWelcomeDesc: {
    fontSize: 18,
    fontFamily: 'QuicksandMedium',
    lineHeight: 1.3,
    marginTop: theme.spacing(1),
  },

  locationConnector: {
    width: 1,
    borderLeft: '2px dashed #dddddd',
    position: 'absolute',
    top: 35,
    bottom: 35,
    left: 8,
  },

  txtLocationLabel: {
    color: '#022491',
    fontSize: 10,
    fontFamily: 'QuicksandBold',
    width: '100%',
    display: 'flex',
  },
  txtLocationValue: {
    fontSize: 14,
    fontFamily: 'QuicksandBold',
    padding: 0,
    height: 20,
    flex: 1,
    justifyContent: 'flex-start',
    textAlign: 'left',
  },

  btnAddLocation: {
    marginLeft: 'auto',
    padding: 0,
    color: '#022491',
  },

  btnAddLocationDisabled: {
    color: '#dddddd',
    pointerEvents: 'none',
  },

  btnRemoveLocation: {
    color: '#eb0014',
    pointerEvents: 'unset',
  },

  titleRegular: {
    fontSize: 15,
    fontWeight: 400,
    letterSpacing: '-1.5%',
    lineHeight: '15.45px',
  },

  progressing: {
    display: 'block',
    margin: `${theme.spacing(8)} auto`,
  },

  selectAreaBtn: {
    cursor: 'pointer',
    display: 'flex',
    maxWidth: '35vw',
    backgroundColor: 'red',
    padding: '6px 16px',
    borderRadius: '10px',
    alignItems: 'center',

    '& svg': {
      backgroundColor: 'transparent',
      color: 'white',
      paddingRight: '4px',
    },
    '& p': {
      color: 'white',
      fontSize: '10px',
      fontFamily: 'QuickSandBold',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
  },

  selectedSelectAreaBtn: {
    backgroundColor: 'green',
  },

  addressLocationWrapper: {
    display: 'flex',
  },

  areaBottomSheet: {
    maxHeight: '75vh',
    minHeight: '75vh',
  },
}));

export const useWithLabelStyles = makeStyles((theme) => ({
  root: {},
  txtLabel: {
    marginBottom: theme.spacing(2),
  },

  inputPlaceholder: {
    display: 'none',
  },

  inputBaseOnButtonClassName: {
    width: '100%',
    height: 42,
    margin: 0,
    color: '#171725',
    justifyContent: 'flex-start',

    borderRadius: 6,
    border: '1px solid #CBD5E1',
    padding: theme.spacing(0, 2),
    boxShadow: 'none',

    '& .MuiButton-startIcon': {
      marginRight: theme.spacing(2),
    },
  },
}));

export const useWithRadioButtonStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    '& label': {
      position: 'absolute',
      inset: 0,
      opacity: 0,
    },
  },
}));

export const useWithJobTypeButtons = makeStyles((theme) => ({
  root: {
    position: 'relative',
    width: '100%',
  },
  buttonWrapper: {
    display: 'flex',
    marginBottom: theme.spacing(4),
  },
  jobTypeBtnWrapper: {
    width: '50%',
    '&:first-child': {
      marginRight: theme.spacing(2),
    },
    '&:last-child': {
      marginLeft: theme.spacing(2),
    },
  },
  jobTypeBtn: {
    width: '100%',
  },
}));
