export const TRELLO_COLORS = {
  sky: '#00C2E3',
  blue: '#0079bf',
  purple: '#C377DF',
  green: '#61bd4f',
  yellow: '#f2d600',
  orange: '#ff9f1a',
  lime: '#51e898',
  pink: '#ff78cb',
  black: '#344563',
  red: '#b04632',
  default: '#b3bac5',
};
export const isTrelloColor = color => color in TRELLO_COLORS;
