import { alpha } from '@mui/material';
import Box from '@mui/material/Box';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    padding: '12px 24px',
    '& .flag': {
      fontSize: 30,
    },
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.08),
    },
  },
}));

const LanguageItem = ({ language, onClick }) => {
  const { name } = language;
  const classes = useStyles();
  return (
    <Box
      className={clsx(classes.root, 'pointer')}
      onClick={() => onClick(language)}
    >
      {name}
    </Box>
  );
};

LanguageItem.defaultProps = {
  language: {},
  onClick: () => {},
};

LanguageItem.propTypes = {
  language: PropTypes.shape({
    name: PropTypes.string,
    icon: PropTypes.string,
  }),
  onClick: PropTypes.func,
};

export default LanguageItem;
