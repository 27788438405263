import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  item: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',

    marginBottom: theme.spacing(1),
    padding: theme.spacing(2, 0),
    borderRadius: 10,

    '&:last-child': {
      marginBottom: 0,
    },
  },
  locationIconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    backgroundColor: '#F5F5F5',
    width: 36,
    height: 36,
    borderRadius: '50%',
  },

  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    marginLeft: theme.spacing(2),
    alignItems: 'flex-start',
  },

  txtName: {
    fontSize: 15,
    marginBottom: '.5em',
    lineHeight: '15.45px',
    letterSpacing: -1,
    fontFamily: 'QuickSandMedium',
  },

  txtNameOnly: {
    marginBottom: 0,
  },

  txtAddress: {
    fontSize: 10,
    color: '#7C7C7C',
    textAlign: 'left',
    lineHeight: 1.4,
  },
}));

export default useStyles;
