import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  inputSearchWrapper: {
    '& .MuiInput-root': {
      width: '100%',
      borderRadius: '30px',
      backgroundColor: 'white',
      border: '1px solid #CBD5E1',
      padding: '11px 24px',
      marginBottom: '24px',

      '&::before': {
        borderBottom: 'none!important',
      },
      '&::after': {
        borderBottom: 'none!important',
      },
      '& svg': {
        fontSize: '30px',
        marginRight: '8px',
      },
    },
  },
  body: {
    overflow: 'scroll',
    height: '55vh',
  },
}));

export default useStyles;
