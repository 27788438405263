import { stringify } from 'query-string';
import { call, put, takeLatest } from 'redux-saga/effects';
import axios from '../../provider/axios';
import { REQUEST_INBOX, setInbox } from './notification.action';

function* fetchInbox(params) {
  const page = params.page || 1;
  const limit = params.limit || 25;

  try {
    const { data } = yield call(
      axios.get,
      `/inbox?${stringify({ page, limit })}`,
    );

    yield put(
      setInbox({
        data: data.data,
        page: data.page,
        limit,
        total: data.total,
        pageCount: data.pageCount,
      }),
    );
  } catch (err) {
    console.error(err);
  }
}

export function* notificationSaga() {
  yield takeLatest(REQUEST_INBOX, fetchInbox);
}
