import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(10, 4, 0),
  },
  inner: {
    textAlign: 'center',
  },
  image: {
    width: '100%',
    maxWidth: 350,
  },

  txtTicketNumber: {
    marginTop: theme.spacing(4),
    fontSize: 32,
    fontWeight: 600,
    lineHeight: '40px',
    fontFamily: 'QuickSandBold',
    '& span': {
      fontFamily: 'QuickSandBold',
      marginLeft: 12,
      fontSize: 'inherit',
      color: '#002169',
    },
  },
  txtNote: {
    marginTop: theme.spacing(4),
    fontSize: 14,
    fontWeight: 300,
    lineHeight: '20px',
    color: '#92929D',
  },

  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(10),
    gap: theme.spacing(2),
  },

  mapButton: {
    border: '1px solid #002169',
    backgroundColor: '#ffffff',
    color: '#000000',
    gap: theme.spacing(1),
    padding: theme.spacing(0, 2),
  },
}));
export default useStyles;
