import React, { useRef } from "react";
import makeStyles from '@mui/styles/makeStyles';
import clsx from "clsx";
import Box from "@mui/material/Box";

const useStyles = makeStyles((theme) => ({
  appHeader: {
    width: "100%",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    transition: "all 0.3s ease",
    backgroundColor: "#fff",
    color: theme.palette.text.primary,
    boxShadow: "0px 1px 2px rgba(25,39,52,0.05),0px 0px 4px rgba(25,39,52,0.1)",
    position: "fixed",
    zIndex: 999,
  },
  headerContent: {
    maxWidth: 1290,
    width: "100%",
  },
}));

const CmtHeader = React.forwardRef((props, ref) => {
  const { children, className } = props;

  const headerRef = useRef(null);
  const classes = useStyles();

  return (
    <Box
      ref={headerRef}
      className={clsx(classes.appHeader, className, "Cmt-header")}
    >
      <Box className={classes.headerContent}>{children}</Box>
    </Box>
  );
});

export default CmtHeader;

CmtHeader.defaultProps = {
  name: "Header",
};
