/* eslint-disable no-shadow */
import ViewListIcon from '@mui/icons-material/ViewList';
import { Icon } from '@mui/material';
import { kebabCase, orderBy, startCase, uniqBy, without } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { useLoading, useLocaleState, useTranslate } from 'react-admin';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { RbacAction, canI } from '../../services/provider/rbacProvider';
import { useNFPerms } from '../context/permissions';
import { isValidUuid } from '../utils';
import useAuthUser from './useAuthUser';

const pageTypes = {
  0: 'home',
  1: 'list',
  2: 'edit',
  3: 'show',
  4: 'sub',
};

const useNavigation = () => {
  const translate = useTranslate();
  const navigate = useNavigate();
  const resources = useSelector((state) => state.resource.resources || []);
  const permissions = useNFPerms();
  const { pathname } = useLocation();
  const user = useAuthUser();
  const [locale] = useLocaleState();
  const loading = useLoading();

  const defaultRoute = user?.role?.defaultRoute;
  const myRoleRealm = user?.superman ? 'LLS' : user?.role?.realm;
  useEffect(() => {
    if (pathname === '/') {
      if (defaultRoute && !canI(RbacAction.READ, 'dashboard', permissions)) {
        navigate(defaultRoute);
      }
    }
  }, [navigate, pathname, defaultRoute]);

  const paths = useMemo(() => without(pathname?.split('/'), ''), [pathname]);
  const currentPageType = useMemo(() => {
    if (paths?.length > 1 && !isValidUuid(paths?.[1])) {
      if (paths?.[0] === 'group') {
        return 'group';
      }
      if (paths?.[1] === 'create') {
        return 'create';
      }
    }
    return pageTypes[paths?.length];
  }, [paths]);
  const currentResourceName = currentPageType !== 'group' && paths?.[0];
  const currentRecordId = isValidUuid(paths?.[1]) && paths?.[1];
  const currentGroupName = currentPageType === 'group' && paths?.[1];

  const resource = useMemo(() => {
    if (!currentResourceName) return null;
    return resources.find((x) => x.name === currentResourceName);
  }, [resources, currentResourceName]);
  const { hideInShow: hideSidebarInShow, hideInList: hideSidebarInList } =
    useMemo(() => resource?.options?.sidebarOptions || {}, [resource]);
  const hideSidebar =
    (currentPageType === 'list' && hideSidebarInList) ||
    (currentPageType === 'show' && hideSidebarInShow) ||
    (currentPageType === 'create' && hideSidebarInShow) ||
    (currentPageType === 'edit' && hideSidebarInShow) ||
    (currentPageType === 'sub' && hideSidebarInShow);

  const getConditionalResourceVisible = (propName) =>
    !user?.merchant || user?.merchant?.[propName];

  const conditionalResources = useMemo(
    () => ({
      wallet: getConditionalResourceVisible('enabledWallet'),
      segment: getConditionalResourceVisible('enabledSegment'),
    }),
    [user?.id],
  );

  const shouldResourceVisible = (resource) => {
    const conditionalResource = Object.keys(conditionalResources).find((x) =>
      resource?.includes(x),
    );
    return conditionalResource
      ? conditionalResources[conditionalResource]
      : true;
  };

  const visibleResources = useMemo(
    () => resources?.filter((r) => shouldResourceVisible(r.name)) || [],
    [resources, conditionalResources],
  );

  const { groups, sections } = useMemo(() => {
    const results = {
      groups: [],
      sections: [],
    };

    if (!visibleResources?.length) return results;

    // extract sections from resources
    const resourceSections = [];
    visibleResources.forEach((resource) => {
      const { groupSections } = resource.options;
      if (groupSections?.length) {
        resourceSections.push(
          ...groupSections
            .filter((gs) => {
              if (myRoleRealm === 'LLS') {
                return true;
              }

              const sectionRealms = gs?.realms
                ?.split(',')
                ?.map((r) => r?.trim())
                ?.filter((r) => !!r);
              const groupSectionRealms = gs?.parent?.realms
                ?.split(',')
                ?.map((r) => r?.trim())
                ?.filter((r) => !!r);

              const acceptedRealmSection =
                !sectionRealms?.length ||
                (myRoleRealm && sectionRealms?.includes(myRoleRealm));

              const acceptedRealmGroupSection =
                !groupSectionRealms?.length ||
                (myRoleRealm && groupSectionRealms?.includes(myRoleRealm));

              return acceptedRealmSection && acceptedRealmGroupSection;
            })
            .map((section) => ({
              ...section,
              resource: {
                ...resource,
                ...resource.options,
                id: resource.options?.resourceId,
              },
            })),
        );
      }
    });

    // merge resources into unique sections
    resourceSections.forEach((section) => {
      let currentSection = results.sections.find((x) => x.id === section.id);
      if (!currentSection) {
        currentSection = section;
        currentSection.resources = [section.resource];
        delete currentSection.resource;

        const sectionRealms = currentSection?.realms
          ?.split(',')
          ?.map((r) => r?.trim())
          ?.filter((r) => !!r);

        if (!sectionRealms?.length) {
          results.sections.push(currentSection);
        } else if (myRoleRealm && sectionRealms?.includes(myRoleRealm)) {
          results.sections.push(currentSection);
        }
      } else {
        currentSection.resources.push(section.resource);
      }
    });
    results.groups = orderBy(
      uniqBy(
        results.sections.map((x) => x.parent),
        'id',
      ),
      'index',
    );

    return results;
  }, [visibleResources, myRoleRealm]);

  const group = useMemo(
    () =>
      currentPageType === 'group' &&
      groups?.find((x) => currentGroupName && x.name === currentGroupName),
    [groups, currentGroupName, currentPageType],
  );
  const currentPageTitle =
    (currentResourceName &&
      `${translate(`resources.${currentResourceName}.name`)}`) ||
    group?.title?.[locale] ||
    group?.title?.en ||
    '';

  const getIcon = (icon) => {
    if (!icon) return <ViewListIcon />; // eslint-disable-line react/jsx-filename-extension
    if (typeof icon !== 'string') {
      return <Icon>{React.createElement(icon)}</Icon>;
    }
    return <Icon>{icon}</Icon>;
  };

  // check resource name exists in resources to prevent showing a warning on the console
  const getResourceTitle = (name) => {
    const isExistsResourceName = resources.find((r) => r.name === name);
    return isExistsResourceName ? translate(`resources.${name}.name`) : name;
  };

  const mapChildNavItem = (c, isResource = false) => {
    let title = getResourceTitle(kebabCase(c.name));

    if (isResource) {
      const altTitle = startCase(c.options.title || '');
      title = altTitle !== title ? altTitle : title;
    } else {
      title = c.title?.[locale] || c.title?.en || c.title?.toString?.();
    }

    return {
      title,
      name: c.name,
      type: 'item',
      link: `/${c.name}`,
      index: c.index,
      icon: getIcon(c.icon),
      count: c?.noticeCount || 0,
      url: c.name,
      id: c.id,
    };
  };

  const mapParentNavItem = (g) => ({
    title: g.title?.[locale] || g.title?.en,
    name: g.name,
    type: 'item',
    link: `/group/${g.name}`,
    icon: getIcon(g.icon),
    index: g.index,
    url: g.name,
    id: g.id,
  });

  const navigationMenus = useMemo(
    () =>
      groups
        ?.map((g) => {
          const group = mapParentNavItem(g);
          const children = orderBy(
            sections
              .filter((s) => s.buildIn && s.parent?.name === g.name)
              ?.map((s) => {
                const section = mapChildNavItem(s);
                const childSections = s.resources
                  ?.filter((r) => r.hasList)
                  ?.map((r) => ({
                    ...mapChildNavItem(r, true),
                    resource: true,
                  }))
                  ?.sort((a, b) => a.index - b.index);

                return {
                  ...section,
                  section: true,
                  count: childSections?.reduce(
                    (total, child) => total + (child?.count || 0),
                    0,
                  ),
                  children: childSections,
                };
              })
              ?.filter((s) => s.children?.length > 0),
            ['buildIn', 'index'],
            ['desc', 'asc'],
          );

          return {
            ...group,
            shortcuts: g?.shortcuts?.sort((a, b) => a.index - b.index),
            group: true,
            children,
            count: children?.reduce(
              (total, child) => total + (child?.count || 0),
              0,
            ),
          };
        })
        ?.filter((g) => g.children?.length > 0)
        ?.sort((a, b) => a.index - b.index),
    [groups, sections, locale],
  );

  const breadcrumbs = useMemo(() => {
    const results = [];
    if (currentResourceName) {
      results.push({
        label: translate(`resources.${currentResourceName}.name`),
        link: `/${currentResourceName}`,
      });
    }
    if (currentRecordId) {
      results.push({
        label: `#${currentRecordId.slice(currentRecordId.length - 4)}`,
        sourceName: currentResourceName,
        recordId: currentRecordId,
      });
    }

    return results;
  }, [
    currentPageType,
    currentResourceName,
    currentRecordId,
    translate,
    locale,
    loading,
  ]);

  return {
    navigationMenus,
    breadcrumbs,
    groups,
    sections,
    currentPageType,
    currentResourceName,
    currentResourceId: resources?.find((r) => r.name === currentResourceName)
      ?.options?.resourceId,
    currentRecordId,
    currentPageTitle,
    currentGroupName,
    group,
    hideSidebar,
  };
};

export default useNavigation;
