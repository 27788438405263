import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import { Box, Collapse, Typography } from '@mui/material';
import { Carousel } from 'antd';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { BookingBottomSheet, DefaultButton } from '..';
import { useSelectEvent } from '../../api/features/common.feature';
import { FORM_FIELD_NAMES, JOB_TYPE } from '../../constants';
import { getSafeValue } from '../../helpers';
import { getSelectedJobType } from '../../services/redux/reducers/job-type.reducer';
import Image from '../image';
import useStyles from './index.style';
import SelectSubJobType from './select-sub-job-type';

const SelectLocationPoint = ({
  photos,
  component,
  locationComponent,
  onChange,
  onConfirmLocationClick,
  carouselProps,
  ctaButtonName,
  fieldLocationName,
  showPlaceholder,
  showDirectionClick,
  value,
}) => {
  const classes = useStyles();
  const { control } = useFormContext();
  const watch = useWatch({ control });
  const selectedJobType = useSelector(getSelectedJobType);
  const { type: jobType } = selectedJobType || {};

  const [directionOpen, setDirectionOpen] = useState(false);

  const { addressName = '', address = '' } = fieldLocationName
    ? getSafeValue(watch, fieldLocationName, {})
    : value || {};
  const { data } = useSelectEvent();

  const direction = data?.eventLocations?.filter((l) => l.name === addressName);

  const isShowInfo = fieldLocationName
    ? !isEmpty(watch[fieldLocationName])
    : !isEmpty(value);

  const handleDirectionClose = () => {
    setDirectionOpen(false);
  };

  const handleDirectionButtonClick = () => {
    setDirectionOpen(true);
  };

  const willShowSubJobTypeDropdown =
    jobType === JOB_TYPE.PREMIUM &&
    fieldLocationName === FORM_FIELD_NAMES.PICK_UP;

  return (
    <>
      <Box className={classes.root}>
        {willShowSubJobTypeDropdown && <SelectSubJobType />}

        <Box className={classes.locationComponentContainer}>
          {locationComponent}
        </Box>
        <Collapse in={isShowInfo}>
          <Box className={classes.carouselContainer}>
            {photos?.length === 0 ? (
              <Box className={classes.wrapperImg}>
                <Image
                  className={classes.image}
                  src="/images/booking/fallback-address.png"
                  alt="Place"
                  loading="lazy"
                />
              </Box>
            ) : (
              <Carousel
                {...carouselProps}
                afterChange={onChange}
                lazyLoad="progressive"
                centerPadding="10px"
              >
                {photos?.map((item) =>
                  React.createElement(component, {
                    ...item,
                    key: item.id,
                  }),
                )}
              </Carousel>
            )}
          </Box>
          <Box className={classes.article}>
            <Box className={classes.info}>
              <Box className={classes.articleHeader}>
                <Box className={classes.articleHeaderInner}>
                  <Typography className={classes.txtAddressName}>
                    {addressName}
                  </Typography>
                  {direction?.[0]?.directions && showDirectionClick && (
                    <DefaultButton
                      name="Directions"
                      className={classes.directionButton}
                      startIcon={<MapOutlinedIcon />}
                      onClick={handleDirectionButtonClick}
                    />
                  )}
                </Box>
              </Box>
              <Box className={classes.articleFooterInner}>
                <Typography className={classes.txtAddress}>
                  {address}
                </Typography>
                <DefaultButton
                  name={ctaButtonName}
                  className={classes.chooseButton}
                  onClick={onConfirmLocationClick}
                />
              </Box>
            </Box>
          </Box>
        </Collapse>
        {showPlaceholder && (
          <Collapse in={!isShowInfo}>
            <Box className={classes.placeholder} />
          </Collapse>
        )}
      </Box>

      {direction?.[0]?.directions && (
        <BookingBottomSheet
          title={`Direction To ${addressName}`}
          open={directionOpen}
          onClose={handleDirectionClose}
          onBack={handleDirectionClose}
        >
          <div
            className={classes.directionContainer}
            /* eslint-disable-next-line */
            dangerouslySetInnerHTML={{ __html: direction?.[0]?.directions }}
          />
        </BookingBottomSheet>
      )}
    </>
  );
};

SelectLocationPoint.propTypes = {
  photos: PropTypes.array.isRequired,
  component: PropTypes.func,
  fieldLocationName: PropTypes.string,
  locationComponent: PropTypes.element,
  onChange: PropTypes.func,
  onConfirmLocationClick: PropTypes.func,
  carouselProps: PropTypes.object,
  location: PropTypes.object,
  ctaButtonName: PropTypes.string,
  showPlaceholder: PropTypes.bool,
  showDirectionClick: PropTypes.bool,
  value: PropTypes.object,
};

SelectLocationPoint.defaultProps = {
  fieldLocationName: null,
  onConfirmLocationClick: undefined,
  onChange: undefined,
  carouselProps: {},
  locationComponent: null,
  component: null,
  location: {},
  value: {},
  ctaButtonName: null,
  showPlaceholder: false,
  showDirectionClick: false,
};
export default React.memo(SelectLocationPoint);
