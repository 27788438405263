import { createStore, applyMiddleware, combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { appReducer } from './app/app.reducer';
import { authReducer } from './auth/auth.reducer';
import { dataReducer } from './data/data.reducer';
import { filterReducer } from './filter/filter.reducer';
import { notificationReducer } from './notification/notification.reducer';
import { schemaReducer } from './schema/schema.reducer';
import { settingReducer } from './setting/setting.reducer';
import { rootSagas } from './root.sagas';
import { resourceReducer } from './resource/resource.reducer';

const rootReducer = combineReducers({
  setting: settingReducer,
  resource: resourceReducer,
  schema: schemaReducer,
  auth: authReducer,
  filter: filterReducer,
  app: appReducer,
  notification: notificationReducer,
  data: dataReducer,
});

const sagaMiddleware = createSagaMiddleware();
export const store = createStore(rootReducer, applyMiddleware(sagaMiddleware));
sagaMiddleware.run(rootSagas);

export default rootReducer;
