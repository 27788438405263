import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import React, { useCallback, useImperativeHandle } from 'react';
import { useMainLayoutContext } from '../../../../base/context/main-layout';
import useResizeDetector from '../../../../base/hooks/useResizeDetector';

const useStyles = makeStyles((theme) => ({
  appMainContentWrapper: {
    position: 'relative',
    paddingBottom: 16,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'scroll',
  },
  appMainContentHeader: {
    // flex: 1,
    display: 'flex',
    flexDirection: 'column',
    marginRight: 'auto',
    marginLeft: 'auto',
    paddingLeft: 15,
    paddingRight: 15,
    width: '100%',
    boxSizing: 'border-box',
  },
  appMainContent: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 'auto',
    marginLeft: 'auto',
    paddingLeft: 15,
    paddingRight: 15,
    width: '100%',
    boxSizing: 'border-box',
    '& > div': { height: '100%' },
  },
  extendHeight: {
    [theme.breakpoints.down('lg')]: {
      height: '72px !important',
    },
    height: ({ height }) => (height ? `${height}px` : '200px'),
  },
}));

const CmtContent = React.forwardRef((props, ref) => {
  const { children, header, height } = props;
  useImperativeHandle(ref, () => ({}));
  const contentRef = React.createRef();
  const classes = useStyles({
    height,
  });
  const { ref: containerRef } = useResizeDetector();
  const { setScrollOffsetY } = useMainLayoutContext();

  const handleOnScroll = useCallback((event) => {
    setScrollOffsetY(event.target.scrollTop);
  }, []);

  return (
    <div
      ref={containerRef}
      className={classes.appMainContentWrapper}
      bgcolor="background.main"
      onScroll={handleOnScroll}
      id="app-main-content-wrapper"
    >
      <Box
        ref={contentRef}
        className={clsx(classes.appMainContent, 'appMainContent')}
      >
        {children}
      </Box>
    </div>
  );
});

export default CmtContent;
CmtContent.defaultProps = {
  name: 'LayoutContent',
};
