import withHeader from '../hoc/with-header';
import DefaultCashSuccessScreen from './cash-success';
import DefaultSplashScreen from './splash-screen';
import DefaultBookingDropOffScreen from './dropoff';
import DefaultBookingPickUpScreen from './pickup';
import DefaultOnlineSuccessScreen from './online-success';
import DefaultReservationScreen from './reservation';
import DefaultError from './error';

export const BookingDropOffScreen = DefaultBookingDropOffScreen;
export const BookingPickUpScreen = DefaultBookingPickUpScreen;
export const BookingSplashScreen = DefaultSplashScreen;
export const BookingErrorScreen = DefaultError;

export const BookingReservationScreen = DefaultReservationScreen;
export const BookingCashSuccessScreen = withHeader(DefaultCashSuccessScreen);
export const BookingOnlineSuccessScreen = withHeader(
  DefaultOnlineSuccessScreen,
);
