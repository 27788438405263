export const RbacAction = {
  CREATE: 'create',
  READ: 'read',
  LIST: 'list',
  UPDATE: 'update',
  DELETE: 'delete',
  SUBMIT: 'submit',
  APPROVE: 'approve',
  CANCEL: 'cancel',
  COMMENT: 'comment',
  DASHBOARD: 'dashboard',
  ONLY_ME: 'onlyMe',
  CLONE: 'clone',
  IMPORT: 'import',
  EXPORT: 'export',
};

export const RbacCommentOpts = {
  NONE: 'NONE', // disabled
  FULL_ACCESS: 'FULL_ACCESS', // can read/write everything
  READ_FULL: 'READ_FULL', // can read everything and not violating hidden rule
  READ_COMMENT: 'READ_COMMENT', // can read only comment
};

export const RbacActionWithoutClone = {
  CREATE: 'create',
  READ: 'read',
  LIST: 'list',
  UPDATE: 'update',
  DELETE: 'delete',
  SUBMIT: 'submit',
  APPROVE: 'approve',
  CANCEL: 'cancel',
  COMMENT: 'comment',
  DASHBOARD: 'dashboard',
  ONLY_ME: 'onlyMe',
  IMPORT: 'import',
  EXPORT: 'export',
};

export const canI = (action, name, myPerms) => {
  let resource = name;
  if (resource?.includes('/')) {
    const splitResource = resource?.split('/');
    resource = splitResource[splitResource.length - 1];
  }

  if (action === RbacAction.ONLY_ME) {
    return myPerms?.some((p) => p.resource?.name === resource && p[action]);
  }
  return (
    myPerms?.length > 0 &&
    myPerms?.some((p) => p.resource?.name === resource && p[action])
  );
};
