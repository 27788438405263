import { combineReducers } from 'redux';
import eventReducer from './event.reducer';
import settingReducer from './setting.reducer';
import jobTypeReducer from './job-type.reducer';

const rootReducer = combineReducers({
  event: eventReducer,
  setting: settingReducer,
  jobType: jobTypeReducer,
});
export default rootReducer;
