/* eslint-disable import/no-cycle */
import { Link, Stack } from '@mui/material';
import { styled } from '@mui/styles';
import axios from 'axios';
import { get, isEmpty, isNil, kebabCase } from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  FileField,
  FileInput,
  ImageField,
  ImageInput,
  email,
  number,
  regex,
  required,
  useTranslate,
} from 'react-admin';
import { useFormContext } from 'react-hook-form';
import configs from '../../../services/configs';
import { getSyntaxJsonUnquoteExtract } from '../../../services/provider/dataProvider';
import { useSchema } from '../../hooks';
import {
  getResourceFieldMessageKey,
  getSourceRefTitle,
  isValidJsonArray,
  saveFileFromResponse,
} from '../../utils';
import TestableContainer from '../container/testable';
import PrintFormat from '../print-format';
import {
  AutocompleteArrayInput,
  AutocompleteInput,
  BooleanInput,
  JsonInput,
  ReferenceArrayInput,
  ReferenceInput,
  SelectInput,
} from '../ra/inputs';
import FiltersInput from '../ra/inputs/filters-input';
import ModificationInput from '../ra/inputs/modification-input';
import ReferenceIteratorForm from '../ra/inputs/reference/iterator';
import { isEmail } from '../ra/validate';
import { guessInputComponent } from './nf-component.guesser';

const HelperLinkStyled = styled(Link)({
  color: 'blue',
  textDecoration: 'underline',
  display: 'block',
});

let dependOnListRef;
const getRefOptionText = ({
  apiRef,
  fieldRef,
  locale,
  defaultOptionText,
  defaultTranslatableInput,
}) => {
  let optionText = defaultOptionText;
  let translatableInput = defaultTranslatableInput;

  const { properties } = apiRef.get(fieldRef);

  // find optionText
  const displayName = Object.keys(properties)?.find(
    (key) => properties[key]?.displayName,
  );
  if (displayName) {
    optionText = displayName;
  } else {
    Object.keys(properties).forEach((prp) => {
      if (properties[prp]?.properties?.optionText?.default) {
        optionText = prp;
      }
    });
  }

  // eslint-disable-next-line no-underscore-dangle
  const metaData = properties._metaData;
  optionText = metaData?.properties?.optionText?.default || optionText;

  // TRANSLATABLE_INPUT
  if (properties[optionText]?.properties?.translatable?.default) {
    optionText = `${optionText}.${locale}`;
    translatableInput = true;
  }

  return {
    optionText,
    translatableInput,
  };
};

const getRefFilter = (refName, refFilter, sourceSchema, values) => {
  const tmpRefFilter = {
    ...refFilter,
  };
  // Get default filter
  const defaultFilter = sourceSchema?.properties?.defaultFilter?.properties;
  tmpRefFilter[defaultFilter]; // eslint-disable-line no-unused-expressions
  const defaultFilterKeys = Object.keys(defaultFilter || {});
  if (defaultFilterKeys?.length) {
    defaultFilterKeys.forEach((key) => {
      const value = defaultFilter?.[key]?.default;
      const paramFilter = defaultFilter[key].format;
      if (value) {
        if (Array.isArray(value)) {
          const arrId = value.map((i) => i?.id || i).join(',');
          const filter = {
            op: '$in',
            value: arrId,
          };
          if (paramFilter) {
            tmpRefFilter[paramFilter] = filter;
          } else {
            tmpRefFilter[`${key}`] = filter;
          }
        } else if (['merchantId', 'merchant'].includes(key)) {
          if (refName === 'user') {
            tmpRefFilter.merchant = {
              id: value?.id || value,
            };
          } else {
            tmpRefFilter[paramFilter || 'merchantId'] = value?.id || value;
          }
        } else if (value?.id) {
          tmpRefFilter[key] = {
            id: value.id,
          };
        } else {
          tmpRefFilter[key] = value;
        }
      }
    });
  }

  const filterOn = sourceSchema?.properties?.filterOn?.properties;
  const filterOnProps = Object.keys(filterOn || {});
  if (filterOn) {
    filterOnProps.forEach((f) => {
      const filterRaw = filterOn[f]?.properties?.rawValue?.default;
      let value = filterRaw
        ? filterOn[f].default
        : get(values, filterOn[f].default);
      const paramFilter = filterOn[f].format;

      if (!value) {
        value = '$isnull';
      }

      if (Array.isArray(value)) {
        const arrId = value.map((i) => i.id).join(',');
        const filter = {
          op: '$in',
          value: arrId,
        };
        if (paramFilter) {
          tmpRefFilter[paramFilter] = filter;
        } else {
          tmpRefFilter[`${f}`] = filter;
        }
      } else if (['merchantId', 'merchant'].includes(f)) {
        if (refName === 'user') {
          tmpRefFilter.merchant = {
            id: value?.id || value,
          };
        } else if (filterOn[f]?.properties?.nullable) {
          tmpRefFilter[paramFilter || 'merchantId'] = {
            op: '$in',
            value: [null, value?.id || value],
          };
        } else {
          tmpRefFilter[paramFilter || 'merchantId'] = value?.id || value;
        }
      } else if (value?.id) {
        if (filterOn[f]?.properties?.nullable) {
          tmpRefFilter[f] = {
            op: '$in',
            value: [null, value.id],
          };
        } else {
          tmpRefFilter[f] = {
            id: value.id,
          };
        }
      } else if (filterOn[f]?.properties?.nullable) {
        tmpRefFilter[f] = {
          op: '$in',
          value: [null, value],
        };
      } else {
        tmpRefFilter[f] = value;
      }
    });
  }

  const filterOnRef = sourceSchema?.properties?.filterOnRef?.properties;
  const filterOnRefProps = Object.keys(filterOnRef || {});
  if (filterOnRef) {
    filterOnRefProps.forEach((f) => {
      const v = values?.[filterOnRef[f].default];
      if (v?.id) {
        tmpRefFilter[f] = v.id;
      } else if (v) {
        tmpRefFilter[f] = v;
      }
    });
  }

  const filter = sourceSchema?.properties?.filter?.properties;
  const filterProps = Object.keys(filter || {});
  if (filter) {
    filterProps.forEach((f) => {
      tmpRefFilter[f] = filter[f].default;
    });
  }

  return tmpRefFilter;
};

const guessInputComponentWithProp = ({
  source,
  properties,
  apiRef,
  resource: globalResource,
  resources,
  locale,
  view,
  record: globalRecord,
  createForm,
  hidden,
  renderExtraComponents,
  withHoc,
  ignoreDependOn,
}) => {
  const realResource = globalResource.includes('/')
    ? globalResource?.split('/')[1]
    : globalResource;
  const sourceSchema = properties?.[source];

  let sourceName = source;
  let refName;
  let refSelectionType;
  let isImage;
  let refFilter = {};

  const displayOn =
    !properties?.[sourceName]?.displayOn ||
    properties[sourceName].displayOn.some((x) => x.target === view);
  if (!displayOn) return null;

  // Get input format
  const inputFormat = sourceSchema?.format?.includes('||')
    ? sourceSchema?.format?.split('||')[1]
    : sourceSchema?.format;
  const isJSON = inputFormat === 'json';
  const isFiltersInput = inputFormat === 'filters-input';
  const isFiltersFixed = sourceSchema?.formatFilterFixed;
  const isFiltersTarget = sourceSchema?.formatFilterTarget;
  const isModificationInput = inputFormat === 'modification-input';
  const isFile = inputFormat === 'file';

  const {
    InputComponent,
    guessedProps,
    WrapperComponent, // TRANSLATABLE_INPUT
  } = guessInputComponent(sourceSchema, view, false, withHoc);

  // TRANSLATABLE INPUTS
  let translatableInput = sourceSchema?.properties?.translatable?.default;
  // find $ref
  let fieldRef =
    sourceSchema?.$ref ||
    sourceSchema?.allOf?.filter((i) => i?.$ref)?.[0]?.$ref ||
    sourceSchema?.properties?.reference?.$ref;

  let optionText = getSourceRefTitle(
    properties,
    source,
    null,
    fieldRef ? apiRef.get(fieldRef) : null,
  );

  // Guess ReferenceInput (one/many to one)
  // ref to a defined object type
  if (fieldRef) {
    refName = kebabCase(fieldRef?.split('/schemas/').pop());
    if (refName.endsWith('-status')) {
      refFilter = {
        type: { op: '$eq', value: fieldRef?.split('/schemas/').pop() },
      };
      refName = 'status';
    }

    const refOptionText = getRefOptionText({
      fieldRef,
      apiRef,
      refName,
      locale,
      defaultOptionText: optionText,
      defaultTranslatableInput: translatableInput,
    });
    optionText = refOptionText.optionText;
    translatableInput = refOptionText.translatableInput;

    const enabledFilter = apiRef.get(fieldRef).properties.enabled !== undefined;
    if (enabledFilter) {
      refFilter = {
        enabled: '1',
      };
    }

    if (!sourceSchema?.properties?.reference?.$ref) {
      sourceName = `${source}.id`;
    }
    refSelectionType = 'single';
    if (refName === 'blob') {
      isImage = true;
    }
  }

  const getHelperText = (translate) => {
    const {
      helperLink,
      helperText,
      description = '',
      fileTemplates,
    } = sourceSchema || {};

    const handleClickTemplate = async (e, fileName, fileLink) => {
      e.preventDefault();
      e.stopPropagation();

      const resp = await axios.post(fileLink);

      if (resp?.data && resp.status < 300) {
        saveFileFromResponse(resp, fileName, 'csv');
      }
    };

    return (
      <>
        {description && <div>{translate(description)}</div>}
        {Boolean(fileTemplates?.length) && (
          <Stack direction="row" spacing={2}>
            {fileTemplates.map((item) => (
              <HelperLinkStyled
                onClick={(e) => handleClickTemplate(e, item?.name, item?.link)}
                href="#"
                key={kebabCase(item?.name)}
              >
                {item?.name}
              </HelperLinkStyled>
            ))}
          </Stack>
        )}

        {helperLink ? (
          <HelperLinkStyled
            onClick={(e) => e.stopPropagation()}
            href={helperLink}
            target="__blank"
          >
            {translate(helperText || helperLink)}
          </HelperLinkStyled>
        ) : (
          helperText && <div>{translate(helperText)}</div>
        )}
      </>
    );
  };

  // Guess validation for "value" of "setting" resource
  // eslint-disable-next-line
  const InputContainer = React.memo(({ children, defaultValue, ...props }) => {
    const translate = useTranslate();
    const { getResourceProperties } = useSchema();
    const { getValues, watch, setValue } = useFormContext();
    const values = {
      ...globalRecord,
      ...watch(),
    };

    useEffect(() => {
      watch();
    }, [watch]);

    const [autoCompleteCreateDialog, setAutoCompleteCreateDialog] =
      useState(false);

    const canCreate = (srcName) =>
      resources?.find((r) => {
        // eslint-disable-line no-shadow
        if (r?.name === srcName) {
          return r?.hasCreate && !r?.options?.docWorkflowable;
        }
        return false;
      });

    // check if this input depend on any other value
    const trueSourceName = props?.source?.split('.')?.[0]; // eslint-disable-line react/prop-types
    const filterTarget = sourceSchema?.filterTarget;

    useEffect(() => {
      const dependOn =
        properties?.[trueSourceName]?.properties?.filterOn?.properties || {};
      Object.keys(dependOn).forEach((key) => {
        const [srcName] = dependOn?.[key]?.default?.split('.');
        dependOnListRef = {
          [realResource]: {
            [srcName]: [trueSourceName],
          },
        };
      });
    }, []);

    if (filterTarget) {
      const resourceName = resources?.find(
        (x) => x?.options?.resourceId === get(values, filterTarget)?.id,
      )?.name;

      if (resourceName) {
        const { ref: responseRef } = getResourceProperties({
          resource: resourceName,
          view: 'list',
        });
        fieldRef = responseRef;
        refSelectionType =
          sourceSchema?.type === 'array' ? 'multiple' : 'single';
        refName = resourceName;

        const refOptionText = getRefOptionText({
          fieldRef,
          apiRef,
          refName,
          locale,
          defaultOptionText: optionText,
          defaultTranslatableInput: translatableInput,
        });
        optionText = refOptionText.optionText;
        translatableInput = refOptionText.translatableInput;
      }
    }

    const dependOnCondition = (dependOns, filterOns) => (i) => {
      const filterOnProperty = filterOns[i]?.default;
      const dependOnValue = get(dependOns, i);
      const shouldFilterOnProp = !dependOnValue && !!filterOnProperty;
      const property = shouldFilterOnProp ? filterOnProperty : i;
      let propertyValue = get(values, property);

      if (Array.isArray(propertyValue)) {
        propertyValue = propertyValue?.map((item) => item?.id);
      }

      // filterOn prop
      if (shouldFilterOnProp) {
        return !propertyValue?.length;
      }

      // dependOn prop
      if (!isNil(propertyValue) && !isNil(dependOnValue?.not?.default)) {
        return propertyValue === dependOnValue?.not?.default;
      }

      if (dependOnValue?.default === undefined) {
        return !propertyValue || propertyValue?.length === 0;
      }

      if (Array.isArray(dependOnValue?.default)) {
        if (Array.isArray(propertyValue)) {
          return !dependOnValue?.default?.some((item) =>
            propertyValue?.includes(item),
          );
        }
        return !dependOnValue?.default?.includes(propertyValue);
      }
      if (Array.isArray(propertyValue)) {
        return !propertyValue?.includes(dependOnValue?.default);
      }
      if (typeof dependOnValue?.default === 'boolean') {
        return propertyValue !== dependOnValue.default;
      }

      return propertyValue !== dependOnValue?.default;
    };

    const generateDependOnPropertyList = (dependOns, filterOns) => {
      const dependOnProperties = {
        ...dependOns,
      };
      Object.keys(filterOns)
        .filter((fo) => !filterOns?.[fo]?.properties?.noDepend)
        .forEach((fo) => {
          dependOnProperties[fo] = {
            default: true,
          };
        });
      return Object.keys(dependOnProperties);
    };

    const handleFilterToQuery = (value, filterField) => {
      let optionTextQuery = filterField;

      if (translatableInput && optionText === filterField) {
        const splitArr = filterField.split('.');
        splitArr.pop();
        const truncated = splitArr.join('.');
        optionTextQuery = `${truncated}${getSyntaxJsonUnquoteExtract(locale)}`;
      }

      try {
        const dependOn = dependOnListRef?.[realResource]?.[trueSourceName];
        if (!isEmpty(dependOn)) {
          dependOn.forEach((key) => {
            if (isEmpty(value?.[key]?.id)) {
              setValue(key, undefined);
            }
          });
        }
      } catch {} // eslint-disable-line
      return {
        [optionTextQuery]: value,
      };
    };

    const filterOns =
      properties?.[trueSourceName]?.properties?.filterOn?.properties || {};
    const dependOns = {
      ...properties?.[trueSourceName]?.properties?.dependOn?.properties,
    };
    const dependOnsAny = {
      ...properties?.[trueSourceName]?.properties?.dependOn?.anyOf
        ?.map((x) => x.properties)
        ?.reduce(
          (prev, curr) => ({
            ...prev,
            ...curr,
          }),
          {},
        ),
    };

    const dependOnPropertyList = generateDependOnPropertyList(
      dependOns,
      filterOns,
    );
    const dependOnAnyPropertyList = generateDependOnPropertyList(
      dependOnsAny,
      filterOns,
    );
    const shouldBeHidden =
      dependOnPropertyList.some(dependOnCondition(dependOns, filterOns)) ||
      (!!dependOnAnyPropertyList.length &&
        dependOnAnyPropertyList.every(
          dependOnCondition(dependOnsAny, filterOns),
        ));

    const transformFile = (file) => {
      if (file instanceof window.File) {
        return { blobUrlBO: URL.createObjectURL(file) };
      }

      return { blobUrlBO: file?.blobUrlBO || file?.src || file };
    };

    const transformFiles = (files) => {
      if (!files) {
        return null;
      }

      return transformFile(files);
    };

    if (!ignoreDependOn && shouldBeHidden) {
      if (sourceSchema.shouldResetValueOnHidden) {
        const currentValue = get(getValues(), source);
        if (currentValue !== guessedProps?.defaultValue) {
          setValue(source, guessedProps?.defaultValue);
        }
      }
      return null;
    }

    if (sourceSchema?.referenced) {
      const { minItems, maxItems, iteratorIndexable } = sourceSchema;
      const format = sourceSchema.format || sourceSchema.items?.format;

      if (format === 'reference-iterator-form') {
        const refField =
          (sourceSchema.allOf || sourceSchema.items?.allOf)?.filter(
            (i) => i?.$ref,
          )?.[0]?.$ref || sourceSchema.items?.$ref;
        refName = kebabCase(refField?.split('/schemas/')?.pop());

        return (
          <ReferenceIteratorForm
            reference={refName}
            resource={sourceSchema.properties?.alias?.default || refName}
            source={source}
            minItems={minItems}
            maxItems={maxItems}
            indexable={iteratorIndexable}
          />
        );
      }
    }

    // Detect Blob object
    if (isImage) {
      return (
        <ImageInput
          source={source}
          accept="image/*"
          key={source}
          helperText={getHelperText(translate)}
          className="image-input"
          format={transformFiles}
        >
          <ImageField source="blobUrlBO" />
        </ImageInput>
      );
    }

    if (isFile) {
      return (
        <FileInput
          source={source}
          accept={
            properties[sourceName]?.properties?.mineType?.default ||
            'application/zip'
          }
          helperText={getHelperText(translate)}
        >
          <FileField source="src" title="title" />
        </FileInput>
      );
    }

    if (refSelectionType === 'single') {
      const realRef = resources?.find((r) => {
        if (r.name.includes('/')) {
          return r.name === kebabCase(refName);
        }

        return false;
      });

      refFilter = getRefFilter(refName, refFilter, sourceSchema, values);

      let label = getResourceFieldMessageKey(properties, realResource, source);

      const sourceRef = apiRef.get(fieldRef);
      optionText = getSourceRefTitle(
        sourceRef.properties,
        null,
        null,
        sourceRef,
      );

      // TRANSLATABLE_INPUT
      if (translatableInput) {
        optionText = `${optionText}.${locale}`;
        label = `${translate(label)} (${locale})`;
      }

      if (sourceSchema?.nfRef?.source) {
        optionText = `${optionText}.${sourceSchema?.nfRef?.source}`;
      }

      return (
        <ReferenceInput
          key={sourceName}
          source={sourceName}
          reference={
            sourceSchema?.properties?.alias?.default || realRef?.name || refName
          }
          filter={refFilter}
          fullWidth
          defaultValue={defaultValue}
          label={label}
        >
          {sourceSchema?.format === 'select-input' ? (
            <SelectInput optionText={optionText} />
          ) : (
            <AutocompleteInput
              disabled={sourceSchema?.options?.disabled}
              optionText={optionText}
              suggestionLimit={10}
              variant={guessedProps.variant}
              filterToQuery={handleFilterToQuery}
              additionFields={sourceSchema.filterFields}
              openCreateDialog={autoCompleteCreateDialog}
              onCreateClick={() => setAutoCompleteCreateDialog(true)}
              helperText={getHelperText(translate)}
              create={
                canCreate(
                  sourceSchema?.properties?.alias?.default ||
                    realRef?.name ||
                    refName,
                ) &&
                React.isValidElement(createForm) &&
                React.cloneElement(createForm, {
                  resource:
                    sourceSchema?.properties?.alias?.default ||
                    realRef?.name ||
                    refName,
                  type: 'autocomplete',
                  onClose: () => {
                    setAutoCompleteCreateDialog(false);
                  },
                })
              }
            />
          )}
        </ReferenceInput>
      );
    }

    // Guess ReferenceArrayInput (one/many to many)
    if (
      refSelectionType === 'multiple' ||
      (sourceSchema?.type === 'array' &&
        !sourceSchema?.properties?.customReferenceInput?.default)
    ) {
      let label = getResourceFieldMessageKey(properties, realResource, source);

      if (!refName || source === 'pools') {
        const name = source?.split('.').pop();
        const refField =
          properties[name].items?.$ref ||
          properties[name].items?.allOf?.filter((i) => i?.$ref)?.[0]?.$ref;
        refName = kebabCase(refField?.split('/schemas/')?.pop());

        // FIND OPTIONTEXT
        const sourceRef = apiRef.get(refField);
        optionText = getSourceRefTitle(
          sourceRef.properties,
          null,
          null,
          sourceRef,
        );

        // TRANSLATABLE_INPUT
        if (
          sourceRef?.properties?.[optionText]?.properties?.translatable?.default
        ) {
          optionText = `${optionText}.${locale}`;
          label = `${translate(label)} (${locale})`;
          translatableInput = true;
        }
      }

      sourceName = `${source}.id`;
      const realRef = resources?.find((r) => {
        if (r.name.includes('/')) {
          return r.name.includes(kebabCase(refName));
        }

        return false;
      });
      refFilter = getRefFilter(refName, refFilter, sourceSchema, values);
      return (
        <ReferenceArrayInput
          key={refName}
          source={source}
          filter={refFilter}
          reference={realRef?.name || refName}
          label={label}
          parse={(ids) =>
            ids?.map((id) => ({
              id,
            }))
          }
          format={(data) => data?.map((d) => d.id)}
          fullWidth
          defaultValue={defaultValue}
          resource={realRef?.name || refName}
        >
          <AutocompleteArrayInput
            label={label}
            openCreateDialog={autoCompleteCreateDialog}
            onCreateClick={() => setAutoCompleteCreateDialog(true)}
            create={
              canCreate(realRef?.name || refName) &&
              React.isValidElement(createForm) &&
              React.cloneElement(createForm, {
                resource: realRef?.name || refName,
                type: 'autocomplete',
                onClose: () => {
                  setAutoCompleteCreateDialog(false);
                },
              })
            }
            optionText={optionText}
            variant={guessedProps.variant}
            filterToQuery={handleFilterToQuery}
            helperText={getHelperText(translate)}
          />
        </ReferenceArrayInput>
      );
    }

    let {
      record: recordFromInputProps, // eslint-disable-line react/prop-types
      resource: inputResource, // eslint-disable-line react/prop-types
    } = props;
    if (globalRecord) recordFromInputProps = globalRecord;
    if (globalResource) inputResource = globalResource;
    let newProps = {
      ...props,
      record: recordFromInputProps,
      resource: inputResource,
      defaultValue:
        guessedProps?.valueformat === 'string'
          ? defaultValue ?? ''
          : defaultValue,
    };
    let Component = InputComponent;

    if (
      inputResource === 'flow' ||
      inputResource === 'form-data' ||
      inputResource === 'form-attribute'
    ) {
      const inputSource = sourceSchema?.options?.useNameToSetSource
        ? props?.name // eslint-disable-line react/prop-types
        : sourceSchema?.source;

      switch (sourceSchema?.format) {
        case 'blob':
          return (
            <>
              <ImageInput
                source={inputSource || ''}
                label={sourceSchema?.options.label}
                accept="image/*"
                key="value"
                onChange={sourceSchema?.options?.onUploadChange}
                className={`image-input ${sourceSchema?.options?.className}`}
                format={(file) => {
                  if (typeof file === 'string') {
                    return transformFiles(
                      sourceSchema?.options?.hasStaticUrl
                        ? `${configs.apiUrl?.split('/api')[0]}${file}`
                        : `${configs.apiUrl}/bo/blob/static?blobId=${file}`,
                    );
                  }
                  return file;
                }}
                helperText={getHelperText(translate)}
              >
                <ImageField source="blobUrlBO" />
              </ImageInput>
              {renderExtraComponents?.(source)}
            </>
          );

        default:
          break;
      }
    }
    if (inputResource === 'setting' && sourceName === 'value') {
      let validate = required();
      switch (
        recordFromInputProps?.format // eslint-disable-line react/prop-types
      ) {
        case 'email':
          validate = [email(), isEmail()];
          break;
        case 'number':
          validate = number();
          break;
        case 'ip':
          validate = regex(
            /^(?=\d+\.\d+\.\d+\.\d+$)(?:(?:25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]|[0-9])\.?){4}$/,
            'Must be a valid IP address',
          );
          break;
        case 'boolean':
          Component = BooleanInput;
          break;
        case 'blob':
          return (
            <ImageInput
              source="value"
              accept="image/*"
              key="value"
              helperText={getHelperText(translate)}
              className="image-input"
              format={transformFiles}
            >
              <ImageField source="blobUrlBO" />
            </ImageInput>
          );
        case 'multiline':
          newProps.multiline = true;
          newProps.rows = 20;
          break;
        default:
          break;
      }
      newProps = {
        ...newProps,
        validate,
      };
    }
    if (isJSON) {
      if (source === 'formatData') {
        Component = PrintFormat;
      } else {
        Component = JsonInput;
        const structJson =
          properties?.[trueSourceName]?.properties?.structJson?.default || [];
        const defaultValueJSON = isValidJsonArray(structJson)
          ? structJson
          : JSON.stringify([]);

        newProps = {
          ...newProps,
          defaultValue: defaultValueJSON,
          jsonString: !isValidJsonArray(structJson),
          reactJsonOptions: {
            // Props passed to react-json-view
            name: null,
            collapsed: false,
            enableClipboard: true,
            displayDataTypes: false,
          },
        };
      }
    }

    if (isFiltersInput) {
      Component = FiltersInput;
      newProps = {
        ...newProps,
        isFiltersFixed,
        isFiltersTarget,
      };
    }

    if (isModificationInput) {
      Component = ModificationInput;
    }

    return children({
      Component,
      props: { ...newProps, helperText: getHelperText(translate) },
      translate,
    });
  });
  // Normal Input
  const componentProps = {
    source: sourceName,
  };
  // omit type prop if value is undefined to prevent wrong behavior of Input Components
  if (inputFormat) componentProps.type = inputFormat;

  return (
    <TestableContainer
      key={sourceName}
      nfid={kebabCase(`${realResource}-${view}-input-${source}`)}
      {...(hidden && {
        className: 'invisible',
      })}
      {...componentProps}
    >
      <InputContainer
        {...componentProps}
        defaultValue={guessedProps?.defaultValue}
        variant={guessedProps?.variant}
      >
        {({ Component, props, translate }) => {
          if (Component) {
            // TRANSLATABLE_INPUT
            return WrapperComponent ? (
              <WrapperComponent {...props}>
                <Component
                  {...guessedProps}
                  {...props}
                  placeholder={sourceSchema?.example}
                  label={
                    guessedProps?.label ||
                    getResourceFieldMessageKey(properties, realResource, source)
                  }
                  helperText={getHelperText(translate)}
                  variant={guessedProps?.variant}
                />
              </WrapperComponent>
            ) : (
              <>
                <Component
                  {...guessedProps}
                  {...props}
                  placeholder={sourceSchema?.example}
                  label={
                    guessedProps?.label ||
                    getResourceFieldMessageKey(properties, realResource, source)
                  }
                  helperText={getHelperText(translate)}
                  variant={guessedProps?.variant}
                />
                {renderExtraComponents?.(source)}
              </>
            );
          }
          return <></>;
        }}
      </InputContainer>
    </TestableContainer>
  );
};

export default guessInputComponentWithProp;
