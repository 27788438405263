import { useDispatch, useSelector } from 'react-redux';
import {
  toogleFeedBack,
} from '../../services/redux/app/app.actions';

const useFeedBack = () => {
  const dispatch = useDispatch();
  const isFeedBackShown = useSelector(({ app }) => app?.isFeedBackShown);
  const toogleFeedBackShown = visible => {
    dispatch(toogleFeedBack(visible));
  };
  return {
    isFeedBackShown,
    toogleFeedBackShown,
  };
};

export default useFeedBack;
