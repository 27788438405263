import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  jobTypeBtn: {
    width: '100%',
    backgroundColor: '#E2E2EA',
    color: '#B5B5BE',
    '& span': {
      fontFamily: 'QuickSandBold',
    },
  },
  selected: {
    backgroundColor: '#002169',
    color: 'white',
  },
}));

export default useStyles;
