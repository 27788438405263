import SwaggerParser from '@apidevtools/swagger-parser';
import { call, put, takeEvery } from 'redux-saga/effects';
import configs from '../../configs';
import { PULL_NF_SCHEMA, PULL_NF_SCHEMA_SUCCESS } from '../root.actions';

export const fetchSchema = async () => {
  const api = await SwaggerParser.parse(`${configs.apiUrl}/api-bo-json`);
  const schema = {
    api,
  };

  return schema;
};

export function* schemaSaga() {
  yield takeEvery(PULL_NF_SCHEMA, function* () {
    try {
      if (global.isPullingSchema) return;
      global.isPullingSchema = true;
      const { api } = yield call(fetchSchema);
      const ref = yield SwaggerParser.resolve(api);

      yield put({
        type: PULL_NF_SCHEMA_SUCCESS,
        payload: {
          api,
          ref,
        },
      });
      global.isPullingSchema = false;
    } catch (ex) {
      console.error(ex);
      global.isPullingSchema = false;
    }
  });
}
