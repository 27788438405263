/* eslint-disable react/jsx-filename-extension */
import { Tooltip } from '@mui/material';
import kebabCase from 'lodash/kebabCase';
import React from 'react';
import { AutocompleteInput, ReferenceInput, SelectInput } from '..';
import { getSourceRefTitle, parseSourceRef } from '../../../../utils';
import { guessInputComponent } from '../../../guesser/nf-component.guesser';
import { ops } from './operators.contants';

export const parseFilterInput = (properties, apiRef, locale) =>
  Object.keys(properties).map((source) => {
    const sourceSchema = properties?.[source];
    const sourceRef =
      sourceSchema?.$ref ||
      sourceSchema?.allOf?.filter((i) => i?.$ref)?.[0]?.$ref;
    const { InputComponent, guessedProps } = guessInputComponent(
      sourceSchema,
      undefined,
      true,
    );
    const componentProps = {
      label: 'Value',
      key: source,
      source,
    };

    if (source === 'username') {
      return (
        <ReferenceInput
          {...componentProps}
          reference={kebabCase('user')}
          ops={ops?.selection}
        >
          <AutocompleteInput
            fullWidth
            filterToQuery={(searchText) => ({ username: searchText })}
            optionText="username"
            PopperProps={{
              sx: { maxWith: '300px', width: 'unset !important' },
            }}
            optionValue="username"
            getOptionText={(item, props) => {
              const text = `${item.username} (${item.fullName})`;
              if (text.length > 40) {
                return (
                  <Tooltip title={item.fullName}>
                    <li {...props}>{`${text.substring(0, 41)}...`}</li>
                  </Tooltip>
                );
              }

              return <li {...props}>{text}</li>;
            }}
          />
        </ReferenceInput>
      );
    }

    if (sourceRef && apiRef) {
      let refFilter;
      const name = source?.split('.').pop();
      const refField =
        properties[name].$ref ||
        properties[name].allOf?.filter((i) => i?.$ref)?.[0]?.$ref;
      let refName = refField?.split('/schemas/').pop();

      if (kebabCase(refName).endsWith('-status')) {
        refFilter = {
          type: { op: '$eq', value: refName },
        };
        refName = 'status';
      }

      let optionText;
      if (refField && source) {
        const refFieldSourceRef = parseSourceRef(
          source,
          properties,
          apiRef,
          locale,
        );
        optionText = refFieldSourceRef.source;
      } else {
        optionText = getSourceRefTitle(
          sourceRef.properties,
          source,
          refName,
          null,
        );
      }

      return (
        <ReferenceInput
          {...componentProps}
          reference={kebabCase(refName)}
          filter={refFilter}
          ops={ops?.selection}
          source={`${componentProps.source}&&id`}
        >
          <SelectInput optionText={optionText} fullWidth />
        </ReferenceInput>
      );
    }

    return (
      <InputComponent
        {...componentProps}
        {...guessedProps}
        ops={ops?.[guessedProps.valueformat]}
      />
    );
  });
