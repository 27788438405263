import React, { useContext } from 'react';
import PropTypes from 'prop-types';

const LanguageContext = React.createContext(null);

export const useLanguageContext = () => useContext(LanguageContext);

export const LanguageContextProvider = ({ children }) => {
  const [language, setLanguage] = React.useState(null);
  const [languages, setLanguages] = React.useState({});

  return (
    <LanguageContext.Provider
      value={{
        language,
        setLanguage,
        languages,
        setLanguages,
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

LanguageContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

LanguageContextProvider.defaultProps = {};
