/* eslint-disable  no-underscore-dangle */
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  InputBase,
  LinearProgress,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { debounce, kebabCase, upperCase } from 'lodash';
import PropTypes from 'prop-types';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslate } from 'react-admin';
import { useNavigate } from 'react-router';
import Axios from '../../../services/provider/axios';
import { useAuthUser } from '../../hooks';
import useError from '../../hooks/useError';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import useResources from '../../hooks/useResources';
import RAButton from '../ra/buttons/Button';

const useStyles = makeStyles((theme) => ({
  dialogContent: { position: 'relative' },
  dialogContentElement: { marginBottom: theme.spacing(3) },
  dialogContentElementResult: {
    fontSize: '.9em',
    margin: '0 auto',
    padding: '36px 0',
    textAlign: 'center',
    width: '80%',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  search: {
    background: '#ebedf0',
    color: '#969faf',
    alignItems: 'center',
    border: 0,
    borderRadius: '40px',
    cursor: 'pointer',
    display: 'flex',
    fontWeight: 500,
    height: '36px',
    justifyContent: 'flex-start',
    padding: '0 8px',
    userSelect: 'none',
    position: 'sticky',
    top: '0px',
    boxShadow:
      'rgba(0, 0, 0, 0.02) 0px 2px 1px -1px, rgba(0, 0, 0, 0.04) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px',
    zIndex: 1,
  },
  placeHolder: {
    fontSize: '1rem',
    padding: '0 12px 0 6px',
  },
  searchIcon: {
    zIndex: 1,
    padding: theme.spacing(0, 2),
    height: '100%',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: '#969faf',
    background: 'transparent',
    padding: '4px',
    fontWeight: 500,
    flexGrow: 1,
  },
  inputInput: {
    fontSize: '16px',
    padding: theme.spacing(1, 1, 1, 0),
    transition: theme.transitions.create('width'),
    width: '100%',
  },
  hitList: {
    listStyleType: 'none',
  },
  hitSource: {
    color: '#5468ff',
    fontSize: '.85em',
    textTransform: 'capitalize',
    fontWeight: '600',
    lineHeight: '32px',
    margin: '0 -4px',
    padding: '8px 4px 0',
    top: 0,
    zIndex: 10,
  },
  hitItem: {
    borderRadius: '4px',
    display: 'flex',
    padding: '4px',
    position: 'relative',
    background: '#eaedf0',
    width: '100%',
    justifyContent: 'unset',
    color: '#656776',
    marginBottom: theme.spacing(2),
    '&:hover': {
      backgroundColor: '#5468ff33',
      // color: '#fff !important',
    },
  },
  hitItemIcon: {
    color: '#969faf',
    strokeWidth: 1.4,
  },
  hitItemAction: {},
  hitItemIdentifier: {
    fontWeight: 500,
    fontSize: '.9em',
    marginRight: theme.spacing(1),
    textTransform: 'lowercase',
  },
  hitItemHighlightKey: {},
  hitItemHighlight: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    flexGrow: 1,
    textAlign: 'left',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  hitItemContent: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    padding: '8px',
    alignItems: 'center',
  },
  hitItemUtilContent: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    padding: 0,
    alignItems: 'center',
  },
  hitItemUtilHighlight: {
    flexGrow: 1,
    textAlign: 'left',
    margin: 0,
    textTransform: 'none',
  },
  hitResourceName: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    borderRadius: theme.spacing(4),
    background: theme.palette.primary.main,
    color: '#fff',
    fontSize: '12px',
    fontWeight: 500,
    padding: '2px 5px',
    '&:hover': {
      background: theme.palette.primary.main,
    },
  },
}));

function SearchDialog(props) {
  const { open, close } = props;
  const translate = useTranslate();
  const { notifyError } = useError();
  const { storedValue: searchValue, setLocalStorage: setSearchValue } =
    useLocalStorage('search_value', '');
  const [loading, setLoading] = useState(false);
  const [resourceResults, setResourceResults] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const classes = useStyles();
  const cancelTokenSourceRef = useRef();
  const navigate = useNavigate();
  const resources = useResources();
  const [canSearch, setCanSearch] = useState(true);
  const user = useAuthUser();
  const inputRef = useRef();

  const searchableResources = useMemo(
    () =>
      (resources || []).map((resource) => ({
        name: resource.name,
        searchableName: `${resource.name.replace(
          /-/g,
          ' ',
        )}-${resource.name.replace(/-/g, '')}`,
      })),
    [resources],
  );

  const search = useCallback(
    debounce(async (searchText) => {
      const lowerSearchText = searchText.trim().toLowerCase();

      setLoading(true);
      setResourceResults(
        searchableResources
          ?.filter((r) => {
            const index = r.searchableName.indexOf(lowerSearchText);
            r.index = index;
            return index !== -1;
          })
          .sort((a, b) => a.index - b.index),
      );

      if (!canSearch) {
        setLoading(false);
        return;
      }

      cancelTokenSourceRef.current?.cancel();
      cancelTokenSourceRef.current = Axios.CancelToken.source();

      try {
        const getSearch = await Axios.get('/resource/search', {
          params: { query: searchText },
          cancelToken: cancelTokenSourceRef.current.token,
        });

        setSearchResults(getSearch?.data?.data || []);
      } catch (ex) {
        if (ex?.response?.status === 403) {
          setCanSearch(false);
        } else if (!Axios.isCancel(ex)) {
          notifyError(ex);
        }
      } finally {
        cancelTokenSourceRef.current = null;
        setLoading(false);
      }
    }, 300),
    [cancelTokenSourceRef, setSearchResults, setLoading, searchableResources],
  );

  useEffect(() => {
    if (open && searchValue.trim()) {
      search(searchValue);
    }
  }, [searchValue, open]);

  useEffect(() => {
    if (!open) inputRef.current = null;
  }, [open]);

  useEffect(() => {
    if (user?.setting?.lastSearchQuery) {
      setSearchValue(user.setting.lastSearchQuery);
    }
  }, [user?.setting?.lastSearchQuery]);

  const handleOnTextChange = (event) =>
    setSearchValue(event?.currentTarget?.value);

  const handleClickHitItem = (resourceName, item) => {
    close();
    if (item?.id) {
      if (resourceName === 'card' && item?.flow?.id) {
        navigate(`/flow/${item.flow.id}/show/${item.id}`);
      } else {
        navigate(`/${kebabCase(resourceName)}/${item.id}/show`);
      }
    } else {
      navigate(`/${kebabCase(resourceName)}`);
    }
  };

  const handlePassInputRef = (ele) => {
    if (ele && !inputRef.current) {
      inputRef.current = ele;
      ele.select();
    }
  };

  return (
    <Dialog
      fullWidth
      open={!!open}
      aria-labelledby="form-dialog-title"
      PaperProps={{ sx: { minHeight: '260px' } }}
      onClose={close}
    >
      <DialogContent className={classes.dialogContent}>
        {!!loading && (
          <LinearProgress
            sx={{
              position: 'absolute',
              top: '0px',
              left: '0px',
              zIndex: 1,
              width: '100%',
            }}
          />
        )}

        <div className={classes.search}>
          <div className={classes.searchIcon}>
            <svg
              width="20"
              height="20"
              className="DocSearch-Search-Icon"
              viewBox="0 0 20 20"
            >
              <path
                // eslint-disable-next-line
                d="M14.386 14.386l4.0877 4.0877-4.0877-4.0877c-2.9418 2.9419-7.7115 2.9419-10.6533 0-2.9419-2.9418-2.9419-7.7115 0-10.6533 2.9418-2.9419 7.7115-2.9419 10.6533 0 2.9419 2.9418 2.9419 7.7115 0 10.6533z"
                stroke="currentColor"
                fill="none"
                fillRule="evenodd"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <InputBase
            placeholder={translate('ra.text.searchPlaceholder')}
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            autoFocus
            inputRef={handlePassInputRef}
            value={searchValue}
            onChange={handleOnTextChange}
            inputProps={{ 'aria-label': 'search' }}
          />
        </div>

        {!!searchValue && resourceResults?.length > 0 && (
          <Box className={clsx(classes.dialogContentElement)}>
            <div className={classes.hitSource}>
              {translate('resources.resource.name')}
            </div>
            {resourceResults.map((r) => (
              <Button
                key={`search-resource-source-${r.name}`}
                className={classes.hitResourceName}
                onClick={() => handleClickHitItem(r.name)}
              >
                {r.name.replace(/-/g, ' ')}
              </Button>
            ))}
          </Box>
        )}

        <Box className={clsx(classes.dialogContentElement)}>
          <ul
            role="listbox"
            aria-labelledby="docsearch-label"
            className={classes.hitList}
          >
            {searchResults.map((item) => {
              if (item?.utilOperation) {
                return (
                  <li key={`operation-result-${item.id}`}>
                    <span className={classes.hitSource}>
                      {item.operation}
                      <Box className={classes.hitItemUtilContent}>
                        <Box className={classes.hitItemUtilHighlight}>
                          {item.output}
                        </Box>
                        <Box className={classes.hitItemAction}>
                          <svg
                            className="DocSearch-Hit-Select-Icon"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                          >
                            <g
                              stroke="currentColor"
                              fill="none"
                              fillRule="evenodd"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path d="M18 3v4c0 2-2 4-4 4H2" />
                              <path d="M8 17l-6-6 6-6" />
                            </g>
                          </svg>
                        </Box>
                      </Box>
                    </span>
                  </li>
                );
              }

              return (
                <li key={`search-result-${item._id}`}>
                  <span className={classes.hitSource}>
                    {`${item._index.substring(3)} #${item?._source?.id
                      ?.substring((item?._source?.id?.length || 0) - 4)
                      ?.toLowerCase()}`}
                  </span>
                  <Button
                    className={classes.hitItem}
                    onClick={() =>
                      handleClickHitItem(item._index.substring(3), item._source)
                    }
                  >
                    <Box className={classes.hitItemContent}>
                      <Box className={classes.hitItemHighlight}>
                        <Box className={classes.hitItemHighlightKey}>
                          {Object.keys(item.highlight || {})
                            ?.filter(
                              (highlightedKey) =>
                                !highlightedKey?.endsWith('.keyword'),
                            )
                            .map((highlightedKey) => (
                              <div key={highlightedKey}>
                                <strong>{upperCase(highlightedKey)}</strong>:{' '}
                                <span
                                  // eslint-disable-next-line react/no-danger
                                  dangerouslySetInnerHTML={{
                                    __html: item?.highlight[highlightedKey][0],
                                  }}
                                />
                              </div>
                            ))}
                        </Box>
                      </Box>
                      <Box className={classes.hitItemAction}>
                        <svg
                          className="DocSearch-Hit-Select-Icon"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                        >
                          <g
                            stroke="currentColor"
                            fill="none"
                            fillRule="evenodd"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path d="M18 3v4c0 2-2 4-4 4H2" />
                            <path d="M8 17l-6-6 6-6" />
                          </g>
                        </svg>
                      </Box>
                    </Box>
                  </Button>
                </li>
              );
            })}
          </ul>

          {searchValue &&
            !loading &&
            !searchResults?.length &&
            !resourceResults?.length && (
              <p className={classes.dialogContentElementResult}>
                {translate('nf.search.noResults', {
                  key: searchValue,
                })}
              </p>
            )}
          {!searchValue && !loading && !searchResults?.length && (
            <p className={classes.dialogContentElementResult}>
              {translate('nf.search.noRecentSearch')}
            </p>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <RAButton
          label={translate('ra.action.close')}
          onClick={close}
          variant="text"
          color="primary"
          size="small"
        />
      </DialogActions>
    </Dialog>
  );
}

SearchDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
};

SearchDialog.defaultProps = {};

export default SearchDialog;
