import { useMediaQuery } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocaleState } from 'react-admin';
import { toggleNavCollapsed } from '../../../services/redux/setting/setting.actions';
import { withShortcut } from '../../hoc/with-shortcut';
import { useAuthUser, useClickOutside } from '../../hooks';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import useResources from '../../hooks/useResources';
import FeedBackForm from './feedback';
import OwnerChangePasswordForm from '../change-password/owner-change-password';
import useSite from '../../hooks/useSite';

const Layout = (props) => {
  const { children } = props;
  const dispatch = useDispatch();
  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down('lg'));
  useResources();
  useSite();

  const setSidebarOpen = (isCollapsed) => {
    dispatch(toggleNavCollapsed(isCollapsed));
  };

  const { storedValue } = useLocalStorage('nfLocale');
  const [, setLocale] = useLocaleState();

  // Close sidebar when click outside on small screen
  const clickRef = React.useRef();
  useClickOutside(clickRef, () => {
    if (smallScreen) {
      setSidebarOpen(false);
    }
  });

  useEffect(() => {
    setLocale(storedValue || 'en');
  }, []);

  useEffect(() => {
    setSidebarOpen(!smallScreen);
  }, [smallScreen]);
  const [open, setOpen] = useState(false);
  const user = useAuthUser();

  useEffect(() => {
    setOpen(user?.requireChangePassword);
  }, [user?.requireChangePassword]);

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <OwnerChangePasswordForm open={open} close={handleClose} record={user} />
      <FeedBackForm />
      {children}
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
};

Layout.defaultProps = {
  children: <div />,
};

export default withShortcut(Layout);
