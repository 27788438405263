import React, { useMemo } from 'react';
import AccountCircleSharpIcon from '@mui/icons-material/AccountCircleSharp';
import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import { useLocaleState, useTranslate } from 'react-admin';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  title: {
    fontSize: '13px',
    fontWeight: 700,
    lineHeight: '17px',
    letterSpacing: '0em',
    color: '#000000',
  },
  content: {
    fontSize: '8px',
    fontWeight: 400,
    lineHeight: '8px',
    letterSpacing: '0em',
    color: '#000000',
    overflow: 'hidden',
    height: '8px',
  },
  time: {
    fontSize: '7px',
    fontWeight: 400,
    lineHeight: '7px',
    letterSpacing: '0em',
    color: '#B0AFAF',
    marginLeft: '1em',
    flexGrow: 1,
    whiteSpace: 'nowrap',
  },
  iconUser: {
    fontSize: '42px',
    color: '#cdcece',
  },
  countInvisible: {
    minWidth: '20px',
    height: '20px',
    backgroundColor: '#E00930',
    color: '#ffffff',
    padding: '4px',
    borderRadius: '10px',
    lineHeight: '12px',
    fontSize: '12px',
    position: 'absolute',
    right: '8px',
    top: '8px',
    textAlign: 'center',
  },
});

const NotificationGroupItem = ({ countInvisible, data, active, ...rest }) => {
  const classes = useStyles();
  const [locale] = useLocaleState();
  const translate = useTranslate();

  const { time, content, title } = useMemo(() => {
    const result = {
      time: data?.created
        ? moment(data?.created)
            .fromNow()
            .replace('a few seconds ago', 'just now')
        : null,
      title: translate('resources.inbox.name'),
    };

    result.content = data?.message?.[locale] || data?.message;

    return result;
  }, [data, locale]);

  return (
    <Stack
      position="relative"
      direction="row"
      spacing="7px"
      padding="4px 9px"
      alignItems="center !important"
      bgcolor={active && '#fff'}
      sx={{
        '&:hover': { backgroundColor: '#fff', cursor: 'pointer' },
        ...rest.sx,
      }}
      {...rest}
    >
      <AccountCircleSharpIcon className={classes.iconUser} />
      <Box>
        <Typography className={classes.title}>{title}</Typography>
        <Stack direction="row" alignItems="flex-end !important">
          <Typography
            className={classes.content}
            dangerouslySetInnerHTML={{ __html: content }}
            component="span"
          />
          <Typography component="span" className={classes.time}>
            {time}
          </Typography>
        </Stack>
      </Box>
      {!!countInvisible && (
        <Box className={classes.countInvisible}>
          {countInvisible > 99 ? '99+' : countInvisible}
        </Box>
      )}
    </Stack>
  );
};

export default NotificationGroupItem;
