import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Axios from '../../services/provider/axios';
import { GET_COUNTRIES_SUCCESS } from '../../services/redux/root.actions';

const useCountries = () => {
  const refFetch = useRef();
  const dispatch = useDispatch();
  const countries = useSelector(({ setting }) => setting.countries);

  const fetchCountries = async () => {
    if (countries.length === 0 || !refFetch.current) {
      try {
        const { data } = await Axios.get('/country');

        dispatch({
          type: GET_COUNTRIES_SUCCESS,
          countries: data,
        });

        refFetch.current = true;
      } catch {
        //
      }
    }
  };

  useEffect(() => {
    fetchCountries();
  }, []);

  return countries;
};

export default useCountries;
