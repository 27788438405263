import { Box } from '@mui/material';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import {
  useSelectEvent,
  useSelectEventBycode,
  useSelectReservationBySessionId,
} from '../../api/features/common.feature';
import Image from '../image';
import useStyles from './index.style';

const BookingHeader = ({ className }) => {
  const classes = useStyles();
  const { data: eventInfo } = useSelectEvent();
  const { data: reservation } = useSelectReservationBySessionId();
  const { data: eventInfoByCode } = useSelectEventBycode();

  const code =
    reservation?.eventReservation?.event?.code ||
    eventInfo?.code ||
    eventInfoByCode?.code;
  const imageUrl =
    eventInfo?.logo?.presignedUrl ||
    reservation?.eventReservation?.event?.logo?.presignedUrl ||
    eventInfoByCode?.logo?.presignedUrl;
  const url = `#/p/event/c/${code}`;

  return (
    <Box className={clsx(classes.header, className)}>
      {code ? (
        <a href={url}>
          <Image className={classes.logo} src={imageUrl} />
        </a>
      ) : (
        <Image className={classes.logo} src={imageUrl} />
      )}
    </Box>
  );
};

BookingHeader.propTypes = {
  className: PropTypes.string,
};

BookingHeader.defaultProps = {
  className: '',
};

export default BookingHeader;
