import { JOB_TYPE_ACTIONS } from '../actions';

const initialStates = {
  selectedJobType: null,
  selectedSubJobType: null,
};

const jobTypeReducer = (state = initialStates, action) => {
  switch (action.type) {
    case JOB_TYPE_ACTIONS.SET_SELECTED_JOB_TYPE: {
      return {
        ...state,
        selectedJobType: action.payload,
      };
    }
    case JOB_TYPE_ACTIONS.SET_SELECTED_SUB_JOB_TYPE: {
      return {
        ...state,
        selectedSubJobType: action.payload,
      };
    }
    default:
      return state;
  }
};

export const getSelectedJobType = (state) => state?.jobType?.selectedJobType || null;
export const getSelectedSubJobType = (state) => state?.jobType?.selectedSubJobType || null;

export default jobTypeReducer;
