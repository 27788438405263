/* eslint-disable react/prop-types */
import Tooltip from '@mui/material/Tooltip';
import React from 'react';

const TooltipWrapper = React.forwardRef(({ children, ...props }, ref) => (
  <span ref={ref} {...props}>
    {children}
  </span>
));

const CustomTooltip = React.forwardRef(
  ({ children, wrapperProps, ...props }, ref) => (
    <Tooltip {...props} ref={ref}>
      <TooltipWrapper {...wrapperProps}>{children}</TooltipWrapper>
    </Tooltip>
  ),
);

export default CustomTooltip;
