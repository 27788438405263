// NF SCHEMA
export const PULL_NF_SCHEMA = 'PULL_NF_SCHEMA';
export const PULL_NF_SCHEMA_SUCCESS = 'PULL_NF_SCHEMA_SUCCESS';

// NF SCHEMA
export const PULL_NF_RESOURCE = 'PULL_NF_RESOURCE';
export const PULL_NF_RESOURCE_SUCCESS = 'PULL_NF_RESOURCE_SUCCESS';

// NAV
export const TOGGLE_NAV_COLLAPSED = 'toggle_nav_collapsed';

// PAYMENT
export const TOPUP_PGC = 'TOPUP_PGC';

// AUTH
export const GET_AUTH_INFO = 'GET_AUTH_INFO';
export const GET_AUTH_INFO_SUCCESS = 'GET_AUTH_INFO_SUCCESS';
export const GET_AUTH_INFO_FAILED = 'GET_AUTH_INFO_FAILED';
export const FORCE_AUTH_INFO = 'FORCE_AUTH_INFO';
export const CLEAR_STATE = 'CLEAR_STATE';
export const UPDATE_USER_INFO = 'UPDATE_USER_INFO';

// ANNOUNCEMENT
export const GET_ANNOUNCEMENTS = 'GET_ANNOUNCEMENTS';
export const GET_ANNOUNCEMENTS_SUCCESS = 'GET_ANNOUNCEMENTS_SUCCESS';
export const GET_ANNOUNCEMENTS_FAILED = 'GET_ANNOUNCEMENTS_FAILED';

// SETTING
export const SETTINGS = 'SETTINGS';

export const GET_COUNTRIES_SUCCESS = 'GET_COUNTRIES_SUCCESS';

// APP
export const TOGGLE_LOADING = 'TOGGLE_LOADING';
export const SET_PERCENT_LOADING = 'SET_PERCENT_LOADING';
export const TOGGLE_FEEDBACK = 'TOGGLE_FEEDBACK';

// REACT-ADMIN
export const RA_CRUD_GET_LIST_SUCCESS = 'RA/CRUD_GET_LIST_SUCCESS';
