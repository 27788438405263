import {
  CLEAR_STATE,
  FORCE_AUTH_INFO,
  GET_AUTH_INFO,
  GET_AUTH_INFO_SUCCESS,
  UPDATE_USER_INFO,
} from '../root.actions';

export const getAuthInfo = () => ({
  type: GET_AUTH_INFO,
});

export const setAuthInfo = (user) => ({
  type: GET_AUTH_INFO_SUCCESS,
  payload: {
    user,
  },
});

export const updateUserInfo = (user) => ({
  type: UPDATE_USER_INFO,
  payload: {
    user,
  },
});

export const setForceAuthInfo = (forceUpdate) => ({
  type: FORCE_AUTH_INFO,
  payload: {
    forceUpdate,
  },
});

export const clearState = () => ({
  type: CLEAR_STATE,
});
