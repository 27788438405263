import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import { Button } from '@mui/material';
import { LocalizationProvider, TimeClock } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { getSafeValue } from '../../../helpers';
import BookingBottomSheet from '../../bottom-sheet';
import useStyles from './index.style';

const TimeInput = ({
  inputBaseOnButtonClassName,
  onChange,
  label,
  defaultInputLabel,
  timeClockProps,
  fieldName,
  disableSetFormValue,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [view, setView] = useState('hours');
  const [localValue, setLocalValue] = useState(defaultInputLabel);
  const [timeLockKey, setTimeLockKey] = useState(1);

  const { setValue, getValues } = useFormContext();

  const value = disableSetFormValue
    ? localValue
    : getSafeValue(getValues(), fieldName, defaultInputLabel);

  const valueIsValid = moment(value, 'HH:ss').isValid();

  const handleTextFieldClick = () => {
    setOpen(true);
  };

  const onClose = useCallback(() => {
    setOpen(false);
    setView('hours');
  }, []);

  const onTimeChange = (newValue, selectionState) => {
    const time = moment(newValue).format('HH:mm');
    onChange?.({ value: time });

    if (!disableSetFormValue) setValue(fieldName, time);
    else setLocalValue(time);

    if (selectionState === 'finish') {
      setOpen(false);
      setView('hours');
    }
  };

  const onViewChange = (currentView) => {
    setView(currentView);
  };

  useEffect(() => {
    setLocalValue(defaultInputLabel);
  }, [defaultInputLabel]);

  useEffect(() => {
    if (!valueIsValid) {
      setTimeLockKey(moment().unix());
    }
  }, [valueIsValid]);

  return (
    <>
      <Button
        startIcon={<AccessTimeRoundedIcon />}
        onClick={handleTextFieldClick}
        className={inputBaseOnButtonClassName}
      >
        {value}
      </Button>

      <BookingBottomSheet open={open} onClose={onClose} title={label}>
        <LocalizationProvider adapterLocale="en-gb" dateAdapter={AdapterMoment}>
          <TimeClock
            key={timeLockKey}
            {...timeClockProps}
            className={classes.time}
            ampm={false}
            view={view}
            views={['hours', 'minutes']}
            onChange={onTimeChange}
            onViewChange={onViewChange}
            {...(valueIsValid && { value })}
          />
        </LocalizationProvider>
      </BookingBottomSheet>
    </>
  );
};

TimeInput.propTypes = {
  fieldName: PropTypes.string,
  inputBaseOnButtonClassName: PropTypes.string,
  label: PropTypes.string,
  defaultInputLabel: PropTypes.string,
  onChange: PropTypes.func,
  timeClockProps: PropTypes.object,
  disableSetFormValue: PropTypes.bool,
};

TimeInput.defaultProps = {
  fieldName: null,
  inputBaseOnButtonClassName: null,
  label: null,
  defaultInputLabel: 'Now',
  onChange: undefined,
  timeClockProps: {},
  disableSetFormValue: false,
};

export default memo(TimeInput);
