import CssBaseline from '@mui/material/CssBaseline';
import jssPreset from '@mui/styles/jssPreset';
import StylesProvider from '@mui/styles/StylesProvider';
import { create } from 'jss';
import rtl from 'jss-rtl';
import React, { useContext, useEffect } from 'react';
import ThemeProvider from '../../../theme/themeProvider';
import AppLayout from '../AppLayout';
import AppContext from '../contextProvider/AppContextProvider/AppContext';

// Configure JSS
const jss = create({
  plugins: [...jssPreset().plugins, rtl()],
  id: {
    minify: false,
  },
});

const AppWrapper = ({ children }) => {
  const { theme, direction } = useContext(AppContext);
  useEffect(() => {
    if (direction === 'rtl') {
      document.body.setAttribute('dir', 'rtl');
    } else {
      document.body.setAttribute('dir', 'ltr');
    }
  }, [direction]);

  return (
    <ThemeProvider>
      <StylesProvider jss={jss}>
        <CssBaseline />
        <AppLayout>{children}</AppLayout>
      </StylesProvider>
    </ThemeProvider>
  );
};

export default AppWrapper;
