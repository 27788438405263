export const ops = {
  number: [
    {
      id: '$eq',
      name: 'equal',
    },
    {
      id: '$ne',
      name: 'not equal',
    },
    {
      id: '$gt',
      name: 'greater than',
    },
    {
      id: '$lt',
      name: 'less than',
    },
    {
      id: '$gte',
      name: 'greater than or equal',
    },
    {
      id: '$lte',
      name: 'less than or equal',
    },
    {
      id: '$isnull',
      name: 'is null',
    },
    {
      id: '$notnull',
      name: 'is not null',
    },
  ],
  string: [
    {
      id: '$cont',
      name: 'contains',
    },
    {
      id: '$starts',
      name: 'starts with',
    },
    {
      id: '$ends',
      name: 'ends with',
    },
    {
      id: '$excl',
      name: 'excludes',
    },
    {
      id: '$is',
      name: 'exact',
    },
    {
      id: '$isnull',
      name: 'is null',
    },
    {
      id: '$notnull',
      name: 'is not null',
    },
  ],
  id: [
    {
      id: '$starts',
      name: 'starts with',
    },
    {
      id: '$ends',
      name: 'ends with',
    },
    {
      id: '$cont',
      name: 'contains',
    },
    {
      id: '$excl',
      name: 'excludes',
    },
    {
      id: '$is',
      name: 'exact',
    },
    {
      id: '$isnull',
      name: 'is null',
    },
    {
      id: '$notnull',
      name: 'is not null',
    },
  ],
  date: [
    {
      id: '$on',
      name: 'on',
    },
    // {
    //   id: '$non',
    //   name: 'not on',
    // },
    {
      id: '$gt',
      name: 'after',
    },
    {
      id: '$lt',
      name: 'before',
    },
    {
      id: '$gte',
      name: 'on or before',
    },
    {
      id: '$lte',
      name: 'on or after',
    },
    {
      id: '$between',
      name: 'between',
    },
    // {
    //   id: '$notbetween',
    //   name: 'not between',
    // },
    {
      id: '$isnull',
      name: 'is null',
    },
    {
      id: '$notnull',
      name: 'is not null',
    },
  ],
  selection: [
    {
      id: '$is',
      name: 'is',
    },
    {
      id: '$isnot',
      name: 'is not',
    },
    {
      id: '$isnull',
      name: 'is null',
    },
    {
      id: '$notnull',
      name: 'is not null',
    },
  ],
  boolean: [
    {
      id: '$eq',
      name: 'equal',
    },
    {
      id: '$ne',
      name: 'not equal',
    },
    {
      id: '$isnull',
      name: 'is null',
    },
    {
      id: '$notnull',
      name: 'is not null',
    },
  ],

  phone: [
    {
      id: '$cont',
      name: 'contains',
    },
    {
      id: '$eq',
      name: 'equal',
    },
    {
      id: '$starts',
      name: 'starts with',
    },
    {
      id: '$ends',
      name: 'ends with',
    },
  ],
  array: [
    {
      id: '$cont',
      name: 'contains',
    },
    {
      id: '$isnull',
      name: 'is null',
    },
    {
      id: '$notnull',
      name: 'is not null',
    },
    {
      id: '$starts',
      name: 'starts with',
    },
    {
      id: '$ends',
      name: 'ends with',
    },
  ],
};
