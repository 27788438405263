/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { Box, Card, IconButton, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import clsx from 'clsx';
import { kebabCase } from 'lodash';
import { useTranslate } from 'react-admin';
import PrintFormatSectionField from './index.section.field';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(2),
    backgroundColor: '#00000005',
  },
  input: {
    width: 'auto',
    margin: theme.spacing(2),
  },
  dragging: {
    userSelect: 'none',
    background: theme.palette.secondary.light,
  },
}));

const selectedFieldDroppableId = 'selected-fields';

const PrintFormatSection = ({
  draggableId,
  section,
  index,
  onChange,
  onRemove,
}) => {
  const classes = useStyles();
  const translate = useTranslate();

  return (
    <Draggable
      draggableId={draggableId}
      index={index}
    >
      {(provided, snapshot) => (
        <Card
          ref={provided.innerRef}
          {...provided.draggableProps}
          variant="outlined"
          style={provided.draggableProps.style}
          className={clsx(
            classes.root,
            snapshot.isDragging && classes.dragging,
          )}
        >
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            p={2}
          >
            <IconButton
              size="small"
              {...provided.dragHandleProps}
            >
              <DragIndicatorIcon fontSize="small" />
            </IconButton>
            <Box
              flex={1}
              display="flex"
              flexDirection="column"
              alignItems="stretch"
            >
              <TextField
                className={classes.input}
                value={section.name}
                label={`${translate('resources.printFormat.fields.sectionLabel')} (${kebabCase(section.name)})`}
                onChange={event => onChange?.({
                  section,
                  value: event.target.value,
                  index,
                  property: 'name',
                })}
              />
              <TextField
                className={classes.input}
                value={section.col}
                label={`${translate('resources.printFormat.fields.totalColumns')} [1-10]`}
                onChange={event => onChange?.({
                  section,
                  value: event.target.value,
                  index,
                  property: 'col',
                })}
                type="number"
              />
            </Box>
            <IconButton
              size="small"
              onClick={() => onRemove?.({
                section,
                index,
              })}
            >
              <HighlightOffIcon fontSize="small" />
            </IconButton>
          </Box>
          <Droppable
            droppableId={`${selectedFieldDroppableId}-${index}`}
            type={`${section.name}-${index}`}
          >
            {provided => (
              <Box
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {section.items?.map((field, fieldIndex) => (
                  <PrintFormatSectionField
                    key={`selected-section-fields-${section.name}-${field.name}-${fieldIndex}`}
                    draggableId={`${selectedFieldDroppableId}-${section.name}-${field.name}-${fieldIndex}`}
                    field={field}
                    index={fieldIndex}
                    onChange={event => onChange?.({
                      ...event,
                      section,
                      sectionIndex: index,
                    })}
                    onRemove={event => onRemove?.({
                      ...event,
                      section,
                      sectionIndex: index,
                    })}
                  />
                ))}
                {provided.placeholder}
              </Box>
            )}
          </Droppable>
        </Card>
      )}
    </Draggable>
  );
};

PrintFormatSection.propTypes = {
  section: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  draggableId: PropTypes.string.isRequired,
};

PrintFormatSection.defaultProps = {};

export default PrintFormatSection;
