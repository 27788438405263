import React, { memo, useEffect, useMemo, useState } from 'react';
import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';
import { useTranslate } from 'react-admin';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
  pageHeaderRoot: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  titleRoot: {
    flex: 1,
    fontSize: '20px',
    fontWeight: 'bold',
    lineHeight: '1.167',
    paddingRight: theme.spacing(1),
    [theme.breakpoints.up(960)]: {
      fontSize: '22px',
    },
    '& span': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitLineClamp: 1,
      WebkitBoxOrient: 'vertical',
    },
  },
  invisible: {
    display: 'none',
  },
}));

const PageHeader = ({ breadcrumbComponent, children, ...rest }) => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const t = useTranslate();
  const [headerTitleVisible, setHeaderTitleVisible] = useState();

  const specialRoutes = [].map((x) => x.path);
  const defaultHeaderTitle = useMemo(() => {
    const paths = _.without(pathname?.split('/') || [], '');
    return paths?.length && specialRoutes.includes(paths[0])
      ? t(`ra.page.${paths[0]}`)
      : '';
  }, [pathname, specialRoutes]);

  // trim header title (uuid -> 4 last chars)
  const titleElement = document.querySelector('#react-admin-title span');
  if (titleElement) {
    const parts = titleElement?.textContent?.split(' ');
    if (parts?.length && !headerTitleVisible) {
      // title is available
      if (parts?.length > 1) {
        const uuidRegex =
          /\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b/;
        if (uuidRegex.test(parts[1])) {
          parts[1] = `#${parts[1].slice(parts[1].length - 6)}`;
          titleElement.textContent = parts.join(' ');
        }
      }
      setHeaderTitleVisible(true);
    }
  }

  useEffect(() => {
    setHeaderTitleVisible(false); // reset flag to enable to trim new title
  }, [pathname]);

  return (
    <Box
      className={clsx(classes.pageHeaderRoot, 'page-header')}
      mt={{
        xs: 4,
        md: 5,
        lg: 6,
      }}
      mb={{
        xs: 4,
        md: 5,
        lg: 6,
      }}
      {...rest}
    >
      {!defaultHeaderTitle ? (
        <Typography
          component="div"
          variant="h1"
          id="react-admin-title"
          className={clsx(
            classes.titleRoot,
            'title',
            !headerTitleVisible && classes.invisible,
          )}
        />
      ) : (
        <div
          className={clsx(
            classes.titleRoot,
            'title',
            !headerTitleVisible && classes.invisible,
          )}
        >
          {defaultHeaderTitle}
        </div>
      )}

      <Box
        flex={1}
        pl={1}
        display="flex"
        flexDirection="row"
        justifyContent="flex-end"
      >
        {breadcrumbComponent}
      </Box>

      {children}
    </Box>
  );
};

export default memo(PageHeader);
