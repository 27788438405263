import { Divider } from '@mui/material';
import {
  CheckboxGroupInput,
  DateTimeInput,
  email,
  RadioButtonGroupInput,
} from 'react-admin';
import MethodTypeInput from '../methodType.input';
import PromotionCurrenciesInput from '../promotionCurrencies.input';
import {
  BooleanField,
  ChipField,
  ColorField,
  CurrencyField,
  DateField,
  DocStatusField,
  EmailField,
  FileField,
  IdField,
  LinkField,
  MaskField,
  NumberField,
  QrField,
  TextField,
  TipTapField,
  UrlField,
  LocationField,
} from '../ra/fields';
import CoordinateField from '../ra/fields/coordinate.field';
import {
  AutocompleteInput,
  BooleanInput,
  ColorInput,
  DateInput,
  NumberInput,
  SelectArrayInput,
  SelectInput,
  TextInput,
  TipTapInput,
  TranslatableInputs,
} from '../ra/inputs';
import AutocompleteFreeSoloInput from '../ra/inputs/autocomplete/freesolo';
import CoordinateInput from '../ra/inputs/coordinate.input';
import PhoneInput from '../ra/inputs/phone.input';
import PointInput from '../ra/inputs/point.input';
import RandomCodeInput from '../ra/inputs/ramdomCode.input';
import { isEmail } from '../ra/validate';
import DateRangeInput from './date-range-input';

export const guessFieldComponent = (schema) => {
  let PropComponent = TextField;
  const guessedPropsComponent = {};
  // Get field format
  const fieldFormat = schema?.format?.includes('||')
    ? schema?.format?.split('||')[0]
    : schema?.format;

  switch (schema?.type) {
    case 'boolean':
      PropComponent = BooleanField;
      break;
    case 'mask':
      PropComponent = MaskField;
      break;
    case 'string':
      switch (fieldFormat) {
        case 'date':
        case 'datetime-local':
        case 'duedate':
        case 'date-only':
          PropComponent = DateField;
          guessedPropsComponent.showTime = !['date-only', 'date'].includes(
            fieldFormat,
          );
          break;
        case 'email':
          PropComponent = EmailField;
          break;
        case 'ip':
        case 'chip':
        case 'status':
          PropComponent = ChipField;
          break;
        case 'image':
          PropComponent = FileField;
          guessedPropsComponent.valueType = 'image';
          break;
        case 'html':
          PropComponent = TipTapField;
          break;
        case 'id':
          PropComponent = IdField;
          break;
        case 'color':
          PropComponent = ColorField;
          break;
        case 'url':
          PropComponent = UrlField;
          guessedPropsComponent.target = '_blank';
          break;
        case 'coordinate':
          PropComponent = CoordinateField;
          break;
        case 'qrString':
          PropComponent = QrField;
          guessedPropsComponent.sourceSchema = schema;
          break;
        case 'location':
          PropComponent = LocationField;
          break;
        default:
          PropComponent = TextField;
          break;
      }
      break;
    case 'number':
      switch (fieldFormat) {
        case 'currency':
          PropComponent = CurrencyField;
          break;
        default:
          PropComponent = NumberField;
          break;
      }
      break;
    case 'section-break':
      PropComponent = Divider;
      break;
    default:
      PropComponent = TextField;
      break;
  }

  if (schema.format === 'link') {
    PropComponent = LinkField;
  }

  if (schema.sourceName === 'docStatus') {
    PropComponent = DocStatusField;
  }

  return {
    PropComponent,
    guessedPropsComponent,
  };
};

export const guessInputComponent = (
  schema,
  view = 'create',
  isFilter = false,
  withHoc = null,
) => {
  let InputComponent = TextInput;
  let WrapperComponent = null;
  const guessedProps = {
    ...schema?.options,
    valueformat: schema?.type,
    variant: 'outlined',
  };
  // Get input format
  const inputFormat = schema?.format?.includes('||')
    ? schema?.format?.split('||')[1]
    : schema?.format;

  // TRANSLATABLE INPUTS
  if (schema?.properties?.translatable?.default) {
    WrapperComponent = TranslatableInputs;
  }

  if (view === 'create') {
    guessedProps.defaultValue = schema?.default;
  }

  switch (schema?.type) {
    case 'boolean':
      InputComponent = BooleanInput;
      break;
    case 'string':
      if (inputFormat) {
        guessedProps.valueformat = inputFormat;
      }

      if (inputFormat === 'email') {
        guessedProps.validate = [email(), isEmail()];
        guessedProps.type = 'email';
        guessedProps.valueformat = 'string';
      }

      if (inputFormat === 'password') {
        guessedProps.type = 'password';
      }

      if (inputFormat === 'color') {
        InputComponent = ColorInput;
      }

      if (inputFormat === 'date') {
        InputComponent = DateInput;
        guessedProps.valueformat = 'date';
      }

      if (inputFormat === 'phone') {
        InputComponent = PhoneInput;
      }

      if (['datetime-local', 'date-time', 'date-only'].includes(inputFormat)) {
        guessedProps.options = {
          inputProps: {
            min: '2000-06-14T00:00',
            max: '2060-06-14T00:00',
          },
        };
        InputComponent = isFilter ? DateInput : DateTimeInput;
        guessedProps.valueformat = 'date';
      }

      if (inputFormat === 'date-range') {
        InputComponent = DateRangeInput;
        guessedProps.valueformat = 'date';
      }

      if (inputFormat === 'multiline') {
        guessedProps.multiline = true;
        guessedProps.rows = 5;
      }
      if (inputFormat === 'html') {
        InputComponent = TipTapInput;
        guessedProps.multiline = true;
        guessedProps.rows = 5;
      }
      // COORDINATE
      if (inputFormat === 'coordinate') {
        InputComponent = CoordinateInput;
      }

      if (inputFormat === 'location' || inputFormat === 'location-select') {
        InputComponent = PointInput;
      }

      if (inputFormat === 'random-code') {
        InputComponent = RandomCodeInput;
      }

      if (['list-freesolo', 'list-freesolo-editable'].includes(inputFormat)) {
        InputComponent = AutocompleteFreeSoloInput;
        if (inputFormat === 'list-freesolo-editable') {
          guessedProps.editable = true;
        }
      }

      if (inputFormat === 'dropdown') {
        InputComponent = AutocompleteInput;
        guessedProps.choices = schema?.dropdown?.map((i) => ({
          id: i.id || i,
          name: i.name || i.label || i,
        }));
      }

      if (schema?.enum) {
        guessedProps.valueformat = 'selection';
        InputComponent = SelectInput;
        if (inputFormat === 'multiple-choices') {
          InputComponent = SelectArrayInput;
        }
        guessedProps.choices = schema.enum.map((i) => ({
          id: i.id || i,
          name: i.name || i.label || i,
        }));
        // RADIO GROUP
        if (inputFormat === 'radio-group') {
          InputComponent = RadioButtonGroupInput;
          guessedProps.choices = schema.enum.map((i) => ({
            id: i.id || i,
            name: i.name || i.label || i,
          }));
        }
        // CHECKBOX GROUP
        if (inputFormat === 'checkbox-group') {
          InputComponent = CheckboxGroupInput;
          guessedProps.choices = schema.enum.map((i) => ({
            id: i.id || i,
            name: i.name || i.label || i,
          }));
        }
        // METHOD TYPE
        if (inputFormat === 'promotion-method-type') {
          InputComponent = MethodTypeInput;
        }
      }
      break;

    case 'number':
      InputComponent = NumberInput;
      break;

    case 'array':
      if (schema?.items?.format === 'promotion-currency') {
        InputComponent = PromotionCurrenciesInput;
      }
      break;
    default:
      break;
  }

  const InputComponentWithHOC = withHoc
    ? withHoc(InputComponent)
    : InputComponent;

  return {
    InputComponent: InputComponentWithHOC,
    guessedProps,
    WrapperComponent,
  };
};
