import axios from 'axios';
import configs from '../../../../../../services/configs';

const instance = axios.create({
  baseURL: `${configs.apiUrl}/annm`,
  headers: {
    nfParty: 'ANNM',
  },
  timeout: 10000,
});

export default instance;
