/* eslint-disable */
/* eslint-disable no-plusplus */
/* eslint-disable no-bitwise */
/* eslint-disable no-restricted-syntax */
import { useCallback } from 'react';

const RADII = {
  km: 6371,
  mile: 3960,
  meter: 6371000,
  nmi: 3440,
};

// convert to radians
const toRad = function (num) {
  return (num * Math.PI) / 180;
};

// convert coordinates to standard format based on the passed format option
const convertCoordinates = function (format, coordinates) {
  switch (format) {
    case '[lat,lon]':
      return { latitude: coordinates[0], longitude: coordinates[1] };
    case '[lon,lat]':
      return { latitude: coordinates[1], longitude: coordinates[0] };
    case '{lon,lat}':
      return { latitude: coordinates.lat, longitude: coordinates.lon };
    case '{lat,lng}':
      return { latitude: coordinates.lat, longitude: coordinates.lng };
    case 'geojson':
      return {
        latitude: coordinates.geometry.coordinates[1],
        longitude: coordinates.geometry.coordinates[0],
      };
    case '{id,location}':
      return {
        latitude: coordinates.lat ? coordinates.lat : coordinates.location.coordinateLatitude,
        longitude: coordinates.lng ? coordinates.lng : coordinates.location.coordinateLongitude
      }
    default:
      return coordinates;
  }
};

export const haversine = (startCoordinates, endCoordinates, options) => {
  options = options || {};

  var R = options.unit in RADII ? RADII[options.unit] : RADII.km;

  var start = convertCoordinates(options.format, startCoordinates);
  var end = convertCoordinates(options.format, endCoordinates);

  var dLat = toRad(end.latitude - start.latitude);
  var dLon = toRad(end.longitude - start.longitude);
  var lat1 = toRad(start.latitude);
  var lat2 = toRad(end.latitude);

  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  if (options.threshold) {
    return options.threshold > R * c;
  }

  return R * c;
};

const useMap = () => {
  const decode = useCallback((t) => {
    const points = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const step of t) {
      const encoded = step.polyline.points;
      let index = 0;
      const len = encoded.length;
      let lat = 0;
      let lng = 0;
      while (index < len) {
        let b;
        let shift = 0;
        let result = 0;
        do {
          b = encoded.charAt(index++).charCodeAt(0) - 63;
          result |= (b & 0x1f) << shift;
          shift += 5;
        } while (b >= 0x20);

        const dlat = (result & 1) != 0 ? ~(result >> 1) : result >> 1;
        lat += dlat;
        shift = 0;
        result = 0;
        do {
          b = encoded.charAt(index++).charCodeAt(0) - 63;
          result |= (b & 0x1f) << shift;
          shift += 5;
        } while (b >= 0x20);
        const dlng = (result & 1) != 0 ? ~(result >> 1) : result >> 1;
        lng += dlng;

        points.push({ latitude: lat / 1e5, longitude: lng / 1e5 });
      }
    }
    return points;
  }, []);

  const getCoordinatesFromRoute = useCallback(
    (route, pickupCoordinate = undefined, dropoffCoordinate = undefined) => {
      if (!route) return [];

      const results = route?.legs?.reduce((carry, curr) => {
        return [...carry, ...decode(curr.steps)];
      }, []);
      if (pickupCoordinate) {
        results.unshift(pickupCoordinate);
      }
      if (dropoffCoordinate) {
        results.push(dropoffCoordinate);
      }

      return results;
    },
    [decode],
  );

  const getCoordinatesForDotDirections = useCallback(
    (route, zoomLevel) => {
      // zoom level 15 -> 80 meters
      // zoom level 12 -> 800 meters
      let threshold = 0;
      if (zoomLevel < 12) {
        threshold = 800;
      } else if (zoomLevel > 15) {
        threshold = 80;
      } else {
        threshold = 800 - (700 / 3) * (zoomLevel - 12);
      }

      const results = [];

      const coordinates = getCoordinatesFromRoute(route);
      let lastCoord;
      for (let index = 0; index < coordinates.length; ) {
        const coord = coordinates[index];
        const distance = haversine(
          lastCoord || { latitude: 0, longitude: 0 },
          coord,
          { unit: 'meter' },
        );
        if (distance > threshold) {
          if (lastCoord) {
            const latOffset =
              (threshold * (coord.latitude - lastCoord.latitude)) / distance;
            const lonOffset =
              (threshold * (coord.longitude - lastCoord.longitude)) / distance;
            const newCoord = {
              latitude: lastCoord.latitude + latOffset,
              longitude: lastCoord.longitude + lonOffset,
            };
            results.push(newCoord);
            lastCoord = newCoord;
          } else {
            results.push(coord);
            lastCoord = coord;
          }
        }
        index++;
      }

      return results;
    },
    [getCoordinatesFromRoute],
  );

  return {
    getCoordinatesFromRoute,
    getCoordinatesForDotDirections,
  };
};

export default useMap;
