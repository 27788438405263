import { isEqual } from 'lodash';
import { useEffect, useMemo, useRef } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { FORM_FIELD_NAMES, NUMBER_OF_DROPOFF } from '../constants';
import { genUUID, getSafeValue } from '../helpers';
import { EVENT_ACTIONS } from '../services/redux/actions';
import { selectDropOff } from '../services/redux/reducers/event.reducer';
import { generateArrayToIndex } from '../utils';

const useMultipleDropoff = (params) => {
  const {
    numOfDropOff = NUMBER_OF_DROPOFF,
    fieldName = FORM_FIELD_NAMES.DROP_OFF,
  } = params || {};

  const store = useStore();
  const dispatch = useDispatch();
  const dropOffList = useSelector(selectDropOff);
  const { control, setValue } = useFormContext() || {};
  const watch = control ? useWatch({ control }) : {};

  const currentDropOffIndex = useRef(0);
  const watchDropOff = getSafeValue(watch, fieldName, []);
  const currentDropOffValue = watchDropOff?.[currentDropOffIndex.current] || {};

  const currentValue = useRef(null);

  const generateDropoff = useMemo(() => {
    return generateArrayToIndex(numOfDropOff, {}, true);
  }, []);

  const setIndex = (newIndex) => {
    currentDropOffIndex.current = newIndex;
  };

  const add = () => {
    dispatch({
      type: EVENT_ACTIONS.ADD_DROPOFF,
      payload: {
        id: genUUID(
          `item-${dropOffList.length + 1}-${new Date().getTime()}`,
          'event',
        ),
      },
    });
  };

  const update = (value, index) => {
    dispatch({
      type: EVENT_ACTIONS.UPDATE_DROPOFF,
      payload: {
        value,
        index,
      },
    });
  };

  const remove = (index) => {
    dispatch({
      type: EVENT_ACTIONS.REMOVE_DROPOFF,
      payload: index,
    });
  };

  const init = () => {
    dispatch({ type: EVENT_ACTIONS.SET_DROPOFF, payload: generateDropoff });
  };

  const handleSubcribeStore = () => {
    const state = store.getState();
    const dropoff = selectDropOff(state);
    // const { action } = state;
    const previousValue = currentValue.current;
    currentValue.current = dropoff;

    if (!isEqual(previousValue, currentValue.current)) {
      setValue?.(fieldName, dropoff);
    }
  };

  useEffect(() => {
    if (!setValue) return null;
    const unsubscribe = store.subscribe(handleSubcribeStore);
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    init();
  }, []);

  return {
    dropOffList,
    currentDropOffValue,
    currentDropOffIndex: currentDropOffIndex.current,
    setIndex,
    add,
    update,
    remove,
    init,
  };
};

export default useMultipleDropoff;
