import React, { useMemo } from 'react';
import { Shortcut } from '@shopify/react-shortcuts';
import { useNavigate } from 'react-router';
import useNavigation from '../hooks/useNavigation';

export const withShortcut = (WrappedComponent) => (props) => {
  const {
    push = () => {},
    goBack = () => {},
    goForward = () => {},
  } = useNavigate();
  const {
    currentPageType: page,
    currentResourceName: resource,
    currentRecordId: recordId,
  } = useNavigation();

  const held = [['Control'], ['Meta']];
  const shortcuts = useMemo(() => {
    const results = [];

    // go home
    results.push({
      ordered: ['h'],
      onMatch: () => push('/'),
    });
    // go back
    results.push({
      ordered: [','],
      onMatch: goBack,
    });
    // go forward
    results.push({
      ordered: ['.'],
      onMatch: goForward,
    });
    // let's search

    results.push({
      ordered: ['k'],
      onMatch: () => {
        document.getElementById('search-bar-trigger-button')?.click();
      },
    });

    if (page) {
      switch (page) {
        case 'list':
          // go create
          results.push({
            ordered: ['n'],
            onMatch: () => push(`/${resource}/create`),
          });
          // bulk edit
          results.push({
            ordered: ['b', 'e'],
            onMatch: () =>
              document.getElementById('toolbar-bulk-edit-button')?.click(),
          });
          // bulk clone
          results.push({
            ordered: ['b', 'c'],
            onMatch: () =>
              document.getElementById('toolbar-bulk-clone-button')?.click(),
          });
          // bulk delete
          results.push({
            ordered: ['b', 'd'],
            onMatch: () =>
              document.getElementById('toolbar-bulk-delete-button')?.click(),
          });
          // bulk submit
          results.push({
            ordered: ['s', 's'],
            onMatch: () =>
              document.getElementById('toolbar-bulk-submit-button')?.click(),
          });
          // bulk approve
          results.push({
            ordered: ['a', 'a'],
            onMatch: () =>
              document.getElementById('toolbar-bulk-approve-button')?.click(),
          });
          // bulk cancel
          results.push({
            ordered: ['c', 'c'],
            onMatch: () =>
              document.getElementById('toolbar-bulk-cancel-button')?.click(),
          });
          break;
        case 'show':
          // go list
          results.push({
            ordered: ['l'],
            onMatch: () => push(`/${resource}`),
          });
          // go edit
          results.push({
            ordered: ['e'],
            onMatch: () => push(`/${resource}/${recordId}`),
          });
          // delete
          results.push({
            ordered: ['d'],
            onMatch: () =>
              document.getElementById('toolbar-delete-record-button')?.click(),
          });
          // print
          results.push({
            ordered: ['p'],
            onMatch: () =>
              document.getElementById('toolbar-print-record-button')?.click(),
          });
          // submit
          results.push({
            ordered: ['s'],
            onMatch: () =>
              document.getElementById('toolbar-submit-record-button')?.click(),
          });
          // approve
          results.push({
            ordered: ['a'],
            onMatch: () =>
              document.getElementById('toolbar-approve-record-button')?.click(),
          });
          // cancel
          results.push({
            ordered: ['x'],
            onMatch: () =>
              document.getElementById('toolbar-cancel-record-button')?.click(),
          });
          break;
        case 'edit':
        case 'create':
          if (page === 'edit') {
            // go detail
            results.push({
              ordered: ['d'],
              onMatch: () => push(`/${resource}/${recordId}/show`),
            });
          }
          // save
          results.push({
            ordered: ['s'],
            onMatch: () =>
              document.getElementById('toolbar-save-button')?.click(),
          });
          break;
        case 'group':
          break;
        default:
          break;
      }
    }

    return results;
  }, [push, goBack, goForward, page, resource, recordId]);

  return (
    <>
      {shortcuts?.map((shortcut) => (
        <Shortcut key={JSON.stringify(shortcut)} held={held} {...shortcut} />
      ))}
      <WrappedComponent {...props} />
    </>
  );
};
