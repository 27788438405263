/* eslint-disable */
import { DirectionsRenderer, GoogleMap, Marker } from '@react-google-maps/api';
import clsx from 'clsx';
import { isEmpty, isObject } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { EVENTS, MAP_ID } from '../../constants';
import { subscribe } from '../../events';
import useMultipleDropoff from '../../hooks/useMultipleDropoff';
import { parseCoordinate } from '../../utils';
import Image from '../image';
import useStyles from './index.style';
import {
  selectDropOff,
  selectPickUp,
} from '../../services/redux/reducers/event.reducer';
import { useSelector } from 'react-redux';
import { mapStyles } from '../../../../mapStyle';

const center = {
  lat: 1.3521,
  lng: 103.8198,
};

const MapDirectionRenderer = (props) => {
  const { places = [], travelMode = 'DRIVING', isLocationChanged } = props;
  const [directions, setDirections] = useState({});

  useEffect(() => {
    const waypoints = places.map(({ lat, lng }, index) => ({
      location: { lat, lng },
      stopover: index !== places.length - 1,
    }));

    const origin = waypoints.shift().location;
    const destination = waypoints.pop().location;

    const directionsService = new window.google.maps.DirectionsService();
    directionsService.route(
      {
        origin: origin,
        destination: destination,
        travelMode,
        waypoints: waypoints,
      },
      (result, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          setDirections(result);
        }
      },
    );
  }, [isLocationChanged]);

  return (
    <DirectionsRenderer
      options={{
        polylineOptions: {
          strokeColor: '#083AA9',
          strokeWeight: 5,
          strokeOpacity: 0.8,
        },
      }}
      directions={directions}
    />
  );
};

const BookingMap = ({
  className,
  pickup: defaultPickup,
  dropoff: defaultDropoff,
}) => {
  const classes = useStyles();
  const map = useRef();
  const pickupByQuery = useSelector(selectPickUp);
  const { dropOffList } = useMultipleDropoff();
  const [isLocationChanged, setIsLocationChanged] = useState(false);
  const dropOff = isEmpty(defaultDropoff) ? dropOffList : defaultDropoff;

  const getPickup = () => {
    let pickup = center;
    if (!isEmpty(defaultPickup)) pickup = defaultPickup;
    else if (!isEmpty(pickupByQuery)) pickup = pickupByQuery;

    if ('coordinate' in pickup) {
      const [lat, lng] = parseCoordinate(pickup.coordinate);
      pickup = { lat, lng };
    }

    if (!('lat' in pickup) && !('lng' in pickup)) pickup = center;

    return pickup;
  };

  const getDropoff = () => {
    return dropOff?.map((dropoff) => {
      const [lat, lng] = parseCoordinate(dropoff.coordinate);
      if (!lat) return {};
      return { lat, lng };
    });
  };

  const isShowDirection =
    !isEmpty(getPickup()) && dropOff?.some((dropoff) => dropoff.coordinate);

  const moveTo = (coords) => {
    if (!isObject(coords)) return;
    map?.current?.panTo(coords);
  };

  const zoom = (zoomValue = 15) => {
    map?.current?.setZoom(zoomValue);
  };

  const onLoad = (mapEvent) => {
    map.current = mapEvent;
    moveTo(getPickup());
    // getCurrentLocation();
  };
  const handlePickUpChange = (data) => {
    setIsLocationChanged((prev) => !prev);
    zoom(14);
  };

  const handleDropOffChange = (data) => {
    setIsLocationChanged((prev) => !prev);
  };

  useEffect(() => {
    subscribe(EVENTS.PICKUP_LOCATION, handlePickUpChange);
    subscribe(EVENTS.DROPOFF_LOCATION, handleDropOffChange);
  }, []);

  useEffect(() => {
    moveTo(getPickup());
  }, [getPickup()]);

  if (window.google === undefined) {
    return (
      <Image className={classes.map} src="images/booking/map.png" alt="map" />
    );
  }
  return (
    <GoogleMap
      onLoad={(event) => {
        onLoad(event);
      }}
      zoom={10}
      mapContainerClassName={clsx(classes.map, className)}
      id={MAP_ID}
      center={center}
      options={{
        styles: mapStyles,
        streetViewControl: false,
        fullscreenControl: false,
        mapTypeControl: false,
        zoomControl: false,
      }}
    >
      {!isShowDirection && (
        <>
          <Marker position={getPickup()} />
        </>
      )}

      {isShowDirection && (
        <MapDirectionRenderer
          isLocationChanged={isLocationChanged}
          places={[getPickup(), ...getDropoff()]}
        />
      )}
    </GoogleMap>
  );
};

BookingMap.propTypes = {
  className: PropTypes.string,
  pickup: PropTypes.object,
  dropoff: PropTypes.array,
};

BookingMap.defaultProps = {
  className: null,
  pickup: {},
  dropoff: [],
};

export default BookingMap;
