import {
  SET_PERCENT_LOADING,
  TOGGLE_FEEDBACK, TOGGLE_LOADING,
} from '../root.actions';

export const toggleLoading = isLoading => ({
  type: TOGGLE_LOADING,
  payload: isLoading,
});
export const setPercentLoading = percent => ({
  type: SET_PERCENT_LOADING,
  payload: percent,
});
export const toogleFeedBack = isFeedBackShown => ({
  type: TOGGLE_FEEDBACK,
  payload: isFeedBackShown,
});
