import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import useStyles from './index.style';
import Image from '../../image';

const PlaceItem = (props) => {
  const { imageUrl } = props;
  const classes = useStyles();
  return (
    <Box className={classes.item}>
      <Image
        className={classes.image}
        src={imageUrl}
        alt="Place"
        loading="lazy"
      />
    </Box>
  );
};

PlaceItem.propTypes = {
  imageUrl: PropTypes.string,
};

PlaceItem.defaultProps = {
  imageUrl: null,
};

export default PlaceItem;
