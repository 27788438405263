import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { alpha, styled } from '@mui/material/styles';
import isArray from 'lodash/isArray';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SUB_JOB_TYPE } from '../../../../constants';
import JOB_TYPE_ACTIONS from '../../../../services/redux/actions/job-type.action';
import {
  getSelectedJobType,
  getSelectedSubJobType,
} from '../../../../services/redux/reducers/job-type.reducer';
import useStyles from './index.style';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    boxShadow: 'none',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      color: '#000',
      fontFamily: 'QuickSandSemiBold',
      fontSize: 13,
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

export default function CustomizedMenus() {
  const [anchorEl, setAnchorEl] = useState();
  const isFirstTime = useRef(false);

  const selectedJobType = useSelector(getSelectedJobType);
  const { subJobTypes } = selectedJobType || {};

  const selectedSubJobType = useSelector(getSelectedSubJobType);
  const { id: subJobTypeId, name: subJobTypeNameObj } =
    selectedSubJobType || {};
  const { en: subJobTypeName } = subJobTypeNameObj || {};

  const open = Boolean(anchorEl);
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleBtnClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClickItem = (subJobType) => () => {
    dispatch({
      type: JOB_TYPE_ACTIONS.SET_SELECTED_SUB_JOB_TYPE,
      payload: subJobType,
    });
    handleClose();
  };

  useEffect(() => {
    if (
      isArray(subJobTypes) &&
      subJobTypes.length > 0 &&
      !isFirstTime.current
    ) {
      const p2pSubJobType = subJobTypes?.find(
        (subJobType) => subJobType?.type === SUB_JOB_TYPE.POINT2POINT,
      );
      dispatch({
        type: JOB_TYPE_ACTIONS.SET_SELECTED_SUB_JOB_TYPE,
        payload: p2pSubJobType || subJobTypes[0],
      });
      isFirstTime.current = true;
    }
    return () => {
      isFirstTime.current = false;
      dispatch({
        type: JOB_TYPE_ACTIONS.SET_SELECTED_SUB_JOB_TYPE,
        payload: null,
      });
    };
  }, [subJobTypes]);

  return (
    <Box>
      <Button
        id="customized-button"
        aria-controls={open ? 'customized-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        variant="contained"
        disableElevation
        onClick={handleBtnClick}
        endIcon={<KeyboardArrowDownIcon sx={{ color: '#92929D' }} />}
        sx={{
          color: '#686868',
          boxShadow: 'none',
          border: '1px solid #CBD5E1',
          '&:hover': {
            boxShadow: 'none',
          },
          '&:focus': {
            boxShadow: 'none',
          },
        }}
      >
        <Typography className={classes.valueTxt}>{subJobTypeName}</Typography>
      </Button>
      <StyledMenu
        id="customized-menu"
        MenuListProps={{
          'aria-labelledby': 'customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {subJobTypes?.map((subJobType) => {
          const { id, name } = subJobType || {};
          const { en } = name || {};
          const isSelected = subJobTypeId === id;

          return (
            <MenuItem
              key={id}
              selected={isSelected}
              onClick={onClickItem(subJobType)}
              disableRipple
            >
              {en}
            </MenuItem>
          );
        })}
      </StyledMenu>
    </Box>
  );
}
