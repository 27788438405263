import { cloneDeep, isEmpty } from 'lodash';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PULL_NF_SCHEMA } from '../../services/redux/root.actions';
import { docStatus } from '../components/enumStatus';
import useAuthUser from './useAuthUser';

const useSchema = () => {
  const { api, ref } = useSelector((state) => state?.schema);
  const dispatch = useDispatch();
  const user = useAuthUser();

  useEffect(() => {
    if (!api) {
      dispatch({
        type: PULL_NF_SCHEMA,
      });
    }
  }, [api]);

  const getWritableFields = useCallback(
    ({ properties, excludeFields, record, view }) => {
      const hasEditableStatus =
        view === 'edit' &&
        Object.keys(properties || {}).some(
          (key) => properties?.[key]?.properties?.editableStatus?.default,
        );

      return Object.keys(properties || {})
        ?.filter((key) => !excludeFields?.includes(key))
        ?.filter((key) => {
          if (key?.startsWith('_')) return false;

          const simpleFieldRO = properties?.[key]?.readOnly;
          const complexFieldRO = properties?.[key]?.allOf?.some(
            (p) => p.readOnly,
          );
          let matchEditableStatus = true;

          if (!isEmpty(record)) {
            if (hasEditableStatus && record?.docStatus !== docStatus.DRAFT) {
              matchEditableStatus = properties?.[
                key
              ]?.properties?.editableStatus?.default?.includes(
                record?.docStatus,
              );
            }
          }

          return !simpleFieldRO && !complexFieldRO && matchEditableStatus;
        });
    },
    [],
  );

  const getResourceProperties = useCallback(
    ({
      resource,
      view: displayView = 'show',
      relationsOnly = false,
      includeRelations = false,
    }) => {
      if (!api || !resource) return {};

      const forForm = displayView === 'edit' || displayView === 'create';
      const forEdit = displayView === 'edit';
      const target = forEdit ? `/bo/${resource}/{id}` : `/bo/${resource}`;

      const { paths } = api;
      const path = paths?.[target];
      let schema;
      if (forForm) {
        schema =
          path?.[forEdit ? 'patch' : 'post']?.requestBody?.content?.[
            'application/json'
          ]?.schema;
      } else {
        // show | list
        const responses = (path?.get || path?.post)?.responses;
        schema = (responses?.['200'] || responses?.['201'])?.content?.[
          'application/json'
        ]?.schema;
      }
      if (!schema) return null;

      let responseRef;
      if (!forForm && schema?.oneOf) {
        responseRef = schema.oneOf.filter((r) => r.type === 'array')?.[0]
          ?.items;
      } else {
        responseRef = schema;
      }

      responseRef =
        responseRef?.$ref ||
        responseRef?.allOf?.filter((i) => i?.$ref)?.[0]?.$ref;

      const responseComponent = ref?.get(responseRef);

      // need to use clone deep to prevent deleting properties
      // from original object
      const data = cloneDeep(responseComponent);
      const { required } = data;
      let { properties } = data;

      // Filter higher level properties
      Object.keys(properties)
        .filter((key) => !!properties[key]?.nfLevel)
        .forEach((i) => {
          const { nfLevel } = properties[i];
          if (!!nfLevel?.scopes?.length && nfLevel.scopes.includes(resource)) {
            return;
          }

          if (nfLevel?.exclude?.includes(user.level)) {
            return;
          }

          if (!!nfLevel?.min && nfLevel.min <= user.level) {
            return;
          }

          if (!!nfLevel?.max && nfLevel.max >= user.level) {
            return;
          }

          delete properties[i];
        });

      if (!forForm) {
        // Filter hidden properties
        Object.keys(properties)
          .filter(
            (key) => properties[key]?.properties?.hide || key.startsWith('_'),
          )
          .forEach((i) => {
            delete properties[i];
          });

        const filterPredicate = relationsOnly
          ? (prop) =>
              !properties?.[prop]?.deprecated &&
              properties?.[prop]?.type === 'array' &&
              prop !== 'blobs'
          : (prop) =>
              (includeRelations ||
                properties?.[prop]?.type !== 'array' ||
                (properties?.[prop]?.type === 'array' && prop === 'blobs')) &&
              prop !== 'attachment' &&
              properties[prop]?.properties?.relationType?.default !==
                'oneToOne';

        properties = Object.keys(properties)
          ?.filter(filterPredicate)
          ?.sort((a, b) => properties[a].index - properties[b].index)
          ?.reduce(
            (prev, curr) => ({
              ...prev,
              [curr]: properties[curr],
            }),
            {},
          );
      }
      return {
        properties,
        required,
        ref: responseRef,
      };
    },
    [api],
  );

  /**
   * @deprecated use getResourceProperties() instead
   */
  const getResourceReadableProperties = useCallback(
    (resource) => {
      const { properties } = getResourceProperties({
        resource,
      });
      return properties;
    },
    [getResourceProperties],
  );

  return (
    {
      api,
      ref,
      getWritableFields,
      getResourceProperties,
      getResourceReadableProperties,
    } || {}
  );
};

export default useSchema;
