import { List } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { memo } from 'react';
import NavMenuItem from './NavMenuItem';

const useStyles = makeStyles(() => ({
  sideNavMenu: {
    position: 'relative',
    overflowX: 'hidden',
    '#root:has(.show-page[name=job]) &, #root:has(.list-page[name=job] .gm-style) &':
      {
        marginTop: '5px',
        marginLeft: '5px',
        borderRadius: '8px',
        backgroundColor: 'white',
      },
  },
}));

const CmtVertical = (props) => {
  const { menuItems } = props;
  const classes = useStyles();
  return (
    <List component="nav" disablePadding className={classes.sideNavMenu}>
      {menuItems.map((item, index) => (
        <NavMenuItem {...item} key={index} />
      ))}
    </List>
  );
};

export default memo(CmtVertical);
