/* eslint-disable */

import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';
import { convertBufferToBase64 } from '../../utils';

const Image = ({ src, alt, buffer, fallBackSrc, className, ...rest }) => {
  const onError = (event) => {
    event.currentTarget.src = fallBackSrc;
  };

  const getImage = useMemo(() => {
    if (buffer && src) return convertBufferToBase64(src);
    return src;
  }, [src, buffer]);

  return (
    <img
      {...rest}
      src={getImage}
      alt={alt}
      className={className}
      onError={onError}
    />
  );
};

Image.propTypes = {
  alt: PropTypes.string,
  src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  fallBackSrc: PropTypes.string,
  className: PropTypes.string,
  buffer: PropTypes.bool,
};

Image.defaultProps = {
  alt: 'image',
  fallBackSrc: '/images/booking/logo.png',
  src: '/images/booking/logo.png',
  className: null,
  buffer: false,
};
export default memo(Image);
