import { isEmpty } from 'lodash';
import { useCallback } from 'react';
import { useLocaleState, useNotify, useTranslate } from 'react-admin';
import configs from '../../services/configs';

const useError = () => {
  const notify = useNotify();
  const translate = useTranslate();
  const [locale] = useLocaleState();

  const notifyError = useCallback(
    (error) => {
      let errorMessage;
      let args;

      if (configs.nfDev) console.error(error);

      const { body, message } = error;
      const translation = error.translation ?? body?.translation;
      let data = error.response?.data || error.data;

      if (data instanceof ArrayBuffer) {
        const textDecoder = new TextDecoder('utf-8');
        data = JSON.parse(textDecoder.decode(data));
      }

      if (data?.message || data?.key) {
        errorMessage = data.message || data.key;
        args = data.args;
      } else if (body?.message) {
        errorMessage = body.message;
        args = body.args;
      } else if (!isEmpty(translation)) {
        errorMessage = translation[locale];
      } else if (!isEmpty(message?.translation)) {
        errorMessage = message.translation[locale];
      } else if (message) {
        errorMessage = message;
      } else {
        errorMessage = 'ra.message.tryAgain';
      }

      if (!Array.isArray(errorMessage)) {
        errorMessage = [errorMessage];
      }

      errorMessage.forEach((msg) => {
        notify(`Error! ${translate(msg, args)}`, {
          type: 'error',
        });
      });
    },
    [locale, translate, notify],
  );

  return {
    notifyError,
  };
};

export default useError;
