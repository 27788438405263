import { Box } from '@mui/material';
import { isEmpty } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  useSelectEvent,
  useSelectPickupList,
} from '../../api/features/common.feature';
import {
  BookingBottomSheet,
  BookingPlaceItem,
  DateInput,
  DefaultButton,
  LocationTimeInput,
  TimeInput,
} from '../../components';
import SelectLocationPoint from '../../components/select-location-point';
import { EVENTS, FORM_FIELD_NAMES, SUB_JOB_TYPE } from '../../constants';
import { publish, subscribe } from '../../events';
import { getSafeValue } from '../../helpers';
import { formatValueToPlaceItem } from '../../helpers/components';
import {
  getDistanceBetweenCoords,
  getMyLocation,
} from '../../helpers/location';
import useGetPlacePhotos from '../../hooks/useGetPlacePhotos';
import { EVENT_ACTIONS } from '../../services/redux/actions';
import { getSelectedSubJobType } from '../../services/redux/reducers/job-type.reducer';
import useStyles from './index.style';

const BookingPickUpScreen = ({ onDone }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { setValue, control, getValues } = useFormContext();
  const { data: pickupList } = useSelectPickupList();
  const { data: eventInfo } = useSelectEvent();
  const placePhotoService = useGetPlacePhotos();

  const selectedSubJobType = useSelector(getSelectedSubJobType);
  const { type: typeOfSubJobType } = selectedSubJobType || {};

  const willBeFreeInputLocation = [SUB_JOB_TYPE.HOURLY].includes(
    typeOfSubJobType,
  );

  const watch = useWatch({ control });
  const { placeId, addressName } = getSafeValue(
    watch,
    FORM_FIELD_NAMES.PICK_UP,
    {},
  );
  const [scheduleOpen, setScheduleOpen] = useState(false);
  const [tempFormValues, setTempFormValues] = useState({});
  const [photoList, setPhotoList] = useState([]);
  const locationTimeInputRef = useRef();

  const timeLimit = useMemo(() => {
    let minTime = moment(eventInfo?.started);
    const maxTime = moment(eventInfo?.ended);

    const isCurrentDate = moment(
      tempFormValues?.[FORM_FIELD_NAMES.DATE] || moment(),
    ).isSame(moment(), 'day');

    if (eventInfo?.started) minTime = moment();
    const selectedDate = moment(tempFormValues?.[FORM_FIELD_NAMES.DATE]);

    if (tempFormValues?.[FORM_FIELD_NAMES.DATE]) {
      if (isCurrentDate) minTime = selectedDate.add(moment().hour(), 'hour');
      else minTime = selectedDate;
    }
    return {
      minTime,
      maxTime,
    };
  }, [eventInfo?.started, tempFormValues?.[FORM_FIELD_NAMES.DATE]]);

  const dateLimit = useMemo(() => {
    let minDate = moment(eventInfo?.started);
    const maxDate = moment(eventInfo?.ended);
    const currentDate = moment();

    if (currentDate.isAfter(minDate)) minDate = currentDate;
    return {
      minDate,
      maxDate,
    };
  }, [eventInfo?.started, eventInfo?.ended]);

  const onDateTimeChange = useCallback(
    (fieldName) =>
      ({ value }) => {
        setTempFormValues((prev) => ({
          ...prev,
          [fieldName]: value,
        }));
      },
    [],
  );

  const onResetDateTime = () => {
    [
      FORM_FIELD_NAMES.DATE,
      FORM_FIELD_NAMES.TIME,
      FORM_FIELD_NAMES.HISTORY_DATE,
      FORM_FIELD_NAMES.HISTORY_TIME,
    ].forEach((fieldName) => {
      const value = [
        FORM_FIELD_NAMES.DATE,
        FORM_FIELD_NAMES.HISTORY_DATE,
      ].includes(fieldName)
        ? 'Today'
        : 'Now';
      onDateTimeChange(fieldName)({ value });
      setValue(fieldName, value);
    });
  };

  const onRollbackHistory = () => {
    const { historyDate, historyTime } = getValues();
    [FORM_FIELD_NAMES.DATE, FORM_FIELD_NAMES.TIME].forEach((fieldName) => {
      const value =
        fieldName === FORM_FIELD_NAMES.DATE ? historyDate : historyTime;
      onDateTimeChange(fieldName)({ value });
      setValue(fieldName, value);
    });
  };

  const setRollbackHistoryDateTime = () => {
    const { date, time } = getValues();
    [FORM_FIELD_NAMES.HISTORY_DATE, FORM_FIELD_NAMES.HISTORY_TIME].forEach(
      (fieldName) => {
        const value = fieldName === FORM_FIELD_NAMES.HISTORY_DATE ? date : time;
        setValue(fieldName, value);
      },
    );
  };

  const handleScheduleClose = useCallback(() => {
    setScheduleOpen(false);
  }, []);

  const onScheduleButtonClick = useCallback(() => {
    if (tempFormValues?.date) {
      setValue(FORM_FIELD_NAMES.DATE, tempFormValues.date);
    }
    if (tempFormValues?.time) {
      setValue(FORM_FIELD_NAMES.TIME, tempFormValues.time);
    }

    handleScheduleClose();
  }, [tempFormValues]);

  const onTimeButtonClick = useCallback(() => {
    setScheduleOpen(true);
  }, []);

  const onConfirmLocationButtonClick = () => {
    setRollbackHistoryDateTime();
    onDone?.();
    locationTimeInputRef?.current?.clearTextInput();
  };

  const onLocationChange = useCallback((value) => {
    let locationData = {};
    if (value?.location) {
      locationData = {
        locationId: value?.id,
        ...value?.location,
      };
    }
    setValue(FORM_FIELD_NAMES.PICK_UP, locationData);
    dispatch({ type: EVENT_ACTIONS.SET_PICKUP, payload: locationData });
    publish(EVENTS.PICKUP_LOCATION);
  }, []);

  const handleAutoFillPickup = (myLocation) => {
    if (isEmpty(pickupList)) {
      onLocationChange(null);
      return;
    }
    const { latitude: lat, longitude: lng } = myLocation?.coords;

    const furthestAndClosest = getDistanceBetweenCoords(
      { lat, lng },
      pickupList,
    );

    if (myLocation?.coords) {
      const closest = pickupList.filter(
        (closestInList) => closestInList.id === furthestAndClosest.closest.id,
      );
      onLocationChange(closest[0]);
    } else {
      onLocationChange(pickupList[0]);
    }
  };

  useEffect(() => {
    getMyLocation(handleAutoFillPickup, () => onLocationChange(pickupList[0]));
  }, [pickupList.length, selectedSubJobType, eventInfo]);

  useEffect(() => {
    if (!placeId) {
      setPhotoList([]);
      return;
    }

    placePhotoService.getList(placeId, (photos) => {
      setPhotoList(formatValueToPlaceItem(photos));
    });
  }, [placeId]);

  useEffect(() => {
    subscribe(EVENTS.RESET_DATE_TIME, onResetDateTime);
    subscribe(EVENTS.ROLLBACK_DATE_TIME, onRollbackHistory);
  }, []);

  const locationComponent = willBeFreeInputLocation ? (
    <LocationTimeInput
      ref={locationTimeInputRef}
      onLocationChange={(location) => {
        onLocationChange({ location });
      }}
      placeholder="Enter your pickup"
      fieldTimeName={FORM_FIELD_NAMES.TIME}
      fieldDateName={FORM_FIELD_NAMES.DATE}
      onTimeButtonClick={onTimeButtonClick}
    />
  ) : (
    <LocationTimeInput
      data={pickupList}
      placeholder={addressName}
      fieldTimeName={FORM_FIELD_NAMES.TIME}
      fieldDateName={FORM_FIELD_NAMES.DATE}
      fieldLocationName={FORM_FIELD_NAMES.PICK_UP}
      onTimeButtonClick={onTimeButtonClick}
      onSelectionItemClick={onLocationChange}
      disableItemSetValue
    />
  );

  return (
    <>
      {/* Booking Pickup Location */}
      <SelectLocationPoint
        ctaButtonName="Choose this Pickup"
        fieldLocationName={FORM_FIELD_NAMES.PICK_UP}
        photos={photoList}
        component={BookingPlaceItem}
        onConfirmLocationClick={onConfirmLocationButtonClick}
        showDirectionClick
        locationComponent={locationComponent}
      />

      {/* Booking Schedule */}
      <BookingBottomSheet
        title="Schedule a Ride"
        open={scheduleOpen}
        onClose={handleScheduleClose}
      >
        <Box className={classes.inputContainer}>
          <DateInput
            disableSetFormValue
            fieldName={FORM_FIELD_NAMES.DATE}
            label="Select a Date"
            calendarProps={{
              ...(eventInfo?.started && { minDate: dateLimit.minDate }),
              ...(eventInfo?.ended && { maxDate: dateLimit.maxDate }),
            }}
            onChange={onDateTimeChange(FORM_FIELD_NAMES.DATE)}
            defaultInputLabel={tempFormValues[FORM_FIELD_NAMES.DATE]}
          />
          <TimeInput
            disableSetFormValue
            defaultInputLabel={tempFormValues[FORM_FIELD_NAMES.TIME]}
            fieldName={FORM_FIELD_NAMES.TIME}
            label="Select a Time"
            timeClockProps={{
              ...(eventInfo?.started && { minTime: timeLimit.minTime }),
            }}
            onChange={onDateTimeChange(FORM_FIELD_NAMES.TIME)}
          />
          <DefaultButton onClick={onScheduleButtonClick} name="Schedule" />
        </Box>
      </BookingBottomSheet>
    </>
  );
};

BookingPickUpScreen.propTypes = {
  onDone: PropTypes.func,
};

BookingPickUpScreen.defaultProps = {
  onDone: undefined,
};

export default React.memo(BookingPickUpScreen);
