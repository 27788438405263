import { Box, LinearProgress, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import React, {
  Suspense,
  memo,
  useContext,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { useResizeDetector } from 'react-resize-detector';
import useLoading from '../../../../../base/hooks/useLoading';
import CmtHorizontalLayout from '../../../../@coremat/CmtLayouts/Horizontal';
import CmtContent from '../../../../@coremat/CmtLayouts/Horizontal/Content';
import CmtHeader from '../../../../@coremat/CmtLayouts/Horizontal/Header';
import CmtHeaderTop from '../../../../@coremat/CmtLayouts/Horizontal/Header/HeaderTop';
import CmtSidebar from '../../../../@coremat/CmtLayouts/Horizontal/Sidebar';
import ContentLoader from '../../ContentLoader';
import AppContext from '../../contextProvider/AppContextProvider/AppContext';
// import Footer from "../partials/Footer";
import { useLanguageContext } from '../../../../../base/context/language';
import HeaderTop from '../partials/Header/HeaderTop';
import SideBar from '../partials/SideBar';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  fixedHeader: {
    width: '100%',
    zIndex: 999,
    background: 'white',
  },
  loadingWrapper: {
    position: 'absolute',
    bottom: -4,
    left: 0,
    right: 0,
    width: '100%',
    height: 4,
  },
  loading: {
    position: 'fixed',
    width: '100%',
    zIndex: 9,
    backgroundColor: 'rgb(249, 250, 250)',
    height: 'calc(100vh + 3em)',
  },
  bodyContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
}));

const DefaultLayout = ({ className, children }) => {
  const { drawerBreakPoint, sidebarSize, sidebarStyle, showFooter } =
    useContext(AppContext);
  const { language } = useLanguageContext();

  const classes = useStyles();
  const { isLoading, percentLoading } = useLoading();
  const [height, setHeight] = useState(60);
  const { height: menuHeight, ref: menuRef } = useResizeDetector();

  useLayoutEffect(() => {
    setHeight(menuHeight);
  }, [menuHeight]);

  const layout = useMemo(
    () => (
      <div className={classes.container}>
        <CmtHorizontalLayout
          drawerBreakPoint={drawerBreakPoint}
          className={clsx('Cmt-defaultLayout', className)}
        >
          <CmtHeader>
            <div ref={menuRef} className={clsx(classes.fixedHeader)}>
              <CmtHeaderTop>
                <HeaderTop />
              </CmtHeaderTop>
              <div className={classes.loadingWrapper}>
                {isLoading && percentLoading === 0 && <LinearProgress />}
                {isLoading && percentLoading > 0 && (
                  <>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Box sx={{ width: '100%', mr: 1 }}>
                        <LinearProgress
                          variant="determinate"
                          value={percentLoading}
                        />
                      </Box>
                      <Box sx={{ minWidth: 35 }}>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                        >{`${percentLoading}%`}</Typography>
                      </Box>
                    </Box>
                  </>
                )}
              </div>
            </div>
          </CmtHeader>
          <CmtSidebar sidebarWidth={sidebarSize} {...sidebarStyle}>
            <SideBar />
          </CmtSidebar>
          <CmtContent height={height}>
            <Suspense fallback={<></>}>{children}</Suspense>
            <ContentLoader />
          </CmtContent>
        </CmtHorizontalLayout>
      </div>
    ),
    [
      drawerBreakPoint,
      sidebarSize,
      sidebarStyle,
      showFooter,
      language,
      classes,
      isLoading,
      height,
      menuRef,
      children,
      className,
    ],
  );

  return layout;
};

export default memo(DefaultLayout);
