import React from 'react';
import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  headerTop: {
    width: '100%',
    fontSize: 14,
    boxSizing: 'border-box',
    height: '53px',
    [theme.breakpoints.down('md')]: {
      height: '48.5px',
    },
    '& .Cmt-container': {
      marginRight: 'auto',
      marginLeft: 'auto',
      paddingLeft: 15,
      paddingRight: 15,
      width: '100%',
      boxSizing: 'border-box',
      height: '100%',
    },
  },
}));

const CmtHeaderTop = React.forwardRef((props, ref) => {
  const { children } = props;

  const contentRef = React.createRef();
  const classes = useStyles();

  return (
    <Box ref={contentRef} className={classes.headerTop} {...props}>
      <Box className="Cmt-container">{children}</Box>
    </Box>
  );
});

export default CmtHeaderTop;
