import { Box, FormControlLabel, Radio } from '@mui/material';
import React from 'react';
import { useWithRadioButtonStyles } from '../common/style';

const withRadioButton = (WrappedComponent) => (props) => {
  const { id } = props || {};
  const radioButtonStyles = useWithRadioButtonStyles();
  return (
    <Box className={radioButtonStyles.root}>
      <FormControlLabel value={id} control={<Radio />} />
      <WrappedComponent {...props} />
    </Box>
  );
};

export default withRadioButton;
