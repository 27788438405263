import makeStyles from '@mui/styles/makeStyles';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import axios from 'axios';
import { capitalize, cloneDeep, isEmpty, lowerCase } from 'lodash';
import kebabCase from 'lodash/kebabCase';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  useLocaleState,
  useNotify,
  useRefresh,
  useTranslate,
} from 'react-admin';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { composeFilter } from '../../services/provider/dataProvider';
import { guessProperty } from '../components/guesser/nf-guesser';
import { useNFPerms } from '../context/permissions';
import { isAccessibleCtaButton, saveFileFromResponse } from '../utils';
import useError from './useError';
import useSchema from './useSchema';

const useStyles = makeStyles((theme) => ({
  outlinedSuccess: {
    borderColor: theme.palette.success.main,
    color: theme.palette.success.main,
  },
  outlinedError: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
  },
  outlinedWarning: {
    borderColor: theme.palette.warning.main,
    color: theme.palette.warning.main,
  },
  outlinedInfo: {
    borderColor: theme.palette.info.main,
    color: theme.palette.info.main,
  },
  containedSuccess: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.error.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.success.dark,
    },
  },
  containedError: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
  containedWarning: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.error.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.warning.dark,
    },
  },
  containedInfo: {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.info.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.info.dark,
    },
  },
}));

const useCtaButton = ({ item, resource, record, view }) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const permissions = useNFPerms();
  const resourceContext = window?.location?.hash?.split('/')?.[1];
  const { ref, getWritableFields } = useSchema();
  const resources = useSelector((state) => state.resource.resources || []);
  const translate = useTranslate();
  const [locale] = useLocaleState();
  const notify = useNotify();
  const { notifyError } = useError();
  const refresh = useRefresh();
  const [isVisible, setVisible] = useState(false);
  const [isAccessible, setAccessible] = useState(false);
  const [callToActionForm, setCallToActionForm] = useState({
    open: false,
    components: [],
    action: {},
  });

  const [confirm, setConfirm] = useState({
    open: false,
    message: '',
    confirming: false,
  });

  const name = translate(item.title, {
    _: item.title,
  });
  const icon = item.icon || 'arrow_right';
  const variant = item.variant || 'contained';
  const color = (item.color && lowerCase(item.color)) || '';
  const className = classes?.[`${variant}${capitalize(color)}`];
  const colorProp =
    ['default', 'inherit', 'primary', 'secondary'].indexOf(kebabCase(color)) >
    -1
      ? color
      : undefined;

  const resourceId = useMemo(
    () => resources?.find((x) => x.name === resource)?.options?.resourceId,
    [resources, resource],
  );

  const onCloseConfirm = () => {
    setConfirm({ open: false, message: '' });
  };

  const checkIfButtonVisible = useCallback(async () => {
    if (item?.api === '[self]/print' && resourceId) {
      const printFormatQuery = RequestQueryBuilder.create({
        filter: [
          {
            field: 'resource.id',
            operator: '$eq',
            value: resourceId,
          },
          {
            field: 'enabled',
            operator: '$eq',
            value: true,
          },
        ],
      });
      const { data } = await axios.get(
        `/print-format?${printFormatQuery.query()}`,
      );
      if (!data?.length) {
        setVisible(false);
        return;
      }
    }

    setVisible(true);
  }, [item?.api, resourceId]);

  useEffect(() => {
    checkIfButtonVisible();
  }, [checkIfButtonVisible]);

  const checkIfButtonAccessible = useCallback(() => {
    const accessible = isAccessibleCtaButton({
      item,
      resource,
      record,
      permissions,
    });
    setAccessible(accessible);
  }, [item, resource, record, permissions]);

  useEffect(() => {
    checkIfButtonAccessible();
  }, [checkIfButtonAccessible]);

  const handleConfirm = async () => {
    const action = cloneDeep(item);
    const hasIdRegex = /:id/g;
    const hasId = hasIdRegex.test(action?.api);
    const hasSelf = /\[self\]/g.test(action?.api);
    const queryString = window.location.href?.split('/#/')?.[1];
    const [targetResource, ...restOfQuery] =
      ((view === 'list' && queryString?.split('?')) ||
      queryString?.includes('/show/')
        ? queryString?.split('/show/')
        : queryString?.split('/show')) || [];
    const options = {
      validateStatus: (status) => status <= 500,
    };

    if (hasSelf) {
      action.api = action.api.replace(/\[self\]/g, targetResource);
    }

    if (action.saveFileExtension) {
      options.responseType = 'arraybuffer';
    }

    if (action?.options?.redirect) {
      if (action.options?.openNewWindow) window.open(action.api);
      else {
        navigate(`/${action.api}`);
      }

      return;
    }

    if (action.collectFilter) {
      const appliedQuery = restOfQuery?.join('?');
      const [restOfApiUrl, ...restOfApiQuery] = action?.api?.split('?');
      const apiParams = Object.fromEntries(
        new URLSearchParams(restOfApiQuery?.join('?')?.split('/')[0]),
      );
      const params = Object.fromEntries(new URLSearchParams(appliedQuery));
      const preMergedParams = {
        ...apiParams,
        ...params,
      };
      const { order, sort } = preMergedParams;
      let { filter: mergedParams } = preMergedParams;

      if (typeof mergedParams === 'string' && !isEmpty(mergedParams)) {
        mergedParams = JSON.parse(mergedParams);
      }

      // Get rid of null/undefined/empty string search value
      const enhancedFilters = Object.entries(mergedParams || {}).reduce(
        (obj, [key, value]) => {
          if (!Number.isInteger(value) && typeof value !== 'boolean') {
            if (!value) {
              return obj;
            }
          }
          obj[key] = value; // eslint-disable-line no-param-reassign
          return obj;
        },
        {},
      );
      const encodedQueryFilter = RequestQueryBuilder.create({
        filter: composeFilter(enhancedFilters),
      });

      if (sort) {
        encodedQueryFilter.sortBy({
          field: sort,
          order,
        });
      }

      const toppinQuery = restOfApiQuery?.join('?')?.split('/')?.[1];
      action.api = toppinQuery
        ? `${restOfApiUrl}/${toppinQuery}?${encodedQueryFilter.query()}`
        : `${restOfApiUrl}?${encodedQueryFilter.query()}`;
    }

    if (action.dto) {
      const componentResource = kebabCase(action.dto);
      const schemas = ref.get(`#/components/schemas/${action.dto}`);
      const { properties, required } = schemas;

      const refName = Object.entries(properties).find(
        ([, value]) =>
          value?.referenced &&
          value?.allOf?.some(
            (x) => x.$ref === `#/components/schemas/${capitalize(resource)}`,
          ),
      )?.[0];

      const writableFields = getWritableFields({
        properties,
      });
      const components = writableFields
        ?.filter((x) => required?.includes(x) && x !== refName)
        ?.map((prop) =>
          guessProperty({
            source: prop,
            properties,
            required,
            apiRef: ref,
            view: 'create',
            resource: `${resourceContext}.cta.${componentResource}`,
            resources,
            locale,
            record,
            translate,
          }),
        );

      setCallToActionForm({
        ...callToActionForm,
        open: true,
        components,
        action,
        hasIdRegex,
        refName,
      });
    } else {
      try {
        let resp = null;
        if (!hasId) {
          switch (action.method) {
            case 'GET':
              resp = await axios.get(action?.api, options);
              break;
            case 'POST':
              resp = await axios.post(action?.api, null, options);
              break;
            case 'PATCH':
              resp = await axios.patch(action?.api, null, options);
              break;
            default:
              break;
          }
        } else {
          switch (action.method) {
            case 'GET':
              resp = await axios.get(
                action?.api?.replace(hasIdRegex, record.id),
                options,
              );
              break;
            case 'POST':
              resp = await axios.post(
                action?.api?.replace(hasIdRegex, record.id),
                null,
                options,
              );
              break;
            case 'PATCH':
              resp = await axios.patch(
                action?.api?.replace(hasIdRegex, record.id),
                null,
                options,
              );
              break;
            default:
              break;
          }
        }

        if (action.saveFileExtension && resp?.data && resp.status < 300) {
          saveFileFromResponse(resp, null, action.saveFileExtension);

          if (action.messageOnCompleted) {
            notify(action.messageOnCompleted, { type: 'success' }, 1);
          }
        } else if (action.saveFileExtension && !!('TextDecoder' in window)) {
          const enc = new TextDecoder('utf-8');
          const arr = new Uint8Array(resp.data);
          const respMessage = JSON.parse(enc.decode(arr));
          notify(translate(respMessage.message), { type: 'error' }, 1);
        } else if (resp.status >= 400) {
          notifyError(resp);
        } else if (resp.status < 300) {
          notify(
            action?.messageOnCompleted || 'Successful Action',
            { type: 'success' },
            1,
          );
        }

        if (action.reloadOnCompleted) {
          window.location.reload();
        } else if (action.refreshedOnCompleted) {
          refresh();
        }
      } catch (error) {
        notifyError(error);
      }
    }
  };

  const onClick = (isConfirmed) => {
    if (!isConfirmed && !item.dto) {
      setConfirm((prev) => ({
        ...prev,
        open: true,
        message: item?.confirmMessage || `Do you want to ${name}?`,
      }));
      return;
    }

    setConfirm((prev) => ({ ...prev, confirming: true }));

    handleConfirm().finally(() =>
      setConfirm((prev) => ({ ...prev, open: false, confirming: false })),
    );
  };

  return {
    isAccessible,
    isVisible,
    confirm,
    condition: isAccessible && isVisible,
    name,
    icon,
    variant,
    color,
    className,
    colorProp,
    onClick,
    callToActionForm,
    setCallToActionForm,
    onCloseConfirm,
  };
};

export default useCtaButton;
