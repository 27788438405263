import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@mui/material';
import { get, isBoolean, isEmpty } from 'lodash';
import { useRecordContext } from 'react-admin';

const FieldTooltip = (props) => {
  const {
    data,
    ids,
    source,
    children,
    loading, // eslint-disable-line react/prop-types, no-unused-vars
    ...rest
  } = props;
  const record = useRecordContext();
  let tooltipTitle;

  if (ids?.length && data) {
    // array field
    tooltipTitle = ids.map((id) => data[id][source]).join(', ');
  } else {
    tooltipTitle = get(record, source);
  }

  if (typeof tooltipTitle !== 'string') {
    tooltipTitle = JSON.stringify(tooltipTitle);
  }

  const tooltipVisible =
    !isEmpty(String(tooltipTitle)) && !isBoolean(tooltipTitle);

  return (
    <Tooltip
      title={<span>{tooltipTitle}</span>}
      placement="bottom"
      disableHoverListener={!tooltipVisible}
    >
      <span>
        {React.cloneElement(children, {
          data,
          ids,
          source,
          ...rest,
          ...children.props,
          record,
        })}
      </span>
    </Tooltip>
  );
};

FieldTooltip.propTypes = {
  record: PropTypes.object,
  data: PropTypes.object,
  ids: PropTypes.array,
  source: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
};

FieldTooltip.defaultProps = {
  record: {},
  ids: [],
  data: {},
};

export default FieldTooltip;
