import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  valueTxt: {
    color: '#000',
    fontFamily: 'QuickSandSemiBold',
    fontSize: 13,
  },
}));

export default useStyles;
