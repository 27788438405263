import { takeLatest, put } from 'redux-saga/effects';
import axios from '../../provider/axios';
import { GET_AUTH_INFO, GET_AUTH_INFO_SUCCESS, GET_AUTH_INFO_FAILED } from '../root.actions';

function* fetchAuthData() {
  const { data } = yield axios.get('/Auth/me');
  try {
    yield put({
      type: GET_AUTH_INFO_SUCCESS,
      payload: {
        user: data,
      },
    });
  } catch {
    yield put({
      type: GET_AUTH_INFO_FAILED,
      payload: {
        user: {},
      },
    });
  }
}

export function* authSaga() {
  yield takeLatest(GET_AUTH_INFO, fetchAuthData);
}
