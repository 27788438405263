import { Box } from '@mui/material';
import Hidden from '@mui/material/Hidden';
import makeStyles from '@mui/styles/makeStyles';
import React, { useMemo, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import useAuthUser from '../../../../../base/hooks/useAuthUser';
import useSetting from '../../../../../base/hooks/useSetting';
import configs from '../../../../../services/configs';
import CmtImage from '../../../../@coremat/CmtImage';

const LOGO_MERCHANT = '/images/logo-transparent.png';
const LOGO_SYSTEM = '/images/logo-transparent.png';
const LOGO_SYSTEM_SYMBOL = '/images/logo-transparent.png';

const useStyles = makeStyles(() => ({
  link: {
    display: 'flex',
  },
}));

const Logo = ({ color, ...props }) => {
  const classes = useStyles();
  const settings = useSetting();
  const user = useAuthUser();
  const logoRef = useRef();

  const logoUrlData = useMemo(() => {
    const logoResult = {
      logoSymbol: LOGO_SYSTEM_SYMBOL,
      logoSetting: '',
      logoFallback: '',
    };

    const getLogoFallback = (logo) => logo;

    if (user?.merchant?.id) {
      logoResult.logoSetting = settings?.merchantSettings?.siteLogo
        ? `${configs.apiUrl}/bo/blob/static?blobId=${settings.merchantSettings.siteLogo.id}`
        : '';
      logoResult.logoFallback = getLogoFallback(LOGO_MERCHANT);
    } else {
      logoResult.logoSetting = settings?.site_logo || '';
      logoResult.logoFallback = getLogoFallback(LOGO_SYSTEM);
    }
    return logoResult;
  }, [settings, user?.merchant?.id]);

  const handleLogoFallback = (logoFallback) => {
    logoRef.current.onerror = null;
    logoRef.current.src = logoFallback;
  };

  return (
    <Box className="pointer" {...props}>
      <Hidden mdDown>
        <NavLink to="/" className={classes.link}>
          <CmtImage
            ref={logoRef}
            src={logoUrlData.logoSetting}
            alt="logo"
            height={37}
            onError={() => handleLogoFallback(logoUrlData.logoFallback)}
          />
        </NavLink>
      </Hidden>
    </Box>
  );
};

export default Logo;
