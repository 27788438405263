/* eslint-disable no-unused-vars */
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Tooltip from '@mui/material/Tooltip';
import CasinoIcon from '@mui/icons-material/Casino';
import PropTypes from 'prop-types';
import React from 'react';
import { InputPropTypes, useInput } from 'react-admin';
import { TextInput } from './index';

const generateCode = (minLength, maxLength) => {
  const lengthDiff = maxLength - minLength;
  const rand = Math.ceil(Math.random() * lengthDiff);
  const length = rand + minLength;
  const stringA = Math.random().toString(36).substring(2);
  const stringB = Math.random().toString(36).substring(2);

  return `${stringA}${stringB}`.substring(0, length).toUpperCase();
};

const RandomCodeInput = React.forwardRef((props, ref) => {
  const {
    record,
    source,
    disabled,
    label,
    resource,
    minLength,
    maxLength,
    length,
    ...rest
  } = props;
  const {
    field: { onChange, ...inputProps },
  } = useInput(props);

  const handleOnClick = () => {
    onChange(generateCode(minLength, maxLength));
  };

  return (
    <TextInput
      label={label}
      source={source}
      fullWidth
      InputProps={{
        length,
        endAdornment: (
          <Tooltip title="Random">
            <InputAdornment position="end">
              <IconButton color="primary" onClick={handleOnClick} size="large">
                <CasinoIcon />
              </IconButton>
            </InputAdornment>
          </Tooltip>
        ),
      }}
      disabled={disabled}
      {...inputProps}
      {...rest}
      ref={ref}
    />
  );
});

RandomCodeInput.propTypes = {
  ...InputPropTypes,
  maxLength: PropTypes.number,
  minLength: PropTypes.number,
};

RandomCodeInput.defaultProps = {
  maxLength: 6,
  minLength: 6,
};

export default RandomCodeInput;
