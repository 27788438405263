const ACTIONS = {
  SET_DROPOFF: 'SET_DROPOFF',
  SET_PICKUP: 'SET_PICKUP',

  ADD_DROPOFF: 'ADD_DROPOFF',
  UPDATE_DROPOFF: 'UPDATE_DROPOFF',
  REMOVE_DROPOFF: 'REMOVE_DROPOFF',
};

export default ACTIONS;
