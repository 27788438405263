import { Box } from '@mui/material';
import MUICheckbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

const StyledFormControlLabel = styled((props) => (
  <FormControlLabel {...props} />
))(() => ({
  '& .MuiFormControlLabel-label': {
    color: '#000000',
    fontFamily: 'QuickSandSemiBold',
    fontSize: 16,
  },
}));

const Checkbox = ({ label, onChange, defaultChecked, checked }) => {
  return (
    <Box>
      <StyledFormControlLabel
        onChange={onChange}
        control={
          <MUICheckbox checked={checked} defaultChecked={defaultChecked} />
        }
        label={label}
      />
    </Box>
  );
};

Checkbox.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  defaultChecked: PropTypes.bool,
  checked: PropTypes.bool,
};

Checkbox.defaultProps = {
  label: '',
  onChange: undefined,
  defaultChecked: false,
  checked: false,
};

export default memo(Checkbox);
