import {
  TOGGLE_LOADING,
  SET_PERCENT_LOADING,
  TOGGLE_FEEDBACK,
} from '../root.actions';

const initialApps = {
  isLoading: false,
  percentLoading: 0,
  isFeedBackShown: false,
};

export const appReducer = (state = initialApps, action) => {
  switch (action.type) {
    case TOGGLE_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case TOGGLE_FEEDBACK:
      return {
        ...state,
        isFeedBackShown: action.payload,
      };
    case SET_PERCENT_LOADING:
      return {
        ...state,
        percentLoading: action.payload,
      };
    default:
      return state;
  }
};
