import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Input from "@mui/material/Input";
import { Chip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { isEmpty } from "lodash";

const ENTER_KEY_CODE = 13;
const DELETE_KEY_CODE = 8;

const useStyles = makeStyles(() => ({
  root: {},
  input: {
    fontSize: "12px",
    lineHeight: "18px",
    height: 24,
    margin: 3,
    paddingLeft: 12,
    paddingRight: 12,
    borderRadius: 6,
    fontWeight: 500,
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "& input": {
      minWidth: "100px",
    },
  },
  chip: {},
}));

const EditableChip = ({
  onDelete,
  onChange,
  value: defaultValue,
  className,
  editable,
  ...props
}) => {
  const classes = useStyles();
  const [isEditing, setEditing] = useState(false);
  const [label, setLabel] = useState(defaultValue?.label);

  useEffect(() => {
    setLabel(defaultValue?.label);
  }, [defaultValue?.label]);

  useEffect(() => {
    if (!isEditing) {
      onChange?.({
        ...defaultValue,
        label,
      });
    }
  }, [isEditing]);

  const handleChange = (e) => setLabel(e.target.value || "");

  const handleFocus = () => {
    if (isEditing && isEmpty(label)) {
      onDelete?.();
      return;
    }
    if (editable) {
      setEditing((prev) => !prev);
    }
  };

  const handleKeyDown = (e) => {
    switch (e.keyCode) {
      case ENTER_KEY_CODE:
        handleFocus();
        break;
      case DELETE_KEY_CODE:
        e.stopPropagation();
        break;
      default:
        break;
    }
  };

  if (isEditing) {
    return (
      <Input
        className={classes.input}
        value={label}
        onChange={handleChange}
        onBlur={handleFocus}
        onKeyDown={handleKeyDown}
        autoFocus
        disableUnderline
      />
    );
  }

  return (
    <Chip
      {...props}
      className={clsx(classes.chip, className)}
      label={label || ""}
      onClick={handleFocus}
      clickable
      onDelete={onDelete}
    />
  );
};

EditableChip.propTypes = {
  onDelete: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.object,
  className: PropTypes.string,
  editable: PropTypes.bool,
};

EditableChip.defaultProps = {
  onDelete: undefined,
  onChange: undefined,
  value: undefined,
  className: undefined,
  editable: true,
};

export default EditableChip;
