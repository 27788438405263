import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  button: {
    height: 42,
    fontSize: 14,
    backgroundColor: '#002169',
    color: '#ffffff',
    borderRadius: 10,

    padding: theme.spacing(2, 8),
    alignSelf: 'center',

    '& span': {
      fontFamily: 'QuickSandSemiBold',
    },
  },

  textVariantButton: {
    minHeight: '30px',
    height: 'auto',
    backgroundColor: '#ffffff',
    color: '#000000',
    padding: theme.spacing(1),
    marginLeft: theme.spacing(-1),
  },

  buttonDisabled: {
    backgroundColor: '#f2f2f2',
  },

  buttonSuccess: {
    backgroundColor: '#4ea40f',
    '& svg': {
      fontSize: 16,
    },
  },

  loading: {
    color: '#dcdcdc',
  },
}));

export default useStyles;
