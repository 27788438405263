import NotificationsIcon from '@mui/icons-material/Notifications';
import { alpha, Box, IconButton, Tooltip } from '@mui/material';
import Badge from '@mui/material/Badge';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSocketContext } from '../../../../../../../base/context/socket';
import { SET_INVISIBLE_INBOX_COUNT } from '../../../../../../../services/redux/notification/notification.action';
import NotificationPopup from './NotificationPopup';

const useStyles = makeStyles((theme) => ({
  iconRoot: {
    position: 'relative',
    color: alpha(theme.palette.common.white, 0.38),
    '&:hover, &.active': {
      color: theme.palette.common.white,
    },
    height: '30px',
    background: '#ebedf0',
    borderRadius: '21px',
    margin: '0 8px',
    border: 'solid 1px #e2e3e5',
  },
  counterRoot: {
    color: theme.palette.common.white,
    border: `solid 1px ${theme.palette.common.white}`,
    backgroundColor: theme.palette.error.main,
  },
  hideBage: { display: 'none' },
}));

const HeaderNotifications = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const { socketClient, connected } = useSocketContext();
  const { inboxCount, id } = useSelector((state) => state.auth?.user);
  const { countInvisible: inboxCountInvisible } = useSelector(
    ({ notification }) => notification.inbox,
  );

  React.useEffect(() => {
    if (connected) {
      socketClient.emit('joinInboxRoom');
      socketClient.on(`MYINBOX-${id}`, (data) => {
        dispatch({
          type: SET_INVISIBLE_INBOX_COUNT,
          countInvisible: data?.unread ?? 0,
        });
      });
    }
  }, [socketClient, connected]);

  useEffect(() => {
    dispatch({ type: SET_INVISIBLE_INBOX_COUNT, countInvisible: inboxCount });
  }, [inboxCount]);

  const toggle = () => setOpen(!open);

  const onOpenPopOver = () => {
    toggle();
    requestAnimationFrame(() => {
      window.dispatchEvent(new CustomEvent('resize'));
    });
  };

  return (
    <Box>
      <Tooltip title="Notifications">
        <IconButton
          onClick={onOpenPopOver}
          className={clsx(classes.iconRoot, 'Cmt-appIcon')}
          size="large"
        >
          <Badge
            classes={{
              badge: clsx(
                classes.counterRoot,
                !inboxCountInvisible && classes.hideBage,
              ),
            }}
            variant="dot"
            overlap="rectangular"
          >
            <NotificationsIcon />
          </Badge>
        </IconButton>
      </Tooltip>

      {open && <NotificationPopup onClose={toggle} open={open} />}
    </Box>
  );
};

export default memo(HeaderNotifications);
