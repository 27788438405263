import { Person } from '@mui/icons-material';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import NormalInput from '../normal';
import useStyles from './index.style';

const PassengerNameField = ({ fieldName }) => {
  const classes = useStyles();
  const { setValue, unregister } = useFormContext();

  const inputRef = useRef();

  const handleChange = (event) => {
    event.preventDefault();
    const { value } = event?.target || {};
    setValue(fieldName, value);
  };

  useEffect(() => {
    inputRef.current.getElementsByTagName('input')[0].maxLength = 255;
    return () => {
      unregister(fieldName);
    };
  }, []);

  return (
    <Box className={classes.root}>
      <NormalInput
        ref={inputRef}
        onChange={handleChange}
        placeholder="Name (required)"
        startAdornment={<Person />}
      />
    </Box>
  );
};

PassengerNameField.propTypes = {
  fieldName: PropTypes.string.isRequired,
};

PassengerNameField.defaultProps = {};

export default PassengerNameField;
