import { Box, Button, Hidden } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { useTranslate } from 'react-admin';
import SearchDialog from './search.dialog';

const useStyles = makeStyles(() => ({
  search: {
    background: '#ebedf0',
    color: '#969faf',
    alignItems: 'center',
    border: 0,
    borderRadius: '40px',
    cursor: 'pointer',
    display: 'flex',
    fontWeight: 500,
    height: '30px',
    justifyContent: 'space-between',
    margin: '0 0 0 16px',
    padding: '0 8px',
    userSelect: 'none',
  },
  placeHolder: {
    fontSize: '1rem',
    padding: '0 12px 0 6px',
  },
  buttonGroup: {
    display: 'flex',
    minWidth: 'calc(40px + 0.8em)',
  },
  buttonKey: {
    alignItems: 'center',
    background: 'linear-gradient(-225deg,#d5dbe4,#f8f8f8)',
    borderRadius: '3px',
    boxShadow:
      'inset 0 -2px 0 0 #cdcde6,inset 0 0 1px 1px #fff,0 1px 2px 1px rgba(30,35,90,0.4)',
    color: '#969faf',
    display: 'flex',
    height: '18px',
    justifyContent: 'center',
    marginRight: '0.4em',
    paddingBottom: '2px',
    position: 'relative',
    top: '-1px',
    width: '20px',
  },
}));

const SearchBar = () => {
  const classes = useStyles();
  const translate = useTranslate();
  const [openDialog, setOpenDialog] = React.useState(false);
  function handleCloseDialog() {
    setOpenDialog(false);
  }

  return (
    <Box>
      <Button
        id="search-bar-trigger-button"
        className={classes.search}
        onClick={() => setOpenDialog(true)}
      >
        <svg
          width="20"
          height="20"
          className="DocSearch-Search-Icon"
          viewBox="0 0 20 20"
        >
          <path
            // eslint-disable-next-line
            d="M14.386 14.386l4.0877 4.0877-4.0877-4.0877c-2.9418 2.9419-7.7115 2.9419-10.6533 0-2.9419-2.9418-2.9419-7.7115 0-10.6533 2.9418-2.9419 7.7115-2.9419 10.6533 0 2.9419 2.9418 2.9419 7.7115 0 10.6533z"
            stroke="currentColor"
            fill="none"
            fillRule="evenodd"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <Hidden smDown>
          <span className={classes.placeHolder}>
            {translate('ra.text.searchPlaceholder')}
          </span>
          <span className={classes.buttonGroup}>
            <span className={classes.buttonKey}>⌘</span>
            <span className={classes.buttonKey}>K</span>
          </span>
        </Hidden>
      </Button>
      <SearchDialog open={openDialog} close={() => handleCloseDialog()} />
    </Box>
  );
};

export default SearchBar;
