import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import { Button } from '@mui/material';
import { DateCalendar, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { getSafeValue } from '../../../helpers';
import BookingBottomSheet from '../../bottom-sheet';

moment.locale('en');
const DateInput = ({
  inputBaseOnButtonClassName,
  onChange,
  label,
  defaultInputLabel,
  calendarProps,
  fieldName,
  disableSetFormValue,
}) => {
  const [open, setOpen] = useState(false);
  const [localValue, setLocalValue] = useState(defaultInputLabel);
  const [dateCalendarKey, setDateCalendarKey] = useState(1);
  const { setValue, getValues } = useFormContext();

  const value = disableSetFormValue
    ? localValue
    : getSafeValue(getValues(), fieldName, defaultInputLabel);

  const valueIsValid = moment(value).isValid();

  const handleTextFieldClick = () => {
    setOpen(true);
  };

  const onClose = useCallback(() => {
    setOpen(false);
  }, []);

  const onDateChange = (event) => {
    const date = moment(event).format('YYYY-MM-DD');
    onChange?.({ event, value: date });
    setOpen(false);
    if (!disableSetFormValue) setValue(fieldName, date);
    else setLocalValue(date);
  };

  useEffect(() => {
    setLocalValue(defaultInputLabel);
  }, [defaultInputLabel]);

  useEffect(() => {
    if (!valueIsValid) {
      setDateCalendarKey(moment().unix());
    }
  }, [valueIsValid]);

  return (
    <>
      <Button
        startIcon={<CalendarMonthRoundedIcon />}
        onClick={handleTextFieldClick}
        className={inputBaseOnButtonClassName}
      >
        {value}
      </Button>

      <BookingBottomSheet open={open} onClose={onClose} title={label}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateCalendar
            key={dateCalendarKey}
            {...calendarProps}
            {...(valueIsValid && { value })}
            onChange={onDateChange}
          />
        </LocalizationProvider>
      </BookingBottomSheet>
    </>
  );
};

DateInput.propTypes = {
  fieldName: PropTypes.string.isRequired,
  inputBaseOnButtonClassName: PropTypes.string,
  label: PropTypes.string,
  defaultInputLabel: PropTypes.string,
  onChange: PropTypes.func,
  calendarProps: PropTypes.object,
  disableSetFormValue: PropTypes.bool,
};

DateInput.defaultProps = {
  inputBaseOnButtonClassName: null,
  label: null,
  defaultInputLabel: 'Today',
  onChange: undefined,
  calendarProps: {},
  disableSetFormValue: false,
};

export default DateInput;
