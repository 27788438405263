import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  root: {
    height: '42px',
    width: '100%',
    background: 'white',
    border: '1px solid #CBD5E1',
    borderRadius: '8px',
    padding: '0px 16px',

    '& input': {
      color: 'black',
      fontSize: '16px',
    },
    '&:before': {
      borderBottom: 'none!important',
    },
    '&:after': {
      borderBottom: 'none',
    },
    '& svg': {
      marginRight: '10px',
    }
  },
}));

export default useStyles;
