import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(4),
  },
  title: {
    fontSize: 15,
    fontFamily: 'QuickSandBold',
    color: '#686868',
    marginBottom: theme.spacing(4),
  },
  telInput: {
    minHeight: 42,
    '& .MuiOutlinedInput-root': {
      minHeight: 42,
      backgroundColor: 'white',
      border: '1px solid #CBD5E1',
    },
    '& p': {
      fontSize: 14,
      color: 'black',
    },
    '& input': {
      fontSize: 16,
      color: 'black',
    },
  },
  classes: {},
}));

export default useStyles;
