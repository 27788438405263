import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Box, Alert, AlertTitle } from '@mui/material';
import { useLocaleState } from 'react-admin';

const PermanentAnnouncement = ({
  isVisible, subject, content, onClose,
}) => {
  const [open, setOpen] = React.useState(isVisible);
  const [locale] = useLocaleState();

  const handleClose = () => {
    if (onClose instanceof Function) {
      onClose();
    }
    setOpen(false);
  };

  if (!open) return null;

  return (
    <Box
      mt={4}
      mb={4}
    >
      <Alert
        severity="info"
        onClose={handleClose}
      >
        <AlertTitle>{subject?.[locale]}</AlertTitle>
        {content?.[locale]}
      </Alert>
    </Box>
  );
};

PermanentAnnouncement.propTypes = {
  subject: PropTypes.string,
  content: PropTypes.string,
  isVisible: PropTypes.bool,
  onClose: PropTypes.func,
};

PermanentAnnouncement.defaultProps = {
  subject: '',
  content: '',
  isVisible: true,
  onClose: undefined,
};

export default memo(PermanentAnnouncement);
