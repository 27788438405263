import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import { Box, ButtonBase, Typography } from '@mui/material';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import useStyles from './index.style';

const BookingLocationItem = (props) => {
  const { onClick, location } = props;
  const { address, addressName } = location || {};
  const classes = useStyles();
  return (
    <ButtonBase className={classes.item} onClick={onClick}>
      <Box className={classes.locationIconContainer}>
        <LocationOnRoundedIcon />
      </Box>
      <Box className={classes.infoContainer}>
        <Typography
          component="span"
          className={clsx(classes.txtName, !address && classes.txtNameOnly)}
        >
          {addressName}
        </Typography>
        <Typography component="span" className={classes.txtAddress}>
          {address}
        </Typography>
      </Box>
      <ArrowForwardRoundedIcon />
    </ButtonBase>
  );
};

BookingLocationItem.propTypes = {
  onClick: PropTypes.func,
};

BookingLocationItem.defaultProps = {
  onClick: undefined,
};

export default BookingLocationItem;
