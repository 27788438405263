import {
  Badge,
  Box,
  Card,
  CardContent,
  Fade,
  List,
  Popper,
  Typography,
} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import React, { cloneElement, isValidElement, useContext } from 'react';
import useAnalyticsTracker from '../../../../../src/base/hooks/useAnalyticsTracker';
import { GA_EVENTS } from '../../../../base/constants';
import LayoutContext from '../../CmtLayouts/Horizontal/LayoutContext/LayoutContext';

const useStyles = makeStyles((theme) => ({
  navMenuItem: {
    padding: '0',
    position: 'relative',
    '.Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &': {
      paddingLeft: 16,
    },
  },
  muiSubmenu: {
    '& .MuiMenuItem-root': {
      width: 180,
    },

    '& .MuiBadge-anchorOriginTopRightRectangular': {
      position: 'relative',
      transform: 'none',
      right: 0,
      top: 0,
    },
  },
  navMenuLink: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px 12px',
    marginBottom: '2px',
    borderRadius: 8,
    cursor: 'pointer',
    color: theme.palette.sidebar.textColor,
    minWidth: 170,
    '&:hover, &:focus': {
      color: theme.palette.sidebar.textColor,
      backgroundColor: theme.palette.sidebar.navHoverBgColor,
      '& .Cmt-icon-root, & .Cmt-nav-text': {
        color: theme.palette.sidebar.textColor,
      },
    },
    '&.active': {
      color: theme.palette.sidebar.textColor,
      backgroundColor: theme.palette.sidebar.navHoverBgColor,
      '& .Cmt-icon-root, & .Cmt-nav-text': {
        color: theme.palette.sidebar.textColor,
      },
      '&:hover, &:focus': {
        '& .Cmt-nav-text, & .Cmt-icon-root': {
          color: theme.palette.sidebar.textColor,
        },
      },
    },
    '.Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &': {
      justifyContent: 'center',
      padding: 0,
      height: 40,
      width: 40,
      borderRadius: '50%',
      marginLeft: 4,
      marginRight: 4,
    },
  },
  navText: {
    flex: 1,
    fontSize: 14,
    '.Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &': {
      display: 'none',
    },
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  iconRoot: {
    marginRight: 8,
    fontSize: 16,
    '.Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &': {
      marginRight: 0,
    },
  },
  popper: {
    zIndex: 9999,
    maxHeight: '100vh',
    boxShadow: theme.components.MuiPaper.styleOverrides.elevation1.boxShadow,
    overflowY: 'scroll',
    '@media screen and (max-width: 369px)': {
      transform: 'translate3d(170px, 5px, 0px) !important',
    },
  },
  counterRoot: {
    right: '15px',
    top: '-2px',
  },
  sectionName: {
    fontSize: 11,
    fontWeight: 400,
    lineHeight: '14px',
    textTransform: 'uppercase',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  popperCard: {},
  popperCardContent: {
    padding: `${theme.spacing(3)} !important`,
  },
}));

const NavMenuItem = (props) => {
  const { id, title, icon, link, count, children, onClick } = props;
  const classes = useStyles();
  const { setOpen } = useContext(LayoutContext);
  const eventGATracker = useAnalyticsTracker('SIDEBAR MENU');

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const pathname = history.location?.pathname;
  const isActive = pathname === link;
  const handleOnClick = (event) => {
    event.stopPropagation();
    setOpen(false);
    handleOnBlur();
    onClick?.();

    eventGATracker(`${GA_EVENTS.CLICK}${title}`, 'MENU');
  };

  const handleOnChildClick = (item) => {
    handleOnBlur();
    eventGATracker(`${GA_EVENTS.CLICK}${item.title}`, 'SUB MENU');
  };

  const handleOnHover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOnBlur = () => {
    setAnchorEl(null);
  };

  const renderIcon = () => {
    if (icon && isValidElement(icon)) {
      return cloneElement(icon, {
        className: clsx(classes.iconRoot, 'Cmt-icon-root'),
      });
    }

    return null;
  };

  const renderBadge = () => {
    if (count > 0) {
      return (
        <Box ml={2}>
          <Badge
            badgeContent={count}
            color="secondary"
            classes={{
              badge: classes.counterRoot,
            }}
            overlap="rectangular"
          />
        </Box>
      );
    }
    return null;
  };

  const MenuItemChildren = !!children?.length && (
    <Popper
      open={open}
      anchorEl={anchorEl}
      transition
      className={classes.popper}
      placement="right"
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <Card className={classes.popperCard}>
            <CardContent className={classes.popperCardContent}>
              <List component="div" disablePadding>
                {children.map((item) => (
                  <div key={`${id}-${item.id}`}>
                    <Typography variant="body2" className={classes.sectionName}>
                      {item.title}
                    </Typography>
                    <div className={classes.muiSubmenu}>
                      {item.children?.map((x) => (
                        <NavMenuItem
                          {...x}
                          key={`${id}-${item.id}-${x.id}`}
                          onClick={() => handleOnChildClick(x)}
                        />
                      ))}
                    </div>
                  </div>
                ))}
              </List>
            </CardContent>
          </Card>
        </Fade>
      )}
    </Popper>
  );

  return (
    <MenuItem
      className={clsx(
        classes.navMenuLink,
        'Cmt-nav-menu-link',
        isActive && 'active',
      )}
      onClick={handleOnClick}
      onMouseEnter={handleOnHover}
      onMouseLeave={handleOnBlur}
      component="a"
      href={`#${link}`}
    >
      {/* Display an icon if any */}
      {renderIcon()}
      <Box
        component="span"
        flex={1}
        className={clsx(classes.navText, 'Cmt-nav-text')}
      >
        {title}
      </Box>
      {renderBadge()}
      {MenuItemChildren}
    </MenuItem>
  );
};

export default NavMenuItem;
