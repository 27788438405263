import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  freezeWindow: {
    overflow: 'hidden !important',
  },
  drawerPaper: {
    borderRadius: '20px 20px 0px 0px',
    zIndex: theme.zIndex.drawer + 1,
    margin: '0 auto',
  },
  drawerPaperMinHeight: {
    minHeight: '495px',
  },

  container: {
    padding: theme.spacing(4, 4),
  },

  allowMinHeight: {
    minHeight: 330,
  },

  inner: {
    position: 'relative',
    '&::after': {
      content: "''",
      display: 'block',
      width: '25%',
      height: 2,
      backgroundColor: '#E2E2EA',

      position: 'absolute',
      top: theme.spacing(-2),
      left: 0,
      right: 0,
      borderRadius: 10,
      margin: '0 auto',
    },
  },

  header: {
    display: 'flex',
    flexDirection: 'row',
    position: 'sticky',
    top: 0,

    gap: theme.spacing(2),
    padding: theme.spacing(4, 0, 2),
    alignItems: 'center',
    backgroundColor: '#ffffff',
  },

  txtTitle: {
    fontSize: 20,
    lineHeight: '30px',
    fontFamily: 'QuickSandBold',
  },

  article: {},
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
}));

export default useStyles;
