import { Box, Typography } from '@mui/material';
import { MuiTelInput } from 'mui-tel-input';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { validatePhone } from '../../../utils';
import useStyles from './index.style';

const PhoneField = ({ fieldName }) => {
  const { setValue, unregister } = useFormContext();
  const classes = useStyles();
  const telInput = useRef();

  const [phone, setPhone] = React.useState('');
  const [error, setError] = React.useState(false);

  const handleChange = (val) => {
    setValue(fieldName, val);
    setPhone(val);
    if (!val || val?.length === 3) {
      setError(false);
    } else {
      setError(!validatePhone({ phone: val }));
    }
  };

  useEffect(() => {
    telInput.current.getElementsByTagName('input')[0].maxLength = 8;
    return () => {
      setPhone('');
      setValue(fieldName, '');
      unregister(fieldName);
    };
  }, []);

  return (
    <Box className={classes.root}>
      <MuiTelInput
        ref={telInput}
        value={phone}
        className={classes.telInput}
        forceCallingCode
        onlyCountries={['SG']}
        defaultCountry="SG"
        disableDropdown
        onChange={handleChange}
        disableFormatting
        placeholder="Phone number (required)"
        error={error}
      />
      {error && (
        <Typography
          sx={{
            fontSize: 12,
            color: 'red',
          }}
        >
          Invalid phone number
        </Typography>
      )}
    </Box>
  );
};

PhoneField.propTypes = {
  fieldName: PropTypes.string.isRequired,
};

PhoneField.defaultProps = {};

export default PhoneField;
