import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  time: {
    '& span.MuiClockNumber-root:not(.Mui-disabled)': {
      fontFamily: 'QuickSandBold',
    },
  },
}));

export default useStyles;
