import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
import { io } from 'socket.io-client';
import configs from '../../../services/configs';
import useInterval from '../../hooks/useInterval';

const SocketContext = React.createContext(null);
export const useSocketContext = () => useContext(SocketContext);

export const SocketContextProvider = ({ children }) => {
  const [socketClient, setSocketClient] = React.useState(null);

  useInterval(() => {
    if (socketClient?.connected) {
      socketClient.emit('ping');
    }
  }, 10000);

  useEffect(() => {
    if (configs.coniUrl) {
      const socket = io(configs.coniUrl, {
        transports: ['polling'],
        withCredentials: true,
        reconnectionDelay: 10000,
        reconnectionDelayMax: 40000,
      });
      socket.on('connect', () => {
        console.log('Connected To NFSocket', socket?.id);
        setSocketClient(socket);
      });
      socket.on('disconnect', () => {
        console.log('Disconnected From NFSocket');
        setSocketClient(null);
      });

      return () => {
        socket.disconnect();
        setSocketClient(null);
      };
    }
    return () => {};
  }, []);

  return (
    <SocketContext.Provider
      value={{
        socketClient,
        connected: socketClient?.connected,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

SocketContextProvider.propTypes = {
  children: PropTypes.object.isRequired,
};

SocketContextProvider.defaultProps = {};
