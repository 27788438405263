import {
  SaveButton as RaSaveButton,
  DeleteButton as RaDeleteButton,
  CreateButton as RaCreateButton,
  ListButton as RaListButton,
  EditButton as RaEditButton,
  Button as RaButton,
} from 'react-admin';
import { withStandardButtonProps } from '../../../hoc/with-standard-button-props';

export const SaveButton = withStandardButtonProps(RaSaveButton);
export const DeleteButton = withStandardButtonProps(RaDeleteButton);
export const CreateButton = withStandardButtonProps(RaCreateButton);
export const ListButton = withStandardButtonProps(RaListButton);
export const EditButton = withStandardButtonProps(RaEditButton);
export const Button = withStandardButtonProps(RaButton);
