import PropTypes from 'prop-types';
import {
  Chip,
  Tooltip,
  DialogContent,
  Typography,
  Dialog,
  IconButton,
  AppBar,
  Toolbar,
  Slide,
} from '@mui/material';
import React, { useState } from 'react';
import MapIcon from '@mui/icons-material/Map';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import { parseCoordinate } from '../../../utils';
import Gmap from '../../googleMap';

const Transition = React.forwardRef((props, ref) => (
  <Slide
    direction="up"
    ref={ref}
    {...props}
  />
));

const useStyles = makeStyles(theme => ({
  closeMapBtn: {
    '& .MuiSvgIcon-root': {
      color: theme.palette.common.white,
    },
  },
}));

const CoordinateField = props => {
  const classes = useStyles();
  const [mapOpen, setMapOpen] = useState(false);
  const settings = useSelector(({ setting }) => setting.commonSettings);
  const {
    record, source, view,
  } = props;
  const addressSource = `${source?.slice(0, source.indexOf('Point'))}Address`;
  const [lat, lng] = parseCoordinate(record?.[source]) || [null, null];

  const handleCloseMap = () => {
    setMapOpen(false);
  };

  const handleOpenMap = () => {
    setMapOpen(true);
  };

  return (
    <>
      {view === 'list' ? (
        `(${lat}, ${lng})`
      ) : (
        <>
          <Chip label={`Latitude: ${lat}`} />
          {' '}
          <Chip label={`Longitude: ${lng}`} />
          {' '}
          <Tooltip title="Show on map">
            <IconButton
              onClick={handleOpenMap}
              size="large"
            >
              <MapIcon />
            </IconButton>
          </Tooltip>
          {/* Dialog */}
          <Dialog
            fullScreen
            open={mapOpen}
            onClose={handleCloseMap}
            TransitionComponent={Transition}
          >
            {/* Dialog Appbar */}
            <AppBar>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleCloseMap}
                  aria-label="close"
                  size="large"
                  className={classes.closeMapBtn}
                >
                  <CloseIcon />
                </IconButton>
                <Typography>{record?.[addressSource]}</Typography>
              </Toolbar>
            </AppBar>

            {/* Content */}
            <DialogContent
              style={{
                padding: '64px 0 0 0',
              }}
            >
              {settings?.map_api_key ? (
                <Gmap
                  markerPos={{
                    lat,
                    lng,
                  }}
                />
              ) : (
                <>Loading...</>
              )}
            </DialogContent>
          </Dialog>
        </>
      )}
    </>
  );
};

CoordinateField.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.string,
  }),
  source: PropTypes.string.isRequired,
  view: PropTypes.string,
};
CoordinateField.defaultProps = {
  record: {},
  view: 'show',
};

export default React.memo(CoordinateField);
