import { Box, Button, Popover } from '@mui/material';
import { isEmpty } from 'lodash';
import moment from 'moment';
import 'moment/locale/th';
import 'moment/locale/zh-cn';
import React, { useEffect, useState } from 'react';
import { useLocaleState } from 'react-admin';
import CmtCard from '../../../../../theme/@coremat/CmtCard';
import CmtList from '../../../../../theme/@coremat/CmtList';
import { useLanguageContext } from '../../../../context/language';
import { useLocalStorage } from '../../../../hooks/useLocalStorage';
import LanguageItem from './LanguageItem';
import useStyles from './index.style';

const getLanguages = (locale, resolve) => {
  let data = localStorage.getItem(`languages`);
  data = data ? JSON.parse(data)?.languages : null;

  if (!isEmpty(data)) {
    resolve(data);
  } else {
    setTimeout(() => getLanguages(locale, resolve), 100);
  }
};

const LanguageSwitcher = (props) => {
  const classes = useStyles(props);
  const [anchorEl, setAnchorEl] = useState(null);
  const [locale, setLocale] = useLocaleState();
  const { setLanguage, languages, setLanguages } = useLanguageContext();

  const { setLocalStorage } = useLocalStorage('nfLocale');

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'language' : undefined;

  const switchLanguage = (language) => {
    setLocale(language.locale);
    setLocalStorage(language.locale);
    handleClose();
  };

  useEffect(() => {
    getLanguages(locale, (data) => {
      const languagesObj = data.reduce((obj, item) => {
        obj[item.locale] = item;
        return obj;
      }, {});

      setLanguages(languagesObj);
    });
  }, []);

  useEffect(() => {
    if (locale) {
      setLocale(locale);
    }
  }, [locale]);

  useEffect(() => {
    if (languages?.[locale]) {
      setLanguage(languages?.[locale]);
      moment.locale(locale);
    }
  }, [languages?.[locale]]);

  return (
    <>
      <Button
        label={languages?.[locale]?.name || 'EN'}
        variant="text"
        size="small"
        onClick={handleClick}
        className={classes.langBtn}
      >
        {(languages?.[locale]?.locale ?? 'EN')?.toUpperCase()}
      </Button>

      <Popover
        className={classes.popoverRoot}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <CmtCard className={classes.cardRoot}>
          <Box className={classes.perfectScrollbarLanguage}>
            <CmtList
              data={Object.values(languages)}
              renderRow={(item, index) => (
                <LanguageItem
                  key={index}
                  language={item}
                  onClick={switchLanguage}
                />
              )}
            />
          </Box>
        </CmtCard>
      </Popover>
    </>
  );
};

export default LanguageSwitcher;
