/* eslint-disable react/require-default-props, no-param-reassign, react/prop-types, no-unused-vars */
import { Box, alpha, Grid, Menu, MenuItem, Typography } from '@mui/material';
import ContentFilterIcon from '@mui/icons-material/FilterList';
import { makeStyles } from '@mui/styles';
import axios from 'axios';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Button,
  FormDataConsumer,
  required,
  useLocaleState,
  useRecordContext,
} from 'react-admin';
import { useFormContext } from 'react-hook-form';
import get from 'lodash/get';
import { SelectInput } from '..';
import { useSchema } from '../../../../hooks';
import { parseTokenName, pascalCase } from '../../../../utils';
import DatePickerInput from './datePicker.input';
import { parseFilterInput } from './filter-input.guesser';
import RemoveFilterButton from './removeFilter.button';

const useStyles = makeStyles((theme) => ({
  formInputWrapper: {
    '& .react-tel-input': {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
      '& > input': {
        paddingTop: `${theme.spacing(2.5)} !important`,
        paddingBottom: `${theme.spacing(2.5)} !important`,
      },
    },
  },
  formInput: {
    minWidth: 'auto',
    width: '100%',
  },
  filterBox: {
    border: `1px solid ${alpha(theme.palette.common.black, 0.12)}`,
    borderRadius: 4,
    width: '100%',
    margin: 0,
    marginBottom: theme.spacing(4),
    padding: `${theme.spacing(2)} !important`,
  },
  datePickerInput: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    '& input': {
      minWidth: 'unset !important',
    },
  },
}));

function FiltersInput(props) {
  const { resource, source, isFiltersFixed, isFiltersTarget } = props;
  const record = useRecordContext();
  const { getValues, setValue } = useFormContext();
  const classes = useStyles();
  const [locale] = useLocaleState();
  const [selectedFilters, setSelectedFitlers] = useState([]);
  const [filterFormInputs, setFilterFormInputs] = useState([]);
  const [resourceProperties, setResourceProperties] = useState();
  const { ref } = useSchema();
  const filters = record?.[source];
  const values = getValues();

  // Hydrate value when edit
  useEffect(() => {
    if (filters && Object.keys(filters).length) {
      setSelectedFitlers([
        ...selectedFilters,
        ...Object.keys(filters).map((filterName) => filterName),
      ]);
    }
  }, [filters]);
  useEffect(() => {
    const filterInputs = parseFilterInput(
      Object.keys(resourceProperties || {})
        ?.filter((name) =>
          selectedFilters.some((i) => i === name || i.startsWith(`${name}&&`)),
        )
        ?.reduce((obj, name) => {
          obj[name] = resourceProperties[name];
          return obj;
        }, {}),
      ref,
      locale,
    );
    setFilterFormInputs(filterInputs);
  }, [selectedFilters, resourceProperties, ref, locale]);

  useEffect(() => {
    let mounted = true;
    async function fetchResource() {
      let name = null;
      if (isFiltersFixed) {
        const { data } = await axios.get(`resource/${isFiltersFixed}`);
        name = data.name;
      } else if (isFiltersTarget) {
        const { data } = await axios.get(
          `resource/${get(values, isFiltersTarget)?.id}`,
        );
        name = data.name;
      } else {
        console.error('UNKNOWN FILTER TARGET');
      }

      if (name === 'vip-point') {
        name = 'VIPPoint';
      }
      if (name && mounted) {
        const schema = ref?.get(`#/components/schemas/${parseTokenName(name)}`);
        const properties = Object.keys(schema?.properties || {})
          // Get supported-filters
          ?.filter((key) => !key.startsWith('_'))
          ?.reduce((obj, key) => {
            obj[key] = schema?.properties[key];
            return obj;
          }, {});

        setResourceProperties(properties);
      }
    }
    fetchResource();
    return () => {
      mounted = false;
    };
  }, [
    isFiltersFixed,
    ...(isFiltersTarget ? [get(values, isFiltersTarget)?.id] : []),
  ]);

  const handleAddFilter = (filterName) => {
    setSelectedFitlers([...selectedFilters, filterName]);
  };

  const handleRemoveFilter = (filterNKey) => {
    const [filterName, key] = filterNKey?.split('&&');
    setSelectedFitlers([
      ...selectedFilters.filter(
        (filter) => filter?.split('&&')?.[0] !== filterName,
      ),
    ]);

    if (filters) {
      delete filters[filterNKey];
      setValue(source, filters);
    }
  };

  const [open, setOpen] = useState(false);
  const anchorEl = useRef();

  const handleClickButton = useCallback(
    (event) => {
      // This prevents ghost click.
      event.preventDefault();
      setOpen(true);
      anchorEl.current = event.currentTarget;
    },
    [anchorEl, setOpen],
  );

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleSelectFilter = (name) => {
    handleAddFilter(name);
    handleClose();
  };

  return (
    <>
      <Button
        className="add-filter"
        label={`resources.${resource}.fields.${source}`}
        onClick={handleClickButton}
      >
        <ContentFilterIcon />
      </Button>
      {!!filterFormInputs?.length && (
        <Box className={classes.filterBox}>
          {filterFormInputs?.map((input) => (
            <Grid container spacing={4} key={`${input.props.source}`}>
              <Grid item sm={6} xs={6} container>
                <Box mt={1.25}>
                  <Typography>
                    <RemoveFilterButton
                      filterName={input.props.source}
                      onClick={handleRemoveFilter}
                    />
                    {pascalCase(input.props.source)}
                  </Typography>
                </Box>
              </Grid>
              <Grid item sm={3} xs={6}>
                {input?.props?.valueformat !== 'boolean' && (
                  <SelectInput
                    label="Condition"
                    source={`${source}.${input.props.source}.op`}
                    choices={input.props.ops}
                    validate={required()}
                    className={classes.formInput}
                  />
                )}
              </Grid>

              <Grid item sm={3} xs={12} className={classes.formInputWrapper}>
                <FormDataConsumer>
                  {({ formData, ...rest }) =>
                    !['$isnull', '$notnull'].includes(
                      formData?.[source]?.[input.props.source]?.op,
                    ) && (
                      <>
                        {input?.props?.valueformat === 'date' ? (
                          <DatePickerInput
                            source={`${source}.${input.props.source}`}
                            {...rest}
                            validate={required()}
                            className={clsx(
                              classes.formInput,
                              classes.datePickerInput,
                            )}
                          />
                        ) : (
                          React.cloneElement(input, {
                            source: `${source}.${input.props.source}.value`,
                            validate: required(),
                            ...rest,
                            className: classes.formInput,
                          })
                        )}
                      </>
                    )
                  }
                </FormDataConsumer>
              </Grid>
            </Grid>
          ))}
        </Box>
      )}
      <Menu open={open} anchorEl={anchorEl.current} onClose={handleClose}>
        {Object.keys(resourceProperties || {})
          ?.filter((name) => !selectedFilters.includes(name))
          ?.map((name) => (
            <MenuItem key={name} onClick={() => handleSelectFilter(name)}>
              {name}
            </MenuItem>
          ))}
      </Menu>
    </>
  );
}

FiltersInput.propTypes = {
  properties: PropTypes.object,
};

export default React.memo(FiltersInput);
