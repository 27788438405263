/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
/* eslint-disable no-nested-ternary */
import { Box, Grid, Input, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useInput, useTranslate } from 'react-admin';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useSchema } from '../../hooks';
import { getRefField, parseSourceRef } from '../../utils';
import { FieldTitle } from '../ra/fields/FieldTitle';
import { TipTapInput } from '../ra/inputs';
import PrintFormatField from './index.field';
import PrintFormatSection from './index.section';

const useStyles = makeStyles((theme) => ({
  allFieldContainer: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  columnTitle: {
    padding: theme.spacing(1, 2),
    width: '100%',
    textAlign: 'center',
    backgroundColor: theme.palette.divider,
  },
}));

const allFieldDroppableId = 'all-fields';
const selectedSectionDroppableId = 'selected-sections';
const selectedFieldDroppableId = 'selected-fields';

const defaultHeadingSection = {
  name: 'heading',
  html: '#{{displayName}}',
};
const isHeadingSection = (section) =>
  section.name === 'heading' && 'html' in section;

const PrintFormat = (props) => {
  const classes = useStyles();
  const resources = useSelector((state) => state.resource.resources || []);
  const translate = useTranslate();
  const { ref, getResourceReadableProperties } = useSchema();
  const {
    field: { onChange },
  } = useInput(props);
  const { getValues } = useFormContext();

  const values = getValues();

  const { resource, record, source, label } = props;

  const [headingSection, setHeadingSection] = useState(
    () => record?.[source]?.find(isHeadingSection) || defaultHeadingSection,
  );
  const [selectedSections, setSelectedSections] = useState(
    () => record?.[source]?.filter((x) => !isHeadingSection(x)) || [],
  );

  const dependOnResource = useMemo(
    () =>
      resources?.find((x) => x?.options?.resourceId === values?.resource?.id),
    [resources, values?.resource?.id],
  );

  const allFields = useMemo(() => {
    if (!dependOnResource) return [];

    const resourceName = dependOnResource?.name;

    const properties = getResourceReadableProperties(resourceName);

    const results =
      Object.keys(properties)
        ?.map((key) => {
          let field = key;

          const sourceSchema = properties[key];
          const refField = getRefField(sourceSchema);
          if (refField) {
            const sourceRef = parseSourceRef(key, properties, ref, 'en');
            field = `${key}.${sourceRef.source}`;
          }

          return {
            ltr: false,
            field,
            label: translate(`resources.${resourceName}.fields.${key}`),
          };
        })
        ?.sort((a, b) => a.label.localeCompare(b.label)) || [];
    results?.unshift({
      ltr: false,
      field: '_section',
      label: translate('resources.printFormat.text.newSection'),
    });

    return results;
  }, [dependOnResource, ref]);

  useEffect(() => {
    onChange([headingSection, ...selectedSections]);
  }, [headingSection, selectedSections]);

  const reorder = useCallback((list, startIndex, endIndex) => {
    const result = Array.from(list ?? []);
    try {
      const [removed] = result?.splice(startIndex, 1) || [];
      if (removed) {
        result.splice(endIndex, 0, removed);
      }
    } catch {} // eslint-disable-line no-empty

    return result;
  }, []);

  const onDragEnd = useCallback(
    ({ source, destination }) => {
      if (
        !source?.droppableId ||
        !destination?.droppableId ||
        !selectedSections?.length
      )
        return;

      const items = Array.from(selectedSections ?? []);

      // reorder sections/fields
      if (
        source?.droppableId &&
        source?.droppableId === destination?.droppableId
      ) {
        // sections
        if (source.droppableId === selectedSectionDroppableId) {
          const newItems = reorder(items, source.index, destination.index);
          setSelectedSections(newItems);
          return;
        }

        // section fields
        if (destination?.droppableId?.includes(selectedFieldDroppableId)) {
          // reorder fields in a section
          const sectionIndex = source.droppableId.replace(
            `${selectedFieldDroppableId}-`,
            '',
          );
          const sectionFields = items[sectionIndex].items || [];
          items[sectionIndex].items = reorder(
            sectionFields,
            source.index,
            destination.index,
          );
          setSelectedSections(items);
          return;
        }
      }

      // add field to a section
      if (
        source?.droppableId === allFieldDroppableId &&
        destination?.droppableId === selectedSectionDroppableId
      ) {
        const newField = allFields[source.index];

        // add new empty section
        if (newField.field === '_section') {
          items.splice(destination.index, 0, {
            name: `section ${items.length + 1}`,
            col: 1,
            items: [],
          });
          setSelectedSections(items);
          return;
        }

        // add new field
        let currentSection = items?.[destination.index];
        if (!currentSection) {
          // add new section if not exist
          currentSection = {
            name: `section ${items.length + 1}`,
            col: 1,
            items: [],
          };
          items.push(currentSection);
        }
        // add the field to current section
        // TODO need to insert at suitable index
        currentSection.items.push(newField);
        setSelectedSections(items);
      }
    },
    [selectedSections],
  );

  const onSectionChange = useCallback(
    ({ field, property, value, sectionIndex, index }) => {
      const items = Array.from(selectedSections ?? []);

      if (field) {
        // change field
        const section = items[sectionIndex];
        section.items[index][property] = value;
      } else {
        // change section
        items[index][property] = value;
      }

      setSelectedSections(items);
    },
    [selectedSections],
  );

  const onRemove = useCallback(
    ({ field, index, sectionIndex }) => {
      const items = Array.from(selectedSections ?? []);

      if (field) {
        // remove field
        items[sectionIndex].items.splice(index, 1);
      } else {
        // remove section
        items.splice(index, 1);
      }

      setSelectedSections(items);
    },
    [selectedSections],
  );

  const allFieldComponents = useMemo(
    () => (
      <Box className={classes.allFieldContainer}>
        <Typography className={classes.columnTitle} variant="body2">
          {translate('resources.printFormat.text.allFields')}
        </Typography>
        <Droppable droppableId={allFieldDroppableId}>
          {(provided) => (
            <Box
              {...provided.droppableProps}
              ref={provided.innerRef}
              flexDirection="column"
              minHeight="200px"
              maxHeight="600px"
              overflow="scroll"
            >
              {allFields.map((item, index) => (
                <PrintFormatField
                  key={`unselected-fields-${item.name}-${index}`}
                  field={item}
                  index={index}
                  draggableId={`${allFieldDroppableId}-${item.name}-${index}`}
                />
              ))}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </Box>
    ),
    [classes, allFields],
  );

  const formatStructureComponents = useMemo(
    () => (
      <Box height="100%">
        <Typography className={classes.columnTitle} variant="body2">
          {translate('resources.printFormat.text.formatStructure')}
        </Typography>
        <Droppable droppableId={selectedSectionDroppableId}>
          {(provided) => (
            <Box
              {...provided.droppableProps}
              ref={provided.innerRef}
              minHeight="200px"
              height="600px"
              overflow="scroll"
            >
              {selectedSections.map((section, sectionIndex) => (
                <PrintFormatSection
                  key={`${selectedSectionDroppableId}-${sectionIndex}`}
                  draggableId={`${selectedSectionDroppableId}-${section.name}-${sectionIndex}`}
                  section={section}
                  index={sectionIndex}
                  onChange={onSectionChange}
                  onRemove={onRemove}
                />
              ))}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </Box>
    ),
    [classes, selectedSections],
  );

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Box mb={4}>
        <Input
          id={source}
          value={JSON.stringify(selectedSections)}
          type="hidden"
        />
        <FieldTitle label={label} source={source} resource={resource} />
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box mb={2}>
              <Typography variant="caption">
                {translate('resources.printFormat.text.heading')}
              </Typography>
              <TipTapInput
                record={headingSection}
                source="html"
                onChange={(html) =>
                  setHeadingSection({
                    ...headingSection,
                    html,
                  })
                }
                multiline
                type="html"
              />
            </Box>
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={4}>
            {allFieldComponents}
          </Grid>
          <Grid item xs={6} sm={6} md={8} lg={8}>
            {formatStructureComponents}
          </Grid>
        </Grid>
      </Box>
    </DragDropContext>
  );
};

PrintFormat.propTypes = {
  resource: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
  label: PropTypes.string,
  record: PropTypes.object,
};

PrintFormat.defaultProps = {
  label: '',
  record: undefined,
};

export default PrintFormat;
