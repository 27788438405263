import { Box } from '@mui/material';
import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGetEventByCode } from '../api/features/common.feature';
import { useWithJobTypeButtons } from '../common/style';
import JobTypeButton from '../components/buttons/job-type';
import { EVENTS, JOB_TYPE } from '../constants';
import { publish } from '../events';
import { JOB_TYPE_ACTIONS } from '../services/redux/actions';
import {
  getSelectedJobType,
  getSelectedSubJobType,
} from '../services/redux/reducers/job-type.reducer';

const withJobTypeButtons = (WrappedComponent) => (props) => {
  const jobTypeButtonsStyles = useWithJobTypeButtons();
  const dispatch = useDispatch();

  const selectedJobType = useSelector(getSelectedJobType);
  const { type: typeOfJobType } = selectedJobType || {};

  const selectedSubJobType = useSelector(getSelectedSubJobType);
  const { id: subJobTypeId } = selectedSubJobType || {};

  const {
    data: eventInfoByCode,
    isSuccess,
    isError,
    refetch,
  } = useGetEventByCode({
    configQueryOptions: {
      refetchOnWindowFocus: false,
    },
    configApiOptions: {
      params: {
        subJobTypeId,
      },
    },
  });

  const { jobTypes } = eventInfoByCode || {};

  const chooseSelectedJobType = (jobTypeItem) => () => {
    dispatch({
      type: JOB_TYPE_ACTIONS.SET_SELECTED_JOB_TYPE,
      payload: jobTypeItem,
    });
  };

  useEffect(() => {
    if (!isEmpty(jobTypes)) {
      const standardJobType = jobTypes?.find(
        (jobType) => jobType.type === JOB_TYPE.STANDARD,
      );
      if (!isEmpty(standardJobType)) {
        dispatch({
          type: JOB_TYPE_ACTIONS.SET_SELECTED_JOB_TYPE,
          payload: standardJobType,
        });
      }
    }
  }, [jobTypes, dispatch]);

  useEffect(() => {
    if (typeOfJobType === JOB_TYPE.PREMIUM) {
      publish(EVENTS.PICKUP_LOCATION_CLICK);
    }
  }, [typeOfJobType]);

  useEffect(() => {
    refetch();
  }, [subJobTypeId]);

  return (
    <Box className={jobTypeButtonsStyles.root}>
      <Box className={jobTypeButtonsStyles.buttonWrapper}>
        {jobTypes?.map((jobType) => {
          const {
            name: { en },
            type,
          } = jobType || {};
          return (
            <Box
              key={jobType.id}
              className={jobTypeButtonsStyles.jobTypeBtnWrapper}
            >
              <JobTypeButton
                selected={typeOfJobType === type}
                name={en}
                onClick={chooseSelectedJobType(jobType)}
              />
            </Box>
          );
        })}
      </Box>
      <WrappedComponent {...props} isSuccess={isSuccess} isError={isError} />
    </Box>
  );
};

export default withJobTypeButtons;
