import { Collapse } from '@mui/material';
import { LoadScriptNext } from '@react-google-maps/api';
import { debounce, isEmpty } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  useGetEventByCode,
  usePostCreateReservation,
  usePostFee,
} from './api/features/common.feature';
import { useBookingCommonStyles } from './common/style';
import { BookingBottomSheet } from './components';
import ZoneAreas from './components/zone-areas';
import {
  EVENTS,
  FORM_FIELD_NAMES,
  PAYMENT_VALUES,
  SUB_JOB_TYPE,
} from './constants';
import { publish, subscribe, unsubscribe } from './events';
import withJobTypeButtons from './hoc/with-job-type-buttons';
import withMainLayout from './hoc/with-main-layout';
import withMap from './hoc/with-map';
import useMultipleDropoff from './hooks/useMultipleDropoff';
import useStripeCheckout from './hooks/useStripeCheckout';
import {
  BookingDropOffScreen,
  BookingPickUpScreen,
  BookingSplashScreen,
} from './screens';
import ReservationScreen from './screens/reservation';
import { JOB_TYPE_ACTIONS } from './services/redux/actions';
import {
  getSelectedJobType,
  getSelectedSubJobType,
} from './services/redux/reducers/job-type.reducer';
import store from './services/redux/store';

const WORKFLOW_STEPS = {
  PICK_UP: 'pickUp',
  DROP_OFF: 'dropOff',
};

export const BookingApp = ({ isSuccess, isError }) => {
  const formMethods = useForm();
  const formRef = useRef();
  const reservationRef = useRef();
  const classes = useBookingCommonStyles();
  const { getValues, handleSubmit } = formMethods;
  const { update, setIndex, currentDropOffIndex } = useMultipleDropoff();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { stripeCheckout } = useStripeCheckout();

  const selectedJobType = useSelector(getSelectedJobType);
  const { id: jobTypeId } = selectedJobType || {};

  const selectedSubJobType = useSelector(getSelectedSubJobType);
  const { id: subJobTypeId, type: typeOfSubJobType } = selectedSubJobType || {};

  const reservationFeeMutation = usePostFee();
  const bookingMutation = usePostCreateReservation();
  const { data } = useGetEventByCode();

  const [steps, setSteps] = useState(
    Object.values(WORKFLOW_STEPS).map((step) => ({ [step]: false })),
  );
  const [isPickupOpen, setPickupOpen] = useState(false);
  const [isAreaOpen, setAreaOpen] = useState(false);

  const doStepCompleted = (step) => {
    setSteps((previousSteps) => ({
      ...previousSteps,
      [step]: true,
    }));
  };

  const doStepIncompleted = (step) => {
    setSteps((previousSteps) => ({
      ...previousSteps,
      [step]: false,
    }));
  };

  const resetDateTime = () => {
    publish(EVENTS.RESET_DATE_TIME);
  };

  const rollbackDateTime = () => {
    publish(EVENTS.ROLLBACK_DATE_TIME);
  };

  const handlePickupClose = useCallback(() => {
    setPickupOpen(false);
  }, []);

  const onClosePickup = () => {
    handlePickupClose();
    rollbackDateTime();
  };

  const handleSelectAreaClose = useCallback(() => {
    setAreaOpen(false);
  }, []);

  const clearStopIdKey = (dropoffs) => {
    const stopIdIndex = dropoffs?.findIndex((dropoff) => dropoff?.stopId);
    if (stopIdIndex !== -1) {
      update(
        Object.assign(dropoffs[stopIdIndex], {
          stopId: '',
        }),
        stopIdIndex,
      );
    }
  };

  const handleNotFoundPostalCode = (err, dropoffs) => {
    const { stopId } = err?.response?.data?.args || {};
    if (isEmpty(stopId)) return;
    const dropoffIndex = dropoffs?.findIndex(
      (dropoff) => dropoff?.id === stopId,
    );
    if (dropoffIndex !== -1) {
      update(
        Object.assign(dropoffs[dropoffIndex], {
          stopId,
          // just for show UI
          showSelectArea: !!stopId,
        }),
        dropoffIndex,
      );
    }
  };

  const getReservationTime = (d, t) => {
    let result = null;
    let date = d;
    let time = t;

    if (!date || !moment(date).isValid()) date = moment().format('YYYY-MM-DD');
    if (!time || !moment(time, 'HH:ss').isValid())
      time = moment().add(10, 'minutes').format('HH:mm:ss');

    const formattedTime = moment(`${date} ${time}`);
    result = moment.utc(moment.utc(formattedTime)).toJSON();
    return result;
  };

  const transformToDropOffFormat = (dropoffList) => {
    return dropoffList
      .map((item) => ({
        ...item,
        type: 'DROP_OFF',
      }))
      ?.filter((item) => item?.coordinate);
  };

  const scrollToBottom = useCallback(
    debounce(() => {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: 'smooth',
      });
    }, 500),
    [],
  );

  const fetchReservationFee = (params) => {
    const {
      dropoff = [],
      pickup,
      date,
      time,
      childBoosterSeat,
      totalHours,
    } = getValues() || {};
    const finalTypeOfSubJobType = typeOfSubJobType || params?.typeOfSubJobType;
    if (
      isEmpty(pickup) ||
      (![SUB_JOB_TYPE.HOURLY, SUB_JOB_TYPE.FIXED_PRICE].includes(
        finalTypeOfSubJobType,
      ) &&
        isEmpty(transformToDropOffFormat(dropoff)))
    ) {
      return;
    }
    const finalJobTypeId = jobTypeId || params?.jobTypeId;
    const finalSubJobTypeId = subJobTypeId || params?.subJobTypeId;
    const body = {
      childBoosterSeat,
      totalHours: Number(totalHours),
      jobType: {
        id: finalJobTypeId,
      },
      subJobType: {
        id: finalSubJobTypeId,
      },
      event: {
        id: data?.id,
      },
      stops: [
        {
          ...pickup,
          estimateTime: getReservationTime(date, time),
          type: 'PICK_UP',
        },
        ...transformToDropOffFormat(dropoff),
      ],
    };
    reservationFeeMutation.mutate(
      { data: body },
      {
        onSuccess: () => {
          doStepCompleted(WORKFLOW_STEPS.DROP_OFF);
          doStepCompleted(WORKFLOW_STEPS.PICK_UP);
          scrollToBottom();
          clearStopIdKey(dropoff);
        },
        onError: (err) => {
          handleNotFoundPostalCode(err, dropoff);
        },
      },
    );
  };

  const onDone = useCallback(
    (step) => () => {
      doStepCompleted(step);
      switch (step) {
        case WORKFLOW_STEPS.DROP_OFF: {
          if (!isEmpty(getValues(FORM_FIELD_NAMES.PICK_UP))) {
            fetchReservationFee();
          }
          break;
        }
        case WORKFLOW_STEPS.PICK_UP:
          // handlePickupClose();
          setPickupOpen(false);
          fetchReservationFee();
          break;
        default:
          break;
      }
    },
    [data, formRef.current, selectedJobType, selectedSubJobType],
  );

  const handleOnClickAreaItem = (postalCodes, name) => () => {
    const { dropoff } = getValues() || {};
    if (isEmpty(dropoff)) return;
    update(
      Object.assign(dropoff[currentDropOffIndex], {
        postalCode: postalCodes?.[0]?.code,
        areaName: name,
        // just for show UI
        selectedAreaName: name,
      }),
      currentDropOffIndex,
    );
    setAreaOpen(false);
    fetchReservationFee();
  };

  const onSubmit = (formData) => {
    const {
      dropoff,
      pickup,
      date,
      time,
      type,
      payment,
      childBoosterSeat,
      phoneNumber,
      totalHours,
      numberOfGuest,
      numberOfLuggages,
      flightNumber,
      fullName,
    } = formData || {};
    const payload = {
      totalHours: Number(totalHours),
      childBoosterSeat,
      event: {
        id: data?.id,
      },
      eventReservation: {
        fullName,
        phoneNumber,
        metadata: {
          numberOfGuest: Number(numberOfGuest),
          numberOfLuggages: Number(numberOfLuggages),
          flightNumber,
        },
      },
      subJobType: {
        id: type,
      },
      stops: [
        {
          ...pickup,
          estimateTime: getReservationTime(date, time),
          type: 'PICK_UP',
        },
        ...transformToDropOffFormat(dropoff),
      ],
    };
    bookingMutation.mutate(
      { data: payload },
      {
        onSuccess: (response) => {
          dispatch({
            type: JOB_TYPE_ACTIONS.SET_SELECTED_JOB_TYPE,
            payload: null,
          });
          switch (payment) {
            case PAYMENT_VALUES.CASH:
              navigate(
                `/p/event/c/${data.code}/t/${response.code}/cash-success`,
              );
              break;
            case PAYMENT_VALUES.ONLINE:
              stripeCheckout(response.id);
              break;
            default:
              break;
          }
        },
      },
    );
  };

  const injectBookingDropOffScreen = ![SUB_JOB_TYPE.HOURLY].includes(
    typeOfSubJobType,
  );

  useEffect(() => {
    subscribe(EVENTS.PICKUP_LOCATION_CLICK, () => {
      setPickupOpen(true);
    });

    subscribe(EVENTS.SELECT_AREA, ({ detail }) => {
      setIndex(detail);
      setAreaOpen(true);
    });

    subscribe(EVENTS.DROPOFF_LOCATION_CLICK, ({ detail }) => {
      if (detail?.typeOfSubJobType !== SUB_JOB_TYPE.FIXED_PRICE) {
        doStepIncompleted(WORKFLOW_STEPS.DROP_OFF);
      }
    });
    subscribe(EVENTS.INCREASE_DROPOFF_LOCATION_CLICK, ({ detail }) => {
      if (detail?.typeOfSubJobType !== SUB_JOB_TYPE.FIXED_PRICE) {
        doStepIncompleted(WORKFLOW_STEPS.DROP_OFF);
      }
    });
  }, []);

  useEffect(() => {
    const handleEvent = ({ detail }) => {
      if (data?.id) {
        fetchReservationFee({
          jobTypeId: detail?.jobTypeId,
          subJobTypeId: detail?.subJobTypeId,
          typeOfSubJobType: detail?.typeOfSubJobType,
        });
      }
    };
    subscribe(EVENTS.ON_CHANGE_TOTAL_TIME_BOOKING, handleEvent);
    subscribe(EVENTS.ON_CHANGE_BOOSTER_SEAT, handleEvent);
    subscribe(EVENTS.DECREASE_DROPOFF_LOCATION, handleEvent);

    return () => {
      [
        EVENTS.ON_CHANGE_TOTAL_TIME_BOOKING,
        EVENTS.ON_CHANGE_BOOSTER_SEAT,
        EVENTS.DECREASE_DROPOFF_LOCATION,
      ].forEach((evt) => unsubscribe(evt, handleEvent));
    };
  }, [data?.id]);

  useEffect(() => {
    resetDateTime();
    reservationFeeMutation.reset();
    formMethods.setValue(FORM_FIELD_NAMES.DROP_OFF, []);
    formMethods.setValue(FORM_FIELD_NAMES.CHILD_BOOSTER_SEAT, false);
  }, [selectedJobType, selectedSubJobType]);

  return (
    <>
      <BookingSplashScreen isStart={isSuccess || isError} />
      <ToastContainer />
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
          {injectBookingDropOffScreen && (
            <Collapse
              in={
                (typeOfSubJobType !== SUB_JOB_TYPE.FIXED_PRICE &&
                  !steps[WORKFLOW_STEPS.DROP_OFF]) ||
                typeOfSubJobType === SUB_JOB_TYPE.FIXED_PRICE
              }
            >
              <BookingDropOffScreen onDone={onDone(WORKFLOW_STEPS.DROP_OFF)} />
            </Collapse>
          )}

          <Collapse in={steps[WORKFLOW_STEPS.DROP_OFF]}>
            <Collapse
              ref={reservationRef}
              in={
                reservationFeeMutation.isSuccess ||
                reservationFeeMutation.isLoading
              }
            >
              <ReservationScreen />
            </Collapse>
          </Collapse>

          <BookingBottomSheet
            allowMinHeight
            open={isPickupOpen}
            onClose={onClosePickup}
            drawerPaperMinHeight
          >
            <BookingPickUpScreen onDone={onDone(WORKFLOW_STEPS.PICK_UP)} />
          </BookingBottomSheet>

          <BookingBottomSheet
            disableScroll
            className={classes.areaBottomSheet}
            open={isAreaOpen}
            onClose={handleSelectAreaClose}
          >
            {isAreaOpen && (
              <ZoneAreas handleOnClickAreaItem={handleOnClickAreaItem} />
            )}
          </BookingBottomSheet>
        </form>
      </FormProvider>
    </>
  );
};

BookingApp.propTypes = {
  isSuccess: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
};

const App = withMainLayout(withJobTypeButtons(withMap(memo(BookingApp))));

export const AppWithRedux = () => {
  const googleMapsApiKey = useSelector(
    (state) => state.setting.commonSettings?.map_api_key,
  );

  const navigate = useNavigate();

  const onError = () => {
    navigate('#/p/event/oops');
  };

  if (!googleMapsApiKey) return <BookingSplashScreen />;

  return (
    <Provider store={store}>
      <LoadScriptNext
        googleMapsApiKey={googleMapsApiKey}
        libraries={['places']}
        loadingElement={<BookingSplashScreen />}
        onError={onError}
      >
        <App />
      </LoadScriptNext>
    </Provider>
  );
};

export default AppWithRedux;
