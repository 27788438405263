/* eslint-disable no-shadow, react/jsx-indent-props, react/jsx-closing-bracket-location, react/jsx-indent */
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import { FieldTitle as RAFieldTitle } from 'react-admin';

export const FieldTitle = forwardRef((props, ref) => {
  const { needTranslate = true, label, ...rest } = props;

  if (needTranslate) {
    return <RAFieldTitle innerRef={ref} label={label} {...rest} />;
  }

  return (
    <span ref={ref}>
      {label}
      {props.isRequired && <span aria-hidden="true">&thinsp;*</span>}
    </span>
  );
});

FieldTitle.propTypes = {
  needTranslate: PropTypes.bool,
  label: PropTypes.string,
  isRequired: PropTypes.bool,
};

FieldTitle.defaultProps = {
  needTranslate: true,
  isRequired: false,
  label: undefined,
};
