import React, { forwardRef } from 'react';

const CmtImage = forwardRef(({
  src, alt, ...rest
}, ref) => (
  <img
    ref={ref}
    src={src}
    alt={alt}
    {...rest}
  />
));

export default CmtImage;
