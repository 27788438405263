import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  splash: {
    position: 'fixed',
    inset: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#ffffff',
    zIndex: 9,
    opacity: 1,

    transition: 'all 0.2s ease-in',

    '& img': {
      width: 128,
    },
  },

  hideSplash: {
    opacity: 0,
    zIndex: -1,
  },
}));

export default useStyles;
