import { Box, Typography } from '@mui/material';
import React from 'react';
import useStyles from './index.style';
import Menu from './menu';

const SelectSubJobType = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box>
        <Typography className={classes.serviceSelectedTxt}>
          {'Service Selected: '}
        </Typography>
      </Box>
      <Box className={classes.menuWrapper}>
        <Menu />
      </Box>
    </Box>
  );
};

SelectSubJobType.propTypes = {};

SelectSubJobType.defaultProps = {};

export default React.memo(SelectSubJobType);
