import axios from 'axios';
import configs from '../configs';

if (configs.nfLocal) {
  axios.defaults.baseURL = '/api/bo';
} else {
  axios.defaults.baseURL = `${configs.apiUrl}/bo`;
}
axios.defaults.withCredentials = true;
axios.defaults.headers = {
  nfParty: configs.nfParty,
  nfLocal: configs.nfLocal,
  nfChrome: !!window?.chrome,
  nfRealm: 'LLO',
};
axios.defaults.xsrfCookieName = 'nf-xsrf-token';
axios.defaults.xsrfHeaderName = 'nf-xsrf-token';
axios.interceptors.request.use(
  (config) => {
    // eslint-disable-next-line no-param-reassign
    config.headers.nfLocale = localStorage.getItem('nfLocale') || 'en';
    return config;
  },
  (error) => Promise.reject(error),
);

export default axios;
