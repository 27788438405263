import { PULL_NF_SCHEMA_SUCCESS } from '../root.actions';

export const schemaReducer = (state = {}, action) => {
  switch (action.type) {
    case PULL_NF_SCHEMA_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};
