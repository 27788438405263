import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  home: { marginTop: theme.spacing(2) },
  inner: {},
  popularDestinationContainer: {
    marginTop: theme.spacing(4),
  },
  txtTitle: {
    color: '#686868',
    marginBottom: '.7em',
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
  },
}));

export default useStyles;
