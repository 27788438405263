import {
  Unstable_NumberInput as BaseNumberInput,
  numberInputClasses,
} from '@mui/base/Unstable_NumberInput';
import { styled } from '@mui/system';
import * as React from 'react';

const InputRoot = styled('div')(
  () => `
  font-family: QuicksandMedium, IBM Plex Sans, sans-serif;
  font-weight: 400;
  border-radius: 8px;
  color: black;
  background: white;
  border: 1px solid #CBD5E1;
  display: grid;
  grid-template-columns: 1fr auto 19px;
  grid-template-rows: 1fr 1fr;
  overflow: hidden;


  &.${numberInputClasses.focused} {
    border-color: #CBD5E1;
  }

  &:hover {
    border-color: #CBD5E1;
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`,
);

const InputElement = styled('input')`
  font-size: 16px;
  font-family: inherit;
  font-weight: 400;
  line-height: 1.5;
  grid-row: 1/3;
  color: inherit;
  background: inherit;
  border: none;
  border-radius: inherit;
  padding: 8px 12px;
  outline: 0;
`;

const Button = styled('button')(
  () => `
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  appearance: none;
  padding: 0;
  width: 19px;
  height: 19px;
  font-family: system-ui, sans-serif;
  font-size: 0.875rem;
  line-height: 1;
  box-sizing: border-box;
  background: white;
  border: 0;
  color: black;

  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 120ms;

  &:hover {
    background: white;
    border-color: #CBD5E1;
    cursor: pointer;
  }

  &.${numberInputClasses.incrementButton} {
    grid-column: 4/5;
    grid-row: 1/2;
  }

  &.${numberInputClasses.decrementButton} {
    grid-column: 4/5;
    grid-row: 2/3;
  }

  & .arrow {
    transform: translateY(-1px);

    &_rotate {
      transform: rotate(180deg);
    }
  }
`,
);

const NumberInput = React.forwardRef((props, ref) => {
  return (
    <BaseNumberInput
      slots={{
        root: InputRoot,
        input: InputElement,
        incrementButton: Button,
        decrementButton: Button,
      }}
      slotProps={{
        incrementButton: {
          children: <span className="arrow arrow_rotate">▾</span>,
        },
        decrementButton: {
          children: <span className="arrow">▾</span>,
        },
      }}
      {...props}
      ref={ref}
    />
  );
});

export default NumberInput;
