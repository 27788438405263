import { SETTING_ACTIONS } from '../actions';

const initialSettings = {};

const settingReducer = (state = initialSettings, action) => {
  switch (action.type) {
    case SETTING_ACTIONS.SETTINGS: {
      return {
        ...state,
        ...action,
      };
    }

    default:
      return state;
  }
};

export default settingReducer;
