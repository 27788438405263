import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.scss';
import checkAppVersion from './services/util/checkAppVersion';

import '@ant-design/flowchart/dist/index.css';
import 'remixicon/fonts/remixicon.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

checkAppVersion();

ReactDOM.render(<App />, document.getElementById('root'));
