import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  langBtn: {
    maxHeight: theme.spacing(6),
    margin: 0,
    padding: theme.spacing(3),
    fontWeight: 'bolder',
    borderRadius: theme.spacing(4),
  },
  overflowHidden: {
    overflow: 'hidden',
  },
  alignMiddle: {
    verticalAlign: 'middle',
    display: 'inline-block',
  },
  textUppercase: {
    textTransform: 'uppercase',
  },
  cardRoot: {
    '& .Cmt-header-root': {
      paddingTop: 14,
      paddingBottom: 14,
    },
  },
  perfectScrollbarLanguage: {
    maxHeight: 324,
    overflowX: 'hidden',
    '& .MuiBox-root': {
      fontSize: '13px',
      lineHeight: '19.5px',
      color: 'rgb(51, 60, 68)',
    },
    '& > div > div': {
      paddingTop: 8,
      paddingBottom: 8,
    },
  },
  menuItemRoot: {
    paddingTop: 0,
    paddingBottom: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  popoverRoot: {
    '& .MuiPopover-paper': {
      width: 205,
    },
  },
}));

export default useStyles;
