import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  item: {
    width: '100%',
    marginBottom: theme.spacing(6),
    '&:last-child': {
      marinBottom: 0,
    },
  },
  image: {
    height: 275,
    width: '100%',
    borderRadius: 10,
  },

  txtcontent: {
    fontSize: 14,
  },
}));

export default useStyles;
