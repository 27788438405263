import React from "react";
import globalStyles from "../../../theme/GlobalCss";
import DefaultLayout from "./DefaultLayout";

const AppLayout = ({ children }) => {
  globalStyles();

  return <DefaultLayout children={children} />;
};

export default AppLayout;
