import { Box } from '@mui/material';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useStyles from './index.style';

const GIF_TIME = 2000;
const SplashScreen = ({ isStart }) => {
  const classes = useStyles();
  const [isHide, setIsHide] = useState(false);

  useEffect(() => {
    if (!isStart) return null;
    const timeout = setTimeout(() => {
      setIsHide(true);
    }, GIF_TIME);

    return () => clearTimeout(timeout);
  }, [isStart]);

  return (
    <Box className={clsx(classes.splash, isHide && classes.hideSplash)}>
      {!isHide && <img src="images/booking/logo.gif" alt="logo" />}
    </Box>
  );
};

SplashScreen.propTypes = {
  isStart: PropTypes.bool,
};

SplashScreen.defaultProps = {
  isStart: false,
};

export default SplashScreen;
