import React from 'react';
import PropTypes from 'prop-types';
import ListView from './ListView';
import ListFooter from './ListFooter';

const CmtList = ({
  footerProps, ...props
}) => (
  <ListView
    {...props}
    ListFooterComponent={footerProps && (
      <ListFooter
        loading={footerProps.loading}
        footerText={footerProps.footerText}
      />
    )}
  />
);

export default CmtList;
CmtList.propTypes = {
  ListEmptyComponent: PropTypes.element,
  ListFooterComponent: PropTypes.element,
  data: PropTypes.array.isRequired,
  onEndReached: PropTypes.func,
  footerProps: PropTypes.object,
};
CmtList.defaultProps = {
  data: [],
};
