import {
  CLEAR_STATE,
  FORCE_AUTH_INFO,
  GET_AUTH_INFO,
  GET_AUTH_INFO_FAILED,
  GET_AUTH_INFO_SUCCESS,
  UPDATE_USER_INFO,
} from '../root.actions';

const initialSettings = {
  user: {},
  forceUpdate: false,
};

export const authReducer = (state = initialSettings, action) => {
  switch (action.type) {
    case GET_AUTH_INFO:
      return {
        ...state,
        loading: true,
      };

    case GET_AUTH_INFO_SUCCESS:
      return {
        ...state,
        user: action.payload.user,
        loading: false,
      };

    case UPDATE_USER_INFO:
      return {
        ...state,
        user: { ...state.user, ...action.payload.user },
      };

    case GET_AUTH_INFO_FAILED:
      return {
        ...state,
        user: {},
        loading: false,
      };

    case FORCE_AUTH_INFO:
      return {
        ...state,
        forceUpdate: action.payload.forceUpdate,
      };
    case CLEAR_STATE:
      return initialSettings;
    default:
      return state;
  }
};
