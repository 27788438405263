/* eslint-disable react/prop-types */
import React, { Suspense } from 'react';

export const lazyWrapper = (LaziedComponent) => (props) => {
  const { fallback, className: originClassName, ...rest } = props;

  if (typeof originClassName !== 'boolean') rest.className = originClassName;

  return (
    <Suspense fallback={fallback || <></>}>
      <LaziedComponent {...rest} />
    </Suspense>
  );
};
