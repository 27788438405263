import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    position: 'relative',
  },
  inner: { width: '100%', borderRadius: 10, overflow: 'hidden' },

  paper: {
    width: '100%',
    // backgroundColor: 'transparent',
    overflow: 'inherit',
    borderRadius: 10,
  },

  image: {
    width: '70%',
    padding: theme.spacing(6, 4, 4),
    margin: '0 auto',
    display: 'block',
  },

  title: {
    fontSize: 20,
    fontFamily: 'QuickSandBold',
    textAlign: 'center',
    marginTop: theme.spacing(2),
  },

  description: {
    fontSize: 16,
    fontFamily: 'QuickSandLight',
    textAlign: 'center',
    lineHeight: 1.3,
    marginTop: theme.spacing(3),
    color: '#92929D',
  },

  closeButton: {
    position: 'absolute',
    top: -36,
    right: -24,
    color: '#ffffff',
  },
  content: {
    padding: theme.spacing(4, 6, 8),
    backgroundColor: '#ffffff',
  },
}));

export default useStyles;
