const apiUrl =
  process?.env?.REACT_APP_NF_API_URL ?? window?.REACT_APP_NF_API_URL;
const coniUrl =
  process?.env?.REACT_APP_NF_CONI_URL ?? window?.REACT_APP_NF_CONI_URL;
const nfParty = process?.env?.REACT_APP_NF_PARTY ?? window?.REACT_APP_NF_PARTY;
const nfLocal =
  (process?.env?.REACT_APP_NF_LOCAL ?? window?.REACT_APP_NF_LOCAL) === '1';
const nfDev =
  (process?.env?.REACT_APP_NF_DEV ?? window?.REACT_APP_NF_DEV) === 'true';
const nfSentryDSN =
  process?.env?.REACT_APP_NF_SENTRY_DSN ?? window?.REACT_APP_NF_SENTRY_DSN;
const nfEnv = process?.env?.REACT_APP_NF_ENV ?? window?.REACT_APP_NF_ENV;
const nfGa = process?.env?.REACT_APP_NF_GA_TAG ?? window?.REACT_APP_NF_GA_TAG;

export default {
  apiUrl: nfLocal ? '/api' : `${apiUrl}`,
  coniUrl,
  nfParty,
  nfLocal,
  nfDev,
  nfEnv,
  nfSentryDSN,
  nfGa,
};
