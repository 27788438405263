/* eslint-disable */
import {
  ErrorBoundary as SentryErrorBoundary,
  init as SentryInit,
} from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import React, { Suspense, lazy, useMemo } from 'react';
import { Admin, CustomRoutes, NotFound, Resource } from 'react-admin';
import { QueryClient } from 'react-query';
import { Provider } from 'react-redux';
import { Route } from 'react-router';
import { HashRouter } from 'react-router-dom';
import PublicApp from './PublicApp';
import GoogleAnalytics from './base/components/google-analytics';
import { LanguageContextProvider } from './base/context/language';
import PermissionsProvider, {
  usePermissionsContext,
} from './base/context/permissions';
import SettingManager from './base/context/setting';
import UserManager from './base/context/user';
import RoutingCrud from './routes/routing.crud';
import configs from './services/configs';
import authProvider from './services/provider/authProvider';
import dataProvider from './services/provider/dataProvider';
import { i18nProvider } from './services/provider/i18n/i18nProvider';
import { RbacAction, canI } from './services/provider/rbacProvider';
import { store } from './services/redux/root.reducer';
import theme from './theme';
import Layout from './theme/App';
import ThemeProvider from './theme/theme/themeProvider';

const Notification = lazy(() =>
  import('./base/components/layout/notification'),
);
const Dashboard = lazy(() => import('./base/components/dashboard'));
const NFListGuesser = lazy(() =>
  import('./base/components/guesser/nf-list.guesser'),
);
const NFEditGuesser = lazy(() =>
  import('./base/components/guesser/nf-edit.guesser'),
);
const NFShowGuesser = lazy(() =>
  import('./base/components/guesser/nf-show.guesser'),
);
const NFCreateGuesser = lazy(() =>
  import('./base/components/guesser/nf-create.guesser'),
);
const AuthPage = lazy(() => import('./base/components/layout/authPage'));
const LoginPage = lazy(() => import('./base/components/layout/loginPage'));
const GroupPage = lazy(() => import('./routes/group'));
const ProfilePage = lazy(() => import('./routes/profile'));
const ResetPasswordPage = lazy(() => import('./routes/reset-password'));
const TokenInvalidPage = lazy(() => import('./routes/token-invalid'));

const pkg = require('../package.json');

if (configs.nfSentryDSN !== '') {
  SentryInit({
    dsn: configs.nfSentryDSN,
    integrations: [new BrowserTracing()],
    environment: configs.nfEnv,
    enabled: !!configs.nfSentryDSN,
    release: pkg.version,
    attachStacktrace: true,
    ignoreErrors: [
      'Non-Error exception captured',
      'Non-Error promise rejection captured',
      'Request failed with status code',
      'Network Error',
      'ResizeObserver loop limit exceeded',
      'ChunkLoadError',
    ],
    beforeSend: (event, hint) => {
      if (hint?.originalException === 'Timeout') {
        return null;
      }

      return event;
    },

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const getResources = (resources, permissions) => {
  const noCustomComponents = [];
  resources = resources?.map(
    ({
      id,
      name,
      title,
      list,
      edit,
      editOnSubmittable,
      show,
      icon,
      create,
      delete: hasDelete,
      group: isGroup,
      children,
      popupable,
      commentable,
      submittable,
      approvable,
      cancelable,
      cancelAfterApproval,
      attachable,
      isChild,
      prefix,
      reportStructure,
      beHeatMap,
      beCalendar,
      callToActionList,
      seedPerm,
      grantPerm,
      groupSections,
      noticeCount,
      sidebarOptions,
      visuals,
    }) => {
      const docWorkflowable =
        submittable || approvable || cancelAfterApproval || editOnSubmittable;
      const hasList = list && canI(RbacAction.LIST, name, permissions);
      const hasEdit =
        (editOnSubmittable || edit) &&
        canI(RbacAction.UPDATE, name, permissions);
      const hasCreate = create && canI(RbacAction.CREATE, name, permissions);
      const hasShow = show && canI(RbacAction.READ, name, permissions);

      return {
        name,
        icon,
        hasList,
        hasEdit,
        hasCreate,
        hasShow,
        visuals,
        options: {
          hasClone: canI(RbacAction.CREATE, name, permissions),
          hasDelete: hasDelete && canI(RbacAction.DELETE, name, permissions),
          isGroup,
          resources,
          prefix,
          children,
          isChild,
          commentable,
          popupable,
          submittable:
            submittable && canI(RbacAction.SUBMIT, name, permissions),
          approvable: approvable && canI(RbacAction.APPROVE, name, permissions),
          cancelable: cancelable && canI(RbacAction.CANCEL, name, permissions),
          cancelAfterApproval:
            cancelAfterApproval && canI(RbacAction.CANCEL, name, permissions),
          editOnSubmittable,
          hasAttach: attachable && canI(RbacAction.READ, name, permissions),
          resourceId: id,
          reportStructure,
          noticeCount,
          beHeatMap,
          beCalendar,
          docWorkflowable,
          callToActionList,
          seedPerm,
          grantPerm,
          groupSections,
          title,
          sidebarOptions,
          visuals,
        },
      };
    },
  );
  global.resources = resources;
  const components = resources?.map(
    ({ name, icon, hasList, hasEdit, hasCreate, hasShow, options }) => {
      let customList, customShow, customEdit, customCreate;
      const gotCustomRoute = RoutingCrud[name];

      if (!!gotCustomRoute) {
        try {
          if (gotCustomRoute?.list) {
            customList = lazy(() => import(`./routes/${name}/route.list`));
          }

          if (gotCustomRoute?.show) {
            customShow = lazy(() => import(`./routes/${name}/route.show`));
          }

          if (gotCustomRoute?.create) {
            customCreate = lazy(() => import(`./routes/${name}/route.create`));
          }

          if (gotCustomRoute?.edit) {
            customEdit = lazy(() => import(`./routes/${name}/route.edit`));
          }
        } catch (ex) {
          if (!ex?.message?.includes('Cannot find module')) {
            console.error(ex);
          }
          noCustomComponents.push(name);
        }
      }

      return (
        <Resource
          key={`nfresource-routing-${name}`}
          name={name}
          icon={icon && (() => icon)}
          list={hasList && (customList || NFListGuesser)}
          edit={hasEdit && (customEdit || NFEditGuesser)}
          create={hasCreate && (customCreate || NFCreateGuesser)}
          show={hasShow && (customShow || NFShowGuesser)}
          options={options}
        />
      );
    },
  );

  return components;
};

const PrivateApp = () => {
  let { perms: permissions, permitted: resources } = usePermissionsContext();

  const Resources = useMemo(
    () => getResources(resources, permissions),
    [permissions, resources],
  );

  return (
    <Suspense fallback={<></>}>
      <Admin
        title="BO - NF System"
        dataProvider={dataProvider}
        disableTelemetry
        authProvider={authProvider}
        i18nProvider={i18nProvider}
        layout={Layout}
        theme={theme}
        loginPage={LoginPage}
        dashboard={Dashboard}
        notification={Notification}
        queryClient={queryClient}
        catchAll={NotFound}
        authCallbackPage
        requireAuth
      >
        {Resources}
        <CustomRoutes>
          <Route exact path="/authenticated" element={<AuthPage />} noLayout />
          <Route
            exact
            path="/reset-password/:tokenString"
            element={<ResetPasswordPage />}
            noLayout
          />
          <Route
            exact
            path="/token-invalid"
            element={<TokenInvalidPage />}
            noLayout
          />
          <Route exact path="/profile" element={<ProfilePage />} />
          <Route path="/group/*" element={<GroupPage />} />
        </CustomRoutes>
      </Admin>
    </Suspense>
  );
};

const App = () => {
  const isPublic = window.location.hash.startsWith('#/p/');
  return (
    <SentryErrorBoundary>
      <HashRouter>
        <Provider store={store}>
          <ThemeProvider>
            <LanguageContextProvider>
              <SettingManager>
                <GoogleAnalytics>
                  {isPublic && <PublicApp />}
                  {!isPublic && (
                    <UserManager>
                      <PermissionsProvider>
                        <PrivateApp />
                      </PermissionsProvider>
                    </UserManager>
                  )}
                </GoogleAnalytics>
              </SettingManager>
            </LanguageContextProvider>
          </ThemeProvider>
        </Provider>
      </HashRouter>
    </SentryErrorBoundary>
  );
};

export default App;
