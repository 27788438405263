import Resizer from 'react-image-file-resizer';
import heic2any from 'heic2any';
import { getUploadFileType } from '../../base/utils';

export const convertBinaryFileToBase64 = async (file) =>
  new Promise((resolve, reject) => {
    // eslint-disable-next-line no-undef
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const convertFileToJPG = (blob, file) => {
  return new File([blob], file, {
    lastModified: new Date().getTime(),
    type: 'image/jpg',
    name: file?.name?.replaceAll('HEIC', 'jpg'),
  });
};

const resizerBlob = (file, options) => {
  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      options?.maxWidth || 800,
      options?.maxHeight || 800,
      null,
      options?.quality || 100,
      0,
      (uri) => {
        resolve(uri);
      },
      'base64',
      options?.minWidth || 200,
      options?.minHeight || 200,
    );
  });
};

export const convertFileToBase64 = async (file, options) => {
  const { type: fileType, typeList } = getUploadFileType(file);
  const heicType = 'image/heic';

  if (file?.type === heicType) {
    return heic2any({ blob: file, toType: 'image/jpg', quality: 1 }).then(
      (newImage) => {
        const image = convertFileToJPG(newImage, file);
        return resizerBlob(image, options);
      },
    );
  }
  if (fileType === typeList.IMAGE) {
    return resizerBlob(file, options);
  }

  return convertBinaryFileToBase64(file);
};
