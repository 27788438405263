import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import { Backdrop, Box, Drawer, IconButton, Typography } from '@mui/material';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import useStyles from './index.style';

const BookingBottomSheet = ({
  children,
  onClose,
  onBack,
  open: defaultOpen,
  title,
  drawerProps,
  allowMinHeight,
  className,
  disableScroll,
  drawerPaperMinHeight,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
    onClose?.();
  };

  useEffect(() => {
    setOpen(defaultOpen);
  }, [defaultOpen]);

  useEffect(() => {
    if (open) document.documentElement.classList.add(classes.freezeWindow);
    else document.documentElement.classList.remove(classes.freezeWindow);
  }, [open]);
  return (
    <>
      <Backdrop
        open={open}
        onClick={handleClose}
        className={classes.backdrop}
      />
      <Drawer
        open={open}
        anchor="bottom"
        onClose={handleClose}
        variant="persistent"
        classes={{
          paper: clsx(
            classes.drawerPaper,
            disableScroll && classes.freezeWindow,
            drawerPaperMinHeight && classes.drawerPaperMinHeight,
          ),
        }}
        {...drawerProps}
      >
        <Box
          className={clsx(
            classes.container,
            allowMinHeight && classes.allowMinHeight,
            className,
          )}
        >
          <Box className={classes.inner}>
            <Box className={classes.header}>
              {onBack && (
                <IconButton onClick={onBack}>
                  <ArrowBackIosNewRoundedIcon />
                </IconButton>
              )}
              {title && (
                <Typography className={classes.txtTitle}>{title}</Typography>
              )}
            </Box>
            <Box className={classes.article}>{children}</Box>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

BookingBottomSheet.propTypes = {
  children: PropTypes.element.isRequired,
  open: PropTypes.bool,
  allowMinHeight: PropTypes.bool,
  onClose: PropTypes.func,
  onBack: PropTypes.func,
  title: PropTypes.string,
  drawerProps: PropTypes.object,
  className: PropTypes.object,
  disableScroll: PropTypes.bool,
  drawerPaperMinHeight: PropTypes.bool,
};

BookingBottomSheet.defaultProps = {
  open: false,
  allowMinHeight: false,
  onClose: undefined,
  onBack: undefined,
  title: null,
  drawerProps: {},
  className: undefined,
  disableScroll: false,
  drawerPaperMinHeight: false,
};

export default BookingBottomSheet;
