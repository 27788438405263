import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';

import { Box, Dialog, IconButton, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import Image from '../image';
import useStyles from './index.style';

const BookingDialog = ({
  title,
  description,
  children,
  open,
  onCloseClick,
  imageSrc,
}) => {
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      classes={{
        paper: classes.paper,
      }}
    >
      <Box className={classes.container}>
        <Box className={classes.inner}>
          <IconButton className={classes.closeButton} onClick={onCloseClick}>
            <HighlightOffRoundedIcon />
          </IconButton>
          {imageSrc && (
            <Image className={classes.image} src={imageSrc} alt="icon" />
          )}
          <Box className={classes.content}>
            {title && (
              <Typography className={classes.title}>{title}</Typography>
            )}
            {description && (
              <Typography className={classes.description}>
                {description}
              </Typography>
            )}
            {children}
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

BookingDialog.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.element,
  onCloseClick: PropTypes.func,
  open: PropTypes.bool,
  imageSrc: PropTypes.string,
};

BookingDialog.defaultProps = {
  title: null,
  description: null,
  children: null,
  onCloseClick: false,
  open: false,
  imageSrc: null,
};

export default BookingDialog;
