import { useState, useEffect } from 'react';
import { camelCase, startCase } from 'lodash';
import useSchema from './useSchema';

const useEnumOptions = (resource, property) => {
  const [options, setOptions] = useState([]);
  const { api } = useSchema();
  const pascalCase = str => startCase(camelCase(str)).replace(/ /g, '');

  const getOptions = () => {
    const data = api?.components?.schemas
      ?.[pascalCase(resource)]
      ?.properties
      ?.[property]
      ?.enum
      ?.map(item => ({
        id: item,
        name: item,
      }));
    setOptions(data);
  };

  useEffect(() => {
    getOptions();
  }, [api, resource, property]);

  return options || [];
};

export default useEnumOptions;
