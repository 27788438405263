import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import { Box, Typography } from '@mui/material';
import React from 'react';
import { useParams } from 'react-router';
import { useGetEventByCodeOnly } from '../../api/features/common.feature';
import { DefaultButton } from '../../components';
import useStyles from './index.style';
import BookingSplashScreen from '../splash-screen';

const BookingCashSuccessScreen = () => {
  const classes = useStyles();
  const { data, isError, isSuccess } = useGetEventByCodeOnly({
    configQueryOptions: {
      refetchOnWindowFocus: false,
    },
  });

  const handleMapClick = () => {
    if (!data?.mapUrl) return;
    window.open(data.mapUrl, '_blank');
  };

  const { ticketNumber } = useParams();

  return (
    <>
      <BookingSplashScreen isStart={isSuccess || isError} />

      <Box className={classes.root}>
        <Box className={classes.inner}>
          <img
            className={classes.image}
            src="images/booking/cash-success.png"
            alt="success"
          />
          <Typography className={classes.txtTicketNumber}>
            Your Ticket Number Is:
            <Typography component="span">{ticketNumber}</Typography>
          </Typography>
          <Typography className={classes.txtNote}>
            Rides will only be confirmed after payment is made. Please present
            this screen to our friendly staff at the booths for payment.
          </Typography>

          <Box className={classes.buttonContainer}>
            <DefaultButton
              className={classes.mapButton}
              startIcon={<MapOutlinedIcon />}
              name="Map"
              onClick={handleMapClick}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default BookingCashSuccessScreen;
