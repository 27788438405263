import { Link } from '@mui/icons-material';
import { Breadcrumbs, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import copy from 'copy-to-clipboard';
import { default as React, memo } from 'react';
import { useNotify, useTranslate } from 'react-admin';
import { NavLink } from 'react-router-dom';
import { useMainLayoutContext } from '../../../../../base/context/main-layout';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 20,
    lineHeight: 1.2,
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
      '&.small': { fontSize: '12px' },
    },
  },
  textSm: {
    fontSize: 13,
    maxWidth: '160px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    [theme.breakpoints.down('md')]: {
      maxWidth: '120px',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '80px',
    },
    color: 'rgb(104, 113, 120)',
    cursor: 'copy',
  },
  linkBlock: {
    display: 'block',
    color: (props) => props.sidebarContentTextColor ?? 'rgb(31, 39, 46)',
    cursor: 'pointer',
  },
  label: {
    color: (props) =>
      props.sidebarContentTextColor ?? theme.palette.sidebar.textColor,
    [theme.breakpoints.down('sm')]: {
      fontSize: '10px',
      lineHeight: 1.2,
    },
    display: 'flex',
    gap: '4px',
    alignItems: 'center',
    '& svg': { display: 'none' },
    '&:hover': { cursor: 'pointer', '& svg': { display: 'block' } },
  },
  breadCrumbs: {
    display: 'flex',
    height: '36px',
    textShadow: '1.2px 0.1px #808080',
    '& .MuiBreadcrumbs-separator': {
      color: (props) => props.sidebarContentTextColor ?? 'rgb(31, 39, 46)',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '118px',
      '& li:has(p)': { alignSelf: 'unset' },
      '& .MuiBreadcrumbs-separator': { fontSize: '10px', paddingBottom: '2px' },
    },
    [theme.breakpoints.down('md')]: {
      '&.indrawer': {
        maxWidth: '156px',
      },
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '186px',
    },
    '&:has(.long-label)': {
      '& .MuiBreadcrumbs-ol': { width: '100%' },
      '& .MuiBreadcrumbs-li': { width: '100%' },
      '& .MuiBreadcrumbs-separator': { display: 'none' },
      '& .MuiBreadcrumbs-li a': {
        width: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    },
  },
  textFiledHidden: {
    width: 0,
    height: 0,
    position: 'absolute',
    left: 0,
  },
}));

const PageBreadcrumbs = ({ items, indrawer }) => {
  const { sidebarContentTextColor } = useMainLayoutContext();
  const classes = useStyles({ sidebarContentTextColor });
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only('xs'));
  const sm = useMediaQuery(theme.breakpoints.only('sm'));
  const md = useMediaQuery(theme.breakpoints.only('md'));
  const totalMiddleItems = xs ? 0 : sm ? 1 : md ? 2 : 10;
  const { origin } = window.location;
  const notify = useNotify();
  const translate = useTranslate();

  const handleCopyLink = (e) => {
    const locationLink = e.currentTarget.dataset.link;
    copy(locationLink);
    notify(translate('nf.notice.copied'), { type: 'success' });
  };

  return (
    <Breadcrumbs
      className={clsx(
        'bread-crumbs',
        classes.breadCrumbs,
        indrawer && 'indrawer',
      )}
      aria-label="breadcrumb"
    >
      {items.map((item, index) => {
        const { length } = items;
        const label = item.label;

        if (index > 0 && index < length - 1 - totalMiddleItems) {
          if (index === 1) {
            return (
              <Typography
                key={index}
                className={classes.textSm}
                color="textPrimary"
              >
                ...
              </Typography>
            );
          }
          return null;
        }

        const headingProps =
          index === 0
            ? {
                className: classes.title,
                component: 'h3',
              }
            : { className: classes.textSm };

        return item.isActive || !item.link ? (
          <Tooltip key={item.label + index} title={'Copy link'} placement="top">
            <Typography
              color="textPrimary"
              {...headingProps}
              onClick={handleCopyLink}
              className={classes.label}
              data-link={`${origin}/#/${item.sourceName}/${item.recordId}/show`}
            >
              {label}
              <Link />
            </Typography>
          </Tooltip>
        ) : (
          <Tooltip key={item.label + index} title={item.label} placement="top">
            <NavLink
              className={clsx(
                classes.linkBlock,
                index === 0 ? classes.title : classes.textSm,
                label.length > 12 && 'long-label',
                (label.length > 12 || items.length > 1) && 'small',
              )}
              color="inherit"
              to={item.link || '/'}
            >
              {label}
            </NavLink>
          </Tooltip>
        );
      })}
    </Breadcrumbs>
  );
};

export default memo(PageBreadcrumbs);
