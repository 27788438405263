import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  '@global': {
    '& .pac-container': {
      width: `calc(100vw - ${theme.spacing(8)}) !important`,
      left: `${theme.spacing(4)} !important`,
    },
  },
  input: {
    paddingRight: 0,
    border: '1px solid #CBD5E1',
    borderRadius: 20,
    height: 46,
  },
  inner: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
  },
  locationInputContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(1.5),
    flex: 0.65,

    '& > svg': {
      width: 34,
      height: 24,
    },

    '& > div': {
      lineHeight: 1,
      width: '100%',
      paddingRight: '4px',
    },

    '& .MuiInputBase-root': {
      backgroundColor: 'transparent',
      minHeight: 'auto',
    },

    '& input': {
      backgroundColor: 'transparent',
      padding: '0 !important',
      fontSize: 14,
      fontFamily: 'QuickSandSemiBold',
    },

    '& .Mui-focused fieldset,& .Mui-focused': {
      borderColor: 'transparent !important',
      outlined: 'none',
    },
    '& .Mui-focused fieldset': {
      display: 'none',
    },
  },
  inputContainerFullWidth: {
    flex: 1,
    paddingRight: theme.spacing(2),
    '& > div': {
      width: '100%',
    },
  },

  timeInputContainer: {
    height: 46,
    position: 'relative',
    top: -1,
    flex: 0.35,

    border: '1px solid #CBD5E1',
    borderRight: 'none',
    borderRadius: 20,
    overflow: 'hidden',
  },
  dateButton: {
    width: '100%',
    height: '100%',
    margin: 0,
    fontSize: 14,

    color: '#686868',
    justifyContent: 'flex-start',
    boxShadow: 'none',
    padding: theme.spacing(0, 2),

    '& > .MuiButton-startIcon': {
      marginRight: theme.spacing(2),
      color: '#171725',
    },
  },
  timeButton: {
    width: '100%',
    height: '100%',
    fontSize: 14,
    fontFamily: 'QuickSandSemiBold',
    color: '#686868',

    margin: 0,
    padding: theme.spacing(0, 4),
    boxShadow: 'none',
    justifyContent: 'space-between',

    '& > .MuiButton-startIcon': {
      marginRight: theme.spacing(2),
      color: '#171725',
    },
    '& > .MuiButton-endIcon': {
      marginRight: theme.spacing(2),
      color: '#92929D',
    },
  },

  locationInputPlaceholder: {
    display: 'none',
  },

  fullDate: {
    fontSize: 12,
  },

  selectionLabel: {
    height: '100%',
    width: '100%',
    padding: theme.spacing(0, 1),
    marginLeft: theme.spacing(-1),
    color: '#686868',
    justifyContent: 'flex-start',
  },
}));

export default useStyles;
